import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from 'components/modal/Modal.css';

const SidebarItem = ({ isActive, title, avatarUrl, numSelected, onClick }) => (
  <li className={cx(isActive && styles.active)} onClick={onClick}>
    <div className={styles.creatorPreview}>
      <img src={avatarUrl} alt={title} />
      <span>{title}</span>
    </div>
    {numSelected > 0 && <span className={styles.episodeCountPill}>{numSelected}</span>}
  </li>
);

SidebarItem.defaultProps = {
  isActive: false,
};

SidebarItem.propTypes = {
  isActive: PropTypes.bool,
  title: PropTypes.string.isRequired,
  avatarUrl: PropTypes.string.isRequired,
  numSelected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default SidebarItem;
