// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.styles_selectContents___174Yy7b6Gg {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.styles_selectContents___174Yy7b6Gg .styles_metadataContainer___3nALD-uWM2 {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    grid-gap: 10px;\n    gap: 10px;\n  }\n\n.styles_selectContents___174Yy7b6Gg .styles_metadataContainer___3nALD-uWM2 .styles_thumbnail___lff1bZQjdS {\n      width: 40px;\n      height: 40px;\n      background-size: cover;\n      background-position: center;\n      border-radius: 6px;\n    }\n\n.styles_selectContents___174Yy7b6Gg .styles_metadataContainer___3nALD-uWM2 .styles_creatorAvatar___Cc5sAc8hoS {\n      height: 24px;\n      width: 24px;\n      border-radius: 6px;\n    }\n\n.styles_selectContents___174Yy7b6Gg .styles_metadataContainer___3nALD-uWM2 .styles_labelTitle___3y-1Cui_zP {\n      margin: 0;\n      color: #000000;\n      font-weight: 500;\n      font-size: 16px;\n      line-height: 18px;\n    }\n\n.styles_selectContents___174Yy7b6Gg .styles_labelSubtitle___29wjMnBP1h {\n    margin: 4px 0 0;\n    color: rgba(0, 0, 0, 0.6);\n    font-weight: 500;\n    font-size: 14px;\n    line-height: 16px;\n  }\n", ""]);
// Exports
exports.locals = {
	"selectContents": "styles_selectContents___174Yy7b6Gg",
	"metadataContainer": "styles_metadataContainer___3nALD-uWM2",
	"thumbnail": "styles_thumbnail___lff1bZQjdS",
	"creatorAvatar": "styles_creatorAvatar___Cc5sAc8hoS",
	"labelTitle": "styles_labelTitle___3y-1Cui_zP",
	"labelSubtitle": "styles_labelSubtitle___29wjMnBP1h"
};
module.exports = exports;
