// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.CollectionStat_statWrapper___145q1010Hy {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  grid-gap: 8px;\n  gap: 8px;\n}\n\n.CollectionStat_statWrapper___145q1010Hy p {\n    margin: 0;\n  }\n\n.CollectionStat_statWrapper___145q1010Hy.CollectionStat_creatorsStat___mFD2_60N32 .CollectionStat_avatarGroup___3CsA4cTVx7 {\n      display: flex;\n      flex-direction: row-reverse;\n    }\n\n.CollectionStat_statWrapper___145q1010Hy.CollectionStat_creatorsStat___mFD2_60N32 .CollectionStat_avatarGroup___3CsA4cTVx7 .CollectionStat_avatar___3YzVsTj4oO {\n        width: 20px;\n        height: 20px;\n        border: 1px solid #ffffff;\n        border-radius: 50%;\n      }\n", ""]);
// Exports
exports.locals = {
	"statWrapper": "CollectionStat_statWrapper___145q1010Hy",
	"creatorsStat": "CollectionStat_creatorsStat___mFD2_60N32",
	"avatarGroup": "CollectionStat_avatarGroup___3CsA4cTVx7",
	"avatar": "CollectionStat_avatar___3YzVsTj4oO"
};
module.exports = exports;
