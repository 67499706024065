import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { NotificationContainer } from 'react-notifications';
import 'babel-polyfill';
// import * as Sentry from '@sentry/react';

import 'index.css';

import Routes from 'routes';

// Sentry.init({ dsn: 'https://fb7389dc5413484795cf5a8aaf76f69b@o427795.ingest.sentry.io/5776447' });
//
// ReactDOM.render(
//   <StrictMode>
//     <Sentry.ErrorBoundary fallback="An error has occurred">
//       <Routes />
//       <NotificationContainer />
//     </Sentry.ErrorBoundary>
//   </StrictMode>,
//   document.getElementById('root'),
// );

ReactDOM.render(
  <StrictMode>
    <Routes />
    <NotificationContainer />
  </StrictMode>,
  document.getElementById('root'),
);

// For navbar/modals https://css-tricks.com/prevent-page-scrolling-when-a-modal-is-open (See also: utils.js)
window.addEventListener('scroll', () => {
  document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
});
