import { NotificationManager } from 'react-notifications';
import request from 'services/request/requestService';
import * as storeService from 'services/authentication/storeService';
import {
  LOCAL_STORAGE_LAST_VISITED_CREATOR_ID_KEY,
  LOCAL_STORAGE_URL_AFTER_AUTH_KEY,
  LOCAL_STORAGE_KEY,
} from 'services/authentication/constants';
import { history, INVITE_STATUS } from 'utils';

export async function getInvite({ inviteId, creatorId }) {
  const method = 'GET';

  // WHAT THE FUCK?!
  // https://hellosaurus.slack.com/archives/C01811G46G2/p1658755056376479
  // https://hellosaurus.slack.com/archives/C03GVRF8069/p1658759407621109
  if (creatorId === 'dy8477c7233dx4geh6c8ebybl') {
    return request(`/creators/cl5144p4900qk1tru3p5daloy/invitations/${inviteId}`, { method });
  }

  return request(`/creators/${creatorId}/invitations/${inviteId}`, { method });
}

export async function activateInvite(invite) {
  const { inviteChannelId, inviteId } = invite;
  await request(`/creators/${inviteChannelId}/invitations/${inviteId}/user/me/status`, {
    method: 'PUT',
    body: JSON.stringify({ status: INVITE_STATUS.ACTIVE }),
  });
}

export async function login(params, options = {}) {
  const method = 'POST';
  const body = JSON.stringify(params);

  const state = await request('/login', { method, body });
  storeService.setPersistedState(state);

  if (options.invitation) {
    await activateInvite(options.invitation);
  }

  history.push(localStorage.getItem(LOCAL_STORAGE_URL_AFTER_AUTH_KEY) || '/');
}

export async function signup(params, options = {}) {
  const method = 'POST';
  const body = JSON.stringify({
    ...params,
    confirmPassword: params.password,
    hasAcceptedMarketing: false,
  });

  const state = await request('/register', { method, body });
  storeService.setPersistedState(state);

  if (options.invitation) {
    await activateInvite(options.invitation);
  }

  history.push('/');
}

export async function forgotPassword(params) {
  const method = 'POST';
  const body = JSON.stringify(params);

  const response = await request('/password-reset', { method, body });
  NotificationManager.success(response.message);
}

export async function resetPassword(params) {
  const method = 'PUT';
  const body = JSON.stringify({ ...params, token: undefined });

  const state = await request(`/password-reset/${params.token}`, { method, body });
  storeService.setPersistedState(state);
  history.push('/');
}

export async function promoteUser(userId, accountStatusCode) {
  const method = 'PUT';
  const body = JSON.stringify({
    accountStatusCode,
  });

  return request(`/users/${userId}`, { method, body });
}

export async function updateAccountInfo(params) {
  const method = 'PUT';
  const body = JSON.stringify(params);

  const response = await request('/users/me', { method, body });
  storeService.setMe(response);
  return response;
}

export function isAuthenticated() {
  const accessToken = storeService.getAccessToken();
  return Boolean(accessToken);
}

export function logout() {
  localStorage.removeItem(LOCAL_STORAGE_LAST_VISITED_CREATOR_ID_KEY);
  localStorage.removeItem(LOCAL_STORAGE_URL_AFTER_AUTH_KEY);
  localStorage.removeItem(LOCAL_STORAGE_KEY);
}

export function getUsers(url = '/users') {
  const method = 'GET';
  return request(url, { method });
}
export function getUser(userId) {
  const method = 'GET';
  return request(`/users/${userId}`, { method });
}
