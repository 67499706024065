import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/modal/Modal';
import { CMSButton, CMSWhiteButton } from 'components/button/Button';
import CreatorBanner from 'components/modal/components/CreatorBanner';

const FormModal = ({
  creatorId,
  formNode,
  title,
  isOpen,
  onRequestClose,
  primaryFooterBtnLabel,
  formProps,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const formRef = useRef();
  const Form = formNode;
  const isCreatorSpecificForm = Boolean(creatorId);

  const footerButtons = [
    <CMSWhiteButton label="Cancel" onClick={onRequestClose} isDisabled={isLoading} />,
    <CMSButton
      label={primaryFooterBtnLabel}
      onClick={async () => {
        await formRef.current.submit();
        onRequestClose();
      }}
      isDisabled={isLoading || !isValid}
    />,
  ];

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      footerButtons={footerButtons}
      onRequestClose={onRequestClose}
      {...rest}
    >
      <>
        {isCreatorSpecificForm && <CreatorBanner creatorId={creatorId} />}
        <Form
          ref={formRef}
          isNew
          hideButtons
          {...formProps}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          setIsValid={setIsValid}
        />
      </>
    </Modal>
  );
};

FormModal.defaultProps = {
  creatorId: null,
  isOpen: false,
  formProps: {},
  primaryFooterBtnLabel: 'Create',
};

FormModal.propTypes = {
  formNode: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  creatorId: PropTypes.string,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  primaryFooterBtnLabel: PropTypes.string,
  formProps: PropTypes.object,
};

export default FormModal;
