import PropTypes from 'prop-types';
import CheckboxInput from 'components/input/CheckboxInput';
import TrimInput from 'components/input/TrimInput';
import RangeInput from 'components/input/RangeInput';
import { AudioUploadInput } from 'components/file-upload/FileUploadInput';

const addTimingParamsToUrl = (assetUrl, startMilliseconds, endMilliseconds) => {
  const url = new URL(assetUrl);
  url.searchParams.set('startMilliseconds', startMilliseconds);
  url.searchParams.set('endMilliseconds', endMilliseconds);
  // @see https://hellosaurus.slack.com/archives/C012GNW4BT3/p1604414773007200
  url.searchParams.set('unityHack', '.mp3');
  return url.href;
};

const getTimingsFromUrlParams = (assetUrl) => {
  const url = new URL(assetUrl);

  return {
    startMilliseconds: Number(url.searchParams.get('startMilliseconds')),
    endMilliseconds: Number(url.searchParams.get('endMilliseconds')),
  };
};

const AudioConfig = ({ label, hideLabel, value, onChange, allowLooping }) => {
  const audioUri = value?.audioUri;
  const audioFile = value?.audioFile;
  const originalMilliseconds = value?.audioFile?.originalMilliseconds;

  let startMilliseconds;
  let endMilliseconds;
  if (audioFile?.awsPublicUrl) {
    const timings = getTimingsFromUrlParams(audioFile?.awsPublicUrl);
    startMilliseconds = timings.startMilliseconds;
    endMilliseconds = timings.endMilliseconds;
  }

  const shouldShowAudioControls = audioUri && originalMilliseconds;
  const shouldCalculateOriginalMilliseconds = !originalMilliseconds;

  return (
    <>
      {shouldCalculateOriginalMilliseconds && (
        <audio
          src={audioUri}
          preload="metadata"
          onLoadedMetadata={(e) =>
            onChange({
              ...value,
              audioFile: {
                ...audioFile,
                originalMilliseconds: Math.floor(e.currentTarget.duration * 1000),
              },
            })
          }
        />
      )}
      <AudioUploadInput
        label={label}
        hideLabel={hideLabel}
        onChange={(audioFile) =>
          onChange({ ...value, audioFile, audioUri: audioFile?.awsPublicUrl })
        }
        value={value?.audioFile}
      />

      {shouldShowAudioControls && (
        <>
          <TrimInput
            originalMilliseconds={originalMilliseconds}
            startMilliseconds={startMilliseconds || 0}
            endMilliseconds={endMilliseconds || originalMilliseconds}
            onChange={([startMilliseconds, endMilliseconds]) => {
              onChange({
                ...value,
                audioFile: {
                  ...audioFile,
                  awsPublicUrl: addTimingParamsToUrl(
                    audioFile.awsPublicUrl,
                    startMilliseconds,
                    endMilliseconds,
                  ),
                },
              });
            }}
          />
          <RangeInput
            label="Volume"
            value={value?.volume ?? '0.5'}
            onChange={(volume) => onChange({ ...value, volume })}
          />
          {allowLooping && (
            <CheckboxInput
              label="Play on Loop"
              description="Audio will repeat until doit closes."
              onChange={(loopAudio) => onChange({ ...value, loopAudio })}
              value={value?.loopAudio}
            />
          )}
        </>
      )}
    </>
  );
};

AudioConfig.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  allowLooping: PropTypes.bool,
  hideLabel: PropTypes.bool,
};

AudioConfig.defaultProps = {
  hideLabel: false,
  allowLooping: false,
};

export default AudioConfig;
