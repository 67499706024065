import SequenceItForm from 'components/do-it-forms/forms/SequenceIt';
import FindItForm from 'components/do-it-forms/forms/FindIt';
import SoundboardItForm from 'components/do-it-forms/forms/SoundboardIt';
import StampItForm from 'components/do-it-forms/forms/StampIt';
import PutItForm from 'components/do-it-forms/forms/PutIt';
import SurveyItForm from 'components/do-it-forms/forms/SurveyIt';
import TriviaItForm from 'components/do-it-forms/forms/TriviaIt';
import DrawItForm from 'components/do-it-forms/forms/DrawIt';
import SortItForm from 'components/do-it-forms/forms/SortIt';
import CaptureItForm from 'components/do-it-forms/forms/CaptureIt';
import BuildItForm from 'components/do-it-forms/forms/BuildIt';
import AssembleItForm from 'components/do-it-forms/forms/AssembleIt';
import WipeItForm from 'components/do-it-forms/forms/WipeIt';
import SayItForm from 'components/do-it-forms/forms/SayIt';
import CollageItForm from 'components/do-it-forms/forms/CollageIt';

import imageAutoLayout from 'assets/images/doits/auto.png';
import imageFreeformLayout from 'assets/images/doits/freeform.png';

export const SUPPORTED_DO_ITS = [
  {
    baseTemplateId: 'sequence-it-template',
    displayName: 'Sequence It',
    Component: SequenceItForm,
    hexColor: '#8C19FF',
  },
  {
    baseTemplateId: 'soundboard-it-template',
    displayName: 'Soundboard It',
    Component: SoundboardItForm,
    hexColor: '#39C3EF',
    variants: [
      {
        variantId: 'auto-layout',
        variantName: 'Auto Layout',
        variantIcon: imageAutoLayout,
      },
      {
        variantId: 'freeform',
        variantName: 'Freeform',
        variantIcon: imageFreeformLayout,
      },
    ],
  },
  {
    baseTemplateId: 'survey-it-template',
    displayName: 'Survey It',
    Component: SurveyItForm,
    hexColor: '#D6B716',
    variants: [
      {
        variantId: 'auto-layout',
        variantName: 'Auto Layout',
        variantIcon: imageAutoLayout,
      },
      {
        variantId: 'freeform',
        variantName: 'Freeform',
        variantIcon: imageFreeformLayout,
      },
    ],
  },
  {
    baseTemplateId: 'trivia-it-template',
    displayName: 'Trivia It',
    Component: TriviaItForm,
    hexColor: '#4C3CFF',
    variants: [
      {
        variantId: 'auto-layout',
        variantName: 'Auto Layout',
        variantIcon: imageAutoLayout,
      },
      {
        variantId: 'freeform',
        variantName: 'Freeform',
        variantIcon: imageFreeformLayout,
      },
    ],
  },
  {
    baseTemplateId: 'find-it-template',
    displayName: 'Find It',
    Component: FindItForm,
    hexColor: '#FF7373',
  },
  {
    baseTemplateId: 'stamp-it-template',
    displayName: 'Stamp It',
    Component: StampItForm,
    hexColor: '#41D33E',
  },
  {
    baseTemplateId: 'put-it-template',
    displayName: 'Put It',
    Component: PutItForm,
    hexColor: '#FF62C0',
  },
  {
    baseTemplateId: 'draw-it-template',
    displayName: 'Draw It',
    Component: DrawItForm,
    hexColor: '#EE8D34',
  },
  {
    baseTemplateId: 'sort-it-template',
    displayName: 'Sort It',
    Component: SortItForm,
    hexColor: '#308FFF',
  },
  {
    baseTemplateId: 'capture-it-template',
    displayName: 'Capture It',
    Component: CaptureItForm,
    hexColor: '#B655CE',
  },
  {
    baseTemplateId: 'build-it-template',
    displayName: 'Build It',
    Component: BuildItForm,
    hexColor: '#15BBA7',
  },
  {
    baseTemplateId: 'assemble-it-template',
    displayName: 'Assemble It',
    Component: AssembleItForm,
    hexColor: '#9FB900',
  },
  {
    baseTemplateId: 'wipe-it-template',
    displayName: 'Wipe It',
    Component: WipeItForm,
    hexColor: '#9BAEBF',
  },
  {
    baseTemplateId: 'say-it-template',
    displayName: 'Say It',
    Component: SayItForm,
    hexColor: '#E05B31',
  },
  {
    baseTemplateId: 'collage-it-template',
    displayName: 'Collage It',
    Component: CollageItForm,
    hexColor: '#F5075D',
  },
];

export const MAX_CONVERT_FILE_TO_URI_DEPTH = 3;
export const UNSUPPORTED_DO_IT = { hexColor: '#34495e', displayName: 'Unsupported' };
