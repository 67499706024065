// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.NotFoundPage_not-found-page___1adv4M4xJ0 {\n  text-align: center;\n}\n\n.NotFoundPage_not-found-page___1adv4M4xJ0 img {\n    margin: 2em 0 0;\n  }\n\n.NotFoundPage_not-found-page___1adv4M4xJ0 h1 {\n    font-size: 50px;\n    line-height: 58px;\n    margin-bottom: 0.1em;\n  }\n\n.NotFoundPage_not-found-page___1adv4M4xJ0 p {\n    font-size: 22px;\n    line-height: 120%;\n    margin-bottom: 1.5em;\n    color: #ae5eeb;\n  }\n\n.NotFoundPage_not-found-page___1adv4M4xJ0 a {\n    max-width: 300px;\n    margin: auto;\n    text-align: center;\n  }\n\n.NotFoundPage_not-found-page___1adv4M4xJ0 a span {\n      width: 100%;\n    }\n", ""]);
// Exports
exports.locals = {
	"not-found-page": "NotFoundPage_not-found-page___1adv4M4xJ0",
	"notFoundPage": "NotFoundPage_not-found-page___1adv4M4xJ0"
};
module.exports = exports;
