import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import NavBar from 'components/navbar/NavBar';

const PageLayout = ({ title, children }) => (
  <div>
    <Helmet>
      <title>{title || 'Loading...'} :: Hellosaurus CMS</title>
    </Helmet>
    <NavBar />
    {children}
  </div>
);

PageLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PageLayout;
