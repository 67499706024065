import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cuid from 'cuid';

import * as styles from 'components/episode-edit-panel/EpisodeEditBody.css';

import Timeline from 'components/timeline/Timeline';
import Unity from 'components/unity/Unity';
import unityUtils from 'components/unity/utils';
import {
  DEFAULT_DO_IT_DURATION_SECS,
  DEFAULT_DO_IT_SETTINGS_OBJECT,
  DEFAULT_PARAMS_BY_TEMPLATE,
} from 'components/episode-edit-panel/constants';
import { SUPPORTED_DO_ITS } from 'components/do-it-forms/constants';

class EpisodeEditBody extends PureComponent {
  static propTypes = {
    onEpisodeConfigJsonChange: PropTypes.func.isRequired,
    episodeId: PropTypes.string.isRequired,
    videoUrl: PropTypes.string.isRequired,
    videoDurationSecs: PropTypes.number.isRequired,
    episodeDurationSecs: PropTypes.number.isRequired,
    doits: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    onDoitChange: PropTypes.func.isRequired,
    onDoitDelete: PropTypes.func.isRequired,
    onSaveEpisodeConfigJson: PropTypes.func.isRequired,
    episodeTitle: PropTypes.string.isRequired,
  };

  state = {
    playerCurrentTime: 0,
    viewingItemId: null,
    hideTimeline: false,
  };

  componentDidMount() {
    unityUtils.onMediaLibraryOpen(this.handleMediaLibraryOpen);
    unityUtils.onMediaLibraryClose(this.handleMediaLibraryClose);
    unityUtils.onUnityItemIdChange(this.handleViewingItemIdChange);
    unityUtils.onUnityVideoTimeChange((time) => this.setState({ playerCurrentTime: time }));
    unityUtils.onInsertActivity(this.handleInsertActivity);
    unityUtils.onInsertLibraryActivity(this.insertLibraryActivity);
    unityUtils.updateEpisodeConfigJson((config) => {
      const parsedConfig = JSON.parse(config);
      this.props.onEpisodeConfigJsonChange(parsedConfig);
      window.EPISODE_CONFIG_FOR_UNITY = parsedConfig;
    });
    unityUtils.saveEpisodeConfigJson((config) => {
      const parsedConfig = JSON.parse(config);
      this.props.onSaveEpisodeConfigJson(parsedConfig);
    });
  }

  getDefaultStartEnd = () => {
    const { doits, episodeDurationSecs } = this.props;
    const { playerCurrentTime } = this.state;

    let startSecsWithEpisodeOffset = Number(playerCurrentTime.toFixed(2));

    const overlappingStartDoit = doits.find(
      (doit) =>
        doit.startSecsWithEpisodeOffset <= startSecsWithEpisodeOffset &&
        startSecsWithEpisodeOffset <= doit.finishSecsWithEpisodeOffset,
    );

    if (overlappingStartDoit) {
      startSecsWithEpisodeOffset = overlappingStartDoit.finishSecsWithEpisodeOffset + 0.01;
    }

    let finishSecsWithEpisodeOffset = Math.min(
      startSecsWithEpisodeOffset + DEFAULT_DO_IT_DURATION_SECS,
      episodeDurationSecs,
    );

    const overlappingFinishDoit = doits.find(
      (doit) =>
        doit.startSecsWithEpisodeOffset <= finishSecsWithEpisodeOffset &&
        finishSecsWithEpisodeOffset <= doit.finishSecsWithEpisodeOffset,
    );

    if (overlappingFinishDoit) {
      finishSecsWithEpisodeOffset = overlappingFinishDoit.startSecsWithEpisodeOffset - 0.01;
    }

    const containingDoit = doits.find(
      (doit) =>
        startSecsWithEpisodeOffset <= doit.startSecsWithEpisodeOffset &&
        finishSecsWithEpisodeOffset >= doit.finishSecsWithEpisodeOffset,
    );

    if (containingDoit) {
      finishSecsWithEpisodeOffset = containingDoit.startSecsWithEpisodeOffset - 0.01;
    }

    return {
      startSecsWithEpisodeOffset,
      finishSecsWithEpisodeOffset,
    };
  };

  getVideoTimesFromEpisodeTimes = (doit) => {
    let episodeOffsetTime = 0;
    const doits = [...this.props.doits];

    const existingDoitIndex = doits.findIndex((d) => d.itemId === doit.itemId);

    if (existingDoitIndex > -1) {
      doits[existingDoitIndex] = { ...doit };
    } else {
      doits.push(doit);
    }

    const sortedDoits = doits.sort(
      (a, b) => a.startSecsWithEpisodeOffset - b.startSecsWithEpisodeOffset,
    );

    /* eslint-disable-next-line no-restricted-syntax */
    for (const sortedDoit of sortedDoits) {
      if (sortedDoit.itemId === doit.itemId) {
        break;
      }

      if (sortedDoit.pauseVideoUntilComplete) {
        episodeOffsetTime += sortedDoit.maxDuration;
      }
    }

    const startSecs = doit.startSecsWithEpisodeOffset - episodeOffsetTime;
    const finishSecs = doit.finishSecsWithEpisodeOffset - episodeOffsetTime;

    return {
      startSecs,
      finishSecs,
    };
  };

  handleInsertActivity = (templateId) => {
    // NOTE: this is silly, but I'm putting it here for now and
    // we can fix it if we actually maintain this software again.
    const { displayName } = SUPPORTED_DO_ITS.find((doit) => doit.baseTemplateId === templateId);

    const newDoit = {
      itemId: cuid(),
      baseTemplateId: templateId,
      pauseVideoUntilComplete: false,
      linkedDoItId: null,
      params: DEFAULT_PARAMS_BY_TEMPLATE[templateId],
      settings: DEFAULT_DO_IT_SETTINGS_OBJECT,
      name: displayName,
      ...this.getDefaultStartEnd(),
    };

    this.props.onDoitChange({
      ...newDoit,
      ...this.getVideoTimesFromEpisodeTimes(newDoit),
      maxDuration: newDoit.finishSecsWithEpisodeOffset - newDoit.startSecsWithEpisodeOffset,
    });
  };

  insertLibraryActivity = (stringifiedDoit) => {
    const doit = JSON.parse(stringifiedDoit);
    Object.assign(doit, { itemId: cuid(), ...this.getDefaultStartEnd() });
    Object.assign(doit, { ...this.getVideoTimesFromEpisodeTimes(doit) });

    this.props.onDoitChange(
      Object.assign(doit, {
        maxDuration: doit.finishSecsWithEpisodeOffset - doit.startSecsWithEpisodeOffset,
      }),
    );
  };

  handleTimelineClick(playerCurrentTime) {
    unityUtils.seek(playerCurrentTime);
  }

  handleViewingItemIdChange = (viewingItemId) => {
    this.setState({ viewingItemId });
  };

  handleMediaLibraryOpen = () => {
    this.setState({ hideTimeline: true });
  };

  handleMediaLibraryClose = () => {
    this.setState({ hideTimeline: false });
  };

  render() {
    const {
      videoUrl,
      doits,
      episodeId,
      onDoitChange,
      episodeDurationSecs,
      videoDurationSecs,
      episodeTitle,
      onDoitDelete,
    } = this.props;
    const { playerCurrentTime, viewingItemId, hideTimeline } = this.state;

    return (
      <div className={`${styles.episodeEditBody} ${styles.new}`}>
        <div className={styles.episodeEditBodyPreview}>
          <div className={styles.episodeEditBodyPreviewVideoContainer}>
            <Unity
              newUnityBuild
              episodePayload={{
                episodeId,
                title: episodeTitle,
                doits,
                videoUrl,
                durationSecs: videoDurationSecs,
              }}
            />
          </div>
          <Timeline
            videoUrl={videoUrl}
            doits={doits}
            episodeDurationSecs={episodeDurationSecs}
            onDoitChange={onDoitChange}
            onDoitDelete={onDoitDelete}
            playerCurrentTime={playerCurrentTime}
            onPlayerCurrentTimeChange={(playerCurrentTime) =>
              this.handleTimelineClick(playerCurrentTime)
            }
            viewingItemId={viewingItemId}
            hideTimeline={hideTimeline}
            newUnityBuild
          />
        </div>
      </div>
    );
  }
}

export default EpisodeEditBody;
