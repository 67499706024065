import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as adminService from 'services/content/adminService';
import * as contentService from 'services/content/contentService';

import {
  PUBLISH_STATUSES,
  pluralize,
  getTopicTileBackgroundStyles,
  getBackgroundImageGradientStyles,
} from 'utils';
import {
  ADMIN_CREATOR_SIDEBAR_ITEM_ID,
  CREATOR_COLLECTION_KEY,
  MULTI_CREATOR_COLLECTION_KEY,
} from 'components/modal/constants';
import { FullHeightModal } from 'components/modal/Modal';
import CreatorsSidebar from 'components/modal/components/CreatorSidebar';
import SelectableCard from 'components/modal/components/SelectableCard';
import { CMSButton, CMSTextButton, CMSWhiteButton } from 'components/button/Button';

import styles from 'components/modal/Modal.css';

export const BaseSelectionModal = ({
  title,
  data,
  initialSelectedData,
  isOpen,
  onClose,
  onSubmit,
  comparisonFn,
  transformFn,
  EmptyState,
  sidebarRenderer,
  selectionCardRenderer,
}) => {
  const [selected, setSelected] = useState(initialSelectedData);
  const numSelected = selected.length;
  const hasSidebar = typeof sidebarRenderer === 'function';

  useEffect(() => {
    if (!isOpen) {
      setSelected(initialSelectedData);
    }
  }, [isOpen, initialSelectedData]);

  const toggleSelection = (datum) => {
    const foundDatumIndex = selected.findIndex((selection) => comparisonFn(selection, datum));

    if (foundDatumIndex !== -1) {
      const selectedCopy = [...selected];
      selectedCopy.splice(foundDatumIndex, 1);
      setSelected(selectedCopy);
    } else {
      setSelected([...selected, datum]);
    }
  };

  const clearSelected = () => setSelected([]);

  const handleSubmit = () => {
    const transformedData = selected.map(transformFn);
    onSubmit(transformedData);
  };

  const footerButtons = [
    <CMSWhiteButton label="Cancel" onClick={onClose} />,
    <CMSButton label="Add to Bundle" onClick={handleSubmit} />,
  ];

  return (
    <FullHeightModal
      title={`Add to "${title}"`}
      footerButtons={footerButtons}
      isOpen={isOpen}
      onRequestClose={onClose}
      className={cx(styles.modal, hasSidebar && styles.withSidebar)}
    >
      {hasSidebar && sidebarRenderer({ selected })}
      <div className={styles.episodeSelectionModal}>
        <div className={styles.listHeader}>
          <div className={styles.actionsContainer}>
            <p className={styles.numSelected}>{numSelected} Selected</p>
            <CMSTextButton label="Clear" onClick={clearSelected} />
          </div>
        </div>
        <div className={styles.episodeCardList}>
          {/* eslint-disable no-nested-ternary */}
          {data.length > 0
            ? data.map((datum, index, data) => {
                const isSelected = selected.some((selection) => comparisonFn(selection, datum));
                return selectionCardRenderer({ datum, index, data, isSelected, toggleSelection });
              })
            : EmptyState}
        </div>
      </div>
    </FullHeightModal>
  );
};

BaseSelectionModal.defaultProps = {
  EmptyState: null,
  sidebarRenderer: null,
};

BaseSelectionModal.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  initialSelectedData: PropTypes.arrayOf(PropTypes.object).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  comparisonFn: PropTypes.func.isRequired,
  transformFn: PropTypes.func.isRequired,
  EmptyState: PropTypes.node,
  sidebarRenderer: PropTypes.func,
  selectionCardRenderer: PropTypes.func.isRequired,
};

export const CollectionSelectionModal = ({ bundle, selectedData, isOpen, onClose, onSubmit }) => {
  const [activeCreatorId, setActiveCreatorId] = useState(ADMIN_CREATOR_SIDEBAR_ITEM_ID);
  const [filteredCollections, setFilteredCollections] = useState([]);

  useEffect(() => setActiveCreatorId(ADMIN_CREATOR_SIDEBAR_ITEM_ID), [isOpen]);

  useEffect(() => {
    const fetchCollectionsByCreatorId = async (creatorId = ADMIN_CREATOR_SIDEBAR_ITEM_ID) => {
      let collections = [];

      if (creatorId === ADMIN_CREATOR_SIDEBAR_ITEM_ID) {
        collections = await adminService.getCollections();
      } else {
        collections = await contentService.getCollections({ creatorId });
      }

      setFilteredCollections(collections);
    };
    fetchCollectionsByCreatorId(activeCreatorId);
  }, [activeCreatorId]);

  const CollectionEmptyState = (
    <div className={styles.emptyState}>
      <p className={styles.title}>No Collections</p>
      <p className={styles.subtitle}>No collections have been added to this creator yet.</p>
    </div>
  );

  const renderCreatorsSidebar = ({ selected }) => (
    <CreatorsSidebar
      selected={selected}
      activeCreatorId={activeCreatorId}
      setActiveCreatorId={setActiveCreatorId}
    />
  );

  const renderSelectableCard = ({ datum: collectionResponse, isSelected, toggleSelection }) => {
    const { collection } = collectionResponse;
    const { collectionId, title, backgroundImageUrl, isPublished, features } = collection;
    const { AGE_RANGE, EPISODE_COUNT } = features;
    const collectionStatus = isPublished ? 'published' : 'draft';

    return (
      <SelectableCard
        key={collectionId}
        isSelected={isSelected}
        title={title}
        backgroundImageUrl={backgroundImageUrl}
        status={collectionStatus}
        insetDescription={AGE_RANGE}
        description={EPISODE_COUNT}
        onClick={() => toggleSelection(collectionResponse)}
      />
    );
  };

  return (
    <BaseSelectionModal
      title={bundle.title}
      data={filteredCollections}
      initialSelectedData={selectedData.map(({ data }) => data)}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      comparisonFn={(a, b) => a.collection.collectionId === b.collection.collectionId}
      transformFn={({ collection, creators }) => {
        const isMultiCreatorCollection = typeof creators !== 'undefined';
        const key = isMultiCreatorCollection
          ? MULTI_CREATOR_COLLECTION_KEY
          : CREATOR_COLLECTION_KEY;
        return { [key]: collection.collectionId };
      }}
      EmptyState={CollectionEmptyState}
      sidebarRenderer={renderCreatorsSidebar}
      selectionCardRenderer={renderSelectableCard}
    />
  );
};

CollectionSelectionModal.propTypes = {
  bundle: PropTypes.object.isRequired,
  selectedData: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export const CreatorSelectionModal = ({ bundle, selectedData, isOpen, onClose, onSubmit }) => {
  const [creators, setCreators] = useState([]);

  useEffect(() => {
    const fetchCreators = async () => {
      const creators = await adminService.getAllCreators();
      setCreators(creators);
    };
    fetchCreators();
  }, []);

  const renderSelectableCard = ({ datum: creator, isSelected, toggleSelection }) => {
    const { creatorId, title, avatarUrl, isPublished, attributes } = creator;
    const { numEpisodes, new: isNew } = attributes;

    return (
      <SelectableCard
        key={creatorId}
        isSelected={isSelected}
        title={title}
        backgroundImageUrl={avatarUrl}
        status={isPublished ? PUBLISH_STATUSES.ACTIVE : PUBLISH_STATUSES.INACTIVE}
        insetDescription={isNew ? 'New' : null}
        description={pluralize(numEpisodes, 'Story', 'Stories')}
        onClick={() => toggleSelection(creator)}
      />
    );
  };

  return (
    <BaseSelectionModal
      title={bundle.title}
      data={creators}
      initialSelectedData={selectedData}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      comparisonFn={(a, b) => a.creatorId === b.creatorId}
      transformFn={({ channelId }) => ({ channelId })}
      selectionCardRenderer={renderSelectableCard}
    />
  );
};

CreatorSelectionModal.propTypes = {
  bundle: PropTypes.object.isRequired,
  selectedData: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export const TopicSelectionModal = ({ bundle, selectedData, isOpen, onClose, onSubmit }) => {
  const [topics, setTopics] = useState([]);

  useEffect(() => {
    const fetchTopics = async () => {
      const topics = await adminService.getTopics();
      setTopics(topics);
    };
    fetchTopics();
  }, []);

  const renderSelectableCard = ({ datum: topic, isSelected, toggleSelection }) => {
    const { topicId, title, color } = topic;

    return (
      <div key={topicId} className={styles.topicCard}>
        <div
          className={cx(styles.thumbnailContainer, isSelected && styles.selected)}
          onClick={() => toggleSelection(topic)}
        >
          <img src={topic.dinoUrl} alt={title} style={getTopicTileBackgroundStyles(color)} />
        </div>
        <p>{title}</p>
      </div>
    );
  };

  return (
    <BaseSelectionModal
      title={bundle.title}
      data={topics}
      initialSelectedData={selectedData}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      comparisonFn={(a, b) => a.topicId === b.topicId}
      transformFn={({ topicId }) => ({ topicId })}
      selectionCardRenderer={renderSelectableCard}
    />
  );
};

TopicSelectionModal.propTypes = {
  bundle: PropTypes.object.isRequired,
  selectedData: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export const SeriesSelectionModal = ({ bundle, selectedData, isOpen, onClose, onSubmit }) => {
  const [series, setSeries] = useState([]);

  useEffect(() => {
    const fetchSeries = async () => {
      const series = await adminService.getSeries();
      setSeries(series.filter(({ isPublished }) => isPublished));
    };
    fetchSeries();
  }, []);

  const renderSelectableCard = ({ datum: series, isSelected, toggleSelection }) => {
    const { seriesId, extendedName, logoUrl, thumbnailUrl, backgroundColor } = series;

    const bgImageStyle = getBackgroundImageGradientStyles({
      backgroundImageUrl: thumbnailUrl,
      backgroundColor,
    });

    return (
      <div key={seriesId} className={styles.seriesCard}>
        <div
          className={cx(styles.thumbnailContainer, isSelected && styles.selected)}
          onClick={() => toggleSelection(series)}
          style={{ backgroundImage: `${bgImageStyle}` }}
        >
          <img src={logoUrl} alt={extendedName} />
        </div>
      </div>
    );
  };

  return (
    <BaseSelectionModal
      title={bundle.title}
      data={series}
      initialSelectedData={selectedData}
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      comparisonFn={(a, b) => a.seriesId === b.seriesId}
      transformFn={({ seriesId }) => ({ seriesId })}
      selectionCardRenderer={renderSelectableCard}
    />
  );
};

SeriesSelectionModal.propTypes = {
  bundle: PropTypes.object.isRequired,
  selectedData: PropTypes.array.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
