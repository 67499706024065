import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as adminService from 'services/content/adminService';
import * as contentService from 'services/content/contentService';

import EmptyState from 'components/empty-state/EmptyState';
import { CollectionsStat, EpisodesStat } from 'components/collection-stat/CollectionStat';
import { CMSWhiteButton } from 'components/button/Button';
import CollectionsTable, { MultiCreatorCollectionsTable } from 'components/table/CollectionsTable';
import CollectionForm, { MultiCreatorCollectionForm } from 'components/forms/CollectionForm';
import FormModal from 'components/modal/FormModal';
import Loading from 'components/loading/Loading';

import styles from 'pages/content/styles.css';

const CollectionsPage = ({
  title,
  creatorId,
  collectionService,
  formComponent,
  tableComponent,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [collections, setCollections] = useState([]);
  const [isCollectionModalOpen, setIsCollectionModalOpen] = useState(false);

  const CollectionsTable = tableComponent;

  const numCollections = collections.length;
  const numEpisodes = collections.reduce((acc, { episodes }) => acc + episodes.length, 0);

  useEffect(() => {
    const fetchCollections = async () => {
      const collections = await collectionService.getCollections({ creatorId });
      setCollections(collections);
      setIsLoading(false);
    };
    fetchCollections();
  }, []);

  return (
    <div className={styles.pageContainer}>
      <section>
        <div className={styles.panelHeader}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.metaContainer}>
            <div className={styles.stats}>
              <CollectionsStat num={numCollections} />
              {collections.length > 0 && <EpisodesStat num={numEpisodes} />}
            </div>
            <CMSWhiteButton
              label="+ New Collection"
              onClick={() => setIsCollectionModalOpen(true)}
            />
          </div>
        </div>
        <Loading isLoading={isLoading}>
          {collections.length ? (
            <CollectionsTable collections={collections} />
          ) : (
            <EmptyState
              title="Create your first collection"
              subtitle="Get started by creating your first collection."
              cta="+ New Collection"
              onclick={() => setIsCollectionModalOpen(true)}
              className={[styles.emptyState]}
            />
          )}
        </Loading>
      </section>
      <FormModal
        creatorId={creatorId}
        formNode={formComponent}
        title="Create Collection"
        contentLabel="Create Collection"
        primaryFooterBtnLabel="Save"
        formProps={{ creatorId }}
        isOpen={isCollectionModalOpen}
        onRequestClose={() => setIsCollectionModalOpen(false)}
      />
    </div>
  );
};

export const MultiCreatorCollectionsPage = (props) => (
  <CollectionsPage
    {...props}
    collectionService={adminService}
    formComponent={MultiCreatorCollectionForm}
    tableComponent={MultiCreatorCollectionsTable}
  />
);

CollectionsPage.defaultProps = {
  title: 'Collections',
  creatorId: null,
  collectionService: contentService,
  formComponent: CollectionForm,
  tableComponent: CollectionsTable,
};

CollectionsPage.propTypes = {
  title: PropTypes.string,
  creatorId: PropTypes.string,
  collectionService: PropTypes.object,
  formComponent: PropTypes.elementType,
  tableComponent: PropTypes.elementType,
};

export default CollectionsPage;
