// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.EpisodeEditBody_episode-edit-body___BVfv5DfWiz {\n  display: flex;\n  min-height: 100vh;\n  background-color: #000000;\n}\n\n.EpisodeEditBody_episode-edit-body___BVfv5DfWiz .EpisodeEditBody_episode-edit-body__preview___2vy9I0BmTv {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    position: sticky;\n    top: 95px;\n    overflow: hidden;\n    align-self: flex-start;\n  }\n\n.EpisodeEditBody_episode-edit-body___BVfv5DfWiz .EpisodeEditBody_episode-edit-body__preview___2vy9I0BmTv .EpisodeEditBody_episode-edit-body__preview__video-container___2OfQvA9rC5 {\n      height: calc((100vw - 35em) * (9 / 16));\n      width: 100%;\n      position: relative\n    }\n\n.EpisodeEditBody_episode-edit-body___BVfv5DfWiz .EpisodeEditBody_episode-edit-body__preview___2vy9I0BmTv .EpisodeEditBody_episode-edit-body__preview__video-container___2OfQvA9rC5 > div {\n        width: 100%;\n      }\n\n.EpisodeEditBody_new___3SXHOkcqPv .EpisodeEditBody_episode-edit-body___BVfv5DfWiz {\n    height: 100vh;\n  }\n\n.EpisodeEditBody_new___3SXHOkcqPv .EpisodeEditBody_episode-edit-body__preview___2vy9I0BmTv {\n    top: 0;\n  }\n\n.EpisodeEditBody_new___3SXHOkcqPv .EpisodeEditBody_episode-edit-body__preview___2vy9I0BmTv .EpisodeEditBody_episode-edit-body__preview__video-container___2OfQvA9rC5 {\n      height: unset\n    }\n\n.EpisodeEditBody_new___3SXHOkcqPv .EpisodeEditBody_episode-edit-body__preview___2vy9I0BmTv .EpisodeEditBody_episode-edit-body__preview__video-container___2OfQvA9rC5 > div {\n        width: 100vw;\n        height: calc(100vh - 120px);\n      }\n", ""]);
// Exports
exports.locals = {
	"episode-edit-body": "EpisodeEditBody_episode-edit-body___BVfv5DfWiz",
	"episodeEditBody": "EpisodeEditBody_episode-edit-body___BVfv5DfWiz",
	"episode-edit-body__preview": "EpisodeEditBody_episode-edit-body__preview___2vy9I0BmTv",
	"episodeEditBodyPreview": "EpisodeEditBody_episode-edit-body__preview___2vy9I0BmTv",
	"episode-edit-body__preview__video-container": "EpisodeEditBody_episode-edit-body__preview__video-container___2OfQvA9rC5",
	"episodeEditBodyPreviewVideoContainer": "EpisodeEditBody_episode-edit-body__preview__video-container___2OfQvA9rC5",
	"new": "EpisodeEditBody_new___3SXHOkcqPv"
};
module.exports = exports;
