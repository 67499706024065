import PropTypes from 'prop-types';
import { deepClone } from 'utils';
import { CMSTextButton } from 'components/button/Button';

const ObjectList = ({ objects, onChange, getDefault, Component, name, ...props }) => {
  const onObjectChange = (i) => (prop, value) => {
    const objectsCopy = deepClone(objects);
    objectsCopy[i][prop] = value;
    onChange(objectsCopy);
  };

  const handleDelete = (formIndex) => onChange(objects.filter((_, i) => i !== formIndex));
  const onDelete = (i) => (objects.length > 1 ? () => handleDelete(i) : null);
  const handleAdd = () => onChange([...objects, getDefault(objects[objects.length - 1])]);

  return (
    <>
      {objects.map((item, i) => (
        <Component
          i={i}
          value={item}
          onChange={onObjectChange(i)}
          onDelete={onDelete(i)}
          {...props}
        />
      ))}
      {!props.addingDisabled && (
        <CMSTextButton label={`Add Another ${name}`} onClick={() => handleAdd()} />
      )}
    </>
  );
};

ObjectList.defaultProps = {
  addingDisabled: false,
};

ObjectList.propTypes = {
  addingDisabled: PropTypes.bool,
  objects: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  getDefault: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  Component: PropTypes.func.isRequired,
};

export default ObjectList;
