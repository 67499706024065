import { LOCAL_STORAGE_KEY } from 'services/authentication/constants';

// State Contents:
// type AppState = {
//   accessToken: string,
//   accessTokenExpires: Date,
//   user: {
//     userId: string
//     givenName: string,
//     surname: string,
//     email: string,
//     accountType: string,
//   }
// };

function getPersistedState() {
  const localStorageItem = localStorage.getItem(LOCAL_STORAGE_KEY);

  if (!localStorageItem) {
    return null;
  }

  try {
    const state = JSON.parse(localStorageItem);
    return state;
  } catch (e) {
    return null;
  }
}

export function setPersistedState(state) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state));
}

export function getAccessToken() {
  const userState = getPersistedState();

  if (!userState) {
    return null;
  }

  const { accessToken, accessTokenExpires } = userState;

  if (!accessToken || !accessTokenExpires || new Date(accessTokenExpires) < new Date()) {
    return null;
  }

  return userState.accessToken;
}

export function getMe() {
  const userState = getPersistedState();

  if (!userState) {
    return null;
  }

  return userState.user;
}

export function setMe(userState) {
  const currentState = getPersistedState();
  setPersistedState({ ...currentState, user: userState });
}
