/* eslint-disable max-classes-per-file */

import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import DatePicker from 'react-datepicker';
import { DebounceInput } from 'react-debounce-input';

import { noop } from 'utils';
import { INPUT_TYPES, INPUT_COLORS } from 'components/input/constants';
import * as styles from 'components/input/Input.css';
import * as utils from 'components/input/utils';

export class Input extends PureComponent {
  static propTypes = {
    placeholder: PropTypes.string,
    type: PropTypes.oneOf(Object.values(INPUT_TYPES)),
    color: PropTypes.oneOf(Object.values(INPUT_COLORS)),
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    onInput: PropTypes.func,
    label: PropTypes.string,
    readOnly: PropTypes.bool,
    textarea: PropTypes.bool,
    debounced: PropTypes.bool,
    style: PropTypes.object, // eslint-disable-line react/no-unused-prop-types
  };

  static defaultProps = {
    type: INPUT_TYPES.TEXT,
    onInput: noop,
    placeholder: '',
    color: null,
    label: '',
    readOnly: false,
    textarea: false,
    debounced: false,
    style: {},
  };

  state = {
    hasChanged: false,
    parentFocusCount: 0,
  };

  inputRef = createRef();

  focusInput = () => {
    this.inputRef.current && this.inputRef.current.focus();
    this.setState(({ parentFocusCount }) => ({ parentFocusCount: parentFocusCount + 1 }));
  };

  handleChange(value, event) {
    const { onChange, type } = this.props;
    const isValid = utils.isInputValid(type, value);

    onChange && onChange(value, isValid, event);
    this.setState({ hasChanged: true });
  }

  handleInput(value, event) {
    const { onInput } = this.props;
    onInput && onInput(value, event);
  }

  handleKeyUp(e) {
    e.stopPropagation();
  }

  getInputElement() {
    const { placeholder, value, type, readOnly, textarea, debounced, ...rest } = this.props;

    let InputElement;
    if (type === INPUT_TYPES.DATE) {
      InputElement = (
        <DatePicker
          selected={value}
          onChange={(date) => this.handleChange(date)}
          showTimeSelect
          dateFormat="Pp O"
          {...rest}
        />
      );
    } else if (textarea) {
      InputElement = (
        <textarea
          className={styles.textareaInput}
          placeholder={placeholder}
          value={value}
          onChange={(e) => this.handleChange(e.target.value, e)}
          onKeyUp={this.handleKeyUp}
          ref={this.inputRef}
          readOnly={readOnly}
          maxLength={1024}
          rows={3}
        />
      );
    } else {
      InputElement = (
        <DebounceInput
          placeholder={placeholder}
          value={value}
          onChange={(e) => this.handleChange(e.target.value, e)}
          onInput={(e) => this.handleInput(e.target.value, e)}
          onKeyUp={this.handleKeyUp}
          ref={this.inputRef}
          type={type}
          readOnly={readOnly}
          debounceTimeout={debounced ? 350 : 0}
        />
      );
    }

    return InputElement;
  }

  render() {
    const { value, type, color, label } = this.props;
    const { hasChanged, parentFocusCount } = this.state;
    const inputStyles = cx(styles.inputContainer, color && styles[`input-container--${color}`], {
      [styles.inputContainerError]: hasChanged && !utils.isInputValid(type, value),
      [styles.inputContainerHighlightFocusLeft]: parentFocusCount > 0 && parentFocusCount % 2 === 0,
      [styles.inputContainerHighlightFocusRight]:
        parentFocusCount > 0 && parentFocusCount % 2 === 1,
    });
    const inputElement = this.getInputElement();

    return (
      <>
        {label && <label>{label}</label>}
        <div className={inputStyles}>{inputElement}</div>
      </>
    );
  }
}

class CMSInput extends Input {
  render() {
    const { value, type, color, label, style } = this.props;
    const { hasChanged } = this.state;
    const inputStyles = cx(
      styles.cmsInputContainer,
      color && styles[`cms-input-container--${color}`],
      {
        [styles.cmsInputContainerError]: hasChanged && !utils.isInputValid(type, value),
      },
    );
    const inputElement = this.getInputElement();

    return (
      <>
        <div className={inputStyles} style={style}>
          {label && <label>{label}</label>}
          {inputElement}
        </div>
      </>
    );
  }
}

export class CMSWhiteInput extends Input {
  static propTypes = {
    ...Input.propTypes,
    isRequired: PropTypes.bool,
  };

  render() {
    const { label, isRequired, style, readOnly } = this.props;
    const inputElement = this.getInputElement();
    const requiredSpan = isRequired && <span>*</span>;

    return (
      <div className={cx(styles.cmsWhiteInputContainer, readOnly && styles.readOnly)} style={style}>
        {label && (
          <label>
            {label} {requiredSpan}
          </label>
        )}
        <div className={styles.cmsWhiteInputContainerInput}>{inputElement}</div>
      </div>
    );
  }
}

export const PurpleInput = (props) => <Input {...props} color={INPUT_COLORS.PURPLE} />;

export default CMSInput;
