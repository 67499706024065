// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.TagsSelector_topics-container___1RKVHib0SH {\n  margin: 0 !important;\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n  grid-gap: 16px;\n  gap: 16px;\n}\n\n.TagsSelector_topic-card___3ubBezOeD1 {\n  margin: 0 !important;\n  border-radius: 16px;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  flex: 1 0 20%;\n}\n\n.TagsSelector_topic-card___3ubBezOeD1:hover {\n  cursor: pointer;\n}\n\n.TagsSelector_topic-card___3ubBezOeD1.TagsSelector_selected___3sZYMUxq9a {\n  position: relative;\n  box-shadow: 0 0 0 2px #ffffff, 0 0 0 5px #8b18e3;\n}\n\n.TagsSelector_topic-card___3ubBezOeD1.TagsSelector_selected___3sZYMUxq9a::after {\n  /* ✓ Check Mark icon */\n  content: '\\2713';\n  margin: 0;\n  padding: 6px;\n  position: absolute;\n  top: -10px;\n  right: -10px;\n  font-size: 16px;\n  line-height: 12px;\n  color: #ffffff;\n  background: #8b18e3;\n  box-shadow: 0 0 0 2px #ffffff;\n  border-radius: 50%;\n  font-weight: bold;\n}\n\n.TagsSelector_background-image___37Z04eAsk4 {\n  width: 100%;\n}\n\n.TagsSelector_title___2CFXlusqxS {\n  margin: 0 0 10px;\n  width: 100%;\n\n  color: #ffffff;\n  font-size: 14px;\n  text-align: center;\n  font-weight: bold;\n  text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);\n}\n", ""]);
// Exports
exports.locals = {
	"topics-container": "TagsSelector_topics-container___1RKVHib0SH",
	"topicsContainer": "TagsSelector_topics-container___1RKVHib0SH",
	"topic-card": "TagsSelector_topic-card___3ubBezOeD1",
	"topicCard": "TagsSelector_topic-card___3ubBezOeD1",
	"selected": "TagsSelector_selected___3sZYMUxq9a",
	"background-image": "TagsSelector_background-image___37Z04eAsk4",
	"backgroundImage": "TagsSelector_background-image___37Z04eAsk4",
	"title": "TagsSelector_title___2CFXlusqxS"
};
module.exports = exports;
