import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { NotificationManager } from 'react-notifications';

import styles from 'components/do-it-forms/forms/DoItForm.css';
import commonStyles from 'index.css';

import { CMSTextButton, CMSButton } from 'components/button/Button';
import { CrossIcon } from 'components/Icon';
import { CMSWhiteInput } from 'components/input/Input';
import TimestampInput from 'components/input/TimestampInput';
import CheckboxInput from 'components/input/CheckboxInput';
import DropdownInput from 'components/input/DropdownInput';
import Accordion from 'components/accordion/Accordion';
import AccordionSection from 'components/accordion/AccordionSection';
import AudioConfig from 'components/do-it-forms/components/AudioConfig';
import { LAYOUT_OPTIONS, TRANSITION_OPTIONS } from 'components/do-it-forms/forms/constants';
import { getDefaultTransitionForLayout } from 'components/do-it-forms/utils';

export const DoItSectionBreaker = ({ title }) => (
  <div className={styles.doItSectionBreaker}>
    <h6>{title}</h6>
  </div>
);
DoItSectionBreaker.propTypes = {
  title: PropTypes.string.isRequired,
};

class DoItForm extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    doit: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    children: PropTypes.node,
    subsequentDoitId: PropTypes.string,
  };

  static defaultProps = {
    children: null,
    subsequentDoitId: null,
  };

  state = { ...this.props.doit };

  componentWillReceiveProps({ doit }) {
    this.setState({ ...doit });
  }

  componentDidMount() {
    this.handleSave();
  }

  handleTimingChange(startSecsWithEpisodeOffset, finishSecsWithEpisodeOffset) {
    this.setState(
      {
        startSecsWithEpisodeOffset,
        finishSecsWithEpisodeOffset,
      },
      () => this.handleSave(),
    );
  }

  handleChange(key, value) {
    this.setState({ [key]: value }, () => this.handleSave());
  }

  handleSettingsChange(key, value) {
    this.handleChange('settings', { ...this.state.settings, [key]: value });
  }

  handleSave() {
    const { startSecsWithEpisodeOffset, finishSecsWithEpisodeOffset } = this.state;

    const hasInvalidTimes = isNaN(startSecsWithEpisodeOffset) || isNaN(finishSecsWithEpisodeOffset);

    if (hasInvalidTimes) {
      return false;
    }

    if (!(finishSecsWithEpisodeOffset > startSecsWithEpisodeOffset)) {
      NotificationManager.error('Please ensure the doit finishes after it starts.');
      return false;
    }

    this.props.onSave(this.state);

    return true;
  }

  handleDelete() {
    if (!confirm('Are you sure you want to delete this doit?')) {
      return;
    }

    const { doit, onDelete } = this.props;
    onDelete(doit.itemId);
  }

  render() {
    const { title, children, onClose, subsequentDoitId } = this.props;
    const {
      name,
      startSecsWithEpisodeOffset,
      finishSecsWithEpisodeOffset,
      pauseVideoUntilComplete,
      settings,
      linkedDoItId,
    } = this.state;

    const {
      backgroundAudio,
      layoutType,
      completeSound,
      voiceoverAudio,
      transitionInSound,
      transitionOutSound,
      transitionInType,
      transitionOutType,
      allowRestart,
      dismissOnComplete,
      dismissDelay,
      isUntimed,
    } = settings;

    return (
      <div className={styles.doItForm}>
        <div className={styles.doItFormHeader}>
          <h4>
            {title}
            {name ? ` – ${name}` : ''}
          </h4>
          <span onClick={onClose}>
            <CrossIcon />
          </span>
        </div>
        <form>
          <Accordion title="General Settings">
            <CMSWhiteInput
              label="Do It Name"
              value={name}
              onChange={(value) => this.handleChange('name', value)}
              isRequired
              debounced
            />
            <br />
            <div className={commonStyles.sideBySide}>
              <div>
                <TimestampInput
                  label="Start Time"
                  value={startSecsWithEpisodeOffset}
                  onChange={(newStartSecs) =>
                    this.handleTimingChange(newStartSecs, finishSecsWithEpisodeOffset)
                  }
                />
              </div>
              <div>
                <TimestampInput
                  label="End Time"
                  value={finishSecsWithEpisodeOffset}
                  onChange={(newFinishSecs) =>
                    this.handleTimingChange(startSecsWithEpisodeOffset, newFinishSecs)
                  }
                />
              </div>
            </div>
            <CheckboxInput
              label="Interrupt Video"
              description="The video will pause until the doit finishes."
              onChange={(value) => this.handleChange('pauseVideoUntilComplete', value)}
              value={pauseVideoUntilComplete}
            />
            <CheckboxInput
              label="Allow Restart"
              description="Show the 'Again?' button after the doit is completed."
              onChange={(value) => this.handleSettingsChange('allowRestart', value)}
              value={allowRestart}
            />
            <CheckboxInput
              label="Dismiss on Complete"
              description="Allows the Do It to be dismissed as soon as it is completed if enabled."
              onChange={(value) => this.handleSettingsChange('dismissOnComplete', value)}
              value={dismissOnComplete}
            />
            {dismissOnComplete && (
              <CMSWhiteInput
                label="Dismiss Delay"
                value={dismissDelay || 0}
                onChange={(value) => this.handleSettingsChange('dismissDelay', value)}
                isRequired
                debounced
                type="number"
                min="0"
              />
            )}
            <CheckboxInput
              label="Link to Next Do It"
              description="NOTE: if experiencing issues, uncheck this option."
              onChange={(value) =>
                this.handleChange('linkedDoItId', value ? subsequentDoitId : null)
              }
              value={Boolean(linkedDoItId)}
            />
            <CheckboxInput
              label="Untimed"
              description="Doit does not automatically go away after duration."
              onChange={(value) => this.handleSettingsChange('isUntimed', value)}
              value={isUntimed}
            />
          </Accordion>

          <Accordion title="Layout">
            <DropdownInput
              label="Layout Type"
              onChange={({ value }) => {
                this.handleSettingsChange('layoutType', value);
              }}
              value={layoutType || LAYOUT_OPTIONS.HALF_SCREEN.value}
              options={Object.values(LAYOUT_OPTIONS)}
            />
            <br />
            <DropdownInput
              label="Transition In Type"
              onChange={({ value }) => this.handleSettingsChange('transitionInType', value)}
              value={transitionInType ?? getDefaultTransitionForLayout(layoutType)}
              options={Object.values(TRANSITION_OPTIONS)}
            />
            <br />
            <DropdownInput
              label="Transition Out Type"
              onChange={({ value }) => this.handleSettingsChange('transitionOutType', value)}
              value={transitionOutType ?? getDefaultTransitionForLayout(layoutType)}
              options={Object.values(TRANSITION_OPTIONS)}
            />
          </Accordion>

          <Accordion title="Audio">
            <AccordionSection title="Background Track">
              <AudioConfig
                label="Background Track"
                hideLabel
                value={backgroundAudio}
                onChange={(value) => this.handleSettingsChange('backgroundAudio', value)}
                allowLooping
              />
            </AccordionSection>
            <AccordionSection title="Voiceover Track">
              <AudioConfig
                label="Voiceover Track"
                hideLabel
                value={voiceoverAudio}
                onChange={(value) => this.handleSettingsChange('voiceoverAudio', value)}
              />
            </AccordionSection>
            <AccordionSection title="Transition In Sound Effect">
              <AudioConfig
                label="Transition In Sound Effect"
                hideLabel
                value={transitionInSound}
                onChange={(value) => this.handleSettingsChange('transitionInSound', value)}
              />
            </AccordionSection>
            <AccordionSection title="Transition Out Sound Effect">
              <AudioConfig
                label="Transition Out Sound Effect"
                hideLabel
                value={transitionOutSound}
                onChange={(value) => this.handleSettingsChange('transitionOutSound', value)}
              />
            </AccordionSection>
            <AccordionSection title="Complete Sound Effect">
              <AudioConfig
                label="Complete Sound Effect"
                hideLabel
                value={completeSound}
                onChange={(value) => this.handleSettingsChange('completeSound', value)}
              />
            </AccordionSection>
          </Accordion>

          <DoItSectionBreaker title="Content" />
          {children}
        </form>
        <div className={styles.doItFormFooter}>
          <div className={styles.doItFormFooterLeft}>
            <CMSTextButton label="Remove Do It" onClick={() => this.handleDelete()} />
          </div>
          <div className={styles.doItFormFooterRight}>
            <CMSButton label="Close It" onClick={onClose} />
          </div>
        </div>
      </div>
    );
  }
}

export default DoItForm;
