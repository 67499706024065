import PropTypes from 'prop-types';

import * as styles from 'components/accordion/Accordion.css';

function AccordionSection({ title, description, children }) {
  if (!title && !description) {
    return children;
  }

  return (
    <div className={styles.accordionSection}>
      <div className={styles.accordionSectionHeader}>
        <h6>{title}</h6>
        <p>{description}</p>
      </div>
      <div className={styles.accordionSectionBody}>{children}</div>
    </div>
  );
}

AccordionSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};
AccordionSection.defaultProps = {
  title: '',
  description: '',
};

export default AccordionSection;
