// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Unity_unityContainer___2bgUC7QHOL {\n  background: url('/images/dino-loading.gif');\n  background-size: 200px;\n  background-repeat: no-repeat;\n  background-position: center;\n}\n\n  .Unity_unityContainer___2bgUC7QHOL canvas {\n    width: 100%;\n    height: 100%;\n  }\n", ""]);
// Exports
exports.locals = {
	"unityContainer": "Unity_unityContainer___2bgUC7QHOL"
};
module.exports = exports;
