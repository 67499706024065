import cuid from 'cuid';

import { CMSTextButton } from 'components/button/Button';
import DoItForm from 'components/do-it-forms/forms/DoIt';
import BaseForm from 'components/do-it-forms/forms/Base';
import BackgroundAccordion from 'components/do-it-forms/accordions/BackgroundAccordion';
import BuildItPair from 'components/do-it-forms/components/BuildItPair';
import {
  getDefaultDraggableObject,
  getDefaultDropTarget,
  getDefaultBackground,
} from 'components/do-it-forms/utils';
import { deepClone } from 'utils';

class BuildItForm extends BaseForm {
  getDefaultParams() {
    return {
      background: getDefaultBackground(),
      buildItTargets: [getDefaultDropTarget()],
      buildItObjects: [getDefaultDraggableObject()],
    };
  }

  getDoitParams() {
    return this.state;
  }

  handleAdd() {
    const id = cuid();

    this.setState(({ buildItTargets, buildItObjects }) => ({
      buildItTargets: [...buildItTargets, getDefaultDropTarget({ acceptedTypes: [id] })],
      buildItObjects: [...buildItObjects, getDefaultDraggableObject({ type: id })],
    }));
  }

  handleDelete(deleteIndex) {
    this.setState(({ buildItTargets, buildItObjects }) => ({
      buildItTargets: buildItTargets.filter((_, i) => i !== deleteIndex),
      buildItObjects: buildItObjects.filter((_, i) => i !== deleteIndex),
    }));
  }

  handleTargetChange(i, key, value) {
    this.setState(({ buildItTargets }) => {
      const buildItTargetsCopy = deepClone(buildItTargets);
      buildItTargetsCopy[i][key] = value;

      return { buildItTargets: buildItTargetsCopy };
    });
  }

  handleObjectChange(i, key, value) {
    this.setState(({ buildItObjects }) => {
      const buildItObjectsCopy = deepClone(buildItObjects);
      buildItObjectsCopy[i][key] = value;

      return { buildItObjects: buildItObjectsCopy };
    });
  }

  render() {
    const { onClose, onDelete, doit, subsequentDoitId } = this.props;
    const { buildItTargets, buildItObjects, background } = this.state;

    return (
      <DoItForm
        title="Build It"
        doit={doit}
        onClose={onClose}
        onSave={(options) => this.handleSave(options)}
        onDelete={onDelete}
        subsequentDoitId={subsequentDoitId}
      >
        <BackgroundAccordion
          value={background}
          onChange={(key, value) => this.setState({ [key]: value })}
        />
        {buildItTargets.map((_, i) => (
          <BuildItPair
            i={i}
            target={buildItTargets[i]}
            object={buildItObjects[i]}
            onTargetChange={(key, value) => this.handleTargetChange(i, key, value)}
            onObjectChange={(key, value) => this.handleObjectChange(i, key, value)}
            onDelete={buildItTargets.length > 1 ? () => this.handleDelete(i) : null}
          />
        ))}

        <CMSTextButton label="Add Another Object/Target Pair" onClick={() => this.handleAdd()} />
      </DoItForm>
    );
  }
}

export default BuildItForm;
