import PropTypes from 'prop-types';
import cx from 'classnames';

import { PurpleButton } from 'components/button/Button';
import styles from 'components/empty-state/EmptyState.css';

const EmptyState = ({ className, title, subtitle, cta, onclick }) => (
  <div className={cx(styles.emptyState, ...className)}>
    <h1>{title}</h1>
    <p>{subtitle}</p>
    <PurpleButton label={cta} onClick={onclick} />
  </div>
);

EmptyState.defaultProps = {
  className: [],
};

EmptyState.propTypes = {
  className: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  onclick: PropTypes.func.isRequired,
};

export default EmptyState;
