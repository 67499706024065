import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import cx from 'classnames';

import { create, update } from 'components/forms/utils';
import { getBackgroundImageGradientStyles } from 'utils';

import FormSection from 'components/forms/FormSection';
import { CMSWhiteInput } from 'components/input/Input';
import { CMSWhiteButton, PurpleButton } from 'components/button/Button';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import ColorRadioGroup, { BACKGROUND_COLORS } from 'components/input/ColorRadioGroup';

import styles from 'components/forms/styles.css';

const AssetPreviewInfo = ({
  title,
  description,
  hint,
  uploadBtnLabel,
  asset,
  children,
  handleOnClick,
}) => (
  <>
    <div className={styles.assetPreviewContent}>
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>{description}</p>
      <div className={styles.buttonGroup}>
        <ImageUploadInput
          className={styles.fileUploadInput}
          label={uploadBtnLabel}
          hideLabel
          onChange={({ awsInternalPath, awsPublicUrl }) =>
            handleOnClick(awsInternalPath, awsPublicUrl)
          }
        />
        {asset && <CMSWhiteButton label="Remove" onClick={() => handleOnClick(null, null)} />}
      </div>
      <p>{hint}</p>
    </div>
    {children}
  </>
);

AssetPreviewInfo.defaultProps = {
  hint: '',
  children: undefined,
};

AssetPreviewInfo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  hint: PropTypes.string,
  uploadBtnLabel: PropTypes.string.isRequired,
  asset: PropTypes.string.isRequired,
  children: PropTypes.node,
  handleOnClick: PropTypes.func.isRequired,
};

/**
 * When form is wrapped in `forwardRef` and uses the `useImperativeHandle` hook,
 * the parent component then has access to whatever data is selectively exposed.
 */
const SeriesForm = forwardRef(({ series, creatorId, isNew, hideButtons, onSubmit }, ref) => {
  const [seriesDetails, updateSeriesDetails] = useState(series);
  useEffect(() => updateSeriesDetails(series), [series]);

  const { shortName, extendedName, description, logoUrl, thumbnailUrl, backgroundColor } =
    seriesDetails;
  const attributes = [
    'shortName',
    'extendedName',
    'description',
    'backgroundColor',
    'logoKey',
    'thumbnailKey',
  ];

  async function submit() {
    if (isNew) {
      const response = await create({
        path: `/creators/${creatorId}/series`,
        attributes,
        obj: seriesDetails,
      });

      window.location.href = `/creators/${creatorId}/series/${response.series.seriesId}`;
    } else {
      const { seriesId } = series;
      const updatedSeries = await update({
        path: `/creators/${creatorId}/series/${seriesId}`,
        attributes,
        obj: seriesDetails,
      });

      NotificationManager.success('Successfully saved!');
      onSubmit(updatedSeries);
    }
  }

  useImperativeHandle(ref, () => ({ submit }));

  return (
    <div className={cx(styles.form, styles.seriesForm, !hideButtons && styles.padded)}>
      <FormSection title="Basic Info" mainClassName={styles.creatorInfo}>
        <CMSWhiteInput
          value={shortName}
          label="Short Title"
          isRequired
          onChange={(shortName) => updateSeriesDetails({ ...seriesDetails, shortName })}
        />

        <CMSWhiteInput
          value={extendedName}
          label="Extended Title"
          isRequired
          onChange={(extendedName) => updateSeriesDetails({ ...seriesDetails, extendedName })}
        />

        <CMSWhiteInput
          textarea
          value={description}
          label="Series Description"
          placeholder="Describe your series here…"
          onChange={(description) => updateSeriesDetails({ ...seriesDetails, description })}
        />
      </FormSection>

      <FormSection title="Background Color">
        <section>
          <div className={styles.assetPreviewContent}>
            <ColorRadioGroup
              name={isNew ? 'new-creator-bg-color' : 'creator-bg-color'}
              value={backgroundColor}
              onChange={(value) =>
                updateSeriesDetails({
                  ...seriesDetails,
                  backgroundColor: value,
                })
              }
            />
          </div>
        </section>
      </FormSection>

      <FormSection title="Series Appearance" mainClassName={styles.appearanceSection}>
        <section>
          <div>
            <AssetPreviewInfo
              title="Series Background Image"
              description="Please upload a vertical image specific for the series."
              uploadBtnLabel="Image"
              asset={thumbnailUrl}
              handleOnClick={(awsInternalPath, awsPublicUrl) =>
                updateSeriesDetails({
                  ...seriesDetails,
                  thumbnailKey: awsInternalPath,
                  thumbnailUrl: awsPublicUrl,
                })
              }
            />
            <AssetPreviewInfo
              title="Series Logo Image"
              description="Please upload an image containing the series extended title. Reach out to #design for more information."
              uploadBtnLabel="Image"
              asset={logoUrl}
              handleOnClick={(awsInternalPath, awsPublicUrl) =>
                updateSeriesDetails({
                  ...seriesDetails,
                  logoKey: awsInternalPath,
                  logoUrl: awsPublicUrl,
                })
              }
            />
          </div>
          <div className={styles.assetPreviewMedia}>
            <div
              className={styles.seriesHeaderPreview}
              style={{
                backgroundImage: getBackgroundImageGradientStyles({
                  backgroundImageUrl: thumbnailUrl,
                  backgroundColor,
                }),
              }}
            >
              <img src={logoUrl} alt={extendedName} />
            </div>
          </div>
        </section>
      </FormSection>

      {!hideButtons && (
        <PurpleButton label={isNew ? 'Create Series' : 'Save Changes'} onClick={submit} />
      )}
    </div>
  );
});

SeriesForm.displayName = 'SeriesForm';

SeriesForm.defaultProps = {
  series: {
    shortName: '',
    extendedName: '',
    description: '',
    logoUrl: '',
    thumbnailUrl: '',
    backgroundColor: BACKGROUND_COLORS.PURPLE.name,
  },
  creatorId: null,
  isNew: false,
  hideButtons: false,
  onSubmit: () => {},
};

SeriesForm.propTypes = {
  series: PropTypes.object,
  creatorId: PropTypes.string,
  isNew: PropTypes.bool,
  hideButtons: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default SeriesForm;
