import { PureComponent } from 'react';

import * as styles from 'pages/NotFoundPage.css';

import { YellowButton } from 'components/button/Button';
import PageLayout from 'components/page-layout/PageLayout';

class NotFoundPage extends PureComponent {
  render() {
    return (
      <PageLayout>
        <div className={styles.notFoundPage}>
          <h1>404 Not Found!</h1>
          <p>Sorry! We couldn&apos;t find the page you were looking for.</p>
          <YellowButton label="Go to the homepage" href="/" isLarge />
        </div>
      </PageLayout>
    );
  }
}

export default NotFoundPage;
