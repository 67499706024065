export const reorderItems = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getListStyle = (isDraggingOver) => ({
  // prevent hover effects from triggering when dragging a table row over other rows
  pointerEvents: isDraggingOver ? 'none' : 'auto',
});

export const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  display: isDragging ? 'table' : 'table-row',
  background: isDragging && '#FFFCF6',
  boxShadow: isDragging ? '0px 9px 34px rgba(0, 0, 0, 0.2)' : 'none',
  borderRadius: isDragging ? '8px' : '0px',
  ...draggableStyle,
});

export const getDragHandleStyle = (isDragging) => ({
  visibility: isDragging ? 'visible' : 'collapse',
});
