/* eslint-disable no-alert, react/prop-types */

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NotificationManager } from 'react-notifications';

import * as contentService from 'services/content/contentService';
import { getEpisodeStatus } from 'utils';

import Table from 'components/table/Table';
import { CMSButton, CMSWhiteButton } from 'components/button/Button';

import styles from 'components/table/Table.css';

const SeriesEpisodesTable = ({ episodes, seriesId, creatorId, updateSeriesDetails }) => {
  const handleRemoveFromSeries = async (episodeId) => {
    if (!window.confirm('Are you sure you want to remove that story from this series?')) {
      return;
    }

    const filteredEpisodeIds = episodes
      .filter((episode) => episode.episodeId !== episodeId)
      .map((episode) => episode.episodeId);

    const updatedSeries = await contentService.updateSeries({
      creatorId,
      seriesId,
      series: { episodeIds: filteredEpisodeIds },
    });

    updateSeriesDetails(updatedSeries);
    NotificationManager.success('Successfully removed story.');
  };

  const handleReorder = async (items) => {
    updateSeriesDetails({ episodes: items });

    const episodeIds = items.map(({ episodeId }) => episodeId);
    const series = await contentService.updateSeries({
      creatorId,
      seriesId,
      series: { episodeIds },
    });

    updateSeriesDetails(series);
    NotificationManager.success('Successfully reordered stories.');
  };

  const columns = useMemo(
    () => [
      {
        id: 'thumbnailUrl',
        Header: () => null,
        Cell: ({
          row: {
            original: { thumbnailUrl, title },
          },
        }) => <img src={thumbnailUrl} alt={title} className={styles.thumbnail} />,
        style: { width: '86px' },
      },
      {
        accessor: 'title',
        Header: 'Title',
        Cell: ({
          row: {
            original: { title },
          },
        }) => <p className={styles.title}>{title}</p>,
      },
      {
        accessor: 'durationLong',
        Header: 'Length',
        Cell: ({
          row: {
            original: { durationLong },
          },
        }) => <p className={styles.text}>{durationLong}</p>,
      },
      {
        accessor: 'availability',
        Header: 'Status',
        Cell: ({
          row: {
            original: { availability, releaseDateFormatted },
          },
        }) => (
          <div>
            <p className={styles.text}>{getEpisodeStatus(availability)}</p>
            <p className={cx(styles.text, styles.light)}>{releaseDateFormatted}</p>
          </div>
        ),
      },
    ],
    [],
  );

  const HoverActions = ({ episodeId }) => (
    <>
      <CMSWhiteButton
        label="Remove from Series"
        onClick={() => handleRemoveFromSeries(episodeId)}
      />
      <CMSButton label="Edit" href={`/creators/${creatorId}/episodes/${episodeId}`} />
    </>
  );

  return (
    <Table
      data={episodes}
      columns={columns}
      datumIdAccessor="episodeId"
      hideFilters
      hoverActions={HoverActions}
      onReorder={handleReorder}
    />
  );
};

SeriesEpisodesTable.defaultProps = {
  creatorId: null,
};

SeriesEpisodesTable.propTypes = {
  episodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  seriesId: PropTypes.string.isRequired,
  creatorId: PropTypes.string,
  updateSeriesDetails: PropTypes.func.isRequired,
};

export default SeriesEpisodesTable;
