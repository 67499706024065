import React, { useState, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import request from 'services/request/requestService';
import { isAuthenticated } from 'services/authentication/authenticationService';

const CreatorsContext = createContext();
CreatorsContext.displayName = 'CreatorsContext';

const LOGOUT_PATH = '/logout';

export const CreatorsProvider = ({ children }) => {
  const { pathname } = useLocation();
  const isUserLoggedIn = isAuthenticated();
  const [isLoading, setIsLoading] = useState(true);
  const [creators, setCreators] = useState([]);

  useEffect(() => {
    if (isUserLoggedIn && pathname !== LOGOUT_PATH) {
      setIsLoading(true);
      request('/creators/mine').then((response) => {
        setCreators(response);
        setIsLoading(false);
      });
    }
  }, [isUserLoggedIn]);

  const updateCreator = (creatorToUpdate) => {
    setCreators((creators) =>
      creators.map((creator) => {
        if (creatorToUpdate.creatorId === creator.creatorId) {
          return creatorToUpdate;
        }
        return creator;
      }),
    );
  };

  return (
    <CreatorsContext.Provider value={{ creators, updateCreator, isLoading }}>
      {children}
    </CreatorsContext.Provider>
  );
};

CreatorsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useCreators = () => {
  const context = useContext(CreatorsContext);
  if (!context) {
    throw new Error('CreatorsContext must be used with CreatorsProvider!');
  }
  return context;
};
