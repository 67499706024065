import request from 'services/request/requestService';

export function getEpisode(creatorId, episodeId) {
  const method = 'GET';
  const url = `/creators/${creatorId}/episodes/${episodeId}`;

  return request(url, { method });
}

export function getVideos() {
  const method = 'GET';
  return request('/videos', { method });
}

export function updateEpisode(
  creatorId,
  episodeId,
  { doits, thumbnailFile, isPublished, videoId, title, description, tags, releaseDate },
) {
  const method = 'PUT';
  const body = JSON.stringify({
    doits,
    thumbnailFile,
    isPublished,
    videoId,
    title,
    description,
    tags,
    releaseDate,
  });
  const url = `/creators/${creatorId}/episodes/${episodeId}`;

  return request(url, { method, body });
}

export function changeEpisodePublished(creatorId, episodeId, isPublished, releaseDate = null) {
  return updateEpisode(creatorId, episodeId, { isPublished, releaseDate });
}

export function deleteEpisode(creatorId, episodeId) {
  const method = 'DELETE';
  const url = `/creators/${creatorId}/episodes/${episodeId}`;

  return request(url, { method });
}

export function getMyCreators() {
  const method = 'GET';
  const url = '/creators/mine';

  return request(url, { method });
}

export function leaveCreator(creatorId) {
  const method = 'DELETE';
  request(`/creators/${creatorId}/users/me`, { method });
}

export async function getCreator(creatorId) {
  const url = `/creators/${creatorId}`;
  return request(url);
}

export async function updateCreatorPublishedStatus(creatorId, isPublished) {
  const method = 'PUT';
  const body = JSON.stringify({
    isPublished,
  });
  const url = `/creators/${creatorId}/publish`;
  return request(url, { method, body });
}

/**
 * Collections
 */
export async function getCollections({ creatorId }) {
  const { collections } = await getCreator(creatorId);
  return collections;
}

export async function getCollection({ collectionId, creatorId }) {
  const url = `/creators/${creatorId}/collections/${collectionId}`;
  return request(url);
}

export async function createCollection(creatorId, collection) {
  const method = 'POST';
  const body = JSON.stringify(collection);
  const url = `/creators/${creatorId}/collections`;

  return request(url, { method, body });
}

export async function updateCollection({ creatorId, collectionId, collection }) {
  const method = 'PUT';
  const body = JSON.stringify(collection);
  const url = `/creators/${creatorId}/collections/${collectionId}`;

  return request(url, { method, body });
}

export async function removeCollection({ creatorId, collectionId }) {
  const method = 'DELETE';
  const url = `/creators/${creatorId}/collections/${collectionId}`;

  return request(url, { method });
}

export async function getAllEpisodes({ creatorId }) {
  const { creator, episodes } = await getCreator(creatorId);
  return { creators: [creator], episodes };
}

export async function getAllSeriesForCreator({ creatorId }) {
  const url = `/creators/${creatorId}/series`;
  return request(url);
}

export async function getSeries({ seriesId, creatorId }) {
  const url = `/creators/${creatorId}/series/${seriesId}`;
  return request(url);
}

export async function updateSeries({ creatorId, seriesId, series }) {
  const method = 'PUT';
  const body = JSON.stringify(series);
  const url = `/creators/${creatorId}/series/${seriesId}`;

  return request(url, { method, body });
}

export async function removeSeries({ creatorId, seriesId }) {
  const method = 'DELETE';
  const url = `/creators/${creatorId}/series/${seriesId}`;

  return request(url, { method });
}
