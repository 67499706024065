import PropTypes from 'prop-types';
import Accordion from 'components/accordion/Accordion';
import AccordionSection from 'components/accordion/AccordionSection';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import SizeInput from 'components/input/SizeInput';
import PositionInput from 'components/input/PositionInput';
import CounterInput from 'components/input/CounterInput';
import SelectInput from 'components/input/SelectInput';
import AudioConfig from 'components/do-it-forms/components/AudioConfig';

const DropTarget = ({ onChange, onDelete, value, types, exposeRejectState, i }) => {
  const {
    spriteFile,
    acceptSound,
    position,
    size,
    dropSpriteFile,
    fullSpriteFile,
    maxNumDrops,
    rejectSound,
  } = value;
  const accordionTitle = `Drop Target #${i + 1}`;

  return (
    <Accordion title={accordionTitle} key={accordionTitle} onDelete={onDelete}>
      <SizeInput
        sizeX={size.x}
        sizeY={size.y}
        positionX={position.x}
        positionY={position.y}
        onChange={onChange}
      />
      <PositionInput positionX={position.x} positionY={position.y} onChange={onChange} />
      {types.length > 0 && (
        <SelectInput
          isMulti
          label="Drop Target Acceptable Object Types"
          placeholder="Select Draggable Object Types"
          onChange={(values) => onChange('acceptedTypes', values?.map(({ value }) => value) || [])}
          options={types.map((type) => ({
            value: type,
            label: type,
          }))}
        />
      )}
      <br />
      <br />
      <AccordionSection
        title="Resting State"
        description="The default state of the Drop Target before any Draggable Objects have interacted with it."
      >
        <ImageUploadInput
          label="Drop Target Resting Image"
          onChange={(value) => onChange('spriteFile', value)}
          value={spriteFile}
        />
      </AccordionSection>
      <AccordionSection
        title="Accept State"
        description="The transitional state of the Drop Target when an Accepted Draggable Object is dropped. If an Accept Sound is specified, the transition will last for the length of that sound."
      >
        <ImageUploadInput
          label="Drop Target Accept Image"
          onChange={(value) => onChange('dropSpriteFile', value)}
          value={dropSpriteFile}
        />
        <AudioConfig
          label="Drop Sound Accept Effect"
          onChange={(value) => onChange('acceptSound', value)}
          value={acceptSound}
        />
      </AccordionSection>
      {exposeRejectState && (
        <AccordionSection
          title="Reject State"
          description="The transitional state of the Drop Target when the wrong type of Draggable Object is dropped."
        >
          <AudioConfig
            label="Drop Sound Accept Effect"
            onChange={(value) => onChange('rejectSound', value)}
            value={rejectSound}
          />
        </AccordionSection>
      )}
      <AccordionSection
        title="Populated State"
        description="The final state of the Drop Target after a Draggable Object has been dropped and accepted."
      >
        <ImageUploadInput
          label="Image"
          onChange={(value) => onChange('fullSpriteFile', value)}
          value={fullSpriteFile}
        />
      </AccordionSection>
      <AccordionSection>
        <CounterInput
          label="Max number of items"
          value={maxNumDrops}
          onChange={(value) => onChange('maxNumDrops', value)}
        />
      </AccordionSection>
    </Accordion>
  );
};

DropTarget.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  types: PropTypes.array.isRequired,
  i: PropTypes.number.isRequired,
  onDelete: PropTypes.func,
  exposeRejectState: PropTypes.bool,
};

DropTarget.defaultProps = {
  exposeRejectState: false,
  onDelete: null,
};

export default DropTarget;
