import { Component } from 'react';
import PropTypes from 'prop-types';

import cuid from 'cuid';
import * as styles from 'components/episode-edit-panel/EpisodeEditPanel.css';
import { SUPPORTED_DO_ITS } from 'components/do-it-forms/constants';
import {
  DEFAULT_DO_IT_DURATION_SECS,
  DEFAULT_DO_IT_SETTINGS_OBJECT,
} from 'components/episode-edit-panel/constants';
import DoItTemplateButton from 'components/episode-edit-panel/DoItTemplateButton';
import { ThumbnailUploadInput, VideoUploadInput } from 'components/file-upload/FileUploadInput';
import UnsupportedForm from 'components/do-it-forms/forms/Unsupported';
import DoItLibrary from 'components/episode-edit-panel/DoItLibrary';

class EpisodeEditPanel extends Component {
  static propTypes = {
    videoName: PropTypes.string.isRequired,
    episodeId: PropTypes.string.isRequired,
    creatorId: PropTypes.string.isRequired,
    doits: PropTypes.array.isRequired,
    onDoitChange: PropTypes.func.isRequired,
    onDoitDelete: PropTypes.func.isRequired,
    episodeDurationSecs: PropTypes.number.isRequired,
    viewingItemId: PropTypes.any,
    thumbnailFile: PropTypes.object,
    onViewingItemIdChange: PropTypes.func.isRequired,
    playerCurrentTime: PropTypes.number.isRequired,
    onThumbnailFileChange: PropTypes.func.isRequired,
    onPlayerCurrentTimeChange: PropTypes.func.isRequired,
    handleReplaceVideo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    viewingItemId: null,
    thumbnailFile: null,
  };

  state = {
    viewingTemplateId: null,
    viewingVariantId: null,
  };

  componentWillReceiveProps({ doits, viewingItemId }) {
    const selectedDoIt = this.getSelectedDoit(doits, viewingItemId);
    this.setState({
      viewingTemplateId: selectedDoIt?.baseTemplateId || null,
    });
  }

  // TODO: extract this?
  getVideoTimesFromEpisodeTimes(doit) {
    let episodeOffsetTime = 0;
    const doits = [...this.props.doits];

    const existingDoitIndex = doits.findIndex((d) => d.itemId === doit.itemId);

    if (existingDoitIndex > -1) {
      doits[existingDoitIndex] = { ...doit };
    } else {
      doits.push(doit);
    }

    const sortedDoits = doits.sort(
      (a, b) => a.startSecsWithEpisodeOffset - b.startSecsWithEpisodeOffset,
    );

    /* eslint-disable-next-line no-restricted-syntax */
    for (const sortedDoit of sortedDoits) {
      if (sortedDoit.itemId === doit.itemId) {
        break;
      }

      if (sortedDoit.pauseVideoUntilComplete) {
        episodeOffsetTime += sortedDoit.maxDuration;
      }
    }

    const startSecs = doit.startSecsWithEpisodeOffset - episodeOffsetTime;
    const finishSecs = doit.finishSecsWithEpisodeOffset - episodeOffsetTime;

    return {
      startSecs,
      finishSecs,
    };
  }

  getDoitTemplates() {
    if (this.doitTemplates) {
      return this.doitTemplates;
    }

    this.doitTemplates = SUPPORTED_DO_ITS.map((supportedDoIt) => ({
      ...supportedDoIt,
      Component: (props) => (
        <supportedDoIt.Component
          onClose={this.handleTemplateFormClose.bind(this)}
          onSave={(doit) => {
            this.props.onDoitChange({
              ...doit,
              ...this.getVideoTimesFromEpisodeTimes(doit),
              baseTemplateId: supportedDoIt.baseTemplateId,
            });
          }}
          onDelete={(itemId) => {
            this.props.onDoitDelete(itemId);
            this.handleTemplateFormClose();
          }}
          {...props}
        />
      ),
    }));

    return this.doitTemplates;
  }

  handleDoitButtonClick(viewingTemplateId, viewingVariantId) {
    this.setState({ viewingTemplateId, viewingVariantId });
  }

  handleTemplateFormClose() {
    this.props.onViewingItemIdChange(null);
  }

  // TODO: handle case where there is already a doit in the proceeding space.
  getDefaultStartEnd() {
    const { doits, playerCurrentTime, episodeDurationSecs } = this.props;

    let startSecsWithEpisodeOffset = Math.floor(playerCurrentTime);

    const overlappingStartDoit = doits.find(
      (doit) =>
        doit.startSecsWithEpisodeOffset <= startSecsWithEpisodeOffset &&
        startSecsWithEpisodeOffset <= doit.finishSecsWithEpisodeOffset,
    );

    if (overlappingStartDoit) {
      startSecsWithEpisodeOffset = overlappingStartDoit.finishSecsWithEpisodeOffset + 1;
    }

    let finishSecsWithEpisodeOffset = Math.min(
      startSecsWithEpisodeOffset + DEFAULT_DO_IT_DURATION_SECS,
      episodeDurationSecs,
    );

    const overlappingFinishDoit = doits.find(
      (doit) =>
        doit.startSecsWithEpisodeOffset <= finishSecsWithEpisodeOffset &&
        finishSecsWithEpisodeOffset <= doit.finishSecsWithEpisodeOffset,
    );

    if (overlappingFinishDoit) {
      finishSecsWithEpisodeOffset = overlappingFinishDoit.startSecsWithEpisodeOffset - 1;
    }

    return {
      startSecsWithEpisodeOffset,
      finishSecsWithEpisodeOffset,
    };
  }

  renderTemplateForm(doitTemplates) {
    const { viewingItemId, doits, onDoitDelete } = this.props;
    const { viewingTemplateId, viewingVariantId } = this.state;
    const doitTemplate = doitTemplates.find(
      ({ baseTemplateId }) => baseTemplateId === viewingTemplateId,
    );

    const selectedDoit = this.getSelectedDoit(doits, viewingItemId);

    const doit = selectedDoit || {
      name: '',
      ...this.getDefaultStartEnd(),
      settings: DEFAULT_DO_IT_SETTINGS_OBJECT,
      variantId: viewingVariantId,
    };

    if (!doitTemplate) {
      return (
        <div className={styles.episodeEditPanel}>
          <UnsupportedForm
            onClose={this.handleTemplateFormClose.bind(this)} // eslint-disable-line react/jsx-no-bind
            onSave={() => null}
            onDelete={(itemId) => {
              onDoitDelete(itemId);
              this.handleTemplateFormClose();
            }}
            doit={doit}
          />
        </div>
      );
    }

    const { Component } = doitTemplate;
    const subsequentDoitId = this.getSubsequentDoitId(doits, doit.itemId);

    return (
      <div className={styles.episodeEditPanel}>
        <Component doit={doit} subsequentDoitId={subsequentDoitId} />
      </div>
    );
  }

  getSelectedDoit(doits, viewingItemId) {
    return doits.find((doit) => doit.itemId === viewingItemId) || null;
  }

  getSubsequentDoitId(doits, viewingItemId) {
    const viewingItemIndex = doits.findIndex((doit) => doit.itemId === viewingItemId);

    return viewingItemIndex > -1 && viewingItemIndex !== doits.length - 1
      ? doits[viewingItemIndex + 1].itemId
      : null;
  }

  handlePreviouslyCreatedClick = (doit) => {
    const defaultStartEnd = this.getDefaultStartEnd();
    const videoTimesFromEpisodeTimes = this.getVideoTimesFromEpisodeTimes(defaultStartEnd);

    this.props.onDoitChange({
      ...doit,
      ...defaultStartEnd,
      ...videoTimesFromEpisodeTimes,
      itemId: cuid(),
      baseTemplateId: doit.baseTemplateId,
    });
  };

  render() {
    const { viewingTemplateId } = this.state;
    const doitTemplates = this.getDoitTemplates();

    if (viewingTemplateId) {
      return this.renderTemplateForm(doitTemplates);
    }

    const doItTemplateButtons = doitTemplates.map(
      ({ baseTemplateId, displayName, hexColor, variants }) => (
        <DoItTemplateButton
          key={baseTemplateId}
          name={displayName}
          hexColor={hexColor}
          variants={variants}
          onClick={(variantId) => this.handleDoitButtonClick(baseTemplateId, variantId)}
        />
      ),
    );
    const {
      thumbnailFile,
      episodeId,
      onThumbnailFileChange,
      creatorId,
      handleReplaceVideo,
      videoName,
    } = this.props;

    return (
      <div className={styles.episodeEditPanel}>
        <section>
          <h4>Show Settings</h4>
          <ThumbnailUploadInput
            label="Thumbnail Image"
            onChange={onThumbnailFileChange}
            value={thumbnailFile}
            episodeId={episodeId}
          />
        </section>
        <section>
          <h4>
            Replace Video <span className={styles.videoName}>({videoName})</span>
          </h4>
          <label>NOTE: this saves all changes and refreshes the page</label>
          <VideoUploadInput
            label="Video"
            onChange={(file) => handleReplaceVideo(file)}
            creatorId={creatorId}
            dropAreaOnly
            hideLabel
          />
        </section>
        <section>
          <h4>Do It Templates</h4>
          <div className={styles.episodeEditPanelTemplates}>{doItTemplateButtons}</div>
        </section>
        <section>
          <DoItLibrary onSelection={this.handlePreviouslyCreatedClick} creatorId={creatorId} />
        </section>
      </div>
    );
  }
}

export default EpisodeEditPanel;
