import * as utils from 'utils';
import { CMSTextButton } from 'components/button/Button';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import SizeInput from 'components/input/SizeInput';
import Accordion from 'components/accordion/Accordion';
import DoItForm from 'components/do-it-forms/forms/DoIt';
import BaseForm from 'components/do-it-forms/forms/Base';
import BackgroundAccordion from 'components/do-it-forms/accordions/BackgroundAccordion';
import CounterInput from 'components/input/CounterInput';
import CheckboxInput from 'components/input/CheckboxInput';
import AudioConfig from 'components/do-it-forms/components/AudioConfig';
import { getDefaultAudioConfig } from 'components/do-it-forms/utils';

class StampItForm extends BaseForm {
  getDefaultParams() {
    return {
      background: {
        spriteFile: null,
      },
      maxNumStamps: 0,
      stamps: [StampItForm.getDefaultStamp()],
      randomStampOrder: true,
    };
  }

  static getDefaultStamp(lastStamp) {
    return {
      size: {
        x: lastStamp?.size.x ?? 150,
        y: lastStamp?.size.y ?? 150,
      },
      spriteFile: lastStamp?.spriteFile ?? null,
      stampSound: lastStamp?.audioFile ?? getDefaultAudioConfig(),
    };
  }

  renderStampAccordions({ size, spriteFile, stampSound }, i) {
    const accordionTitle = `Stamp Item #${i + 1}`;
    const onChange = (prop, value) => {
      this.setState(({ stamps }) => {
        const stampsCopy = utils.deepClone(stamps);
        stampsCopy[i][prop] = value;

        return {
          stamps: stampsCopy,
        };
      });
    };
    const onDelete = i > 0 ? () => this.handleRemoveStamp(i) : null;

    return (
      <Accordion title={accordionTitle} key={accordionTitle} onDelete={onDelete}>
        <ImageUploadInput
          label="Image"
          onChange={(value) => onChange('spriteFile', value)}
          value={spriteFile}
        />
        <AudioConfig
          label="Transition Sound Effect"
          value={stampSound}
          onChange={(audio) => onChange('stampSound', audio)}
        />
        <SizeInput sizeX={size.x} sizeY={size.y} onChange={onChange} />
      </Accordion>
    );
  }

  renderCompleteAccordion() {
    const { randomStampOrder = true } = this.state;
    const { maxNumStamps } = this.state;
    const onMaxNumStampsChange = (maxNumStamps) => {
      this.setState({
        maxNumStamps,
      });
    };

    const onRandomOrderChange = (randomStampOrder) => {
      this.setState({ randomStampOrder });
    };

    return (
      <>
        <Accordion title="Stamp It Settings">
          <CounterInput
            label="Max number of stamps"
            value={maxNumStamps}
            onChange={onMaxNumStampsChange}
          />
          <CheckboxInput
            label="Randomize Stamp Order"
            description="Uses the order in which stamps were added if disabled."
            value={randomStampOrder}
            onChange={onRandomOrderChange}
          />
        </Accordion>
      </>
    );
  }

  handleAddStamp() {
    this.setState(({ stamps }) => ({
      stamps: [...stamps, StampItForm.getDefaultStamp(stamps[stamps.length - 1])],
    }));
  }

  handleRemoveStamp(formIndex) {
    this.setState(({ stamps }) => ({
      stamps: stamps.filter((_, i) => i !== formIndex),
    }));
  }

  getDoitParams() {
    return this.state;
  }

  render() {
    const { onClose, onDelete, doit, subsequentDoitId } = this.props;
    const { stamps, background } = this.state;
    const stampAccordionElements = stamps.map((item, i) => this.renderStampAccordions(item, i));
    const completeAccordionElement = this.renderCompleteAccordion();

    return (
      <DoItForm
        title="Stamp It"
        doit={doit}
        onClose={onClose}
        onSave={(options) => this.handleSave(options)}
        onDelete={onDelete}
        subsequentDoitId={subsequentDoitId}
      >
        <BackgroundAccordion
          value={background}
          onChange={(key, value) => this.setState({ [key]: value })}
        />
        {stampAccordionElements}
        <CMSTextButton label="Add Another Stamp Item" onClick={() => this.handleAddStamp()} />
        {completeAccordionElement}
      </DoItForm>
    );
  }
}

export default StampItForm;
