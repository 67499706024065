import PropTypes from 'prop-types';

import { SearchIcon } from 'components/Icon';

import styles from 'components/input/Input.css';

const SearchInput = ({ className, value, placeholder, onChange }) => (
  <span className={className}>
    <SearchIcon />
    <input placeholder={placeholder} value={value} onChange={onChange} />
  </span>
);

SearchInput.defaultProps = {
  value: '',
  placeholder: 'Search…',
  className: styles.searchInput,
  onChange: () => {},
};

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default SearchInput;
