import DoItForm from 'components/do-it-forms/forms/DoIt';
import BaseForm from 'components/do-it-forms/forms/Base';
import BackgroundAccordion from 'components/do-it-forms/accordions/BackgroundAccordion';
import DropTargets from 'components/do-it-forms/components/DropTargets';
import DraggableObjects from 'components/do-it-forms/components/DraggableObjects';
import {
  getDefaultDraggableObject,
  getDefaultDropTarget,
  getDefaultBackground,
} from 'components/do-it-forms/utils';

class SortItForm extends BaseForm {
  getDefaultParams() {
    return {
      background: getDefaultBackground(),
      draggableObjects: [getDefaultDraggableObject()],
      dropTargets: [getDefaultDropTarget()],
    };
  }

  getDoitParams() {
    return this.state;
  }

  render() {
    const { onClose, onDelete, doit, subsequentDoitId } = this.props;
    const { draggableObjects, background, dropTargets } = this.state;

    return (
      <DoItForm
        title="Sort It"
        doit={doit}
        onClose={onClose}
        onSave={(options) => this.handleSave(options)}
        onDelete={onDelete}
        subsequentDoitId={subsequentDoitId}
      >
        <BackgroundAccordion
          value={background}
          onChange={(key, value) => this.setState({ [key]: value })}
        />
        <DropTargets
          types={[...new Set(draggableObjects.map(({ type }) => type))]}
          exposeRejectState
          dropTargets={dropTargets}
          getDefault={getDefaultDropTarget}
          setState={(value) => this.setState(value)}
        />
        <DraggableObjects
          exposeType
          draggableObjects={draggableObjects}
          getDefaultDraggableObject={getDefaultDraggableObject}
          setState={(value) => this.setState(value)}
        />
      </DoItForm>
    );
  }
}

export default SortItForm;
