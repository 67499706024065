import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { INPUT_TYPES } from 'components/input/constants';
import { CMSWhiteInput } from 'components/input/Input';

class NumberInput extends PureComponent {
  static propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    isRequired: PropTypes.bool.isRequired,
  };

  render() {
    const { value, isRequired, onChange } = this.props;

    return (
      <>
        <label>Size {isRequired && <span>*</span>}</label>
        <CMSWhiteInput
          label="Scale Factor"
          type={INPUT_TYPES.NUMBER}
          value={value}
          onChange={onChange}
        />
      </>
    );
  }
}

export default NumberInput;
