import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styles from 'components/creator-layout/CreatorLayout.css';
import NavBar from 'components/navbar/NavBar';

const CreatorLayout = ({ activeCreator, children }) => (
  <div>
    <Helmet>
      <title>{activeCreator.title || 'Loading...'} :: Hellosaurus CMS</title>
    </Helmet>
    <NavBar />
    <main className={styles.main}>
      <div className={styles.pageContents}>{children}</div>
    </main>
  </div>
);

CreatorLayout.defaultProps = {
  activeCreator: {},
};

CreatorLayout.propTypes = {
  children: PropTypes.node.isRequired,
  activeCreator: PropTypes.object,
};

export default CreatorLayout;
