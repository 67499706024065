const GAME_OBJECT = 'Bridge';

export const isWebGLSupported = () => !!document.createElement('canvas').getContext('webgl2');

const updateUnityConfig = (config) => {
  const configWithSettings = {
    ...config,
    unitySettings: {
      hideCloseButton: true,
    },
  };

  window.EPISODE_CONFIG_FOR_UNITY = configWithSettings;
};

const loadAndInstantiateUnity = ({ newUnityBuild, onLoadComplete }) => {
  const existingScript = document.getElementById('unityBuild');

  if (!existingScript) {
    var buildUrl = newUnityBuild ? '/unity-new' : '/unity';
    var loaderUrl = buildUrl + '/unity.loader.js';
    var config = {
      dataUrl: buildUrl + '/unity.data',
      frameworkUrl: buildUrl + '/unity.framework.js',
      codeUrl: buildUrl + '/unity.wasm',
      streamingAssetsUrl: buildUrl + '/StreamingAssets',
    };

    var canvas = document.querySelector('#unityCanvas');

    var script = document.createElement('script');
    script.src = loaderUrl;
    script.id = 'unityBuild';
    script.onload = () =>
      createUnityInstance(canvas, config).then((unityInstance) => {
        window.unityInstance = unityInstance;
        if (onLoadComplete) onLoadComplete();
      });
    document.body.appendChild(script);
  }
};

const destroyUnity = () => {
  if (typeof window.unityInstance !== 'undefined') {
    window.unityInstance.Quit();
    window.unityInstance = undefined;
  }

  const unityScript = document.getElementById('unityBuild');
  if (unityScript) {
    unityScript.parentElement.removeChild(unityScript);
  }
};

const sendMessage = (functionName, param = null) => {
  if (typeof window.unityInstance === 'undefined') {
    return;
  }

  if (param) {
    window.unityInstance.SendMessage(GAME_OBJECT, functionName, param);
  } else {
    window.unityInstance.SendMessage(GAME_OBJECT, functionName);
  }
};

const updateUserJWT = (token) => {
  sendMessage('UpdateUserJWT', token);
};

const updateEpisodeConfigJson = (fn) => {
  window.updateEpisodeConfigJson = fn;
};

const saveEpisodeConfigJson = (fn) => {
  window.saveEpisodeConfigJson = fn;
};

const updateDoits = () => {
  sendMessage('UpdateDoIts');
};

const willUpdateDoits = () => {
  sendMessage('WillUpdateDoIts');
};

const seek = (seconds) => {
  sendMessage('Seek', Number(seconds.toFixed(2)) + 0.001);
};

const onUnityItemIdChange = (fn) => {
  window.updateActiveDoit = fn;
};

const onUnityVideoTimeChange = (fn) => {
  window.updateVideoTime = fn;
};

const onMediaLibraryOpen = (fn) => {
  window.onMediaLibraryOpen = fn;
};

const onMediaLibraryClose = (fn) => {
  window.onMediaLibraryClose = fn;
};

const onInsertActivity = (fn) => {
  window.insertActivity = fn;
};

const onInsertLibraryActivity = (fn) => {
  window.insertLibraryActivity = fn;
};

export default {
  updateUnityConfig,
  loadAndInstantiateUnity,
  destroyUnity,
  updateUserJWT,
  updateEpisodeConfigJson,
  updateDoits,
  seek,
  onUnityItemIdChange,
  onUnityVideoTimeChange,
  onMediaLibraryOpen,
  onMediaLibraryClose,
  onInsertActivity,
  willUpdateDoits,
  onInsertLibraryActivity,
  saveEpisodeConfigJson,
};
