import PropTypes from 'prop-types';
import { deepClone } from 'utils';
import CanvasObject from 'components/do-it-forms/components/CanvasObject';
import { CMSTextButton } from 'components/button/Button';

const CanvasObjects = ({
  canvasObjects,
  setState,
  getDefaultCanvasObject,
  objectName,
  keyName,
}) => {
  const onCanvasObjectChange = (i) => (prop, value) => {
    setState((currentState) => {
      const canvasObjectsCopy = deepClone(currentState[keyName]);
      canvasObjectsCopy[i][prop] = value;

      return {
        [keyName]: canvasObjectsCopy,
      };
    });
  };

  const handleRemoveCanvasObject = (formIndex) => {
    setState((currentState) => ({
      [keyName]: currentState[keyName].filter((_, i) => i !== formIndex),
    }));
  };

  const handleAddCanvasObject = () => {
    setState((currentState) => {
      const currentObjects = currentState[keyName];

      if (!currentObjects) {
        return {
          [keyName]: [getDefaultCanvasObject()],
        };
      }

      return {
        [keyName]: [
          ...currentObjects,
          getDefaultCanvasObject(currentObjects[currentObjects.length - 1]),
        ],
      };
    });
  };

  return (
    <>
      {canvasObjects.map((item, i) => (
        <CanvasObject
          i={i}
          canvasObject={item}
          onChange={onCanvasObjectChange(i)}
          onDelete={() => handleRemoveCanvasObject(i)}
          objectName={objectName}
        />
      ))}
      <CMSTextButton
        label={canvasObjects.length > 0 ? `Add Another ${objectName}` : `Add ${objectName}`}
        onClick={() => handleAddCanvasObject()}
      />
    </>
  );
};

CanvasObjects.propTypes = {
  canvasObjects: PropTypes.array.isRequired,
  setState: PropTypes.func.isRequired,
  getDefaultCanvasObject: PropTypes.func.isRequired,
  objectName: PropTypes.string,
  keyName: PropTypes.string,
};

CanvasObjects.defaultProps = {
  objectName: 'Foreground Object',
  keyName: 'canvasObjects',
};

export default CanvasObjects;
