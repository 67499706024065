import { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import PropTypes from 'prop-types';
import isToday from 'date-fns/isToday';
import endOfToday from 'date-fns/endOfToday';
import startOfTomorrow from 'date-fns/startOfTomorrow';

import { Input } from 'components/input/Input';

import styles from 'components/forms/styles.css';

const RELEASE_OPTIONS = {
  NOW: 'now',
  SCHEDULED: 'scheduled',
};

const roundToNearest30 = (date = new Date()) => {
  const minutes = 30;
  const ms = 1000 * 60 * minutes;

  return new Date(Math.ceil(date.getTime() / ms) * ms);
};

const EpisodeReleaseForm = forwardRef(({ episodeId, handleSetPublished }, ref) => {
  const [value, setValue] = useState(RELEASE_OPTIONS.NOW);
  const [releaseDate, setReleaseDate] = useState(new Date());

  useEffect(() => {
    if (value === RELEASE_OPTIONS.NOW) setReleaseDate(new Date());
    else setReleaseDate(roundToNearest30());
  }, [value]);

  const onRadioChange = (event) => setValue(event.target.value);

  const onDateChange = (date) => {
    let validDate = date;

    if (date < roundToNearest30()) {
      validDate = roundToNearest30();
    }

    setReleaseDate(validDate);
  };

  const submit = () => handleSetPublished(episodeId, true, releaseDate);

  useImperativeHandle(ref, () => ({ submit }));

  return (
    <div className={styles.desiredDateForm}>
      <div className={styles.dateSwitch}>
        <input
          type="radio"
          name="release-date"
          id={RELEASE_OPTIONS.NOW}
          value={RELEASE_OPTIONS.NOW}
          checked={value === RELEASE_OPTIONS.NOW}
          onChange={onRadioChange}
        />
        <label htmlFor={RELEASE_OPTIONS.NOW}>Now</label>

        <input
          type="radio"
          name="release-date"
          id={RELEASE_OPTIONS.SCHEDULED}
          value={RELEASE_OPTIONS.SCHEDULED}
          checked={value === RELEASE_OPTIONS.SCHEDULED}
          onChange={onRadioChange}
        />
        <label htmlFor={RELEASE_OPTIONS.SCHEDULED}>Scheduled</label>
      </div>

      {value === RELEASE_OPTIONS.SCHEDULED ? (
        <Input
          type="date"
          value={releaseDate}
          minDate={new Date()}
          minTime={isToday(releaseDate) ? roundToNearest30() : startOfTomorrow()}
          maxTime={endOfToday()}
          onChange={onDateChange}
        />
      ) : null}
    </div>
  );
});

EpisodeReleaseForm.propTypes = {
  episodeId: PropTypes.string.isRequired,
  handleSetPublished: PropTypes.func.isRequired,
};

export default EpisodeReleaseForm;
