import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as styles from 'components/timeline/TimelinePlayerPin.css';

class TimelinePlayerPin extends PureComponent {
  static propTypes = {
    percent: PropTypes.number.isRequired,
  };

  render() {
    const { percent } = this.props;
    const inlineStyles = {
      left: `${percent}%`,
    };

    return <div className={styles.timelinePlayerPin} style={inlineStyles} />;
  }
}

export default TimelinePlayerPin;
