/* eslint-disable no-alert, react/prop-types */

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash.get';
import { NotificationManager } from 'react-notifications';

import * as adminService from 'services/content/adminService';
import {
  BundleTypes,
  dataKeyForBundleType,
  getBackgroundImageGradientStyles,
  getTopicTileBackgroundStyles,
} from 'utils';

import Table from 'components/table/Table';
import { CMSWhiteButton } from 'components/button/Button';

import styles from 'components/table/Table.css';

const BundleDataConfig = {
  CREATOR: {
    type: 'CREATOR',
    copy: 'Creator',
    bundleDataPropertyKey: 'channelId',
    datumIdAccessor: 'data.creator.creatorId',
    datumPropertyAccessor: (property) => `data.creator.${property}`,
  },
  CREATOR_COLLECTION: {
    type: 'CREATOR_COLLECTION',
    copy: 'Creator Collection',
    bundleDataPropertyKey: 'collectionId',
    datumIdAccessor: 'data.collection.collectionId',
    datumPropertyAccessor: (property) => `data.collection.${property}`,
  },
  MULTI_CREATOR_COLLECTION: {
    type: 'MULTI_CREATOR_COLLECTION',
    copy: 'Multi-Creator Collection',
    bundleDataPropertyKey: 'multiCreatorCollectionId',
    datumIdAccessor: 'data.collection.collectionId',
    datumPropertyAccessor: (property) => `data.collection.${property}`,
  },
  TOPIC: {
    type: 'TOPIC',
    copy: 'Topic',
    bundleDataPropertyKey: 'topicId',
    datumIdAccessor: 'data.topic.topicId',
    datumPropertyAccessor: (property) => `data.topic.${property}`,
  },
  SERIES: {
    type: 'SERIES',
    copy: 'Series',
    bundleDataPropertyKey: 'seriesId',
    datumIdAccessor: 'data.series.seriesId',
    datumPropertyAccessor: (property) => `data.series.${property}`,
  },
};

const BundleDataIdAccessorsByBundleType = {
  [BundleTypes.CREATOR_BUNDLE]: 'data.creator.creatorId',
  [BundleTypes.COLLECTION_BUNDLE]: 'data.collection.collectionId',
  [BundleTypes.TOPIC_BUNDLE]: 'data.topic.topicId',
  [BundleTypes.SERIES_BUNDLE]: 'data.series.seriesId',
};

const getItemIdsFromBundleData = (items) =>
  items.map((datum) => ({
    [BundleDataConfig[datum.type].bundleDataPropertyKey]: get(
      datum,
      BundleDataConfig[datum.type].datumIdAccessor,
    ),
  }));

const getAttribute = (datum, attribute) =>
  get(datum, BundleDataConfig[datum.type].datumPropertyAccessor(attribute));

const BundleDataTable = ({ bundle: { bundleId, type }, updateBundleDetails, data, setData }) => {
  const updateAndPersistBundleData = async (dataIds) => {
    const updatedBundle = await adminService.updateBundle({
      bundleId,
      bundle: {
        [dataKeyForBundleType[type]]: dataIds,
      },
    });

    updateBundleDetails(updatedBundle);
  };

  const handleRemove = async (datumId) => {
    if (!window.confirm('Are you sure you want to remove that from this bundle?')) {
      return;
    }

    const filteredItems = data.filter((currentDatum) => {
      const currentDatumId = get(currentDatum, BundleDataConfig[currentDatum.type].datumIdAccessor);
      return currentDatumId !== datumId;
    });

    const dataIds = getItemIdsFromBundleData(filteredItems);
    await updateAndPersistBundleData(dataIds);
    NotificationManager.success('Successfully removed.');
  };

  const handleReorder = async (items) => {
    setData(items);
    const dataIds = getItemIdsFromBundleData(items);
    await updateAndPersistBundleData(dataIds);
    NotificationManager.success('Successfully reordered items.');
  };

  const columns = useMemo(
    () => [
      {
        id: 'thumbnail',
        Header: () => null,
        Cell: ({ row: { original } }) => {
          const title = getAttribute(original, 'title');
          const thumbnailStyles = [styles.thumbnail];
          let imageUrl = getAttribute(original, 'backgroundImageUrl');

          if (original.type === BundleDataConfig.TOPIC.type) {
            const color = getAttribute(original, 'color');
            return (
              <div className={cx(styles.topicTile)} style={getTopicTileBackgroundStyles(color)}>
                <img src={getAttribute(original, 'dinoUrl')} alt={title} />
              </div>
            );
          }

          if (original.type === BundleDataConfig.SERIES.type) {
            const { extendedName, logoUrl, thumbnailUrl, backgroundColor } = original.data.series;
            const bgImageStyle = getBackgroundImageGradientStyles({
              backgroundImageUrl: thumbnailUrl,
              backgroundColor,
            });

            return (
              <div
                className={cx(styles.thumbnail, styles.vertical)}
                style={{ backgroundImage: `${bgImageStyle}` }}
              >
                <img src={logoUrl} alt={extendedName} />
              </div>
            );
          }

          if (original.type === BundleDataConfig.CREATOR.type) {
            thumbnailStyles.push(styles.square);
            imageUrl = getAttribute(original, 'avatarUrl');
          }

          return <img src={imageUrl} alt={title} className={cx(thumbnailStyles)} />;
        },
        style: { width: '86px' },
      },
      {
        id: 'title',
        Header: 'Title',
        Cell: ({ row: { original } }) => {
          let title = getAttribute(original, 'title');

          if (original.type === BundleDataConfig.SERIES.type) {
            title = getAttribute(original, 'extendedName');
          }

          return <p className={styles.title}>{title}</p>;
        },
      },
      {
        id: 'type',
        Header: 'Type',
        Cell: ({ row: { original } }) => (
          <p className={styles.text}>{BundleDataConfig[original.type].copy}</p>
        ),
      },
      {
        id: 'creator',
        Header: 'Creator',
        Cell: ({ row: { original } }) => {
          let creatorTitle = getAttribute(original, 'title') || 'Hellosaurus - Admin';

          if (original.type === BundleDataConfig.SERIES.type) {
            creatorTitle = getAttribute(original, 'attributes.creatorTitle');
          }

          return <p className={styles.text}>{creatorTitle}</p>;
        },
      },
      {
        id: 'status',
        Header: () => (type !== BundleTypes.TOPIC_BUNDLE ? 'Status' : null),
        Cell: ({ row: { original } }) => {
          if (original.type === BundleDataConfig.TOPIC.type) return null;

          const isPublished = getAttribute(original, 'isPublished');
          return <p className={styles.text}>{isPublished ? 'Active' : 'Inactive'}</p>;
        },
      },
    ],
    [],
  );

  return (
    <Table
      data={data}
      columns={columns}
      datumIdAccessor={BundleDataIdAccessorsByBundleType[type]}
      hideFilters
      hoverActions={(data) => (
        <CMSWhiteButton
          label="Remove from Bundle"
          onClick={() => handleRemove(get(data, BundleDataConfig[data.type].datumIdAccessor))}
        />
      )}
      onReorder={handleReorder}
    />
  );
};

BundleDataTable.propTypes = {
  bundle: PropTypes.object.isRequired,
  updateBundleDetails: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  setData: PropTypes.func.isRequired,
};

export default BundleDataTable;
