import PropTypes from 'prop-types';

import DoItForm from 'components/do-it-forms/forms/DoIt';
import BaseForm from 'components/do-it-forms/forms/Base';
import BackgroundAccordion from 'components/do-it-forms/accordions/BackgroundAccordion';
import ObjectList from 'components/do-it-forms/components/ObjectList';
import Accordion from 'components/accordion/Accordion';
import AccordionSection from 'components/accordion/AccordionSection';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import SizeInput from 'components/input/SizeInput';
import PositionInput from 'components/input/PositionInput';
import CheckboxInput from 'components/input/CheckboxInput';
import AudioConfig from 'components/do-it-forms/components/AudioConfig';
import {
  getDefaultDraggableObject,
  getDefaultDropTarget,
  getDefaultBackground,
} from 'components/do-it-forms/utils';

function getDefaultAssembleItDropTarget(lastDropTarget) {
  return {
    ...getDefaultDropTarget(lastDropTarget),
    hideOnDrop: true,
  };
}

const DropTarget = ({ onChange, onDelete, value, i }) => {
  const accordionTitle = `Drop Target #${i + 1}`;

  return (
    <Accordion title={accordionTitle} key={accordionTitle} onDelete={onDelete}>
      <ImageUploadInput
        label="Drop Target Image"
        onChange={(value) => onChange('spriteFile', value)}
        value={value.spriteFile}
      />
      <SizeInput sizeX={value.size.x} sizeY={value.size.y} onChange={onChange} />
      <PositionInput
        positionX={value.position.x}
        positionY={value.position.y}
        onChange={onChange}
      />
      <CheckboxInput
        label="Hide On Drop"
        description="Hide target image after successful drop."
        value={value.hideOnDrop}
        onChange={(value) => onChange('hideOnDrop', value)}
        forKey={accordionTitle}
      />
    </Accordion>
  );
};

DropTarget.propTypes = {
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
};

const DraggableObject = ({ value, i, onChange, onDelete }) => {
  const accordionTitle = `Draggable Object #${i + 1}`;

  return (
    <Accordion title={accordionTitle} key={accordionTitle} onDelete={onDelete}>
      <AccordionSection
        title="Starting State"
        description="The default state of the Draggable Object before it has been dropped in the Drop Target."
      >
        <ImageUploadInput
          label="Starting Image"
          onChange={(value) => onChange('spriteFile', value)}
          value={value.spriteFile}
        />
        <SizeInput sizeX={value.size.x} sizeY={value.size.y} onChange={onChange} />
        <PositionInput
          positionX={value.position.x}
          positionY={value.position.y}
          onChange={onChange}
        />
      </AccordionSection>
      <AccordionSection
        title="Accepted State"
        description="The final state of the Draggable Object after the it has been dropped and accepted."
      >
        <SizeInput
          label="Final Size"
          sizeX={value.finalSize?.x || value.size.x}
          sizeY={value.finalSize?.y || value.size.y}
          onChange={(_, value) => onChange('finalSize', value)}
        />
        <ImageUploadInput
          label="Final Accepted State Image"
          onChange={(value) => onChange('dropSpriteFile', value)}
          value={value.dropSpriteFile}
        />
        <AudioConfig
          label="Accepted Sound Effect"
          value={value.acceptSound}
          onChange={(value) => onChange('acceptSound', value)}
        />
        <AudioConfig
          label="Rejected Sound Effect"
          value={value.rejectSound}
          onChange={(value) => onChange('rejectSound', value)}
        />
        <CheckboxInput
          forKey={`removable-${i}`}
          label="Is Object Removable"
          description="Object can be picked up after being dropped on a target."
          value={value?.removable}
          onChange={(value) => onChange('removable', value)}
        />
      </AccordionSection>
    </Accordion>
  );
};

DraggableObject.propTypes = {
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
};

class AssembleItForm extends BaseForm {
  getDefaultParams() {
    return {
      background: getDefaultBackground(),
      buildItTargets: [getDefaultAssembleItDropTarget()],
      buildItObjects: [getDefaultDraggableObject()],
    };
  }

  getDoitParams() {
    return this.state;
  }

  render() {
    const { onClose, onDelete, doit, subsequentDoitId } = this.props;
    const { buildItTargets, buildItObjects, background } = this.state;

    return (
      <DoItForm
        title="Assemble It"
        doit={doit}
        onClose={onClose}
        onSave={(options) => this.handleSave(options)}
        onDelete={onDelete}
        subsequentDoitId={subsequentDoitId}
      >
        <BackgroundAccordion
          value={background}
          onChange={(key, value) => this.setState({ [key]: value })}
        />
        <ObjectList
          objects={buildItObjects}
          getDefault={getDefaultDraggableObject}
          onChange={(buildItObjects) => this.onChange('buildItObjects', buildItObjects)}
          name="Draggable Object"
          Component={DraggableObject}
        />
        <ObjectList
          objects={buildItTargets}
          getDefault={getDefaultAssembleItDropTarget}
          onChange={(buildItTargets) => this.onChange('buildItTargets', buildItTargets)}
          name="Drop Target"
          Component={DropTarget}
        />
      </DoItForm>
    );
  }
}

export default AssembleItForm;
