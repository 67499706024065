// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.CreatorLayout_main___2tW33N1YJD {\n  display: flex;\n}\n\n.CreatorLayout_pageContents___3TnzGwskJc {\n  flex-grow: 1;\n}\n\n.CreatorLayout_pageContents___3TnzGwskJc h1 {\n    color: #000000;\n    font-size: 2em;\n    text-align: left;\n    margin: 1em 1em 0;\n  }\n\n.CreatorLayout_pageContents___3TnzGwskJc h2 {\n    color: #000000;\n    font-size: 18px;\n  }\n", ""]);
// Exports
exports.locals = {
	"main": "CreatorLayout_main___2tW33N1YJD",
	"pageContents": "CreatorLayout_pageContents___3TnzGwskJc"
};
module.exports = exports;
