/* eslint-disable no-alert, react/prop-types */

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NotificationManager } from 'react-notifications';

import * as adminService from 'services/content/adminService';
import * as contentService from 'services/content/contentService';
import { getEpisodeStatus } from 'utils';

import Table from 'components/table/Table';
import { CMSButton, CMSWhiteButton } from 'components/button/Button';

import styles from 'components/table/Table.css';

const CollectionEpisodesTable = ({
  episodes,
  collectionId,
  creatorId,
  updateCollectionDetails,
  hideSingleCreatorButtons,
  collectionService,
}) => {
  const handleRemoveFromCollection = async (episodeId) => {
    if (!window.confirm('Are you sure you want to remove that story from this collection?')) {
      return;
    }

    const filteredEpisodeIds = episodes
      .filter((episode) => episode.episodeId !== episodeId)
      .map((episode) => episode.episodeId);

    const updatedCollection = await collectionService.updateCollection({
      creatorId,
      collectionId,
      collection: { episodeIds: filteredEpisodeIds },
    });

    updateCollectionDetails(updatedCollection);
    NotificationManager.success('Successfully removed story.');
  };

  const handleReorder = async (items) => {
    updateCollectionDetails({ episodes: items });

    const episodeIds = items.map(({ episodeId }) => episodeId);
    const collection = await collectionService.updateCollection({
      creatorId,
      collectionId,
      collection: { episodeIds },
    });

    updateCollectionDetails(collection);
    NotificationManager.success('Successfully reordered stories.');
  };

  const columns = useMemo(
    () => [
      {
        id: 'thumbnailUrl',
        Header: () => null,
        Cell: ({
          row: {
            original: { thumbnailUrl, title },
          },
        }) => <img src={thumbnailUrl} alt={title} className={styles.thumbnail} />,
        style: { width: '86px' },
      },
      {
        accessor: 'title',
        Header: 'Title',
        Cell: ({
          row: {
            original: { title },
          },
        }) => <p className={styles.title}>{title}</p>,
      },
      {
        accessor: 'durationLong',
        Header: 'Length',
        Cell: ({
          row: {
            original: { durationLong },
          },
        }) => <p className={styles.text}>{durationLong}</p>,
      },
      {
        accessor: 'availability',
        Header: 'Status',
        Cell: ({
          row: {
            original: { availability, releaseDateFormatted },
          },
        }) => (
          <div>
            <p className={styles.text}>{getEpisodeStatus(availability)}</p>
            <p className={cx(styles.text, styles.light)}>{releaseDateFormatted}</p>
          </div>
        ),
      },
    ],
    [],
  );

  const HoverActions = ({ episodeId }) => (
    <>
      <CMSWhiteButton
        label="Remove from Collection"
        onClick={() => handleRemoveFromCollection(episodeId)}
      />
      {!hideSingleCreatorButtons && (
        <CMSButton label="Edit" href={`/creators/${creatorId}/episodes/${episodeId}`} />
      )}
    </>
  );

  return (
    <Table
      data={episodes}
      columns={columns}
      datumIdAccessor="episodeId"
      hideFilters
      hoverActions={HoverActions}
      onReorder={handleReorder}
    />
  );
};

export const MultiCreatorCollectionEpisodesTable = (props) => (
  <CollectionEpisodesTable {...props} hideSingleCreatorButtons collectionService={adminService} />
);

CollectionEpisodesTable.defaultProps = {
  creatorId: null,
  hideSingleCreatorButtons: false,
  collectionService: contentService,
};

CollectionEpisodesTable.propTypes = {
  episodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  collectionId: PropTypes.string.isRequired,
  creatorId: PropTypes.string,
  updateCollectionDetails: PropTypes.func.isRequired,
  hideSingleCreatorButtons: PropTypes.bool,
  collectionService: PropTypes.object,
};

export default CollectionEpisodesTable;
