import PropTypes from 'prop-types';

import styles from 'components/forms/styles.css';

const FormSection = ({ title, subtitle, mainClassName, children }) => (
  <section className={styles.formSection}>
    <header>
      <h2>{title}</h2>
      {subtitle && <p>{subtitle}</p>}
    </header>
    <main className={mainClassName}>{children}</main>
  </section>
);

FormSection.defaultProps = {
  subtitle: null,
  mainClassName: null,
};

FormSection.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  mainClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default FormSection;
