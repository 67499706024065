/* eslint-disable react/prop-types */

import { useState, useEffect } from 'react';

import { PurpleButton, CMSTextButton } from 'components/button/Button';
import Table from 'components/table/Table';
import { CMSWhiteInput } from 'components/input/Input';
import SelectInput from 'components/input/SelectInput';

import formStyles from 'components/forms/styles.css';
import styles from 'pages/tools/GrantAccessPage.css';
import { INPUT_TYPES } from 'components/input/constants';
import { isInputValid } from 'components/input/utils';

import {
  getActivePreviews,
  getEpisodes,
  grantPreviewAccess,
  deletePreviewAccess,
} from 'services/content/adminService';

const EpisodePreviewAccessPage = () => {
  const [email, setEmail] = useState('');
  const [episodePreviews, setEpisodePreviews] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [selectedEpisode, setSelectedEpisode] = useState(null);

  useEffect(() => {
    const fetchInfo = async () => {
      const previews = await getActivePreviews();
      const allEpisodes = await getEpisodes();
      setEpisodePreviews(previews);
      setEpisodes(allEpisodes);
    };
    fetchInfo();
  }, []);

  const authorizePreviewAccess = async () => {
    const newEpisodePreview = await grantPreviewAccess(email, selectedEpisode.episodeId);
    setEpisodePreviews([...episodePreviews, newEpisodePreview]);
    setEmail('');
    setSelectedEpisode(null);
  };

  const revokePreviewAccess = (episodeId) => async () => {
    await deletePreviewAccess(episodeId);
    const updatedEpisodePreviews = episodePreviews.filter(
      (episodePreview) => episodePreview.episodeId !== episodeId,
    );
    setEpisodePreviews(updatedEpisodePreviews);
  };

  const columns = [
    {
      accessor: 'givenName',
      id: 'member-name',
      Header: 'name',
      Cell: ({ row }) => {
        const { givenName } = row.original.user;
        return <p>{givenName}</p>;
      },
    },
    {
      accessor: 'email',
      id: 'member-email',
      Header: 'email',
      Cell: ({ row }) => {
        const { email } = row.original.user;
        return <p>{email}</p>;
      },
    },
    {
      accessor: 'title',
      id: 'episode-info',
      Header: 'story',
      Cell: ({ row }) => {
        const { title } = row.original.episode;
        return <p>{title}</p>;
      },
    },
    {
      id: 'revoke',
      Header: '',
      Cell: ({ row }) => {
        const { episodeId } = row.original.episode;
        return (
          <CMSTextButton label="&#10060;  Revoke Access" onClick={revokePreviewAccess(episodeId)} />
        );
      },
    },
  ];

  return (
    <div className={styles.grantAccessPage}>
      <h1>
        <span aria-label="film emoji" role="img">
          🎥
        </span>{' '}
        Grant Story Preview Access
      </h1>
      <p>
        Grant preview access to certain users- this may be helpful for potential creators, partners,
        investors or journalists.
      </p>
      <div className={formStyles.form}>
        <section className={formStyles.formBody}>
          <div className={styles.formWrapper}>
            <CMSWhiteInput
              value={email}
              label="User's Email"
              isRequired
              onChange={setEmail}
              type={INPUT_TYPES.EMAIL}
            />
            <SelectInput
              value={selectedEpisode}
              label="Stories"
              placeholder="Select story to provide access"
              options={episodes.map((episode) => ({
                episodeId: episode.episodeId,
                label: episode.title,
              }))}
              onChange={(option) => {
                setSelectedEpisode(option);
              }}
            />
          </div>
          <PurpleButton
            label="Grant Preview Access"
            isDisabled={
              !isInputValid(INPUT_TYPES.EMAIL, email) ||
              !isInputValid(INPUT_TYPES.TEXT, selectedEpisode?.episodeId)
            }
            onClick={authorizePreviewAccess}
          />
        </section>
      </div>

      <section>
        <h1>Active Preview Access</h1>
        <p>These users are currently able to preview the stories specified.</p>
        <Table data={episodePreviews} columns={columns} hideFilters disableHoverEffects />
      </section>
    </div>
  );
};

export default EpisodePreviewAccessPage;
