import PropTypes from 'prop-types';
import { deepClone } from 'utils';
import DraggableObject from 'components/do-it-forms/components/DraggableObject';
import { CMSTextButton } from 'components/button/Button';

const DraggableObjects = ({
  draggableObjects,
  setState,
  getDefaultDraggableObject,
  exposeType,
}) => {
  const onDraggableObjectChange = (i) => (prop, value) => {
    setState(({ draggableObjects }) => {
      const draggableObjectsCopy = deepClone(draggableObjects);
      draggableObjectsCopy[i][prop] = value;

      return {
        draggableObjects: draggableObjectsCopy,
      };
    });
  };

  const handleRemoveDraggableObject = (formIndex) => {
    setState(({ draggableObjects }) => ({
      draggableObjects: draggableObjects.filter((_, i) => i !== formIndex),
    }));
  };

  const onDraggableObjectDelete = (i) => (i > 0 ? () => handleRemoveDraggableObject(i) : null);

  const handleAddDraggableObject = () => {
    setState(({ draggableObjects }) => ({
      draggableObjects: [
        ...draggableObjects,
        getDefaultDraggableObject(draggableObjects[draggableObjects.length - 1]),
      ],
    }));
  };

  return (
    <>
      {draggableObjects.map((item, i) => (
        <DraggableObject
          i={i}
          draggableObject={item}
          onChange={onDraggableObjectChange(i)}
          onDelete={onDraggableObjectDelete(i)}
          exposeType={exposeType}
        />
      ))}
      <CMSTextButton
        label="Add Another Draggable Item"
        onClick={() => handleAddDraggableObject()}
      />
    </>
  );
};

DraggableObjects.propTypes = {
  draggableObjects: PropTypes.array.isRequired,
  setState: PropTypes.func.isRequired,
  getDefaultDraggableObject: PropTypes.func.isRequired,
  exposeType: PropTypes.bool,
};

DraggableObjects.defaultProps = {
  exposeType: false,
};

export default DraggableObjects;
