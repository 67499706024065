import { Component } from 'react';
import PropTypes from 'prop-types';
import cuid from 'cuid';
import * as utils from 'utils';

// Abstract
class BaseForm extends Component {
  static propTypes = {
    onClose: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    onSave: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    doit: PropTypes.object.isRequired,
  };

  state = {
    ...(this.props.doit?.params || this.getDefaultParams()),
    itemId: this.props.doit?.itemId,
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.doit.itemId === prevState.itemId) {
      return prevState;
    }

    return {
      ...nextProps.doit?.params,
      itemId: nextProps.doit?.itemId,
    };
  }

  getDefaultParams() {
    throw new Error('Implement this method in the child class!');
  }

  static DOIT_COPY_POSITION_OFFSET = 10; // When a new accordion is created, offset the position slightly so it's visible

  // Converts a file, e.g. backgroundFile, to a uri, e.g. backgroundUri, for a nested object
  convertFileToUri(params, depth = 1) {
    Object.entries(params).forEach(([key, value]) => {
      if (key.includes('File') || key === 'file') {
        const uriKey = key.replace('File', 'Uri').replace('file', 'uri');
        params[uriKey] = value?.awsPublicUrl || ''; // eslint-disable-line
        return;
      }

      if (value && typeof value === 'object') {
        this.convertFileToUri(value, depth + 1);
      }

      if (Array.isArray(value)) {
        value.forEach((element) => this.convertFileToUri(element, depth + 1));
      }
    });
  }

  getDoitParams() {
    throw new Error('Implement this method in the child class!');
  }

  setState(nextStateOrCallback, callback = null) {
    super.setState(nextStateOrCallback, () => {
      if (callback) {
        callback();
      }

      const { doit } = this.props;
      this.handleSave({
        ...doit,
        params: this.state.params,
      });
    });
  }

  handleSave(options) {
    const { onSave, doit } = this.props;

    const params = utils.deepClone(this.getDoitParams());
    const newOptions = utils.deepClone(options);

    this.convertFileToUri(params);
    this.convertFileToUri(newOptions);

    const { baseTemplateId, finishSecsWithEpisodeOffset, startSecsWithEpisodeOffset } = newOptions;

    delete params.itemId;

    onSave(
      {
        itemId: cuid(),
        ...doit,
        ...newOptions,
        maxDuration: finishSecsWithEpisodeOffset - startSecsWithEpisodeOffset,
        params,
      },
      baseTemplateId,
    );
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }
}

export default BaseForm;
