import PropTypes from 'prop-types';
import { deepClone } from 'utils';
import DropTarget from 'components/do-it-forms/components/DropTarget';
import { CMSTextButton } from 'components/button/Button';

const DropTargets = ({ dropTargets, setState, getDefault, types, exposeRejectState }) => {
  const onChange = (i) => (prop, value) => {
    setState(({ dropTargets }) => {
      const dropTargetsCopy = deepClone(dropTargets);
      dropTargetsCopy[i][prop] = value;

      return {
        dropTargets: dropTargetsCopy,
      };
    });
  };

  const handleDelete = (formIndex) => {
    setState(({ dropTargets }) => ({
      dropTargets: dropTargets.filter((_, i) => i !== formIndex),
    }));
  };

  const onDelete = (i) => (i > 0 ? () => handleDelete(i) : null);

  const handleAdd = () => {
    setState(({ dropTargets }) => ({
      dropTargets: [...dropTargets, getDefault(dropTargets[dropTargets.length - 1])],
    }));
  };

  return (
    <>
      {dropTargets.map((item, i) => (
        <DropTarget
          i={i}
          value={item}
          onChange={onChange(i)}
          onDelete={onDelete(i)}
          types={types}
          exposeRejectState={exposeRejectState}
        />
      ))}
      <CMSTextButton label="Add Another Drop Target" onClick={() => handleAdd()} />
    </>
  );
};

DropTargets.propTypes = {
  dropTargets: PropTypes.array.isRequired,
  setState: PropTypes.func.isRequired,
  getDefault: PropTypes.func.isRequired,
  types: PropTypes.array,
  exposeRejectState: PropTypes.bool,
};

DropTargets.defaultProps = {
  types: [],
  exposeRejectState: false,
};

export default DropTargets;
