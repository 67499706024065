import PropTypes from 'prop-types';

import styles from 'components/toggle-switch/ToggleSwitch.css';

const ToggleSwitch = ({ options, onChange }) => (
  <ul className={styles.radioSwitch}>
    {options.map(({ id, label, icon, checked }, index) => (
      <li key={id} className={styles.item}>
        <input
          type="radio"
          className={styles.input}
          id={id}
          name="radioSwitch"
          checked={checked}
          onChange={onChange}
        />
        <label htmlFor={id} className={styles.label}>
          {icon && icon}
          {label}
        </label>
        {index === options.length - 1 && <div className={styles.marker} aria-hidden="true" />}
      </li>
    ))}
  </ul>
);

ToggleSwitch.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ToggleSwitch;
