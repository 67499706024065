import { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';

import PageLayout from 'components/page-layout/PageLayout';
import Card from 'components/card/Card';
import { CMSButton } from 'components/button/Button';
import CMSInput from 'components/input/Input';
import { isValidForm, getFormValues } from 'utils';

import styles from 'pages/auth/styles.css';

const PageTemplate = ({
  title,
  subtitle,
  submitFunction,
  formData,
  setFormData,
  ctaLabel,
  footerText,
  footerCtaLink,
  footerCtaText,
  cardCtaLink,
  cardCtaText,
  inviteCardCreator,
  inviteCardImg,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const canSubmit = isValidForm(formData) && !isLoading;
  const hasInviteCard = !!(inviteCardCreator && inviteCardImg);
  const locationState = useLocation().state;

  const handleSubmit = async (e) => {
    e && e.preventDefault();

    if (canSubmit) {
      setIsLoading(true);
      submitFunction(getFormValues(formData)).catch((_) => setIsLoading(false));
    }
  };

  const handleChange = (prop, value, isValid) => {
    setFormData({ ...formData, [prop]: { ...formData[prop], value, isValid } });
  };

  return (
    <PageLayout title={title}>
      <h1 className={styles.authHeader}>{title}</h1>
      {subtitle && <h2 className={styles.authSubheader}>{subtitle}</h2>}
      {hasInviteCard && (
        <Card>
          <div className={styles.inviteContainer}>
            <img src={inviteCardImg} alt={inviteCardCreator} />
            <p>
              <span>{inviteCardCreator}</span> has invited you to their creator channel! Sign Up or
              Log In to get started.
            </p>
          </div>
        </Card>
      )}
      <Card>
        <form onSubmit={handleSubmit}>
          {Object.keys(formData).map((key) => {
            const { type, label, value, readOnly } = formData[key];

            return (
              <CMSInput
                key={label}
                type={type}
                label={label}
                value={value}
                readOnly={readOnly ?? false}
                onChange={(value, isValid) => handleChange(key, value, isValid)}
              />
            );
          })}

          <button aria-label="Submit" type="submit" hidden />
          <CMSButton label={ctaLabel} onClick={handleSubmit} isDisabled={!canSubmit} />
        </form>

        {cardCtaText && (
          <p className={styles.cardCta}>
            <Link to={cardCtaLink}>{cardCtaText}</Link>
          </p>
        )}
      </Card>
      <p className={styles.footerText}>
        {footerText}{' '}
        {footerCtaLink && (
          <Link
            to={{
              pathname: footerCtaLink.split('?')[0],
              search: footerCtaLink.split('?')[1] || null,
              state: locationState,
            }}
          >
            {footerCtaText}
          </Link>
        )}
      </p>
    </PageLayout>
  );
};

PageTemplate.defaultProps = {
  subtitle: null,
  footerText: null,
  footerCtaLink: null,
  footerCtaText: null,
  cardCtaLink: null,
  cardCtaText: null,
  inviteCardCreator: null,
  inviteCardImg: null,
};

PageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  submitFunction: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  ctaLabel: PropTypes.string.isRequired,
  footerText: PropTypes.string,
  footerCtaLink: PropTypes.string,
  footerCtaText: PropTypes.string,
  cardCtaLink: PropTypes.string,
  cardCtaText: PropTypes.string,
  inviteCardCreator: PropTypes.object,
  inviteCardImg: PropTypes.string,
};

export default PageTemplate;
