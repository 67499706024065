import BaseForm from 'components/do-it-forms/forms/Base';
import { CMSTextButton } from 'components/button/Button';

class UnsupportedForm extends BaseForm {
  getDefaultParams() {
    return {};
  }

  getDoitParams() {
    return this.state;
  }

  handleDelete() {
    if (!confirm('Are you sure you want to remove this doit?')) {
      return;
    }

    const { doit, onDelete } = this.props;
    onDelete(doit.itemId);
  }

  render() {
    return (
      <>
        <h1>This template is unsupported!</h1>
        <br />
        <CMSTextButton label="Remove Do It" onClick={() => this.handleDelete()} />
      </>
    );
  }
}

export default UnsupportedForm;
