import PropTypes from 'prop-types';

import { useCreators } from 'context/creators-context/CreatorsContext';

import { ADMIN_CREATOR_SIDEBAR_ITEM_ID } from 'components/modal/constants';
import SidebarItem from 'components/modal/components/SidebarItem';

import styles from 'components/modal/Modal.css';

// TODO: add a `variant` prop to CS to reuse with all these modals
// `variant` would be used to determine which "Admin" / "All Creators" tab to show
const CreatorSidebar = ({ selected, activeCreatorId, setActiveCreatorId }) => {
  const { creators } = useCreators();
  const numAdminCollectionsSelected = selected.filter(({ creators }) => Boolean(creators)).length;

  return (
    <div className={styles.sidebar}>
      <ul>
        <SidebarItem
          isActive={activeCreatorId === ADMIN_CREATOR_SIDEBAR_ITEM_ID}
          title="Hellosaurus Admin"
          avatarUrl="https://assets.hellosaurus.com/doits/cknkohu3l001i1ioygn2pgluy.png"
          numSelected={numAdminCollectionsSelected}
          onClick={() => setActiveCreatorId(ADMIN_CREATOR_SIDEBAR_ITEM_ID)}
        />
      </ul>
      <hr />
      <ul className={styles.creatorsList}>
        {creators.map(({ creatorId, avatarUrl, title }) => {
          const numCollectionsSelected = selected.filter(
            ({ creator }) => creator?.creatorId === creatorId,
          ).length;

          return (
            <SidebarItem
              key={creatorId}
              isActive={activeCreatorId === creatorId}
              title={title}
              avatarUrl={avatarUrl}
              numSelected={numCollectionsSelected}
              onClick={() => setActiveCreatorId(creatorId)}
            />
          );
        })}
      </ul>
    </div>
  );
};

CreatorSidebar.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeCreatorId: PropTypes.string.isRequired,
  setActiveCreatorId: PropTypes.func.isRequired,
};

export default CreatorSidebar;
