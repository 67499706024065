// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Snackbar_snackbar___2jpANZxKeD {\n  position: fixed;\n  height: 62px;\n  width: calc(100% - 80px);\n  bottom: 24px;\n  left: 40px;\n  right: 40px;\n  padding: 12px 16px;\n  color: white;\n  background: #8b18e3;\n  border-radius: 8px;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n", ""]);
// Exports
exports.locals = {
	"snackbar": "Snackbar_snackbar___2jpANZxKeD"
};
module.exports = exports;
