import PropTypes from 'prop-types';
import Accordion from 'components/accordion/Accordion';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import SizeInput from 'components/input/SizeInput';
import PositionInput from 'components/input/PositionInput';

const CanvasObject = ({ canvasObject, i, onChange, onDelete, objectName }) => {
  const { size, position, spriteFile } = canvasObject;
  const accordionTitle = `${objectName} #${i + 1}`;

  return (
    <Accordion title={accordionTitle} key={accordionTitle} onDelete={onDelete}>
      <ImageUploadInput
        label="Image"
        onChange={(value) => onChange('spriteFile', value)}
        value={spriteFile}
      />
      <SizeInput
        sizeX={size.x}
        sizeY={size.y}
        positionX={position.x}
        positionY={position.y}
        onChange={onChange}
      />
      <PositionInput positionX={position.x} positionY={position.y} onChange={onChange} />
    </Accordion>
  );
};

CanvasObject.propTypes = {
  canvasObject: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  objectName: PropTypes.string.isRequired,
};

export default CanvasObject;
