import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { INPUT_TYPES } from 'components/input/constants';

import * as styles from 'components/input/Input.css';
import { TargetIcon } from 'components/Icon';
import { CMSWhiteInput } from 'components/input/Input';

class PositionInput extends PureComponent {
  static propTypes = {
    positionX: PropTypes.number.isRequired,
    positionY: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  state = {
    targetSelectorActive: false,
  };

  handleChange(key, value) {
    if (isNaN(value)) {
      return;
    }

    const existingPosition = { x: this.props.positionX, y: this.props.positionY };
    const newPosition = {
      ...existingPosition,
      [key]: Number(value),
    };

    this.props.onChange('position', newPosition);
  }

  handleRequestPositionClick() {
    window.alert(
      'This functionality is currently disabled while we rebuild it in Unity. Please use the number input instead.',
    );
  }

  render() {
    const { positionX, positionY } = this.props;
    const { targetSelectorActive } = this.state;

    const coordinateInputStyles = cx(styles.coordinateInput, {
      [styles.coordinateInputActive]: targetSelectorActive,
    });

    return (
      <>
        <label>Position</label>
        <div className={coordinateInputStyles}>
          <div>
            <CMSWhiteInput
              type={INPUT_TYPES.NUMBER}
              label="X Coordinate"
              value={positionX}
              onChange={(value) => this.handleChange('x', value)}
              debounced
              isRequired
            />
          </div>
          <div>
            <CMSWhiteInput
              type={INPUT_TYPES.NUMBER}
              label="Y Coordinate"
              value={positionY}
              onChange={(value) => this.handleChange('y', value)}
              debounced
              isRequired
            />
          </div>
          <div
            onClick={this.handleRequestPositionClick.bind(this)}
            className={styles.coordinateInputTargetIcon}
          >
            <TargetIcon />
          </div>
        </div>
      </>
    );
  }
}

export default PositionInput;
