/* eslint-disable no-alert, react/prop-types */

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { getBackgroundImageGradientStyles } from 'utils';
import Table, { TableFilters } from 'components/table/Table';

import styles from 'components/table/Table.css';

const CollectionsTable = ({ collections, collectionDetailPath }) => {
  const history = useHistory();

  const data = useMemo(() => collections, [collections]);
  const columns = useMemo(
    () => [
      {
        id: 'backgroundImage',
        disableSortBy: true,
        Header: () => null,
        Cell: ({ row }) => {
          const { collection } = row.original;
          const bgImageStyle = getBackgroundImageGradientStyles(collection);
          return (
            <div className={styles.thumbnail} style={{ backgroundImage: `${bgImageStyle}` }} />
          );
        },
        style: {
          width: 86,
        },
      },
      {
        accessor: (row) => row.collection.title,
        Header: 'Title',
        Cell: ({ row }) => {
          const { title } = row.original.collection;
          return <p className={styles.title}>{title}</p>;
        },
        filter: TableFilters.FUZZY_TEXT,
      },
      {
        accessor: (row) => row.collection.isPublished,
        Header: 'Status',
        Cell: ({ row }) => {
          const { isPublished } = row.original.collection;
          return <p className={styles.text}>{isPublished ? 'Active' : 'Inactive'}</p>;
        },
        filter: TableFilters.PUBLISHED,
      },
      {
        accessor: (row) => row.collection.features.EPISODE_COUNT,
        Header: 'Stories',
        Cell: ({ row }) => {
          const { features } = row.original.collection;
          const { EPISODE_COUNT } = features;
          return <p className={styles.text}>{EPISODE_COUNT}</p>;
        },
        filter: TableFilters.FUZZY_TEXT,
      },
    ],
    [],
  );

  const onRowClick = ({ collection: { collectionId }, creator }) =>
    history.push(collectionDetailPath({ creatorId: creator?.creatorId, collectionId }));

  return <Table entityKey="collection" data={data} columns={columns} onRowClick={onRowClick} />;
};

export const MultiCreatorCollectionsTable = (props) => (
  <CollectionsTable
    {...props}
    collectionDetailPath={({ collectionId }) => `/admin/collections/${collectionId}`}
  />
);

CollectionsTable.defaultProps = {
  collectionDetailPath: ({ creatorId, collectionId }) =>
    `/creators/${creatorId}/collections/${collectionId}`,
};

CollectionsTable.propTypes = {
  collections: PropTypes.arrayOf(PropTypes.object).isRequired,
  collectionDetailPath: PropTypes.func,
};

export default CollectionsTable;
