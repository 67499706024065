import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import cx from 'classnames';

import { useCreators } from 'context/creators-context/CreatorsContext';
import { create, update } from 'components/forms/utils';

import FormSection from 'components/forms/FormSection';
import { CMSWhiteInput } from 'components/input/Input';
import { CMSWhiteButton, PurpleButton } from 'components/button/Button';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import ColorRadioGroup, { BACKGROUND_COLORS } from 'components/input/ColorRadioGroup';

import styles from 'components/forms/styles.css';

const PLACEHOLDER_TITLE = 'Hellosaurus';
const PLACEHOLDER_INITIAL = 'H';

const AssetPreviewInfo = ({
  title,
  description,
  hint,
  uploadBtnLabel,
  asset,
  children,
  handleOnClick,
}) => (
  <>
    <div className={styles.assetPreviewContent}>
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>{description}</p>
      <div className={styles.buttonGroup}>
        <ImageUploadInput
          className={styles.fileUploadInput}
          label={uploadBtnLabel}
          hideLabel
          onChange={({ awsPublicUrl, fileId }) => handleOnClick(awsPublicUrl, fileId)}
        />
        {asset && <CMSWhiteButton label="Remove" onClick={() => handleOnClick(null, null)} />}
      </div>
      <p>{hint}</p>
    </div>
    {children}
  </>
);

AssetPreviewInfo.defaultProps = { children: undefined };

AssetPreviewInfo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  hint: PropTypes.string.isRequired,
  uploadBtnLabel: PropTypes.string.isRequired,
  asset: PropTypes.string.isRequired,
  children: PropTypes.node,
  handleOnClick: PropTypes.func.isRequired,
};

const LogoPreviewCard = ({ title, logoUrl }) => (
  <div className={styles.logoPreviewCard}>
    {logoUrl ? (
      <img src={logoUrl} alt="Creator Logo" />
    ) : (
      <div className={styles.previewWordmarkContainer}>
        <h1 className={styles.previewWordmark}>{title || PLACEHOLDER_TITLE}</h1>
      </div>
    )}
    <p>38 Stories</p>
    <CMSWhiteButton label="See All →" onClick={() => {}} />
  </div>
);

LogoPreviewCard.propTypes = {
  title: PropTypes.string.isRequired,
  logoUrl: PropTypes.string.isRequired,
};

/**
 * When form is wrapped in `forwardRef` and uses the `useImperativeHandle` hook,
 * the parent component then has access to whatever data is selectively exposed.
 */
const CreatorForm = forwardRef(({ creator, isNew, hideButtons }, ref) => {
  const { updateCreator } = useCreators();
  const [creatorDetails, updateCreatorDetails] = useState(creator);
  useEffect(() => updateCreatorDetails(creator), [creator]);

  const { title, description, logoUrl, avatarUrl, backgroundColor, creatorId } = creatorDetails;
  const attributes = ['title', 'description', 'logoFileId', 'avatarFileId', 'backgroundColor'];

  const sanitizedTitle = title.trim();

  async function submit() {
    if (isNew) {
      const newCreator = await create({
        path: '/creators',
        attributes,
        obj: creatorDetails,
      });

      window.location.href = `/creators/${newCreator.creatorId}`;
    } else {
      const updatedCreator = await update({
        path: `/creators/${creatorId}`,
        attributes,
        obj: creatorDetails,
      });

      updateCreator(updatedCreator);
      NotificationManager.success('Successfully saved!');
    }
  }

  useImperativeHandle(ref, () => ({ submit }));

  return (
    <div className={cx(styles.form, styles.creatorForm, !hideButtons && styles.padded)}>
      <FormSection title="Creator Info" mainClassName={styles.creatorInfo}>
        <CMSWhiteInput
          value={title}
          label="Creator Name"
          isRequired
          onChange={(title) => updateCreatorDetails({ ...creatorDetails, title })}
        />

        <CMSWhiteInput
          textarea
          value={description}
          label="Creator Description"
          placeholder="Describe your creator here…"
          onChange={(description) => updateCreatorDetails({ ...creatorDetails, description })}
        />
      </FormSection>
      <FormSection title="App Appearance" mainClassName={styles.avatarSection}>
        <section>
          <AssetPreviewInfo
            title="Creator Avatar"
            description="Your avatar appears throughout the Hellosaurus experience next to your creator name."
            hint="Please use a square PNG or JPEG image. You can update this at any time."
            uploadBtnLabel="Avatar"
            asset={avatarUrl}
            handleOnClick={(awsPublicUrl, fileId) =>
              updateCreatorDetails({
                ...creatorDetails,
                avatarUrl: awsPublicUrl,
                avatarFileId: fileId,
              })
            }
          />
          <div className={styles.assetPreviewMedia}>
            <div className={styles.pictorialPreview}>
              <div className={cx(styles.avatarContainer, avatarUrl && styles.backgroundless)}>
                {avatarUrl ? (
                  <img src={avatarUrl} alt="Creator Avatar" />
                ) : (
                  <p>{sanitizedTitle[0] || PLACEHOLDER_INITIAL}</p>
                )}
              </div>
            </div>
            <div className={styles.fullPreview}>
              <div className={styles.avatarContainer}>
                {avatarUrl ? (
                  <img src={avatarUrl} alt="Creator Avatar" />
                ) : (
                  <p>{sanitizedTitle[0] || PLACEHOLDER_INITIAL}</p>
                )}
              </div>
              <span>{sanitizedTitle || PLACEHOLDER_TITLE}</span>
            </div>
          </div>
        </section>

        <section>
          <div className={styles.assetPreviewContent}>
            <AssetPreviewInfo
              title="Creator Logo"
              description="Your logo will appear in various parts of the Hellosaurus experience to help families identify you."
              hint="Please use a rectangular PNG or JPEG, e.g. 1152x720px. You can update this at any time."
              uploadBtnLabel="Logo"
              asset={logoUrl}
              handleOnClick={(awsPublicUrl, fileId) =>
                updateCreatorDetails({
                  ...creatorDetails,
                  logoUrl: awsPublicUrl,
                  logoFileId: fileId,
                })
              }
            >
              <>
                <hr />
                <div className={styles.assetPreviewContent}>
                  <p className={styles.title}>Default Collection Background Color</p>
                  <ColorRadioGroup
                    name={isNew ? 'new-creator-bg-color' : 'creator-bg-color'}
                    value={backgroundColor}
                    onChange={(value) =>
                      updateCreatorDetails({
                        ...creatorDetails,
                        backgroundColor: value,
                      })
                    }
                  />
                </div>
              </>
            </AssetPreviewInfo>
          </div>
          <div
            className={cx(styles.assetPreviewMedia, styles.logoPreviewBackground)}
            style={{ background: BACKGROUND_COLORS[backgroundColor]?.hex }}
          >
            <LogoPreviewCard title={title} logoUrl={logoUrl} />
          </div>
        </section>
      </FormSection>

      {!hideButtons && (
        <PurpleButton label={isNew ? 'Create Creator' : 'Save Changes'} onClick={submit} />
      )}
    </div>
  );
});

CreatorForm.defaultProps = {
  creator: {
    logoUrl: '',
    avatarUrl: '',
    title: '',
    description: '',
    backgroundColor: BACKGROUND_COLORS.PURPLE.name,
  },
  isNew: false,
  hideButtons: false,
};

CreatorForm.propTypes = {
  creator: PropTypes.object,
  isNew: PropTypes.bool,
  hideButtons: PropTypes.bool,
};

export default CreatorForm;
