import PropTypes from 'prop-types';

import { pluralize, getBackgroundImageGradientStyles } from 'utils';

import styles from 'components/input/components/styles.css';

const SelectContents = ({
  label,
  subtitleLabel,
  countLabel,
  showCountLabel,
  hideThumbnail,
  ThumbnailNode,
}) => (
  <div className={styles.selectContents}>
    <div className={styles.metadataContainer}>
      {hideThumbnail ? null : ThumbnailNode}
      <div>
        <p className={styles.labelTitle}>{label}</p>
        {subtitleLabel ? <p className={styles.labelSubtitle}>{subtitleLabel}</p> : null}
      </div>
    </div>
    {showCountLabel ? <p className={styles.labelSubtitle}>{countLabel}</p> : null}
  </div>
);

SelectContents.defaultProps = {
  subtitleLabel: '',
  countLabel: '',
  showCountLabel: false,
  hideThumbnail: false,
  ThumbnailNode: null,
};

SelectContents.propTypes = {
  label: PropTypes.string.isRequired,
  subtitleLabel: PropTypes.string,
  countLabel: PropTypes.string,
  showCountLabel: PropTypes.bool,
  hideThumbnail: PropTypes.bool,
  ThumbnailNode: PropTypes.node,
};

const CreatorAvatar = ({ creator }) => (
  <img className={styles.creatorAvatar} src={creator.avatarUrl} alt={creator.title} />
);

CreatorAvatar.propTypes = { creator: PropTypes.object.isRequired };

export const CreatorSelectContents = ({ data: { label, value }, showCountLabel }) => {
  const Thumbnail = <CreatorAvatar creator={value} />;
  return <SelectContents label={label} showCountLabel={showCountLabel} ThumbnailNode={Thumbnail} />;
};

export const BundleSelectContents = ({ data: { value, label }, showCountLabel }) => {
  const {
    bundle: { type },
    collections,
    creators,
  } = value;

  const countLabel =
    type === 'COLLECTION_BUNDLE'
      ? pluralize(collections.length, 'Collection', 'Collections')
      : pluralize(creators.length, 'Creator', 'Creators');

  return (
    <SelectContents
      label={label}
      countLabel={countLabel}
      hideThumbnail
      showCountLabel={showCountLabel}
    />
  );
};

export const CollectionSelectContents = ({ data: { value, label }, showCountLabel }) => {
  const { creator, collection, episodes } = value;

  const countLabel = pluralize(episodes.length, 'Story', 'Stories');
  const bgImageStyle = getBackgroundImageGradientStyles(collection);

  const Thumbnail = (
    <div className={styles.thumbnail} style={{ backgroundImage: `${bgImageStyle}` }} />
  );

  return (
    <SelectContents
      label={label}
      subtitleLabel={creator?.title}
      countLabel={countLabel}
      showCountLabel={showCountLabel}
      ThumbnailNode={Thumbnail}
    />
  );
};

export const ModuleTypeSelectContents = ({ data: { label, description }, showCountLabel }) => (
  <SelectContents
    label={label}
    subtitleLabel={description}
    hideThumbnail
    showCountLabel={showCountLabel}
  />
);

const ContentsDefaultProps = { showCountLabel: false };
const ContentsPropTypes = {
  data: PropTypes.object.isRequired,
  showCountLabel: PropTypes.bool,
};

CollectionSelectContents.defaultProps = ContentsDefaultProps;
CollectionSelectContents.propTypes = ContentsPropTypes;

CreatorSelectContents.defaultProps = ContentsDefaultProps;
CreatorSelectContents.propTypes = ContentsPropTypes;

BundleSelectContents.defaultProps = ContentsDefaultProps;
BundleSelectContents.propTypes = ContentsPropTypes;

ModuleTypeSelectContents.defaultProps = ContentsDefaultProps;
ModuleTypeSelectContents.propTypes = ContentsPropTypes;
