import PropTypes from 'prop-types';

import { useCreators } from 'context/creators-context/CreatorsContext';

import styles from 'components/modal/components/CreatorBanner.css';

const CreatorBanner = ({ creatorId }) => {
  const { creators } = useCreators();
  const creator = creators.find((creator) => creator.creatorId === creatorId);

  return (
    <div className={styles.creatorBanner}>
      <p className={styles.label}>Creator:</p>

      <div>
        <img src={creator.avatarUrl} alt={creator.description} />
        <p>{creator.title}</p>
      </div>
    </div>
  );
};

CreatorBanner.propTypes = {
  creatorId: PropTypes.string.isRequired,
};

export default CreatorBanner;
