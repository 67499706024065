import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from 'components/input/Input.css';
import TimestampPlaceValue from 'components/input/TimestampPlaceValue';

const TimestampInput = ({ value, onChange, label }) => (
  <div className={cx(styles.timestampInput, styles.cmsWhiteInputContainer)}>
    <label>
      {label} <span>*</span>
    </label>
    <div className={styles.timestampInputValues}>
      <TimestampPlaceValue
        value={Math.floor(value / 60)}
        onChange={(newMinutes) => onChange(newMinutes * 60 + (value % 60))}
      />
      <span>:</span>
      <TimestampPlaceValue
        value={(value % 60).toFixed(2).padStart(5, '0')}
        onChange={(newSeconds) =>
          onChange(Math.floor(value / 60) * 60 + Math.min(59.99, Number(newSeconds.toFixed(2))))
        }
      />
    </div>
  </div>
);

TimestampInput.propTypes = {
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default TimestampInput;
