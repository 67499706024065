import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from 'components/modal/Modal.css';

const SelectableCard = ({
  isSelected,
  title,
  backgroundImageUrl,
  status,
  description,
  insetDescription,
  onClick,
}) => (
  <div className={styles.episodeCard}>
    <div className={cx(styles.thumbnailContainer, isSelected && styles.selected)} onClick={onClick}>
      <img src={backgroundImageUrl} alt={title} />
      {insetDescription && <span className={styles.episodeDuration}>{insetDescription}</span>}
    </div>
    <p>{title}</p>
    <p className={styles.episodeMetaInfo}>
      {status && <span className={cx(styles.availabilityPill, styles[status])}>{status}</span>}{' '}
      <span className={styles.releaseDate}>{description}</span>
    </p>
  </div>
);

export default SelectableCard;

SelectableCard.defaultProps = {
  isSelected: false,
  insetDescription: '',
  status: '',
};

SelectableCard.propTypes = {
  isSelected: PropTypes.bool,
  status: PropTypes.string,
  title: PropTypes.string.isRequired,
  backgroundImageUrl: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  insetDescription: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};
