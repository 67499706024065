// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.AssetPreview_upload__image___tFhCQiNZUh img {\n    max-height: 75px;\n    max-width: 75px;\n    margin-right: 20px;\n  }\n\n.AssetPreview_upload__video___2g_AZOhDrV video {\n    max-height: 100px;\n    margin-right: 20px;\n    border-radius: 4px;\n  }\n\n.AssetPreview_upload__audio___1oThHkm4vG {\n  display: flex;\n  height: auto;\n  max-width: 3em;\n  width: -moz-fit-content;\n  width: fit-content;\n  justify-content: center;\n  align-items: center;\n  margin-right: 20px;\n}\n\n.AssetPreview_upload__audio___1oThHkm4vG svg {\n    transition: all 0.35s ease\n  }\n\n.AssetPreview_upload__audio___1oThHkm4vG svg:hover {\n      transform: scale(1.1);\n    }\n\n.AssetPreview_upload__audio___1oThHkm4vG svg:active {\n      transform: scale(1);\n    }\n", ""]);
// Exports
exports.locals = {
	"upload__image": "AssetPreview_upload__image___tFhCQiNZUh",
	"uploadImage": "AssetPreview_upload__image___tFhCQiNZUh",
	"upload__video": "AssetPreview_upload__video___2g_AZOhDrV",
	"uploadVideo": "AssetPreview_upload__video___2g_AZOhDrV",
	"upload__audio": "AssetPreview_upload__audio___1oThHkm4vG",
	"uploadAudio": "AssetPreview_upload__audio___1oThHkm4vG"
};
module.exports = exports;
