/* eslint-disable jsx-a11y/control-has-associated-label, no-nested-ternary, react/prop-types */

import { useMemo, useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NotificationManager } from 'react-notifications';
import { useHistory } from 'react-router-dom';

import { CreatorPageContext } from 'pages/content/CreatorPage';

import { getEpisodeStatus, EpisodeAvailabilities } from 'utils';
import request from 'services/request/requestService';
import { changeEpisodePublished, deleteEpisode } from 'services/content/contentService';

import Table from 'components/table/Table';
import { CMSButton, CMSRedButton, CMSWhiteButton } from 'components/button/Button';
import FormModal from 'components/modal/FormModal';
import EpisodeReleaseForm from 'components/forms/EpisodeReleaseForm';

import styles from 'components/table/Table.css';

const HoverActions = (episode) => {
  const history = useHistory();
  const { refreshCreator } = useContext(CreatorPageContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isReleaseDateModalOpen, setIsReleaseDateModalOpen] = useState(false);

  const {
    episodeId,
    availability,
    title,
    creator: { creatorId },
  } = episode;

  const isScheduled = availability === EpisodeAvailabilities.NOT_AVAILABLE_UNRELEASED;
  const isPublished = availability === EpisodeAvailabilities.AVAILABLE;
  const publishButtonLabel = isPublished ? 'Unpublish' : isScheduled ? 'Cancel Release' : 'Publish';

  const handleSetPublished = async (episodeId, isPublished, releaseDate) => {
    if (
      !isPublished &&
      !window.confirm(
        `Are you sure you want to ${isPublished ? 'publish' : 'unpublish'} that story?`,
      )
    ) {
      return;
    }

    setIsLoading(true);

    try {
      await changeEpisodePublished(creatorId, episodeId, isPublished, releaseDate);

      if (releaseDate > new Date()) {
        NotificationManager.info('Successfully scheduled story for future release.');
      } else {
        NotificationManager.success(
          `Successfully ${isPublished ? 'published' : 'unpublished'} story.`,
        );
      }
    } finally {
      setIsLoading(false);
      refreshCreator();
    }
  };

  const handlePublishButtonClick =
    isPublished || isScheduled
      ? () => handleSetPublished(episodeId, false)
      : () => setIsReleaseDateModalOpen(true);

  const handleDelete = async () => {
    const warning = `This action cannot be undone. This will permanently delete the "${title}" story and any related data (doits, moments, viewings). \n\nPlease type the name of the story to confirm:`;

    if (window.prompt(warning).toLowerCase() === title.toLowerCase()) {
      await deleteEpisode(creatorId, episodeId);
      refreshCreator();
    }
  };

  return (
    <>
      <CMSWhiteButton
        label="Details"
        onClick={() =>
          history.push(`/creators/${creatorId}/episodes/${episodeId}/details`, {
            episode,
          })
        }
      />
      <CMSWhiteButton
        label={publishButtonLabel}
        isDisabled={isLoading}
        onClick={handlePublishButtonClick}
      />
      <CMSWhiteButton label="View in Old Editor" href={`/creators/${creatorId}/episodes/${episodeId}`} />
      <CMSButton label="Edit" href={`/creators/${creatorId}/episodes/${episodeId}/new`} />
      {!isPublished && (
        <CMSRedButton label="Delete" isDisabled={isLoading} onClick={handleDelete} />
      )}

      <FormModal
        formNode={EpisodeReleaseForm}
        title="Publish Story"
        contentLabel="Publish Story"
        primaryFooterBtnLabel="Publish"
        isOpen={isReleaseDateModalOpen}
        formProps={{ episodeId, handleSetPublished }}
        onRequestClose={() => setIsReleaseDateModalOpen(false)}
        style={{
          content: {
            height: 'max-content',
            minHeight: 0,
            maxHeight: '450px',
            maxWidth: '420px',
          },
        }}
      />
    </>
  );
};

const EpisodeTable = ({ episodes, creatorId, updateEpisodeOrder }) => {
  const lastSuccessfullySyncedEpisodes = useRef(episodes);

  const handleReorder = async (items) => {
    updateEpisodeOrder(items);

    const episodeIds = items.map(({ episodeId }) => episodeId);

    try {
      const method = 'PUT';
      const body = JSON.stringify(episodeIds);
      await request(`/creators/${creatorId}/episodes`, { method, body });

      lastSuccessfullySyncedEpisodes.current = items;
      NotificationManager.success('Successfully reordered stories.');
    } catch (error) {
      updateEpisodeOrder(lastSuccessfullySyncedEpisodes.current);
    }
  };

  const columns = useMemo(
    () => [
      {
        id: 'thumbnailUrl',
        Header: () => null,
        Cell: ({ row: { original } }) => {
          const { thumbnailUrl, title } = original;
          return <img src={thumbnailUrl} alt={title} className={styles.thumbnail} />;
        },
        style: { width: '86px' },
      },
      {
        accessor: 'title',
        Header: 'Title',
        Cell: ({ row: { original } }) => {
          const { title } = original;
          return <p className={styles.title}>{title}</p>;
        },
      },
      {
        accessor: 'durationLong',
        Header: 'Length',
        Cell: ({ row: { original } }) => {
          const { durationLong } = original;
          return <p className={styles.text}>{durationLong}</p>;
        },
      },
      {
        accessor: 'availability',
        Header: 'Status',
        Cell: ({ row: { original } }) => {
          const { availability, releaseDateFormatted } = original;
          return (
            <div>
              <p className={styles.text}>{getEpisodeStatus(availability)}</p>
              <p className={cx(styles.text, styles.light)}>{releaseDateFormatted}</p>
            </div>
          );
        },
      },
    ],
    [],
  );

  return (
    <Table
      data={episodes}
      columns={columns}
      datumIdAccessor="episodeId"
      hideFilters
      hoverActions={HoverActions}
      onReorder={handleReorder}
    />
  );
};

EpisodeTable.propTypes = {
  episodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  creatorId: PropTypes.string.isRequired,
};

export default EpisodeTable;
