/* eslint-disable react/prop-types */

import { Route, Switch, Router, Redirect } from 'react-router-dom';

import { UserProvider } from 'context/user-context/UserContext';
import { CreatorsProvider } from 'context/creators-context/CreatorsContext';
import ScrollToTop from 'components/ScrollToTop';
import { ACCOUNT_TYPE_NAMES, history } from 'utils';
import * as authenticationService from 'services/authentication/authenticationService';
import * as storeService from 'services/authentication/storeService';
import { LOCAL_STORAGE_URL_AFTER_AUTH_KEY } from 'services/authentication/constants';

import LoginPage from 'pages/auth/LoginPage';
import SignupPage from 'pages/auth/SignupPage';
import ForgotPasswordPage from 'pages/auth/ForgotPasswordPage';
import ResetPasswordPage from 'pages/auth/ResetPasswordPage';
import InviteRedirectPage from 'pages/auth/InviteRedirectPage';

import AdminRoutes from 'routes/AdminRoutes';
import CreatorRoutes from 'routes/CreatorRoutes';
import EditorPage from 'pages/editor/EditorPage';
import NewEditorPage from 'pages/newEditor/NewEditorPage';

import AccountPage from 'pages/account/AccountPage';

import BrowserIncompatiblePage from 'pages/BrowserIncompatiblePage';
import NotFoundPage from 'pages/NotFoundPage';

const AuthenticationMiddlewareRoute =
  ({ shouldAllowAccessFn, redirectLocation }) =>
  ({ component: Component, render, ...rest }) =>
    (
      <Route
        {...rest}
        render={(props) => {
          const isAuthenticated = authenticationService.isAuthenticated();
          const isAllowed = shouldAllowAccessFn(isAuthenticated);

          if (!isAllowed) {
            if (!isAuthenticated) {
              localStorage.setItem(LOCAL_STORAGE_URL_AFTER_AUTH_KEY, props.location.pathname);
            }

            return (
              <Redirect
                to={{
                  pathname: redirectLocation,
                  state: { from: props.location },
                }}
              />
            );
          }

          return <UserProvider>{render ? render(props) : <Component {...props} />}</UserProvider>;
        }}
      />
    );

const AdminOnlyRoute = AuthenticationMiddlewareRoute({
  shouldAllowAccessFn: (isAuthenticated) => {
    const user = storeService.getMe();
    if (isAuthenticated && user?.accountType === ACCOUNT_TYPE_NAMES.ADMIN) {
      return true;
    }
    return false;
  },
  redirectLocation: '/login',
});

const AuthenticatedRoute = AuthenticationMiddlewareRoute({
  shouldAllowAccessFn: (isAuthenticated) => isAuthenticated,
  redirectLocation: '/login',
});

const GuestRoute = AuthenticationMiddlewareRoute({
  shouldAllowAccessFn: (isAuthenticated) => !isAuthenticated,
  redirectLocation: '/creators',
});

const Routes = () => (
  <Router history={history}>
    <Route component={ScrollToTop} />

    <CreatorsProvider>
      <Switch>
        <GuestRoute path="/login" exact component={LoginPage} />
        <GuestRoute path="/signup" exact component={SignupPage} />
        <GuestRoute path="/forgot-password" exact component={ForgotPasswordPage} />
        <GuestRoute path="/reset-password" exact component={ResetPasswordPage} />

        <AuthenticatedRoute path="/account" exact component={AccountPage} />
        <AuthenticatedRoute
          path="/logout"
          exact
          component={() => {
            authenticationService.logout();
            return <Redirect to="/login" />;
          }}
        />
        <AuthenticatedRoute
          path="/creators/:creatorId/episodes/:episodeId"
          exact
          render={({ match: { params } }) => <EditorPage {...params} />}
        />
        <AuthenticatedRoute
          path="/creators/:creatorId/episodes/:episodeId/new"
          exact
          render={({ match: { params } }) => <NewEditorPage {...params} />}
        />
        <AuthenticatedRoute path="/creators" component={CreatorRoutes} />
        <AuthenticatedRoute path="/" exact component={() => <Redirect to="/creators" />} />
        <AuthenticatedRoute path="/browser" exact component={BrowserIncompatiblePage} />

        <AdminOnlyRoute path="/admin" component={AdminRoutes} />

        <Route path="/invite/:inviteId" exact component={InviteRedirectPage} />

        <Route
          render={() => (
            <UserProvider>
              <NotFoundPage />
            </UserProvider>
          )}
        />
      </Switch>
    </CreatorsProvider>
  </Router>
);

export default Routes;
