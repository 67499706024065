import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as styles from 'components/card/Card.css';

class Card extends PureComponent {
  static propTypes = {
    isLarge: PropTypes.bool,
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    isLarge: false,
  };

  render() {
    const { children, isLarge } = this.props;
    const cardStyles = cx(styles.card, isLarge && styles.cardLarge);

    return <div className={cardStyles}>{children}</div>;
  }
}

export default Card;
