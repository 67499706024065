import { useState } from 'react';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';

import { isAuthenticated } from 'services/authentication/authenticationService';

import styles from 'components/navbar/NavBar.css';

import Image from 'components/image/Image';
import AccountDropdown from 'components/account-dropdown/AccountDropdown';
import UserAvatar from 'components/user-avatar/UserAvatar';

import standardLogo from 'assets/images/hellosaurus-logo.png';
import retinaLogo from 'assets/images/hellosaurus-logo@2x.png';

const NavBar = () => {
  const [visible, setVisible] = useState(false);
  const show = () => setVisible(true);
  const hide = () => setVisible(false);

  return (
    <nav className={styles.navbar}>
      <div className={styles.navbarLogo}>
        <Link to="/">
          <Image standard={standardLogo} retina={retinaLogo} alt="Hellosaurus Logo" />
        </Link>
      </div>
      {isAuthenticated() && (
        <Tippy
          content={<AccountDropdown onLinkClick={hide} />}
          interactive
          visible={visible}
          onClickOutside={hide}
          placement="bottom-end"
        >
          <div onClick={visible ? hide : show}>
            <UserAvatar size={40} className={styles.userAvatar} />
          </div>
        </Tippy>
      )}
    </nav>
  );
};

export default NavBar;
