import { createBrowserHistory } from 'history';

export function padNumber(number, amount = 2) {
  return String(number).padStart(amount, '0');
}

export const history = createBrowserHistory();

export const PUBLISH_STATUSES = {
  ALL: 'all',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
};

export const INVITE_STATUS = {
  PENDING: 0,
  ACTIVE: 1,
};

export const ACCOUNT_TYPE_NAMES = {
  CREATOR: 'Creator',
  ADMIN: 'Admin',
  REGULAR_USER: 'RegularUser',
};

export const COLLABORATOR_ROLE = {
  ADMIN: 'admin',
  CREATOR: 'creator',
  OBSERVER: 'observer',
  REMOVE: 'remove',
};

export const MONTHS = {
  JANUARY: 1,
  FEBURARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
};

export const EpisodeAvailabilities = {
  AVAILABLE: 'available', // Public, published, released, has premium
  NOT_AVAILABLE_LOCKED: 'not_available_locked', // Public, user cannot watch as they don't have premium
  NOT_AVAILABLE_UNRELEASED: 'not_available_unreleased', // Public, published but before release date
  NOT_AVAILABLE_UNPUBLISHED: 'not_available_unpublished', // Admin only, not yet published
};

export const BundleTypes = {
  COLLECTION_BUNDLE: 'COLLECTION_BUNDLE',
  CREATOR_BUNDLE: 'CREATOR_BUNDLE',
  TOPIC_BUNDLE: 'TOPIC_BUNDLE',
  SERIES_BUNDLE: 'SERIES_BUNDLE',
};

export const dataKeyForBundleType = {
  [BundleTypes.CREATOR_BUNDLE]: 'creators',
  [BundleTypes.COLLECTION_BUNDLE]: 'collections',
  [BundleTypes.TOPIC_BUNDLE]: 'topics',
  [BundleTypes.SERIES_BUNDLE]: 'series',
};

export const CollectionTypes = {
  CREATOR_COLLECTION: 'CREATOR_COLLECTION',
  MULTI_CREATOR_COLLECTION: 'MULTI_CREATOR_COLLECTION',
};

export const HomescreenModuleTypes = {
  SINGLE_CREATOR_CAROUSEL: 'SINGLE_CREATOR_CAROUSEL',
  SINGLE_CREATOR_COLLECTION_CAROUSEL: 'SINGLE_CREATOR_COLLECTION_CAROUSEL',
  MULTI_CREATOR_COLLECTION_CAROUSEL: 'MULTI_CREATOR_COLLECTION_CAROUSEL',
  LARGE_COLLECTION_BUNDLE_CAROUSEL: 'LARGE_COLLECTION_BUNDLE_CAROUSEL',
  LARGE_CREATOR_BUNDLE_CAROUSEL: 'LARGE_CREATOR_BUNDLE_CAROUSEL',
};

export const HumanReadableModuleTypes = {
  SINGLE_CREATOR_CAROUSEL: 'Featured Creator',
  SINGLE_CREATOR_COLLECTION_CAROUSEL: 'Creator Collection',
  MULTI_CREATOR_COLLECTION_CAROUSEL: 'Multi-Creator Collection',
  LARGE_COLLECTION_BUNDLE_CAROUSEL: 'Collection Bundle',
  LARGE_CREATOR_BUNDLE_CAROUSEL: 'Creator Bundle',
};

export const TOPIC_TILE_BACKGROUND_GRADIENTS = {
  PINK: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), #FF4794',
  LAVENDER: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), #A347FF',
  INDIGO: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), #3330A8',
  PURPLE: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), #49276A',
  BLUE: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), #308FFF',
  GREEN: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), #0D9765',
  PEACH: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), #FF7246',
  ORANGE: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%), #FF5C00',
};

export const getTopicTileBackgroundStyles = (color) => ({
  background: TOPIC_TILE_BACKGROUND_GRADIENTS[color.toUpperCase()],
  backgroundBlendMode: 'overlay, normal',
});

export const BG_IMAGE_FALLBACK_GRADIENT = {
  BLURPLE: 'linear-gradient(0deg, rgba(90, 62, 117, 0.7), rgba(90, 62, 117, 0.7))',
  PURPLE: 'linear-gradient(0deg, rgba(163, 71, 255, 0.6), rgba(163, 71, 255, 0.6))',
  BLUE: 'linear-gradient(0deg, rgba(48, 143, 255, 0.7), rgba(48, 143, 255, 0.7))',
  GREEN: 'linear-gradient(0deg, rgba(0, 209, 171, 0.7), rgba(0, 209, 171, 0.7))',
  ORANGE: 'linear-gradient(0deg, rgba(255, 159, 17, 0.7), rgba(255, 159, 17, 0.7))',
  RED: 'linear-gradient(0deg, rgba(237, 89, 69, 0.7), rgba(237, 89, 69, 0.7))',
  LIGHTBEIGE: 'linear-gradient(0deg, rgba(254, 252, 245, 0.8), rgba(254, 252, 245, 0.8))',
};

export const BG_IMAGE_MASK_GRADIENT = {
  BLURPLE:
    'linear-gradient(0deg, #5a3e75 0%, #5a3e75 26.04%, rgba(90, 62, 117, 0.7) 60.58%, rgba(90, 62, 117, 0) 100%)',
  PURPLE:
    'linear-gradient(0deg, #A347FF 17.39%, rgba(163, 71, 255, 0.9) 42.28%, rgba(163, 71, 255, 0.3) 84.48%)',
  BLUE: 'linear-gradient(0deg, #308fff 0%, #308fff 26.04%, rgba(48, 143, 255, 0.7) 60.58%, rgba(48, 143, 255, 0) 100%)',
  GREEN:
    'linear-gradient(0deg, #00d1ab 0%, #00d1ab 26.04%, rgba(0, 209, 171, 0.7) 60.58%, rgba(0, 209, 171, 0) 100%)',
  ORANGE:
    'linear-gradient(0deg, #ff9f11 17.39%, rgba(255, 159, 17, 0.9) 42.28%, rgba(255, 159, 17, 0.3) 84.48%)',
  RED: 'linear-gradient(0deg, #ed5945 0%, #ed5945 26.04%, rgba(237, 89, 69, 0.7) 60.58%, rgba(237, 89, 69, 0) 100%)',
  LIGHTBEIGE: 'linear-gradient(0deg, rgba(254, 252, 245, 0) 0%, rgba(254, 252, 245, 0) 100%)',
};

export function getBackgroundImageGradientStyles({ backgroundImageUrl, backgroundColor }) {
  const imageLayer = backgroundImageUrl
    ? `url(${encodeURI(backgroundImageUrl)})`
    : BG_IMAGE_FALLBACK_GRADIENT[backgroundColor];

  return `${BG_IMAGE_MASK_GRADIENT[backgroundColor]}, ${imageLayer}`;
}

export function getEpisodeStatus(availability) {
  return {
    [EpisodeAvailabilities.AVAILABLE]: 'published',
    [EpisodeAvailabilities.NOT_AVAILABLE_UNRELEASED]: 'scheduled',
    [EpisodeAvailabilities.NOT_AVAILABLE_UNPUBLISHED]: 'draft',
  }[availability];
}

export async function fetchUrl(url) {
  const baseUrl = `${process.env.API_ADDRESS}${url}`;
  const response = await fetch(baseUrl);
  const json = await response.json();

  return json;
}

export function getHoursMinutesSeconds(seconds) {
  let secondsLeft = seconds;

  if (secondsLeft <= 0) {
    return { hours: 0, minutes: 0, seconds: 0 };
  }

  const MAX_HOURS = 60 * 60 * 99;
  const MAX_MINUTES_SECS = 60 * 59;
  const MAX_SECS = 59;
  const MAX_DISPLAY_SECS = MAX_HOURS + MAX_MINUTES_SECS + MAX_SECS;

  // Display can only show a max of 99:99:99
  if (secondsLeft > MAX_DISPLAY_SECS) {
    return { hours: 99, minutes: 59, seconds: 59 };
  }

  // calculate (and subtract) whole hours
  const hoursPlace = Math.floor(secondsLeft / 3600);
  secondsLeft -= hoursPlace * 3600;

  // calculate (and subtract) whole minutes
  const minutesPlace = Math.floor(secondsLeft / 60) % 60;
  secondsLeft -= minutesPlace * 60;

  // what's left is seconds
  const secondsPlace = secondsLeft % 60;

  return { hours: hoursPlace, minutes: minutesPlace, seconds: secondsPlace };
}

export function getFilterOptions(filteredMonth) {
  const filterOptions = [
    { label: 'Show All', value: null },
    { label: 'January', value: MONTHS.JANUARY },
    { label: 'Feburary', value: MONTHS.FEBURARY },
    { label: 'March', value: MONTHS.MARCH },
    { label: 'April', value: MONTHS.APRIL },
    { label: 'May', value: MONTHS.MAY },
    { label: 'June', value: MONTHS.JUNE },
    { label: 'July', value: MONTHS.JULY },
    { label: 'August', value: MONTHS.AUGUST },
    { label: 'September', value: MONTHS.SEPTEMBER },
    { label: 'October', value: MONTHS.OCTOBER },
    { label: 'November', value: MONTHS.NOVEMBER },
    { label: 'December', value: MONTHS.DECEMBER },
  ];
  filterOptions[filteredMonth || 0].isSelected = true;

  return filterOptions;
}

function easeInOutQuadAnimationCurve(t) {
  return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

export function easeOutQuad(t) {
  return 1 + --t * t * t; // eslint-disable-line
}

export function easeInQuad(t) {
  return t * t * t;
}

function getProgressFromTo(from, to, animationDecimal, animationCurveFn) {
  return from + (to - from) * animationCurveFn(animationDecimal);
}

function getCssNumberAndUnit(styleValue) {
  const [_, numberStr, unit] = styleValue.match(/^([+-]?(?:\d+|\d*\.\d+))([a-z]*|%)$/);
  const number = Number(numberStr);

  return [number, unit];
}

// Given a percentage of the animation, calculates the style's value in the animation.
// e.g. From 2px, To 4px, animationDecimal 0.5 would return 3px
export function getStyleProgressFromTo(
  fromStyleValue,
  toStyleValue,
  animationDecimal,
  animationCurveFn = easeInOutQuadAnimationCurve,
) {
  const [fromStyleNumber, fromStyleUnit] = getCssNumberAndUnit(fromStyleValue);
  const [toStyleNumber] = getCssNumberAndUnit(toStyleValue);
  const currentStyleNumber = getProgressFromTo(
    fromStyleNumber,
    toStyleNumber,
    animationDecimal,
    animationCurveFn,
  );
  const currentStyleValue = `${currentStyleNumber}${fromStyleUnit}`;

  return currentStyleValue;
}

export function getScrollPercentDecimal() {
  const { scrollTop, scrollHeight, clientHeight } = document.documentElement;
  const bodyScrollTop = document.body.scrollTop;

  return (scrollTop + bodyScrollTop) / (scrollHeight - clientHeight);
}

export function deepClone(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function getTimestampFromSeconds(seconds) {
  const minutesPlace = padNumber(Math.floor(seconds / 60));
  const secondsPlace = (seconds % 60).toFixed(2).padStart(5, '0');

  return `${minutesPlace}:${secondsPlace}`;
}

export const isValidForm = (obj) => Object.keys(obj).every((key) => obj[key].isValid);

export const getFormValues = (obj) =>
  Object.keys(obj).reduce((map, key) => ({ ...map, [key]: obj[key].value }), {});

export function getEmptyPaginationResponse() {
  return {
    items: [],
    links: null,
  };
}

export function pluralize(num, singular, plural) {
  return `${num} ${num === 1 ? singular : plural}`;
}

export const addEpisodeOffsetTimesToVideoDuration = (videoDurationSecs, doits) => {
  let episodeOffsetTime = 0;

  doits.forEach((doit) => {
    if (doit.pauseVideoUntilComplete) {
      episodeOffsetTime += doit.maxDuration;
    }
  });
  return videoDurationSecs + episodeOffsetTime;
};

export const addEpisodeOffsetTimesToDoits = (doits) => {
  let episodeOffsetTime = 0;

  const sortedDoits = [...doits].sort((a, b) => a.startSecs - b.startSecs);
  return sortedDoits.map((doit) => {
    const currentEpisodeOffsetTime = episodeOffsetTime;

    if (doit.pauseVideoUntilComplete) {
      episodeOffsetTime += doit.maxDuration;
    }

    return {
      ...doit,
      startSecsWithEpisodeOffset: doit.startSecs + currentEpisodeOffsetTime,
      finishSecsWithEpisodeOffset: doit.startSecs + doit.maxDuration + currentEpisodeOffsetTime,
    };
  });
};

export const isObjectEmpty = (obj) =>
  obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const noop = () => {};
