import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from 'components/media-row/MediaRow.css';

class MediaRow extends PureComponent {
  static propTypes = {
    imageUrl: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    selected: PropTypes.bool,
  };

  static defaultProps = {
    selected: false,
    imageUrl: null,
  };

  render() {
    const { imageUrl, title, description, selected } = this.props;

    return (
      <div className={cx(styles.mediaRow, selected && styles.selected)}>
        {imageUrl && (
          <div className={styles.mediaRowImage}>
            <img src={imageUrl} alt={title} />
          </div>
        )}

        <div className={styles.mediaRowInfo}>
          <h4 className={styles.mediaRowInfoTitle}>{title}</h4>
          <p className={styles.mediaRowInfoDescription}>{description}</p>
        </div>
      </div>
    );
  }
}

export default MediaRow;
