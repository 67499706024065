/* eslint-disable no-case-declarations */

import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import cuid from 'cuid';
import cx from 'classnames';

import * as adminService from 'services/content/adminService';

import { HOMESCREEN_SECTION_TYPES } from 'components/forms/constants';

import SelectInput from 'components/input/SelectInput';
import {
  BundleOption,
  BundleSingleValue,
  CreatorOption,
  CreatorSingleValue,
  CollectionOption,
  CollectionSingleValue,
  ModuleTypeOption,
} from 'components/input/components/SelectComponents';

import styles from 'components/forms/styles.css';

const ModuleDropdownControl = ({ type, data, isLoading, setModuleEntity }) => {
  switch (type) {
    case HOMESCREEN_SECTION_TYPES.SINGLE_CREATOR_CAROUSEL.value:
      return (
        <SelectInput
          key={HOMESCREEN_SECTION_TYPES[type].value}
          label={HOMESCREEN_SECTION_TYPES[type].label}
          isLoading={isLoading}
          onChange={({ value }) => setModuleEntity({ creator: value })}
          options={data.map((creator) => ({
            value: creator,
            label: creator.title,
          }))}
          customComponents={{ Option: CreatorOption, SingleValue: CreatorSingleValue }}
        />
      );
    case HOMESCREEN_SECTION_TYPES.SINGLE_CREATOR_COLLECTION_CAROUSEL.value:
    case HOMESCREEN_SECTION_TYPES.MULTI_CREATOR_COLLECTION_CAROUSEL.value:
      return (
        <SelectInput
          key={HOMESCREEN_SECTION_TYPES[type].value}
          label={HOMESCREEN_SECTION_TYPES[type].label}
          isLoading={isLoading}
          onChange={({ value }) => setModuleEntity(value)}
          options={data.map((collection) => ({
            value: collection,
            label: collection?.collection?.title,
          }))}
          customComponents={{ Option: CollectionOption, SingleValue: CollectionSingleValue }}
          styleOverrides={{
            valueContainer: (base) => ({
              ...base,
              height: '54px',
            }),
          }}
        />
      );
    case HOMESCREEN_SECTION_TYPES.LARGE_COLLECTION_BUNDLE_CAROUSEL.value:
    case HOMESCREEN_SECTION_TYPES.LARGE_CREATOR_BUNDLE_CAROUSEL.value:
      return (
        <SelectInput
          key={HOMESCREEN_SECTION_TYPES[type].value}
          label={HOMESCREEN_SECTION_TYPES[type].label}
          isLoading={isLoading}
          onChange={({ value }) => setModuleEntity(value)}
          options={data.map((bundle) => ({
            value: bundle,
            label: bundle?.bundle?.title,
          }))}
          customComponents={{ Option: BundleOption, SingleValue: BundleSingleValue }}
        />
      );
    default:
      return null;
  }
};

ModuleDropdownControl.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
  setModuleEntity: PropTypes.func.isRequired,
};

const HomescreenModuleForm = forwardRef(({ setWorkingModules, setIsValid }, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState('');
  const [moduleEntity, setModuleEntity] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchModuleEntities = async () => {
      setIsLoading(true);
      switch (type) {
        case HOMESCREEN_SECTION_TYPES.SINGLE_CREATOR_CAROUSEL.value:
          const creators = await adminService.getAllCreators();
          setData(creators);
          break;
        case HOMESCREEN_SECTION_TYPES.SINGLE_CREATOR_COLLECTION_CAROUSEL.value:
          const collections = await adminService.getAllSingleCreatorCollections();
          setData(collections);
          break;
        case HOMESCREEN_SECTION_TYPES.MULTI_CREATOR_COLLECTION_CAROUSEL.value:
          const multiCreatorCollections = await adminService.getCollections();
          setData(multiCreatorCollections);
          break;
        case HOMESCREEN_SECTION_TYPES.LARGE_COLLECTION_BUNDLE_CAROUSEL.value:
          const collectionBundles = await adminService.getCollectionBundles();
          setData(collectionBundles);
          break;
        case HOMESCREEN_SECTION_TYPES.LARGE_CREATOR_BUNDLE_CAROUSEL.value:
          const creatorBundles = await adminService.getCreatorBundles();
          setData(creatorBundles);
          break;
        default:
          break;
      }
      setIsLoading(false);
    };

    setData([]);
    fetchModuleEntities();
    setModuleEntity(null);
  }, [type]);

  useEffect(() => {
    if (!type || !moduleEntity) {
      setIsValid(false);
      return;
    }
    setIsValid(true);
  }, [type, moduleEntity]);

  const submit = () => {
    const newModule = { moduleId: cuid(), type, data: moduleEntity };
    setWorkingModules((prevState) => [...prevState, newModule]);
  };

  useImperativeHandle(ref, () => ({ submit }));

  return (
    <div className={cx(styles.form, styles.homescreenModuleForm)}>
      <section className={styles.typeSection}>
        <SelectInput
          label="Section Type"
          placeholder="Select section type…"
          options={Object.values(HOMESCREEN_SECTION_TYPES)}
          onChange={({ value }) => setType(value)}
          customComponents={{ Option: ModuleTypeOption }}
        />
      </section>

      <section className={styles.moduleEntitySection}>
        {type && (
          <ModuleDropdownControl
            type={type}
            data={data}
            isLoading={isLoading}
            setModuleEntity={setModuleEntity}
          />
        )}
      </section>
    </div>
  );
});

HomescreenModuleForm.displayName = 'HomescreenModuleForm';

HomescreenModuleForm.propTypes = {
  setWorkingModules: PropTypes.func.isRequired,
  setIsValid: PropTypes.func.isRequired,
};

export default HomescreenModuleForm;
