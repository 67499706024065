import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import styles from 'components/modal/Modal.css';

const Modal = ({ title, children, footerButtons, minHeight, ...rest }) => (
  <ReactModal
    className={styles.modal}
    overlayClassName={styles.overlay}
    bodyOpenClassName={styles.modalOpen}
    contentLabel={title}
    style={{
      overlay: { zIndex: 11000 },
      content: minHeight ? { minHeight } : {},
    }}
    {...rest}
  >
    <header>
      <h1>{title}</h1>
    </header>
    <main>{children}</main>
    <footer>{footerButtons}</footer>
  </ReactModal>
);

Modal.defaultProps = {
  minHeight: '',
};

Modal.propTypes = {
  minHeight: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  footerButtons: PropTypes.arrayOf(PropTypes.node).isRequired,
};

export const FullHeightModal = (props) => <Modal {...props} minHeight="90vh" />;

export default Modal;
