import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { SketchPicker } from 'react-color';

import * as styles from 'components/input/Input.css';

export const UNITY_TRANSPARENT_BG_COLOR = '#FFFFFF01';

const ColorInput = ({ value, label, onRemove, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = useCallback((event) => {
    const container = document.querySelector('.sketch-picker');
    if (container && container !== event.target && !container.contains(event.target)) {
      setIsOpen(false);
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      window.addEventListener('click', handleClick);

      return () => {
        window.removeEventListener('click', handleClick);
      };
    }

    window.removeEventListener('click', handleClick);
    return () => {};
  }, [isOpen, handleClick]);

  return (
    <>
      {label && (
        <label className={styles.labelRemovable}>
          <span>{label}</span>{' '}
          {onRemove && (
            <a
              className={styles.remove}
              onClick={() => {
                confirm('Are you sure you want to remove this color?') && onRemove();
              }}
            >
              Remove
            </a>
          )}
        </label>
      )}
      <div className={styles.colorInput}>
        <div
          className={styles.colorInputPreview}
          style={{ backgroundColor: value }}
          onClick={() => setIsOpen(!isOpen)}
        />
        <div className={styles.colorInputText} onClick={() => setIsOpen(!isOpen)}>
          {value === UNITY_TRANSPARENT_BG_COLOR ? 'Transparent' : value}
        </div>
        {isOpen && (
          <SketchPicker
            color={value}
            onChangeComplete={(color) => {
              if (color.rgb.a === 0) {
                onChange(`${color.hex}01`);
              } else if (color.rgb.a === 1) {
                onChange(color.hex);
              } else {
                onChange(
                  `${color.hex}${Math.round(color.rgb.a * 255)
                    .toString(16)
                    .padStart(2, '0')}`,
                );
              }
            }}
          />
        )}
      </div>
    </>
  );
};

ColorInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  label: PropTypes.string,
};

ColorInput.defaultProps = {
  label: null,
};

export default ColorInput;
