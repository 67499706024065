import { useState, useEffect } from 'react';
import cx from 'classnames';
import { NotificationManager } from 'react-notifications';

import request from 'services/request/requestService';

import { CMSWhiteInput } from 'components/input/Input';
import DropdownInput from 'components/input/DropdownInput';
import { PurpleButton, YellowButton } from 'components/button/Button';
import { INPUT_TYPES } from 'components/input/constants';
import { isInputValid } from 'components/input/utils';

import styles from 'pages/tools/GrantAccessPage.css';
import formStyles from 'components/forms/styles.css';

const DURATION_OPTIONS = {
  MONTH: 'Month',
  QUARTER: 'Three Months',
  YEAR: 'Year',
  LIFETIME: 'Lifetime',
};

const GrantAccessPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [duration, setDuration] = useState(DURATION_OPTIONS.QUARTER);
  const [pendingInvites, setPendingInvites] = useState([]);
  const [resentInvites, setResentInvites] = useState({});

  const sendInvites = async (pendingInvite) => {
    const endDate = new Date(
      {
        [DURATION_OPTIONS.MONTH]: new Date().setMonth(new Date().getMonth() + 1),
        [DURATION_OPTIONS.QUARTER]: new Date().setMonth(new Date().getMonth() + 3),
        [DURATION_OPTIONS.YEAR]: new Date().setFullYear(new Date().getFullYear() + 1),
        [DURATION_OPTIONS.LIFETIME]: 'January 1, 2100',
      }[duration],
    );

    let conclusion = '';
    if (pendingInvite && pendingInvite.expirationAt !== '2100-01-01T05:00:00.000Z') {
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
      const unixDate = Date.parse(pendingInvite.expirationAt);
      const date = new Date(unixDate).toLocaleDateString('en-US', options);
      conclusion = ` It expires on ${date}.`;
    }

    const body = JSON.stringify({
      email: email || pendingInvite.user.email,
      message:
        message ||
        `Hi! Bumping this free access to Hellosaurus back up in your inbox in case you missed it.${conclusion}`,
      endDate: endDate || pendingInvite.expirationAt,
    });

    await request('/subscriptions/gift/admin', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body,
    });

    NotificationManager.success(`Email invitation ${pendingInvite ? 'resent!' : 'sent!'}`);
    setEmail('');
    setMessage('');
    setResentInvites({ ...resentInvites, [pendingInvite.userId]: true });
  };

  const resendInvite = (pendingInvite) => () => {
    sendInvites(pendingInvite);
  };

  useEffect(() => {
    request('/subscriptions/gift/admin', {
      method: 'GET',
    }).then((data) => setPendingInvites(data));
  }, []);

  return (
    <div className={styles.grantAccessPage}>
      <h1>
        Grant Free Access to Hellosaurus{' '}
        <span role="img" aria-label="gift">
          🎁
        </span>
      </h1>
      <p>
        Grant time-bound Hellosaurus subscriptions that don&apos;t require a credit card and
        won&apos;t renew. This may be helpful for potential creators, partners, and journalists.
      </p>

      <div className={formStyles.form}>
        <section className={formStyles.formBody}>
          <div className={cx(formStyles.formWrapper, styles.formWrapper)}>
            <CMSWhiteInput value={email} label="Recipient Email" isRequired onChange={setEmail} />
            <CMSWhiteInput
              value={message}
              label="Note to Recipient"
              isRequired
              onChange={setMessage}
              type={INPUT_TYPES.EMAIL}
            />
            <DropdownInput
              options={Object.keys(DURATION_OPTIONS).map((key) => DURATION_OPTIONS[key])}
              value={duration}
              onChange={({ value }) => setDuration(value)}
            />
          </div>
        </section>
        <footer className={formStyles.formFooter}>
          <PurpleButton
            label="Send Email Invite"
            isDisabled={
              !isInputValid(INPUT_TYPES.EMAIL, email) || !isInputValid(INPUT_TYPES.TEXT, message)
            }
            onClick={sendInvites}
          />
        </footer>
        <section className={styles.resendSection}>
          <h1>Pending Invites</h1>
          <p>Message will read:</p>
          <p>
            &#34;Hi! Bumping this free access to Hellosaurus back up in your inbox in case you
            missed it.&#34; followed by the original expiration date, if one exists.
          </p>
          {pendingInvites.map((pendingInvite) => (
            <div className={styles.resendInvite}>
              <p> {pendingInvite.user.email} </p>
              <YellowButton
                label={resentInvites[pendingInvite.userId] ? 'invite resent' : 'resend invite'}
                isDisabled={resentInvites[pendingInvite.userId]}
                onClick={resendInvite(pendingInvite)}
              />
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default GrantAccessPage;
