/* eslint-disable no-nested-ternary */

import PropTypes from 'prop-types';
import Select from 'react-select';

import { getPrimaryColorByOpacity } from 'components/input/utils';

const SELECT_INPUT_Z_INDEX = 15000;

const SelectInput = ({
  label,
  placeholder,
  isDisabled,
  isRequired,
  isSearchable,
  options,
  customComponents,
  optionStyles,
  singleValueStyles,
  styleOverrides,
  onChange,
  ...props
}) => (
  <div>
    <label>
      {label} {isRequired && '*'}
    </label>
    <Select
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
      components={{
        IndicatorSeparator: () => null,
        ...customComponents,
      }}
      menuPortalTarget={document.body}
      styles={{
        menuPortal: (base) => ({
          ...base,
          zIndex: SELECT_INPUT_Z_INDEX,
        }),
        option: (base, { isDisabled, isFocused, isSelected }) => ({
          ...base,
          ...optionStyles,
          cursor: 'pointer',
          color: 'black',
          backgroundColor: isDisabled
            ? null
            : isSelected
            ? getPrimaryColorByOpacity(0.12)
            : isFocused
            ? getPrimaryColorByOpacity(0.06)
            : '#ffffff',

          ':active': {
            ...base[':active'],
            backgroundColor:
              !isDisabled &&
              (isSelected ? getPrimaryColorByOpacity(0.12) : getPrimaryColorByOpacity(0.06)),
          },
        }),
        singleValue: (base) => ({
          ...base,
          ...singleValueStyles,
        }),
        ...styleOverrides,
      }}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: getPrimaryColorByOpacity(),
          primary75: getPrimaryColorByOpacity(0.75),
          primary50: getPrimaryColorByOpacity(0.5),
          primary25: getPrimaryColorByOpacity(0.25),
        },
      })}
      {...props}
    />
  </div>
);

SelectInput.defaultProps = {
  placeholder: 'Select…',
  isDisabled: false,
  isRequired: true,
  isSearchable: true,
  customComponents: null,
  optionStyles: {},
  singleValueStyles: {},
  styleOverrides: {},
  onChange: () => null,
};

SelectInput.propTypes = {
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isSearchable: PropTypes.bool,
  customComponents: PropTypes.object,
  optionStyles: PropTypes.object,
  singleValueStyles: PropTypes.object,
  styleOverrides: PropTypes.object,
  onChange: PropTypes.func,
};

export default SelectInput;
