import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import * as styles from 'components/timeline/Timeline.css';

import * as uploadService from 'services/upload/uploadService';
import { ACCEPTED_IMAGE_MIME_TYPES } from 'components/file-upload/constants';
import { SUPPORTED_DO_ITS, UNSUPPORTED_DO_IT } from 'components/do-it-forms/constants';
import TimelineItem from 'components/timeline/TimelineItem';
import TimelinePlayerPin from 'components/timeline/TimelinePlayerPin';
import * as utils from 'components/timeline/utils';
import { ExtendDirections } from 'components/timeline/constants';

class Timeline extends PureComponent {
  static propTypes = {
    videoUrl: PropTypes.string.isRequired,
    episodeDurationSecs: PropTypes.number.isRequired,
    playerCurrentTime: PropTypes.number.isRequired,
    doits: PropTypes.arrayOf(PropTypes.object).isRequired,
    match: PropTypes.object, // React Router's injected props via withRouter
    onDoitChange: PropTypes.func.isRequired,
    onDoitDelete: PropTypes.func.isRequired,
    onPlayerCurrentTimeChange: PropTypes.func.isRequired,
    viewingItemId: PropTypes.string,
    hideTimeline: PropTypes.bool,
    newUnityBuild: PropTypes.bool.isRequired,
  };

  static defaultProps = {
    match: {},
    viewingItemId: null,
    hideTimeline: false,
  };

  timelineRef = createRef();

  state = {
    frames: [],
  };

  async componentDidMount() {
    const episodeId = this.props.match?.params?.episodeId || null;
    const existingFrames = await uploadService.getUploads(
      ACCEPTED_IMAGE_MIME_TYPES,
      uploadService.UploadTypes.FRAME,
      episodeId,
    );

    if (existingFrames && existingFrames.length) {
      this.setState({ frames: existingFrames.map((upload) => upload.awsPublicUrl) });
      return;
    }

    const frames = await this.extractFramesFromVideo(this.props.videoUrl);
    await this.uploadFrames(frames, episodeId);
  }

  // Mostly taken from https://stackoverflow.com/a/52357558/2957677
  async extractFramesFromVideo(videoUrl, frameCount = 11) {
    const video = document.createElement('video');
    const videoBlob = await fetch(videoUrl).then((r) => r.blob());
    const videoObjectUrl = URL.createObjectURL(videoBlob);

    let seekResolve;
    video.addEventListener('seeked', async () => {
      if (seekResolve) seekResolve();
    });

    video.src = videoObjectUrl;

    // workaround chromium metadata bug (https://stackoverflow.com/q/38062864/993683)
    while ((video.duration === Infinity || isNaN(video.duration)) && video.readyState < 2) {
      await new Promise((r) => setTimeout(r, 1000)); // eslint-disable-line no-await-in-loop
      video.currentTime = 10000000 * Math.random();
    }
    const { duration } = video;

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const [w, h] = [1920, 1080];
    canvas.width = w;
    canvas.height = h;

    const interval = duration / frameCount;
    let currentTime = 0;

    while (currentTime < duration) {
      video.currentTime = currentTime;
      await new Promise((r) => (seekResolve = r)); // eslint-disable-line

      context.drawImage(video, 0, 0, w, h);
      const base64ImageData = canvas.toDataURL();
      this.setState(({ frames }) => ({ frames: [...frames, base64ImageData] }));

      currentTime += interval;
    }

    return this.state.frames;
  }

  async uploadFrames(frames, episodeId) {
    const files = frames.map((frame) =>
      uploadService.getFileFromDataUrl(
        frame,
        `${uploadService.UploadTypes.FRAME}-${episodeId}.png`,
      ),
    );
    const response = await uploadService.uploadFiles(
      files,
      uploadService.UploadTypes.FRAME,
      episodeId,
      null,
    );
    return response;
  }

  secondsToPercent(seconds, episodeDurationSecs) {
    return Number(((seconds / episodeDurationSecs) * 100).toFixed(2));
  }

  getStartFinishPercents(doitStart, doitFinish, episodeDurationSecs) {
    return {
      startPercent: this.secondsToPercent(doitStart, episodeDurationSecs),
      finishPercent: this.secondsToPercent(doitFinish, episodeDurationSecs),
    };
  }

  percentToSeconds(percent, episodeDurationSecs) {
    return Number(((percent / 100) * episodeDurationSecs).toFixed(2));
  }

  getStartFinishSeconds(
    startPercent,
    finishPercent,
    episodeDurationSecs,
    doits,
    itemId,
    extendDirection,
  ) {
    let startSecsWithEpisodeOffset;
    let finishSecsWithEpisodeOffset;
    const doit = doits.find((doit) => doit.itemId === itemId);

    if (!extendDirection) {
      startSecsWithEpisodeOffset = this.percentToSeconds(startPercent, episodeDurationSecs);
      finishSecsWithEpisodeOffset = Math.min(
        Number((startSecsWithEpisodeOffset + doit.maxDuration).toFixed(2)),
        episodeDurationSecs,
      );
    } else if (extendDirection === ExtendDirections.EAST) {
      startSecsWithEpisodeOffset = doit.startSecsWithEpisodeOffset;
      finishSecsWithEpisodeOffset = this.percentToSeconds(finishPercent, episodeDurationSecs);
    } else if (extendDirection === ExtendDirections.WEST) {
      finishSecsWithEpisodeOffset = doit.finishSecsWithEpisodeOffset;
      startSecsWithEpisodeOffset = this.percentToSeconds(startPercent, episodeDurationSecs);
    }

    let episodeOffsetTime = 0;

    const sortedDoits = doits.sort(
      (a, b) => a.startSecsWithEpisodeOffset - b.startSecsWithEpisodeOffset,
    );

    /* eslint-disable-next-line no-restricted-syntax */
    for (const doit of sortedDoits) {
      if (doit.itemId === itemId) {
        break;
      }

      if (doit.pauseVideoUntilComplete) {
        episodeOffsetTime += doit.maxDuration;
      }
    }

    const startSecs = startSecsWithEpisodeOffset - episodeOffsetTime;
    const finishSecs = finishSecsWithEpisodeOffset - episodeOffsetTime;
    const maxDuration = finishSecs - startSecs;

    return {
      startSecs,
      finishSecs,
      startSecsWithEpisodeOffset,
      finishSecsWithEpisodeOffset,
      maxDuration,
    };
  }

  handlePositionChange(itemId, startPercent, finishPercent, episodeDurationSecs, extendDirection) {
    const { doits, onDoitChange } = this.props;
    const changedIndex = doits.findIndex((doit) => doit.itemId === itemId);
    const doitsCopy = [...doits];
    doitsCopy[changedIndex] = {
      ...doitsCopy[changedIndex],
      ...this.getStartFinishSeconds(
        startPercent,
        finishPercent,
        episodeDurationSecs,
        doitsCopy,
        itemId,
        extendDirection,
      ),
    };

    onDoitChange(doitsCopy[changedIndex]);
  }

  handleTimelineClick(e, episodeDurationSecs) {
    const percent = utils.getPercentFromMousePosition(e, this.timelineRef.current);
    const seconds = this.percentToSeconds(percent, episodeDurationSecs);

    this.props.onPlayerCurrentTimeChange(seconds);
  }

  nudge(itemId, seconds) {
    const { doits, onDoitChange } = this.props;
    const changedIndex = doits.findIndex((doit) => doit.itemId === itemId);
    const doitsCopy = [...doits];
    doitsCopy[changedIndex] = {
      ...doitsCopy[changedIndex],
      startSecs: doitsCopy[changedIndex].startSecs + seconds,
      finishSecs: doitsCopy[changedIndex].finishSecs + seconds,
      startSecsWithEpisodeOffset: doitsCopy[changedIndex].startSecsWithEpisodeOffset + seconds,
      finishSecsWithEpisodeOffset: doitsCopy[changedIndex].finishSecsWithEpisodeOffset + seconds,
    };

    onDoitChange(doitsCopy[changedIndex]);
  }

  render() {
    const {
      doits,
      episodeDurationSecs,
      playerCurrentTime,
      viewingItemId,
      onPlayerCurrentTimeChange,
      newUnityBuild,
      onDoitDelete,
    } = this.props;
    const { frames } = this.state;
    const title = frames.length ? 'Story Timeline' : 'Loading Story Timeline…';
    const timelineElements = doits.map((doit) => {
      const { startPercent, finishPercent } = this.getStartFinishPercents(
        doit.startSecsWithEpisodeOffset,
        doit.finishSecsWithEpisodeOffset,
        episodeDurationSecs,
      );

      const { hexColor, displayName } =
        SUPPORTED_DO_ITS.find(
          (supportedDoIt) => supportedDoIt.baseTemplateId === doit.baseTemplateId,
        ) || UNSUPPORTED_DO_IT;

      const isSelected = viewingItemId === doit.itemId;
      const label = doit.name && doit.name.length > 0 ? doit.name : displayName;
      const doitReleaseDatestamp = utils.getTimestampFromSeconds(doit.startSecsWithEpisodeOffset);
      const doitFinishTimestamp = utils.getTimestampFromSeconds(doit.finishSecsWithEpisodeOffset);

      return (
        <TimelineItem
          key={doit.itemId}
          startPercent={startPercent}
          finishPercent={finishPercent}
          hexColor={hexColor}
          isSelected={isSelected}
          label={label}
          displayName={displayName}
          onDoitDelete={() => onDoitDelete(doit.itemId)}
          onClick={() => onPlayerCurrentTimeChange(doit.startSecsWithEpisodeOffset)}
          onPositionChange={(startPercent, finishPercent, extendDirection) =>
            this.handlePositionChange(
              doit.itemId,
              startPercent,
              finishPercent,
              episodeDurationSecs,
              extendDirection,
            )
          }
          getStartFinishSeconds={(startPercent, finishPercent, extendDirection) =>
            this.getStartFinishSeconds(
              startPercent,
              finishPercent,
              episodeDurationSecs,
              doits,
              doit.itemId,
              extendDirection,
            )
          }
          startTimestamp={doitReleaseDatestamp}
          finishTimestamp={doitFinishTimestamp}
          nudgeRight={() => this.nudge(doit.itemId, 0.01)}
          nudgeLeft={() => this.nudge(doit.itemId, -0.01)}
        />
      );
    });
    const { startPercent: playerPercent } = this.getStartFinishPercents(
      playerCurrentTime,
      playerCurrentTime,
      episodeDurationSecs,
    );

    return (
      <div
        className={`${styles.timelineContainer} ${newUnityBuild && styles.new} ${
          this.props.hideTimeline ? styles.fadeOutDown : styles.fadeInUp
        }`}
      >
        <p>{title}</p>
        <div
          className={styles.timelineContainerTimeline}
          onClick={(e) => this.handleTimelineClick(e, episodeDurationSecs)}
          ref={this.timelineRef}
        >
          {frames.map((frame, i) => (
            <img key={i} src={frame} alt={`Video Frame ${i + 1}`} />
          ))}
          {timelineElements}
          <TimelinePlayerPin percent={playerPercent} />
        </div>
      </div>
    );
  }
}

export default withRouter(Timeline);
