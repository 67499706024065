import { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import cx from 'classnames';

import CheckboxInput from 'components/input/CheckboxInput';
import ColorRadioGroup, { BACKGROUND_COLORS } from 'components/input/ColorRadioGroup';
import SeriesPreviewCard from 'components/series-preview-card/SeriesPreviewCard';

import styles from 'components/forms/styles.css';

/**
 * When form is wrapped in `forwardRef` and uses the `useImperativeHandle` hook,
 * the parent component then has access to whatever data is selectively exposed.
 */
const FeaturedStoryAppearanceForm = forwardRef(({ story, onSave }, ref) => {
  const [metadata, setMetadata] = useState(
    story?.metadata || {
      episodeId: '',
      showStoryTitle: false,
      showSeriesThumbnail: false,
      backgroundColor: BACKGROUND_COLORS.PURPLE.name,
    },
  );
  const updateMetadata = (key, value) => setMetadata((prev) => ({ ...prev, [key]: value }));

  const { showStoryTitle, showSeriesThumbnail, backgroundColor } = metadata;

  async function submit() {
    onSave({ episode: story.episode, metadata });
    NotificationManager.success('Successfully saved!');
  }

  useImperativeHandle(ref, () => ({ submit }));

  return (
    <div className={cx(styles.form, styles.featuredStoryForm)}>
      <div className={styles.left}>
        <CheckboxInput
          label="Display Story Title"
          description="If toggled on, Story Title will be displayed under the Series Logo"
          forKey="display story title"
          onChange={(isEnabled) => updateMetadata('showStoryTitle', isEnabled)}
          value={showStoryTitle}
        />

        <CheckboxInput
          label="Display Series Thumbnail"
          description="If toggled on, Series Thumbnail will override Story Thumbnail"
          forKey="display series thumbnail"
          onChange={(isEnabled) => updateMetadata('showSeriesThumbnail', isEnabled)}
          value={showSeriesThumbnail}
        />

        <p className={styles.title}>Custom Background Color</p>
        <ColorRadioGroup
          name="featured-story-backgroundcolor"
          value={backgroundColor}
          onChange={(value) => updateMetadata('backgroundColor', value)}
        />
      </div>

      <div className={styles.right}>
        <SeriesPreviewCard episode={story?.episode} metadata={metadata} />
      </div>
    </div>
  );
});

FeaturedStoryAppearanceForm.displayName = 'FeaturedStoryAppearanceForm';

FeaturedStoryAppearanceForm.propTypes = {
  story: PropTypes.shape({
    episode: PropTypes.object,
    metadata: PropTypes.object,
  }).isRequired,
  onSave: PropTypes.func.isRequired,
};

export default FeaturedStoryAppearanceForm;
