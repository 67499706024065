/* eslint-disable react/prop-types */
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import PositionInput from 'components/input/PositionInput';
import SizeInput from 'components/input/SizeInput';
import ColorInput, { UNITY_TRANSPARENT_BG_COLOR } from 'components/input/ColorInput';
import CheckboxInput from 'components/input/CheckboxInput';
import DropdownInput from 'components/input/DropdownInput';
import Accordion from 'components/accordion/Accordion';
import AccordionSection from 'components/accordion/AccordionSection';
import DoItForm from 'components/do-it-forms/forms/DoIt';
import BaseForm from 'components/do-it-forms/forms/Base';
import BackgroundAccordion from 'components/do-it-forms/accordions/BackgroundAccordion';
import { CMSWhiteInput } from 'components/input/Input';
import { INPUT_TYPES } from 'components/input/constants';
import AudioConfig from 'components/do-it-forms/components/AudioConfig';
import ObjectList from 'components/do-it-forms/components/ObjectList';
import QuestionZone from 'components/do-it-forms/components/QuestionZone';
import { getDefaultBackground, getDefaultChoice } from 'components/do-it-forms/utils';
import { FREEFORM_VARIANT_ID, LAYOUT_TYPES } from 'components/do-it-forms/forms/constants';

const Choice = ({ value, i, onChange, onDelete }) => {
  const accordionTitle = `Choice #${i + 1}`;
  const isLabelDisplayed = typeof value.buttonText.text === 'string';
  const hasPosition = typeof value.position?.x === 'number';
  const hasSize = typeof value.size?.x === 'number';
  const shouldShowTransparency = typeof value.defaultColor === 'string';
  const isDefaultColorTransparent = value.defaultColor === UNITY_TRANSPARENT_BG_COLOR;
  const isSelectedColorTransparent = value.selectedColor === UNITY_TRANSPARENT_BG_COLOR;
  const shouldShowDefaultBackgroundColor = shouldShowTransparency && !isDefaultColorTransparent;
  const shouldShowSelectedBackgroundColor = shouldShowTransparency && !isSelectedColorTransparent;

  return (
    <Accordion title={accordionTitle} key={accordionTitle} onDelete={onDelete}>
      {hasPosition && (
        <PositionInput
          positionX={value.position.x}
          positionY={value.position.y}
          onChange={(key, position) => onChange('position', position)}
        />
      )}
      {hasSize && (
        <SizeInput
          sizeX={value.size.x}
          sizeY={value.size.y}
          onChange={(key, size) => onChange('size', size)}
        />
      )}
      <AccordionSection
        title="Starting State Settings"
        description="The state of the Choice asset before it's tapped"
      />
      {shouldShowTransparency && (
        <CheckboxInput
          label="Use Transparent Background"
          forKey={`choice ${i} transparent background`}
          onChange={(isTransparent) =>
            onChange('defaultColor', isTransparent ? UNITY_TRANSPARENT_BG_COLOR : '#FFFFFF')
          }
          value={isDefaultColorTransparent}
        />
      )}
      {shouldShowDefaultBackgroundColor && (
        <ColorInput
          label="Starting Background Color"
          onChange={(color) => onChange('defaultColor', color)}
          value={value.defaultColor}
        />
      )}
      <ImageUploadInput
        label="Starting State Image"
        onChange={(spriteFile) =>
          onChange('itemDefaultNode', { ...value.itemDefaultNode, spriteFile })
        }
        value={value.itemDefaultNode.spriteFile}
      />
      <PositionInput
        positionX={value.itemDefaultNode.position.x}
        positionY={value.itemDefaultNode.position.y}
        onChange={(key, position) =>
          onChange('itemDefaultNode', { ...value.itemDefaultNode, position })
        }
      />
      <SizeInput
        sizeX={value.itemDefaultNode.size.x}
        sizeY={value.itemDefaultNode.size.y}
        onChange={(key, size) => onChange('itemDefaultNode', { ...value.itemDefaultNode, size })}
      />
      <br />
      <br />
      <AccordionSection title="Pressed State" />
      {shouldShowTransparency && (
        <CheckboxInput
          label="Use Transparent Background"
          forKey={`choice ${i} transparent background selected`}
          onChange={(isTransparent) =>
            onChange('selectedColor', isTransparent ? UNITY_TRANSPARENT_BG_COLOR : '#FFFFFF')
          }
          value={isSelectedColorTransparent}
        />
      )}
      {shouldShowSelectedBackgroundColor && (
        <ColorInput
          label="Pressed State Background Color"
          onChange={(color) => onChange('selectedColor', color)}
          value={value.selectedColor}
        />
      )}
      <ImageUploadInput
        label="Pressed State Image"
        onChange={(spriteFile) => onChange('selectedImage', spriteFile)}
        value={value.selectedImage}
      />
      <AudioConfig
        label="Pressed State Sound Effect"
        onChange={(value) => onChange('buttonSound', value)}
        value={value.buttonSound}
      />
      <br />
      <br />
      <AccordionSection title="Label" />
      <CheckboxInput
        label="Display Label"
        forKey={`display label ${i}`}
        onChange={(isDisplayed) => {
          if (isDisplayed) {
            onChange('buttonText', { ...value.buttonText, text: value.buttonText.lastText });
          } else {
            onChange('buttonText', {
              ...value.buttonText,
              lastText: value.buttonText.text,
              text: null,
            });
          }
        }}
        value={isLabelDisplayed}
      />
      {isLabelDisplayed && (
        <>
          <CMSWhiteInput
            value={value.buttonText.text}
            onChange={(text) => onChange('buttonText', { ...value.buttonText, text })}
            label="Label Text"
            debounced
          />
          <ColorInput
            label="Label Text Color"
            onChange={(color) => onChange('buttonText', { ...value.buttonText, color })}
            value={value.buttonText.color}
          />
          <PositionInput
            positionX={value.buttonText.position.x}
            positionY={value.buttonText.position.y}
            onChange={(key, position) => onChange('buttonText', { ...value.buttonText, position })}
          />
          <SizeInput
            sizeX={value.buttonText.size.x}
            sizeY={value.buttonText.size.y}
            onChange={(key, size) => onChange('buttonText', { ...value.buttonText, size })}
          />
        </>
      )}
    </Accordion>
  );
};

class SurveyItForm extends BaseForm {
  getDefaultParams() {
    const isFreeForm = this.props.doit.variantId === FREEFORM_VARIANT_ID;

    return {
      background: getDefaultBackground(),
      numRequiredResponses: 1,
      questionZone: {
        position: { x: 0, y: 300 },
        size: { x: 400, y: 200 },
        color: '#FFFFFF',
        spriteFile: null,
        questionText: {
          text: '',
          color: '#000000',
          position: { x: 0, y: 0 },
          size: { x: 400, y: 100 },
        },
      },
      choicesZone: {
        ...(isFreeForm
          ? {}
          : {
              position: { x: 0, y: 0 },
              size: { x: 500, y: 500 },
              spriteFile: null,
              color: UNITY_TRANSPARENT_BG_COLOR,
            }),
        choices: [getDefaultChoice(isFreeForm)],
        layoutSettings: {
          layoutType: isFreeForm ? LAYOUT_TYPES.freeForm : LAYOUT_TYPES.autoGrid,
        },
      },
    };
  }

  getDoitParams() {
    return this.state;
  }

  onChoicesZoneChange(key, value) {
    this.setState(({ choicesZone }) => ({
      choicesZone: {
        ...choicesZone,
        [key]: value,
      },
    }));
  }

  render() {
    const { onClose, onDelete, doit, subsequentDoitId } = this.props;
    const { background, questionZone, choicesZone, numRequiredResponses } = this.state;
    const isFreeForm = doit.variantId === FREEFORM_VARIANT_ID;
    const allowMultipleChoiceSelections = numRequiredResponses > 1;

    return (
      <DoItForm
        title={`Survey It (${isFreeForm ? 'Freeform' : 'Auto Layout'})`}
        doit={doit}
        onClose={onClose}
        onSave={(options) => this.handleSave(options)}
        onDelete={onDelete}
        subsequentDoitId={subsequentDoitId}
      >
        <BackgroundAccordion
          value={background}
          onChange={(key, value) => this.setState({ [key]: value })}
        />
        <QuestionZone setState={(...params) => this.setState(...params)} value={questionZone} />
        <Accordion title="Choices">
          {!isFreeForm && (
            <>
              <DropdownInput
                label="Layout Type"
                onChange={({ value }) =>
                  this.onChoicesZoneChange('layoutSettings', { layoutType: value })
                }
                value={choicesZone.layoutSettings.layoutType || LAYOUT_TYPES.autoGrid}
                options={[LAYOUT_TYPES.autoGrid, LAYOUT_TYPES.vertical, LAYOUT_TYPES.horizontal]}
              />
              <PositionInput
                positionX={choicesZone.position.x}
                positionY={choicesZone.position.y}
                onChange={(key, position) => this.onChoicesZoneChange('position', position)}
              />
              <SizeInput
                sizeX={choicesZone.size.x}
                sizeY={choicesZone.size.y}
                onChange={(key, size) => this.onChoicesZoneChange('size', size)}
              />
            </>
          )}
          <CheckboxInput
            label="Allow for multiple choice selections"
            forKey="multiple_choice_selections"
            onChange={(isAllowed) => {
              this.onChange('numRequiredResponses', isAllowed ? 2 : 1);
            }}
            value={allowMultipleChoiceSelections}
          />
          {allowMultipleChoiceSelections && (
            <>
              <CMSWhiteInput
                type={INPUT_TYPES.NUMBER}
                label="Number of Required Selections"
                value={numRequiredResponses}
                onChange={(value) => this.onChange('numRequiredResponses', value)}
                debounced
                isRequired
              />
              <br />
              <br />
            </>
          )}
          <ObjectList
            objects={choicesZone.choices}
            getDefault={() => getDefaultChoice(isFreeForm)}
            onChange={(value) => this.onChoicesZoneChange('choices', value)}
            name="Choice"
            Component={Choice}
          />
        </Accordion>
      </DoItForm>
    );
  }
}

export default SurveyItForm;
