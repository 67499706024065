import PropTypes from 'prop-types';

import styles from 'components/input/Input.css';

export const BACKGROUND_COLORS = {
  BLURPLE: {
    name: 'BLURPLE',
    hex: '#5a3e75',
  },
  PURPLE: {
    name: 'PURPLE',
    hex: '#a347ff',
  },
  BLUE: {
    name: 'BLUE',
    hex: '#3e8ae4',
  },
  GREEN: {
    name: 'GREEN',
    hex: '#00d1ab',
  },
  ORANGE: {
    name: 'ORANGE',
    hex: '#f89d16',
  },
  RED: {
    name: 'RED',
    hex: '#ED5945',
  },
};

const ColorOption = ({ color, name, checked }) => (
  <input
    className={styles.colorOption}
    type="radio"
    name={name}
    value={color.name}
    defaultChecked={checked}
    style={{ color: color.hex, background: color.hex }}
  />
);

ColorOption.propTypes = {
  color: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
};

const ColorRadioGroup = ({ name, value, onChange }) => {
  const handleOnChange = (e) => onChange(e.target.value);

  return (
    <div className={styles.colorRadioGroup} onChange={handleOnChange}>
      {Object.keys(BACKGROUND_COLORS).map((colorKey) => {
        const color = BACKGROUND_COLORS[colorKey];
        return (
          <ColorOption key={color.name} color={color} name={name} checked={value === color.name} />
        );
      })}
    </div>
  );
};

ColorRadioGroup.defaultProps = {
  value: BACKGROUND_COLORS.PURPLE.name,
};

ColorRadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

export default ColorRadioGroup;
