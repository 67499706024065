/* eslint-disable react/prop-types */

import { useState, useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';

const TrimInput = ({ startMilliseconds, endMilliseconds, onChange, originalMilliseconds }) => {
  const [values, setValues] = useState([startMilliseconds, endMilliseconds]);

  useEffect(() => {
    setValues([startMilliseconds, endMilliseconds]);
  }, [startMilliseconds, endMilliseconds]);

  return (
    <>
      <label style={{ marginBottom: 0 }}>Trim Audio</label>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Range
          draggableTrack
          values={values}
          step={1}
          min={0}
          max={originalMilliseconds}
          onChange={(values) => setValues(values)}
          onFinalChange={onChange}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '4px',
                  width: '100%',
                  borderRadius: '33px',
                  background: getTrackBackground({
                    values,
                    colors: ['rgba(83, 56, 112, 0.4)', '#533870', 'rgba(83, 56, 112, 0.4)'],
                    min: 0,
                    max: originalMilliseconds,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '16px',
                width: '16px',
                borderRadius: '50%',
                backgroundColor: '#FFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '2px solid #8B18E3',
                boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
                outline: 'none',
              }}
            />
          )}
        />
      </div>
    </>
  );
};

export default TrimInput;
