import PropTypes from 'prop-types';

import { BACKGROUND_COLORS } from 'components/input/ColorRadioGroup';

import styles from 'components/series-preview-card/SeriesPreviewCard.css';

const SeriesPreviewCard = ({ episode, metadata }) => (
  <div className={styles.card}>
    <div
      className={styles.cardBody}
      style={{
        backgroundImage: `linear-gradient(180deg, #ffffff00 49%, ${
          BACKGROUND_COLORS[metadata.backgroundColor].hex
        } 100%), url(${
          metadata.showSeriesThumbnail ? episode.series.thumbnailUrl : episode.thumbnailUrl
        })`,
      }}
    >
      <img
        className={styles.creatorAvatar}
        src={episode.creator.avatarUrl}
        alt={episode.creator.title}
      />
      <div>
        <img
          className={styles.seriesLogo}
          src={episode.series.logoUrl}
          alt={episode.series.extendedName}
        />
        {metadata.showStoryTitle ? <p>{episode.title}</p> : null}
      </div>
    </div>
    <div
      className={styles.cardFooter}
      style={{ backgroundColor: BACKGROUND_COLORS[metadata.backgroundColor].hex }}
    >
      <div className={styles.tags}>
        <span>Your child learns</span>
        <span>The Arts · Creativity</span>
      </div>
      <button type="button">Series Info &gt;</button>
    </div>
  </div>
);

SeriesPreviewCard.propTypes = {
  episode: PropTypes.object.isRequired,
  metadata: PropTypes.object.isRequired,
};

export default SeriesPreviewCard;
