import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import * as adminService from 'services/content/adminService';
import * as contentService from 'services/content/contentService';
import { getBackgroundImageGradientStyles } from 'utils';

import EmptyState from 'components/empty-state/EmptyState';
import { CMSButton, CMSWhiteButton, CMSRedButton } from 'components/button/Button';
import CollectionForm, { MultiCreatorCollectionForm } from 'components/forms/CollectionForm';
import FormModal from 'components/modal/FormModal';
import EpisodeSelectionModal, {
  MultiCreatorEpisodeSelectionModal,
} from 'components/modal/EpisodeSelectionModal';
import { ShowIcon, HideIcon } from 'components/Icon';
import { EpisodesStat } from 'components/collection-stat/CollectionStat';
import CollectionEpisodesTable, {
  MultiCreatorCollectionEpisodesTable,
} from 'components/table/CollectionEpisodesTable';
import ToggleSwitch from 'components/toggle-switch/ToggleSwitch';

import styles from 'pages/content/styles.css';

const CollectionDetailPage = ({
  collectionId,
  creatorId,
  collectionService,
  formComponent,
  episodeTableComponent,
  episodeSelectionModalComponent,
  onSuccessfulDeletePath,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [collection, setCollection] = useState();
  const [collectionEpisodes, setCollectionEpisodes] = useState([]);
  const [backgroundImageGradient, setBackgroundImageGradient] = useState('');
  const [isCollectionModalOpen, setIsCollectionModalOpen] = useState(false);
  const [isEpisodeSelectionModalOpen, setIsEpisodeSelectionModalOpen] = useState(false);

  const CollectionEpisodesTable = episodeTableComponent;
  const EpisodeSelectionModal = episodeSelectionModalComponent;

  const updateCollectionDetails = ({ collection, episodes }) => {
    if (typeof collection !== 'undefined') {
      setCollection(collection);
    }

    if (typeof episodes !== 'undefined') {
      setCollectionEpisodes(episodes);
    }
  };

  const getCollectionDetails = async () => {
    const { collection, episodes: collectionEpisodes } = await collectionService.getCollection({
      creatorId,
      collectionId,
    });
    updateCollectionDetails({ collection, episodes: collectionEpisodes });
  };

  useEffect(() => {
    const getCollectionData = async () => {
      setIsLoading(true);
      await getCollectionDetails();
      setIsLoading(false);
    };
    getCollectionData();
  }, [collectionId, creatorId]);

  useEffect(() => {
    if (collection) {
      setBackgroundImageGradient(getBackgroundImageGradientStyles(collection));
    }
  }, [collection]);

  const numEpisodes = collectionEpisodes.length;

  const addEpisodes = async (episodeIds) => {
    const { collection, episodes } = await collectionService.updateCollection({
      collectionId,
      creatorId,
      collection: {
        episodeIds,
      },
    });

    updateCollectionDetails({ collection, episodes });
    setIsEpisodeSelectionModalOpen(false);
  };

  const toggleCollectionStatus = async () => {
    const newStatus = !collection.isPublished;
    const { collection: updatedCollection } = await collectionService.updateCollection({
      collectionId,
      creatorId,
      collection: {
        isPublished: newStatus,
      },
    });
    setCollection(updatedCollection);
    NotificationManager.success(
      `Successfully ${newStatus ? 'published' : 'unpublished'} collection.`,
    );
  };

  const removeCollection = async () => {
    if (!window.confirm('Are you sure you want to delete this collection?')) {
      return;
    }

    await collectionService.removeCollection({ creatorId, collectionId });
    NotificationManager.success('Successfully deleted collection!');

    window.location.href = onSuccessfulDeletePath(creatorId);
  };

  const toggleSwitchOptions = [
    {
      id: 'radio1',
      label: 'Active',
      icon: <ShowIcon />,
      checked: collection?.isPublished,
    },
    {
      id: 'radio2',
      label: 'Inactive',
      icon: <HideIcon />,
      checked: !collection?.isPublished,
    },
  ];

  return isLoading ? null : (
    <div className={styles.collectionDetailPage}>
      <header
        className={styles.header}
        style={{
          backgroundImage: backgroundImageGradient,
        }}
      >
        <div className={styles.headerInfoContainer}>
          <h2 className={styles.title}>{collection.title}</h2>
          <div className={styles.metaContainer}>
            <div className={styles.stats}>
              <ToggleSwitch options={toggleSwitchOptions} onChange={toggleCollectionStatus} />
              <EpisodesStat num={numEpisodes} color="white" iconFill="rgba(255, 255, 255, 0.8)" />
            </div>
            <div className={styles.buttonGroup}>
              <CMSRedButton label="Delete Collection" onClick={removeCollection} />
              <CMSButton label="Edit Details" onClick={() => setIsCollectionModalOpen(true)} />
            </div>
          </div>
        </div>
      </header>
      <section className={styles.episodesContainer}>
        {collectionEpisodes.length ? (
          <>
            <div className={styles.actionBar}>
              <p>Stories</p>
              <CMSWhiteButton
                label="+ Add Stories"
                onClick={() => setIsEpisodeSelectionModalOpen(true)}
              />
            </div>
            <CollectionEpisodesTable
              episodes={collectionEpisodes}
              collectionId={collectionId}
              creatorId={creatorId}
              updateCollectionDetails={updateCollectionDetails}
            />
          </>
        ) : (
          <EmptyState
            title="Add stories to get started"
            subtitle="Get started by adding your first story."
            cta="+ Add Stories"
            onclick={() => setIsEpisodeSelectionModalOpen(true)}
            className={[styles.emptyState]}
          />
        )}
      </section>
      <FormModal
        creatorId={creatorId}
        formNode={formComponent}
        title="Edit Collection Details"
        isOpen={isCollectionModalOpen}
        formProps={{
          creatorId,
          collection,
          isNew: false,
          onSubmit: ({ collection }) => setCollection(collection),
        }}
        contentLabel="Edit Collection Details"
        primaryFooterBtnLabel="Save"
        onRequestClose={() => setIsCollectionModalOpen(false)}
      />
      <EpisodeSelectionModal
        creatorId={creatorId}
        collectionEpisodes={collectionEpisodes}
        onSubmit={addEpisodes}
        isOpen={isEpisodeSelectionModalOpen}
        onClose={() => setIsEpisodeSelectionModalOpen(false)}
      />
    </div>
  );
};

export const MultiCreatorCollectionDetailPage = (props) => (
  <CollectionDetailPage
    {...props}
    collectionService={adminService}
    formComponent={MultiCreatorCollectionForm}
    episodeTableComponent={MultiCreatorCollectionEpisodesTable}
    episodeSelectionModalComponent={MultiCreatorEpisodeSelectionModal}
    onSuccessfulDeletePath={() => '/admin/collections'}
  />
);

CollectionDetailPage.defaultProps = {
  creatorId: null,
  collectionService: contentService,
  formComponent: CollectionForm,
  episodeTableComponent: CollectionEpisodesTable,
  episodeSelectionModalComponent: EpisodeSelectionModal,
  onSuccessfulDeletePath: (creatorId) => `/creators/${creatorId}#collections`,
};

CollectionDetailPage.propTypes = {
  collectionId: PropTypes.string.isRequired,
  creatorId: PropTypes.string,
  collectionService: PropTypes.object,
  formComponent: PropTypes.elementType,
  episodeTableComponent: PropTypes.elementType,
  episodeSelectionModalComponent: PropTypes.elementType,
  onSuccessfulDeletePath: PropTypes.func,
};

export default CollectionDetailPage;
