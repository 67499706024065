import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import * as authenticationService from 'services/authentication/authenticationService';
import { LOCAL_STORAGE_URL_AFTER_AUTH_KEY } from 'services/authentication/constants';

const InviteRedirectPage = ({ location, match }) => {
  const history = useHistory();
  const isAuthenticated = authenticationService.isAuthenticated();
  const query = new URLSearchParams(location.search);

  const { inviteId } = match.params;
  const email = query.get('email');
  const creatorId = query.get('creator');
  const isNewUser = query.has('newUser');

  localStorage.setItem(LOCAL_STORAGE_URL_AFTER_AUTH_KEY, `/creators/${creatorId}`);

  useEffect(() => {
    const fetchInvitation = async () => {
      const invite = await authenticationService.getInvite({ inviteId, creatorId });

      if (isAuthenticated) {
        await authenticationService.activateInvite(invite);
        history.push(`/creators/${creatorId}`);
        return window.location.reload();
      }

      return history.push(isNewUser ? '/signup' : '/login', {
        email,
        invitation: invite,
      });
    };
    fetchInvitation();
  }, []);

  return <h1>Loading...</h1>;
};

InviteRedirectPage.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default InviteRedirectPage;
