/* eslint-disable no-param-reassign */

import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { INPUT_TYPES } from 'components/input/constants';
import { isInputValid } from 'components/input/utils';
import { CMSWhiteInput } from 'components/input/Input';
import { IconButton } from 'components/button/Button';
import { PlusIcon, MinusIcon, InfinityIcon } from 'components/Icon';

import * as styles from 'components/input/Input.css';

class CounterInput extends PureComponent {
  static propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
  };

  handleIncrement(addAmount) {
    const { onChange, value } = this.props;

    if (addAmount === Infinity) {
      onChange(0);
      return;
    }

    const total = Math.max(value + addAmount, 0);
    onChange(total);
  }

  handleChange(value, _isValid, event) {
    if (!isInputValid(INPUT_TYPES.NUMBER, value)) {
      this.props.onChange(0);
      event.target.value = '∞';
      return;
    }

    this.props.onChange(Number.parseInt(value, 10));
  }

  handleInput(value, event) {
    // prevent users from typing non-digit characters in the numeric text field
    event.target.value = value.replace(/[^\d]/gi, '');
  }

  render() {
    const { value, label } = this.props;
    const valueStr = value || '∞';

    return (
      <div className={styles.counterInput}>
        <p>
          {label} ({valueStr})
        </p>
        <div className={styles.counterInputButtons}>
          <IconButton
            icon={<MinusIcon />}
            onClick={() => this.handleIncrement(-1)}
            isDisabled={value <= 0}
          />
          <CMSWhiteInput
            debounced
            value={valueStr}
            type={INPUT_TYPES.TEXT}
            onChange={(value, isValid, event) => this.handleChange(value, isValid, event)}
            onInput={(value, event) => this.handleInput(value, event)}
            style={{ width: '100px' }}
          />
          <IconButton icon={<PlusIcon />} onClick={() => this.handleIncrement(1)} />
          <IconButton
            icon={<InfinityIcon />}
            onClick={() => this.handleIncrement(Infinity)}
            isDisabled={value === 0}
          />
        </div>
      </div>
    );
  }
}

export default CounterInput;
