import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { ACCOUNT_TYPE_NAMES } from 'utils';
import { useUser } from 'context/user-context/UserContext';
import UserAvatar from 'components/user-avatar/UserAvatar';

import styles from 'components/account-dropdown/AccountDropdown.css';

const ADMIN_LINKS = [
  { to: '/admin/collections', label: 'Collections' },
  { to: '/admin/bundles', label: 'Bundles' },
  { to: '/admin/access', label: 'Grant Invite Access' },
  { to: '/admin/preview-access', label: 'Grant Story Preview Access' },
];

const ACCOUNT_LINKS = [
  { to: '/account', label: 'Account Settings' },
  { to: '/logout', label: 'Logout' },
];

const AccountDropdown = ({ onLinkClick }) => {
  const { user } = useUser();
  const history = useHistory();

  const handleLinkClick = (path) => {
    onLinkClick();
    history.push(path);
  };

  return (
    <div className={styles.accountDropdown}>
      <div className={styles.info}>
        <UserAvatar size={48} />
        <div>
          <p className={styles.name}>
            {user.givenName} {user.surname}
          </p>
          <p className={styles.email}>{user.email}</p>
        </div>
      </div>
      <ul className={styles.links}>
        {user.accountType === ACCOUNT_TYPE_NAMES.ADMIN && (
          <>
            <label>Admin Tools</label>
            <details>
              <summary>Homescreen</summary>
              <ul className={styles.nestedLinks}>
                <li onClick={() => handleLinkClick('/admin/homescreen')}>
                  <p>Arrange Sections</p>
                </li>
                <li onClick={() => handleLinkClick('/admin/homescreen/featured-stories')}>
                  <p>Stories of the Week</p>
                </li>
              </ul>
            </details>
            {ADMIN_LINKS.map(({ to, label }) => (
              <li key={label} onClick={() => handleLinkClick(to)}>
                <p>{label}</p>
              </li>
            ))}
            <hr />
          </>
        )}
        {ACCOUNT_LINKS.map(({ to, label }) => (
          <li key={label} onClick={() => handleLinkClick(to)}>
            <p>{label}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

AccountDropdown.propTypes = {
  onLinkClick: PropTypes.func.isRequired,
};

export default AccountDropdown;
