import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import { getEpisode, updateEpisode } from 'services/content/contentService';
import EpisodeEditBody from 'components/episode-edit-panel/NewEpisodeEditBody';

import unityUtils, { isWebGLSupported } from 'components/unity/utils';
import { addEpisodeOffsetTimesToVideoDuration, addEpisodeOffsetTimesToDoits } from 'utils';

const NewEpisodePage = ({ creatorId, episodeId }) => {
  const [episode, setEpisode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [doits, setDoits] = useState([]);
  const [episodeDurationSecs, setEpisodeDurationSecs] = useState(0);
  const [thumbnailFile, setThumbnailFile] = useState(undefined);

  useEffect(() => {
    if (!isWebGLSupported()) {
      window.location.href = '/browser';
      return;
    }

    async function fetchData() {
      try {
        const episode = await getEpisode(creatorId, episodeId);
        setEpisode(episode);
        setDoits(addEpisodeOffsetTimesToDoits(episode.doits));
        setThumbnailFile(episode.thumbnailFile ?? undefined);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (episode) {
      setEpisodeDurationSecs(addEpisodeOffsetTimesToVideoDuration(episode.durationSecs, doits));
    }
  }, [doits]);

  const onEpisodeConfigJsonChange = (config) => {
    setEpisode(config);
    setDoits(addEpisodeOffsetTimesToDoits(config.doits));
  };

  const handleChange = (stateChangeFunction, newValue) => {
    stateChangeFunction(newValue);
  };

  const handleSaveEpisodeConfigJson = async () => {
    const updatedEpisode = await updateEpisode(creatorId, episodeId, {
      doits,
      thumbnailFile,
      title: episode?.title,
      tags: episode?.tags,
    });
    setEpisode(updatedEpisode);

    NotificationManager.success('Successfully saved!');
  };

  const isValidPlacement = (proposedDoits, index) => {
    const doit = proposedDoits[index];
    const beforeDoit = proposedDoits[index - 1];
    const afterDoit = proposedDoits[index + 1];

    if (!beforeDoit && !afterDoit) {
      return true;
    }

    if (typeof beforeDoit === 'undefined') {
      return doit.finishSecsWithEpisodeOffset < afterDoit.startSecsWithEpisodeOffset;
    }

    if (typeof afterDoit === 'undefined') {
      return beforeDoit.finishSecsWithEpisodeOffset < doit.startSecsWithEpisodeOffset;
    }

    return (
      beforeDoit.finishSecsWithEpisodeOffset < doit.startSecsWithEpisodeOffset &&
      doit.finishSecsWithEpisodeOffset < afterDoit.startSecsWithEpisodeOffset
    );
  };

  const handleDoitChange = (newOrUpdatedDoit) => {
    const updatedDoits = [...doits];

    const existingDoitIndex = doits.findIndex((doit) => doit.itemId === newOrUpdatedDoit.itemId);
    const newOrUpdatedDoitCopy = { ...newOrUpdatedDoit };
    const isExistingDoit = existingDoitIndex >= 0;
    updatedDoits[isExistingDoit ? existingDoitIndex : updatedDoits.length] = newOrUpdatedDoitCopy;

    // TODO: we can make this more performant by simply moving the changed doit
    // instead of reordering everything.
    const sortedUpdatedDoits = updatedDoits.sort(
      (a, b) => a.startSecsWithEpisodeOffset - b.startSecsWithEpisodeOffset,
    );
    const sortedExistingDoitIndex = sortedUpdatedDoits.findIndex(
      (doit) => doit.itemId === newOrUpdatedDoit.itemId,
    );

    if (isExistingDoit && !isValidPlacement(sortedUpdatedDoits, sortedExistingDoitIndex)) {
      return;
    }

    unityUtils.willUpdateDoits();
    handleChange(setDoits, addEpisodeOffsetTimesToDoits(sortedUpdatedDoits));
    unityUtils.seek(newOrUpdatedDoitCopy.startSecsWithEpisodeOffset);
  };

  const handleDoitDelete = (itemId) => {
    const updatedDoits = [...doits];

    const existingDoitIndex = doits.findIndex((doit) => doit.itemId === itemId);

    if (existingDoitIndex > -1) {
      updatedDoits.splice(existingDoitIndex, 1);
      unityUtils.willUpdateDoits();
      handleChange(setDoits, addEpisodeOffsetTimesToDoits(updatedDoits));
    }
  };

  if (!episode) {
    const text = isLoading ? 'Loading…' : 'That story does not exist!';

    return <h1>{text}</h1>;
  }

  const { title, videoUrl, durationSecs: videoDurationSecs } = episode;

  return (
    <>
      <EpisodeEditBody
        onEpisodeConfigJsonChange={onEpisodeConfigJsonChange}
        episodeId={episodeId}
        videoUrl={videoUrl}
        videoDurationSecs={videoDurationSecs}
        episodeDurationSecs={episodeDurationSecs}
        episodeTitle={title}
        doits={doits}
        onDoitChange={handleDoitChange}
        onDoitDelete={handleDoitDelete}
        onSaveEpisodeConfigJson={handleSaveEpisodeConfigJson}
      />
    </>
  );
};

NewEpisodePage.propTypes = {
  creatorId: PropTypes.string.isRequired,
  episodeId: PropTypes.string.isRequired,
};

export default NewEpisodePage;
