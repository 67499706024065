/* eslint-disable react/prop-types, no-confusing-arrow */

import { Route, Redirect, Switch } from 'react-router-dom';

import { useCreators } from 'context/creators-context/CreatorsContext';
import { LOCAL_STORAGE_LAST_VISITED_CREATOR_ID_KEY } from 'services/authentication/constants';

import CreatorLayout from 'components/creator-layout/CreatorLayout';

import CreatorPage from 'pages/content/CreatorPage';
import CreatorsPage from 'pages/content/CreatorsPage';
import CollectionDetailPage from 'pages/content/CollectionDetailPage';
import EpisodeDetailPage from 'pages/content/EpisodeDetailPage';
import SeriesDetailPage from 'pages/content/SeriesDetailPage';

const CreatorRoutes = ({ match }) => {
  const { creators, isLoading } = useCreators();

  return (
    <Switch>
      <Route
        path={`${match.path}/:creatorId`}
        render={({ match }) =>
          !isLoading && <CreatorLayoutRoutes match={match} creatorId={match.params.creatorId} />
        }
      />

      <Route
        path={match.path}
        exact
        render={({ match }) => {
          if (isLoading) {
            return null;
          }

          if (creators.length) {
            const lastVisitedCreatorId = localStorage.getItem(
              LOCAL_STORAGE_LAST_VISITED_CREATOR_ID_KEY,
            );
            return (
              <Redirect to={`${match.path}/${lastVisitedCreatorId || creators[0].creatorId}`} />
            );
          }

          return <CreatorsPage />;
        }}
      />
    </Switch>
  );
};

const CreatorLayoutRoutes = ({ match, creatorId }) => {
  const { creators } = useCreators();
  const activeCreator = creators.find((creator) => creator.creatorId === creatorId);

  return (
    <CreatorLayout activeCreator={activeCreator}>
      <Switch>
        <Route
          path={match.path}
          exact
          render={({ match: { params } }) => (
            <CreatorPage {...params} activeCreator={activeCreator} creators={creators} />
          )}
        />
        <Route
          path={`${match.path}/collections/:collectionId`}
          exact
          render={({ match: { params } }) => (
            <CollectionDetailPage {...params} activeCreator={activeCreator} />
          )}
        />
        <Route
          path={`${match.path}/series/:seriesId`}
          exact
          render={({ match: { params } }) => (
            <SeriesDetailPage {...params} activeCreator={activeCreator} />
          )}
        />
        <Route
          path={`${match.path}/episodes/:episodeId/details`}
          exact
          render={({ match: { params } }) => (
            <EpisodeDetailPage {...params} activeCreator={activeCreator} />
          )}
        />
      </Switch>
    </CreatorLayout>
  );
};

export default CreatorRoutes;
