import DoItForm from 'components/do-it-forms/forms/DoIt';
import BaseForm from 'components/do-it-forms/forms/Base';
import Accordion from 'components/accordion/Accordion';
import AccordionSection from 'components/accordion/AccordionSection';
import BackgroundAccordion from 'components/do-it-forms/accordions/BackgroundAccordion';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import SizeInput from 'components/input/SizeInput';
import PositionInput from 'components/input/PositionInput';
import RangeInput from 'components/input/RangeInput';
import { getDefaultBackground, getDefaultAudioConfig } from 'components/do-it-forms/utils';
import AudioConfig from 'components/do-it-forms/components/AudioConfig';

class WipeIt extends BaseForm {
  getDefaultParams() {
    return {
      background: getDefaultBackground(),
      progressToComplete: 0.99,
      brushSound: getDefaultAudioConfig(),
      canvas: {
        position: { x: 0, y: 0 },
        size: { x: 400, y: 400 },
        spriteFile: null,
        brush: {
          position: { x: 0, y: 0 },
          size: { x: 100, y: 100 },
          spriteFile: null,
        },
      },
    };
  }

  getDoitParams() {
    return this.state;
  }

  onCanvasChange(key, value) {
    this.setState(({ canvas }) => ({
      canvas: {
        ...canvas,
        [key]: value,
      },
    }));
  }

  onCanvasBrushChange(key, value) {
    this.setState(({ canvas }) => ({
      canvas: {
        ...canvas,
        brush: {
          ...canvas.brush,
          [key]: value,
        },
      },
    }));
  }

  render() {
    const { onClose, onDelete, doit, subsequentDoitId } = this.props;
    const { canvas, background, progressToComplete, brushSound } = this.state;

    return (
      <DoItForm
        title="Wipe It"
        doit={doit}
        onClose={onClose}
        onSave={(options) => this.handleSave(options)}
        onDelete={onDelete}
        subsequentDoitId={subsequentDoitId}
      >
        <BackgroundAccordion
          value={background}
          onChange={(key, value) => this.setState({ [key]: value })}
        />

        <Accordion title="Wipable Surface" key="Wipable Surface">
          <ImageUploadInput
            label="Canvas Image"
            onChange={(file) => this.onCanvasChange('spriteFile', file)}
            value={canvas.spriteFile}
          />
          <SizeInput
            sizeX={canvas.size.x}
            sizeY={canvas.size.y}
            onChange={(k, v) => this.onCanvasChange(k, v)}
          />
          <PositionInput
            positionX={canvas.position.x}
            positionY={canvas.position.y}
            onChange={(k, v) => this.onCanvasChange(k, v)}
          />

          <br />
          <br />
          <AccordionSection title="Success Rules" />
          <RangeInput
            label="Percentage Wiped"
            value={progressToComplete}
            onChange={(progressToComplete) => this.setState({ progressToComplete })}
          />
        </Accordion>

        <Accordion title="Wiper" key="Wiper">
          <ImageUploadInput
            label="Wiper Image"
            onChange={(file) => this.onCanvasBrushChange('spriteFile', file)}
            value={canvas.brush.spriteFile}
          />
          <SizeInput
            sizeX={canvas.brush.size.x}
            sizeY={canvas.brush.size.y}
            onChange={(k, v) => this.onCanvasBrushChange(k, v)}
          />
          <PositionInput
            positionX={canvas.brush.position.x}
            positionY={canvas.brush.position.y}
            onChange={(k, v) => this.onCanvasBrushChange(k, v)}
          />
          <AudioConfig
            label="Wiper Sound Effect"
            value={brushSound}
            onChange={(brushSound) => this.setState({ brushSound })}
          />
        </Accordion>
      </DoItForm>
    );
  }
}

export default WipeIt;
