// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n/* ToggleSwitch.jsx variables */\n\n.NavBar_navbar___3McGQ9zIsi {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #8b18e3;\n  height: 80px;\n  padding: 0 40px;\n}\n\n.NavBar_navbar___3McGQ9zIsi .NavBar_navbar__logo___2GHVpw6suD {\n    width: 200px;\n  }\n\n.NavBar_navbar___3McGQ9zIsi .NavBar_userAvatar___3Yflann4RE {\n    cursor: pointer;\n    box-shadow: 0 0 0 2px #8b18e3, 0 0 0 4px rgba(255, 255, 255, 0.6);\n  }\n", ""]);
// Exports
exports.locals = {
	"navbar": "NavBar_navbar___3McGQ9zIsi",
	"navbar__logo": "NavBar_navbar__logo___2GHVpw6suD",
	"navbarLogo": "NavBar_navbar__logo___2GHVpw6suD",
	"userAvatar": "NavBar_userAvatar___3Yflann4RE"
};
module.exports = exports;
