/* eslint-disable react/prop-types */

import { useState, useEffect } from 'react';
import { Range, getTrackBackground } from 'react-range';
import { CMSWhiteInput } from 'components/input/Input';

const floatToPercentString = (float) => `${Math.round(float * 100)}%`;
const percentStringToFloat = (percentString) => {
  const parsedInt = parseInt(percentString, 10);

  if (isNaN(parsedInt)) {
    return 0;
  }

  if (parsedInt > 100) {
    return 1;
  }

  if (parsedInt < 0) {
    return 0;
  }

  return Number((parsedInt / 100).toString().substr(0, 4));
};

const RangeInput = ({ value, onChange, label }) => {
  const [values, setValues] = useState([value]);

  useEffect(() => {
    setValues([value]);
  }, [value]);

  return (
    <>
      <label style={{ marginBottom: 0 }}>{label}</label>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Range
          values={values}
          step={0.01}
          min={0}
          max={1}
          onChange={(values) => setValues(values)}
          onFinalChange={(values) => onChange(values[0])}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              style={{
                ...props.style,
                height: '36px',
                display: 'flex',
                width: '100%',
              }}
            >
              <div
                ref={props.ref}
                style={{
                  height: '4px',
                  width: '100%',
                  borderRadius: '33px',
                  background: getTrackBackground({
                    values,
                    colors: ['#533870', 'rgba(83, 56, 112, 0.4)'],
                    min: 0,
                    max: 1,
                  }),
                  alignSelf: 'center',
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: '16px',
                width: '16px',
                borderRadius: '50%',
                backgroundColor: '#FFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '2px solid #8B18E3',
                boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.25)',
                outline: 'none',
              }}
            />
          )}
        />
        <CMSWhiteInput
          value={floatToPercentString(values[0])}
          onChange={(value) => {
            const newValue = percentStringToFloat(value);
            setValues([newValue]);
            onChange(newValue);
          }}
          style={{
            marginLeft: '12px',
            width: '64px',
            padding: '4px',
          }}
          debounced
        />
      </div>
    </>
  );
};

export default RangeInput;
