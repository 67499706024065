import request from 'services/request/requestService';
import { BundleTypes } from 'utils';

export async function getCollections() {
  const url = '/content/collections';
  return request(url);
}

export async function getCollection({ collectionId }) {
  const url = `/content/collections/${collectionId}`;
  return request(url);
}

export async function createCollection(collection) {
  const method = 'POST';
  const body = JSON.stringify(collection);
  const url = '/content/collections';

  return request(url, { method, body });
}

export async function updateCollection({ collectionId, collection }) {
  const method = 'PUT';
  const body = JSON.stringify(collection);
  const url = `/content/collections/${collectionId}`;

  return request(url, { method, body });
}

export async function getAllCreators() {
  const url = '/creators/mine';
  return request(url);
}

export async function getAllEpisodes() {
  const creators = await getAllCreators();

  const creatorRequests = creators.map(({ creatorId }) => request(`/creators/${creatorId}`));
  const creatorResponses = await Promise.all(creatorRequests);

  const allEpisodes = creatorResponses.reduce((acc, { episodes }) => [...acc, ...episodes], []);
  return { episodes: allEpisodes, creators };
}

export async function getAllSingleCreatorCollections() {
  const creators = await getAllCreators();

  const creatorRequests = creators.map(({ creatorId }) => request(`/creators/${creatorId}`));
  const creatorResponses = await Promise.all(creatorRequests);

  const allCollections = creatorResponses.reduce(
    (acc, { collections }) => [...acc, ...collections],
    [],
  );
  return allCollections;
}

export async function getHomescreenModules() {
  const url = '/homescreen';
  return request(url);
}

export async function publishHomescreen({ modules }) {
  const method = 'POST';
  const body = JSON.stringify(modules);
  const url = '/homescreen';

  return request(url, { method, body });
}

export async function getBundle({ bundleId }) {
  const url = `/bundles/${bundleId}`;
  return request(url);
}

export async function getBundles(filters) {
  const url = '/bundles';
  return request(url, {}, filters);
}

export async function getCollectionBundles() {
  return getBundles({ type: BundleTypes.COLLECTION_BUNDLE });
}

export async function getCreatorBundles() {
  return getBundles({ type: BundleTypes.CREATOR_BUNDLE });
}

export async function updateBundle({ bundleId, bundle }) {
  const method = 'PUT';
  const body = JSON.stringify(bundle);
  const url = `/bundles/${bundleId}`;

  return request(url, { method, body });
}

export async function getTopics() {
  const url = '/topics';
  return request(url);
}

export async function getSeries() {
  const url = '/series';
  return request(url);
}

export async function getFeaturedStories() {
  const url = '/content/featured-stories';
  return request(url);
}

export async function upsertFeaturedStories(stories) {
  const method = 'PUT';
  const body = JSON.stringify(stories);
  const url = '/content/featured-stories';

  return request(url, { method, body });
}

export async function getActivePreviews() {
  const url = '/admin/preview-access';
  return request(url);
}

export async function getEpisodes() {
  const url = '/admin/episodes';
  return request(url);
}

export async function grantPreviewAccess(email, episodeId) {
  const method = 'POST';
  const body = JSON.stringify({ email, episodeId });
  const url = '/admin/preview-access';

  return request(url, { method, body });
}

export async function deletePreviewAccess(episodeId) {
  const method = 'DELETE';
  const body = JSON.stringify({ episodeId });
  const url = '/admin/preview-access';

  return request(url, { method, body });
}

export async function removeCollection({ collectionId }) {
  const method = 'DELETE';
  const url = `/content/collections/${collectionId}`;

  return request(url, { method });
}

export async function removeBundle({ bundleId }) {
  const method = 'DELETE';
  const url = `/bundles/${bundleId}`;

  return request(url, { method });
}
