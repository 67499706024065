import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';

import CreatorLayout from 'components/creator-layout/CreatorLayout';
import { MultiCreatorCollectionsPage } from 'pages/content/CollectionsPage';
import { MultiCreatorCollectionDetailPage } from 'pages/content/CollectionDetailPage';
import HomescreenPage from 'pages/content/HomescreenPage';
import BundlesPage from 'pages/content/BundlesPage';
import BundleDetailPage from 'pages/content/BundleDetailPage';
import GrantAccessPage from 'pages/tools/GrantAccessPage';
import FeaturedStoriesPage from 'pages/tools/FeaturedStoriesPage';
import EpisodePreviewAccessPage from 'pages/tools/EpisodePreviewAccessPage';

const AdminRoutes = ({ match }) => {
  const BASE_ADMIN_REDIRECT_PATH = `${match.path}/homescreen`;

  return (
    <CreatorLayout activeCreator={{ title: 'Admin' }}>
      <Switch>
        <Route
          path={match.path}
          exact
          component={() => <Redirect to={BASE_ADMIN_REDIRECT_PATH} />}
        />

        <Route path={`${match.path}/homescreen`} exact component={HomescreenPage} />

        <Route
          path={`${match.path}/homescreen/featured-stories`}
          exact
          component={FeaturedStoriesPage}
        />

        <Route
          path={`${match.path}/collections`}
          exact
          render={() => <MultiCreatorCollectionsPage title="Admin Collections" />}
        />
        <Route
          path={`${match.path}/collections/:collectionId`}
          exact
          render={({ match: { params } }) => <MultiCreatorCollectionDetailPage {...params} />}
        />

        <Route path={`${match.path}/bundles`} exact component={BundlesPage} />
        <Route
          path={`${match.path}/bundles/:bundleId`}
          exact
          render={({ match: { params } }) => <BundleDetailPage {...params} />}
        />

        <Route path={`${match.path}/access`} exact component={GrantAccessPage} />
        <Route path={`${match.path}/preview-access`} exact component={EpisodePreviewAccessPage} />
      </Switch>
    </CreatorLayout>
  );
};

AdminRoutes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default AdminRoutes;
