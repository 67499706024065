import { useState, useEffect } from 'react';

import * as adminService from 'services/content/adminService';

import { CMSWhiteButton } from 'components/button/Button';
import { BundlesStat } from 'components/collection-stat/CollectionStat';
import BundlesTable from 'components/table/BundlesTable';
import EmptyState from 'components/empty-state/EmptyState';
import FormModal from 'components/modal/FormModal';
import BundleForm from 'components/forms/BundleForm';
import Loading from 'components/loading/Loading';

import styles from 'pages/content/styles.css';

const BundlesPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [bundles, setBundles] = useState([]);
  const [isBundleModalOpen, setIsBundleModalOpen] = useState(false);

  useEffect(() => {
    const fetchBundles = async () => {
      const bundles = await adminService.getBundles();
      setBundles(bundles);
      setIsLoading(false);
    };
    fetchBundles();
  }, []);

  return (
    <div className={styles.pageContainer}>
      <section>
        <div className={styles.panelHeader}>
          <h2 className={styles.title}>Bundles</h2>
          <div className={styles.metaContainer}>
            <div className={styles.stats}>
              <BundlesStat num={bundles.length} />
            </div>
            <CMSWhiteButton label="+ New Bundle" onClick={() => setIsBundleModalOpen(true)} />
          </div>
        </div>
        <Loading isLoading={isLoading}>
          {bundles.length ? (
            <BundlesTable bundles={bundles} />
          ) : (
            <EmptyState
              title="Whoops, no bundles."
              subtitle="Get started by creating your first bundle."
              cta="+ New Bundle"
              onclick={() => setIsBundleModalOpen(true)}
              className={[styles.emptyState]}
            />
          )}
        </Loading>
        <FormModal
          formNode={BundleForm}
          title="Create Bundle"
          isOpen={isBundleModalOpen}
          contentLabel="Create Bundle"
          primaryFooterBtnLabel="Save"
          onRequestClose={() => setIsBundleModalOpen(false)}
        />
      </section>
    </div>
  );
};

export default BundlesPage;
