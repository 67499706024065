import request from 'services/request/requestService';

const getBodyFromForm = (attributes, obj) => {
  if (!attributes) {
    return JSON.stringify(obj);
  }

  const body = {};

  attributes.forEach((attribute) => {
    if (obj[attribute]) {
      body[attribute] = obj[attribute];
    }
  });

  return JSON.stringify(body);
};

export const update = async ({ attributes, obj, path }) => {
  const response = await request(path, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: getBodyFromForm(attributes, obj),
  });

  return response;
};

export const create = async ({ attributes, obj, path }) => {
  const response = await request(path, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: getBodyFromForm(attributes, obj),
  });

  return response;
};
