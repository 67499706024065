import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import * as styles from 'components/timeline/TimelineItem.css';
import * as utils from 'components/timeline/utils';
import {
  ExtendDirections,
  MIN_HOLD_DURATION_MS,
  LEFT_ARROW_KEY_CODE,
  RIGHT_ARROW_KEY_CODE,
} from 'components/timeline/constants';

const Label = ({
  label,
  displayName,
  startTimestamp,
  finishTimestamp,
  getStartFinishSeconds,
  isMoving,
  startPercent,
  finishPercent,
  extendDirection,
  onDoitDelete,
}) => {
  const { getTimestampFromSeconds } = utils;

  let start = startTimestamp;
  let finish = finishTimestamp;

  if (isMoving) {
    const proposedTimes = getStartFinishSeconds(startPercent, finishPercent, extendDirection);

    start = getTimestampFromSeconds(proposedTimes.startSecsWithEpisodeOffset);
    finish = getTimestampFromSeconds(proposedTimes.finishSecsWithEpisodeOffset);
  }

  return (
    <p>
      <span className={styles.timestamp}>
        {start} – {finish}
      </span>
      <span className={styles.label}>
        {label.length > label.substr(0, 30).length ? `${label.substr(0, 30)}...` : label}
      </span>
      <span
        onClick={() => {
          window.confirm("Are you sure you'd like to delete that activity?") && onDoitDelete();
        }}
        className={styles.remove}
      >
        Remove {displayName}
      </span>
    </p>
  );
};

class TimelineItem extends PureComponent {
  static propTypes = {
    startPercent: PropTypes.number.isRequired,
    finishPercent: PropTypes.number.isRequired,
    hexColor: PropTypes.string.isRequired,
    isSelected: PropTypes.bool,
    label: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onPositionChange: PropTypes.func.isRequired,
    startTimestamp: PropTypes.string.isRequired,
    finishTimestamp: PropTypes.string.isRequired,
    nudgeRight: PropTypes.func.isRequired,
    nudgeLeft: PropTypes.func.isRequired,
    getStartFinishSeconds: PropTypes.func.isRequired,
    onDoitDelete: PropTypes.func.isRequired,
  };

  static defaultProps = {
    isSelected: false,
  };

  timelineItemContainerRef = createRef();

  constructor(props) {
    super(props);

    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {
      requestedMoveDate: null, // Set immediately on mouse down
      isMoving: false, // True after moved past some threshold
      extendDirection: null, // EAST/WEST if dragging, null if moving
      startPercent: this.props.startPercent,
      finishPercent: this.props.finishPercent,
      initialMousePercent: null, // Mouse's position in TimelineItem when dragging starts
      mouseDownStartPercent: null, // Where the start of the doit is right before dragging
      mouseDownFinishPercent: null,
    };
  }

  componentDidMount() {
    if (this.props.isSelected) {
      window.addEventListener('keydown', this.handleKeyDown);
    }
  }

  componentWillReceiveProps({ startPercent, finishPercent, isSelected }) {
    if (this.state.isMoving) {
      return;
    }

    if (isSelected) {
      window.addEventListener('keydown', this.handleKeyDown);
    } else {
      window.removeEventListener('keydown', this.handleKeyDown);
    }

    this.setState({
      startPercent,
      finishPercent,
    });
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('focusout', this.handleMouseUp);
    window.removeEventListener('mouseup', this.handleMouseUp);
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleClick(e) {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    this.props.onClick();
  }

  isMouseOnBorder(initialMousePercent) {
    const { startPercent, finishPercent } = this.props;
    const BORDER_THRESHOLD_PERCENT = 0.5;

    return (
      Math.abs(startPercent - initialMousePercent) < BORDER_THRESHOLD_PERCENT ||
      Math.abs(finishPercent - initialMousePercent) < BORDER_THRESHOLD_PERCENT
    );
  }

  handleMouseDown(e, extendDirection = null) {
    const initialMousePercent = utils.getPercentFromMousePosition(
      e,
      this.timelineItemContainerRef.current.parentElement,
    );

    const { startPercent, finishPercent } = this.props;
    this.setState({
      requestedMoveDate: new Date(),
      extendDirection,
      initialMousePercent,
      mouseDownStartPercent: startPercent,
      mouseDownFinishPercent: finishPercent,
    });

    window.addEventListener('mousemove', this.handleMouseMove);
    window.addEventListener('focusout', this.handleMouseUp);
    window.addEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseUp() {
    const { startPercent, finishPercent, isMoving, extendDirection } = this.state;
    const { onPositionChange } = this.props;

    if (isMoving) {
      onPositionChange(startPercent, finishPercent, extendDirection);
    }

    this.setState({
      isMoving: false,
      requestedMoveDate: false,
      extendDirection: null,
      initialMousePercent: null,
      mouseDownStartPercent: null,
      mouseDownFinishPercent: null,
    });

    window.removeEventListener('mousemove', this.handleMouseMove);
    window.removeEventListener('focusout', this.handleMouseUp);
    window.removeEventListener('mouseup', this.handleMouseUp);
  }

  handleMouseMove(e) {
    this.setState(
      ({
        initialMousePercent,
        mouseDownStartPercent,
        mouseDownFinishPercent,
        extendDirection,
        requestedMoveDate,
      }) => {
        const heldDurationMs = new Date().getTime() - requestedMoveDate.getTime();

        if (heldDurationMs < MIN_HOLD_DURATION_MS) {
          return {};
        }

        const percent = utils.getPercentFromMousePosition(
          e,
          this.timelineItemContainerRef.current.parentElement,
        );

        const initialStartPercentDiff = mouseDownStartPercent - initialMousePercent;
        const initialFinishPercentDiff = mouseDownFinishPercent - initialMousePercent;
        const nowStartPercentDiff = Math.max(percent + initialStartPercentDiff, 0);
        const nowFinishPercentDiff = Math.min(percent + initialFinishPercentDiff, 100);
        const isExtending = Boolean(extendDirection);
        const startPercent =
          !isExtending || extendDirection === ExtendDirections.WEST
            ? nowStartPercentDiff
            : mouseDownStartPercent;
        const finishPercent =
          !isExtending || extendDirection === ExtendDirections.EAST
            ? nowFinishPercentDiff
            : mouseDownFinishPercent;

        return {
          isMoving: true,
          startPercent,
          finishPercent,
        };
      },
    );
  }

  handleKeyDown(e) {
    const { keyCode, target } = e;

    if (target.tagName === 'INPUT' || target.tagName === 'BODY') {
      return;
    }

    if (keyCode === LEFT_ARROW_KEY_CODE) {
      this.props.nudgeLeft();
    } else if (keyCode === RIGHT_ARROW_KEY_CODE) {
      this.props.nudgeRight();
    }
  }

  render() {
    const {
      hexColor,
      isSelected,
      label,
      displayName,
      startTimestamp,
      finishTimestamp,
      onDoitDelete,
    } = this.props;
    const {
      startPercent,
      finishPercent,
      requestedMoveDate,
      extendDirection,
      isMoving,
    } = this.state;
    const durationPercent = finishPercent - startPercent;
    const timelineItemContainerInlineStyles = {
      left: `${startPercent}%`,
      width: `${durationPercent}%`,
    };
    const timelineItemInlineStyles = {
      backgroundColor: hexColor,
    };
    const timelineItemContainerStyles = cx(styles.timelineItemContainer, {
      [styles.timelineItemContainerSelected]: isSelected,
      [styles.timelineItemContainerMoving]: requestedMoveDate && !extendDirection,
      [styles.timelineItemContainerExtending]: requestedMoveDate && extendDirection,
    });

    return (
      <div
        className={timelineItemContainerStyles}
        style={timelineItemContainerInlineStyles}
        onClick={this.handleClick.bind(this)}
        ref={this.timelineItemContainerRef}
      >
        <div
          className={styles.timelineItemContainerWestDragHandle}
          onMouseDown={(e) => this.handleMouseDown(e, ExtendDirections.WEST)}
        />
        <div
          className={styles.timelineItem}
          style={timelineItemInlineStyles}
          onMouseDown={this.handleMouseDown.bind(this)}
        />
        <div className={styles.timelineTooltip}>
          <Label
            label={label}
            displayName={displayName}
            startTimestamp={startTimestamp}
            finishTimestamp={finishTimestamp}
            getStartFinishSeconds={this.props.getStartFinishSeconds}
            isMoving={isMoving}
            startPercent={startPercent}
            finishPercent={finishPercent}
            extendDirection={extendDirection}
            onDoitDelete={onDoitDelete}
          />
          <div className={styles.timelineTooltipChevron} />
        </div>
        <div
          className={styles.timelineItemContainerEastDragHandle}
          onMouseDown={(e) => this.handleMouseDown(e, ExtendDirections.EAST)}
        />
      </div>
    );
  }
}

export default TimelineItem;
