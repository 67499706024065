// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.FileUploadInput_container___20aBLZDNO4 {\n  margin-top: 1.5em\n}\n\n.FileUploadInput_container___20aBLZDNO4:first-of-type {\n    margin-top: 0;\n  }\n\n.FileUploadInput_file-upload-input___27uRA0YcX4 {\n  display: flex;\n  align-items: center;\n  border-radius: 6px;\n  border: 1px solid #533870;\n  padding: 1.25em;\n  cursor: pointer;\n  -webkit-user-select: none;\n          user-select: none;\n  background-color: #ffffff;\n}\n\n.FileUploadInput_file-upload-input___27uRA0YcX4 .FileUploadInput_file-upload-input__details___2reea9b9uK {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    width: 100%;\n  }\n\n.FileUploadInput_file-upload-input___27uRA0YcX4 .FileUploadInput_file-upload-input__details___2reea9b9uK p {\n      color: #000000;\n      margin: 0;\n    }\n\n.FileUploadInput_file-upload-input___27uRA0YcX4 .FileUploadInput_remove___R6paCqx3TR {\n    display: none;\n  }\n\n.FileUploadInput_file-upload-input___27uRA0YcX4:hover .FileUploadInput_remove___R6paCqx3TR {\n      display: block;\n    }\n", ""]);
// Exports
exports.locals = {
	"container": "FileUploadInput_container___20aBLZDNO4",
	"file-upload-input": "FileUploadInput_file-upload-input___27uRA0YcX4",
	"fileUploadInput": "FileUploadInput_file-upload-input___27uRA0YcX4",
	"file-upload-input__details": "FileUploadInput_file-upload-input__details___2reea9b9uK",
	"fileUploadInputDetails": "FileUploadInput_file-upload-input__details___2reea9b9uK",
	"remove": "FileUploadInput_remove___R6paCqx3TR"
};
module.exports = exports;
