import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Prompt } from 'react-router';
import { NotificationManager } from 'react-notifications';

import { getEpisode, updateEpisode } from 'services/content/contentService';
import NavBar from 'components/navbar/NavBar';
import EpisodeEditHeader from 'components/episode-edit-panel/EpisodeEditHeader';
import EpisodeEditBody from 'components/episode-edit-panel/EpisodeEditBody';

import unityUtils, { isWebGLSupported } from 'components/unity/utils';
import { addEpisodeOffsetTimesToVideoDuration, addEpisodeOffsetTimesToDoits } from 'utils';

const EpisodePage = ({ creatorId, episodeId }) => {
  const [episode, setEpisode] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [doits, setDoits] = useState([]);
  const [thumbnailFile, setThumbnailFile] = useState(undefined);
  const [hasChanged, _setHasChanged] = useState(false);
  const [episodeDurationSecs, setEpisodeDurationSecs] = useState(0);
  const hasChangedRef = useRef(hasChanged);

  const setHasChanged = (v) => {
    hasChangedRef.current = v;
    _setHasChanged(v);
  };

  const saveAlert = (e) => {
    e.preventDefault();

    if (hasChangedRef.current) {
      e.returnValue = 'You have unsaved changes, are you sure you want to leave?';
    }
  };

  /* eslint-disable consistent-return */
  useEffect(() => {
    if (!isWebGLSupported()) {
      window.location.href = '/browser';
      return;
    }

    async function fetchData() {
      try {
        const episode = await getEpisode(creatorId, episodeId);
        setEpisode(episode);
        setDoits(addEpisodeOffsetTimesToDoits(episode.doits));
        setThumbnailFile(episode.thumbnailFile ?? undefined);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();

    window.addEventListener('beforeunload', saveAlert);

    return () => {
      window.removeEventListener('beforeunload', saveAlert);
    };
  }, []);
  /* eslint-enable consistent-return */

  useEffect(() => {
    if (episode) {
      setEpisodeDurationSecs(addEpisodeOffsetTimesToVideoDuration(episode.durationSecs, doits));
    }
  }, [doits]);

  const handleSave = async () => {
    const updatedEpisode = await updateEpisode(creatorId, episodeId, {
      doits,
      thumbnailFile,
      title: episode?.title,
      tags: episode?.tags,
    });
    setEpisode(updatedEpisode);
    setHasChanged(false);

    NotificationManager.success('Successfully saved!');
  };

  const handleTitleChange = (title) => setEpisode((episode) => ({ ...episode, title }));

  const handleReplaceVideo = async (file) => {
    await handleSave();
    await updateEpisode(creatorId, episodeId, {
      videoId: file.fileId,
    });
    window.location.reload();
  };

  const handleChange = (stateChangeFunction, newValue) => {
    stateChangeFunction(newValue);
    setHasChanged(true);
  };

  const isValidPlacement = (proposedDoits, index) => {
    const doit = proposedDoits[index];
    const beforeDoit = proposedDoits[index - 1];
    const afterDoit = proposedDoits[index + 1];

    if (!beforeDoit && !afterDoit) {
      return true;
    }

    if (typeof beforeDoit === 'undefined') {
      return doit.finishSecsWithEpisodeOffset < afterDoit.startSecsWithEpisodeOffset;
    }

    if (typeof afterDoit === 'undefined') {
      return beforeDoit.finishSecsWithEpisodeOffset < doit.startSecsWithEpisodeOffset;
    }

    return (
      beforeDoit.finishSecsWithEpisodeOffset < doit.startSecsWithEpisodeOffset &&
      doit.finishSecsWithEpisodeOffset < afterDoit.startSecsWithEpisodeOffset
    );
  };

  const handleDoitChange = (newOrUpdatedDoit) => {
    const updatedDoits = [...doits];

    const existingDoitIndex = doits.findIndex((doit) => doit.itemId === newOrUpdatedDoit.itemId);
    const newOrUpdatedDoitCopy = { ...newOrUpdatedDoit };
    const isExistingDoit = existingDoitIndex >= 0;
    updatedDoits[isExistingDoit ? existingDoitIndex : updatedDoits.length] = newOrUpdatedDoitCopy;

    // TODO: we can make this more performant by simply moving the changed doit
    // instead of reordering everything.
    const sortedUpdatedDoits = updatedDoits.sort(
      (a, b) => a.startSecsWithEpisodeOffset - b.startSecsWithEpisodeOffset,
    );
    const sortedExistingDoitIndex = sortedUpdatedDoits.findIndex(
      (doit) => doit.itemId === newOrUpdatedDoit.itemId,
    );

    if (isExistingDoit && !isValidPlacement(sortedUpdatedDoits, sortedExistingDoitIndex)) {
      NotificationManager.warning('Please ensure that no doits are overlapping.');
      return;
    }

    handleChange(setDoits, addEpisodeOffsetTimesToDoits(sortedUpdatedDoits));
    unityUtils.seek(newOrUpdatedDoitCopy.startSecsWithEpisodeOffset);
  };

  const handleDoitDelete = (itemId) => {
    const updatedDoits = [...doits];

    const existingDoitIndex = doits.findIndex((doit) => doit.itemId === itemId);

    if (existingDoitIndex > -1) {
      updatedDoits.splice(existingDoitIndex, 1);
      handleChange(setDoits, addEpisodeOffsetTimesToDoits(updatedDoits));
    }
  };

  if (!episode) {
    const text = isLoading ? 'Loading…' : 'That story does not exist!';

    return <h1>{text}</h1>;
  }

  const {
    title,
    videoUrl,
    videoName,
    durationSecs: videoDurationSecs,
    modifiedFormatted,
    durationLong,
  } = episode;

  return (
    <>
      <Prompt
        when={hasChanged}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <NavBar />
      <div>
        <EpisodeEditHeader
          episodeId={episodeId}
          title={title}
          durationLong={durationLong}
          doitCount={doits.length}
          modifiedFormatted={modifiedFormatted}
          onSave={handleSave}
          hasChanged={hasChanged}
          onTitleChange={(title) => handleChange(handleTitleChange, title)}
        />
        <EpisodeEditBody
          episodeId={episodeId}
          creatorId={creatorId}
          episodeTitle={title}
          show={episode.show}
          videoUrl={videoUrl}
          videoName={videoName}
          episodeDurationSecs={episodeDurationSecs}
          videoDurationSecs={videoDurationSecs}
          doits={doits}
          thumbnailFile={thumbnailFile}
          onDoitChange={handleDoitChange}
          onDoitDelete={handleDoitDelete}
          onThumbnailFileChange={(thumbnailFile) => handleChange(setThumbnailFile, thumbnailFile)}
          handleReplaceVideo={handleReplaceVideo}
        />
      </div>
    </>
  );
};

EpisodePage.propTypes = {
  creatorId: PropTypes.string.isRequired,
  episodeId: PropTypes.string.isRequired,
};

export default EpisodePage;
