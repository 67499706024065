import PropTypes from 'prop-types';
import cx from 'classnames';

import styles from 'components/episode-edit-panel/DoItTemplateButton.css';

const DoItTemplateButton = ({ name, hexColor, onClick, variants }) => {
  const hasVariants = variants.length > 1;

  return (
    <div
      className={cx(styles.doItButton, hasVariants && styles.withVariants)}
      onClick={() => !hasVariants && onClick(null)}
      style={{ backgroundColor: hexColor }}
    >
      <span className={styles.name}>{name}</span>
      {variants.map(({ variantName, variantId, variantIcon }) => (
        <span className={styles.variant} onClick={() => onClick(variantId)}>
          <img src={variantIcon} /> {variantName}
        </span>
      ))}
    </div>
  );
};

DoItTemplateButton.propTypes = {
  name: PropTypes.string.isRequired,
  hexColor: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      variantId: PropTypes.string.isRequired,
      variantName: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
    }),
  ),
};

DoItTemplateButton.defaultProps = {
  variants: [],
};

export default DoItTemplateButton;
