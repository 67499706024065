// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.ToggleButtonGroup_toggleButtonsWrapper___1zZ9j29kJ5 {\n  display: flex;\n  background-color: #faf7f1;\n  border-radius: 8px;\n}\n\n.ToggleButtonGroup_toggleButton___2qm3lx7VbX {\n  /* hide radio buttons offscreen */\n  /* (but still usable via keyboard) */\n  position: absolute;\n  left: -9999em;\n  top: -9999em\n}\n\n.ToggleButtonGroup_toggleButton___2qm3lx7VbX + label {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n\n    margin: 0;\n    padding: 0;\n    width: 100px;\n    height: 40px;\n\n    cursor: pointer;\n\n    color: grey--medium;\n    background-color: #faf7f1;\n\n    border-radius: 8px;\n    border: none;\n\n    -webkit-user-select: none;\n\n            user-select: none;\n    letter-spacing: unset;\n    text-transform: capitalize;\n    font-style: normal;\n    font-weight: 500;\n    font-size: 16px;\n    line-height: 18px;\n  }\n\n.ToggleButtonGroup_toggleButton___2qm3lx7VbX:checked + label {\n    color: #8b18e3;\n    background: #f8eef5;\n    border: 1px solid rgba(139, 24, 227, 0.8);\n    transition: all 0.35s ease;\n  }\n", ""]);
// Exports
exports.locals = {
	"toggleButtonsWrapper": "ToggleButtonGroup_toggleButtonsWrapper___1zZ9j29kJ5",
	"toggleButton": "ToggleButtonGroup_toggleButton___2qm3lx7VbX"
};
module.exports = exports;
