// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.TimelinePlayerPin_timeline-player-pin___1lUnYpxMsS {\n  position: absolute;\n  height: 90%;\n  width: 0;\n  border: 2px solid #ffffff;\n  border-radius: 16px;\n  z-index: 2;\n}\n", ""]);
// Exports
exports.locals = {
	"timeline-player-pin": "TimelinePlayerPin_timeline-player-pin___1lUnYpxMsS",
	"timelinePlayerPin": "TimelinePlayerPin_timeline-player-pin___1lUnYpxMsS"
};
module.exports = exports;
