import { NotificationManager } from 'react-notifications';
import { MODE, HEADERS } from 'services/request/constants';
import * as storeService from 'services/authentication/storeService';
import { history, isObjectEmpty } from 'utils';

export class HTTPError extends Error {
  constructor(response) {
    super(response.statusText);
    this.name = 'HTTPError';
    this.response = response;
  }
}

function toJson(response) {
  try {
    return response.json();
  } catch (e) {
    return {};
  }
}

async function handleStatusCode(response) {
  if (response.status >= 200 && response.status < 300) {
    return Promise.resolve(response);
  }

  const responseJson = await toJson(response);

  NotificationManager.error(
    responseJson?.error || 'Something went wrong!',
    `Error ${response.status}`,
  );

  if (response.status === 401 && window.location.pathname !== '/login') {
    return history.push('/login');
  }

  throw new HTTPError(response);
}

function getAuthHeaders() {
  const accessToken = storeService.getAccessToken();

  if (!accessToken) {
    return {};
  }

  return {
    Authorization: `Bearer ${accessToken}`,
  };
}

async function request(route, config = {}, searchParams = {}) {
  const { API_ADDRESS } = process.env;
  const url = new URL(route, API_ADDRESS);

  const mergedConfig = {
    mode: MODE,
    method: 'GET',
    ...config,
    headers: {
      ...HEADERS,
      ...getAuthHeaders(),
      ...config.headers,
    },
  };

  if (mergedConfig.body instanceof FormData) {
    delete mergedConfig.headers['Content-Type'];
  }

  if (!isObjectEmpty(searchParams)) {
    url.search = new URLSearchParams(searchParams).toString();
  }

  return window.fetch(url, mergedConfig).then(handleStatusCode).then(toJson);
}

export default request;
