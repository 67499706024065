import request from 'services/request/requestService';

export const UploadTypes = {
  DO_IT_ASSET: 'do_it_asset', // an asset used in a do-it
  FRAME: 'frame', // still frames from a episode
  THUMBNAIL: 'thumbnail', // a episode's thumbnail
  EPISODE_VIDEO: 'episode_video',
};

export async function getUploads(mimeTypes, type, episodeId = null) {
  const queryParams = {
    mimeTypes,
    type,
  };

  if (episodeId) {
    queryParams.originalEpisodeId = episodeId;
  }

  const method = 'GET';
  const response = await request('/uploads', { method }, queryParams);

  return response;
}

export async function uploadFiles(files, type, episodeId, creatorId) {
  const formData = new FormData();

  Array.from(files).forEach((file, i) => {
    formData.append(`files[${i}]`, file);
  });

  formData.append('type', type);

  if (episodeId) {
    formData.append('originalEpisodeId', episodeId);
  }

  if (creatorId) {
    formData.append('creatorId', creatorId);
  }

  const method = 'POST';
  const response = await request('/uploads', { method, body: formData });

  return response;
}

export async function uploadFileFromUrl(url, type, originalEpisodeId) {
  const method = 'POST';
  const body = {
    url,
    type,
  };

  if (originalEpisodeId) {
    body.originalEpisodeId = originalEpisodeId;
  }

  const jsonBody = JSON.stringify(body);

  return request('/uploads/from-url', { method, body: jsonBody });
}

// From https://stackoverflow.com/a/38935990/2957677
export function getFileFromDataUrl(dataUrl, filename) {
  const arr = dataUrl.split(',');
  const mimeType = arr[0].match(/:(.*?);/)[1];
  const bStr = atob(arr[1]);
  let n = bStr.length;
  const u8Arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8Arr[n] = bStr.charCodeAt(n);
  }

  return new File([u8Arr], filename, { type: mimeType });
}
