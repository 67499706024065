// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.MediaRow_media-row___1hg3sgIGrE {\n  background: #ffffff;\n  border: 2px solid rgba(0, 0, 0, 0.1);\n  border-radius: 6px;\n  display: flex;\n  padding: 1.25em;\n  margin-bottom: 0.625em;\n  width: 100%\n}\n\n.MediaRow_media-row___1hg3sgIGrE.MediaRow_selected___2almYJQ3kn {\n    border-color: rgba(139, 24, 227, 0.8);\n  }\n\n.MediaRow_media-row___1hg3sgIGrE .MediaRow_media-row__image___1a90h_YzfR {\n    max-width: 5em;\n    max-height: 5em;\n    margin-right: 1.25em;\n  }\n\n.MediaRow_media-row___1hg3sgIGrE .MediaRow_media-row__image___1a90h_YzfR img {\n      width: 100%;\n      height: 100%;\n    }\n\n.MediaRow_media-row___1hg3sgIGrE .MediaRow_media-row__info___229HAKR5Wu {\n    display: flex;\n    flex-direction: column;\n    flex-grow: 1;\n    justify-content: center;\n  }\n\n.MediaRow_media-row___1hg3sgIGrE .MediaRow_media-row__info___229HAKR5Wu .MediaRow_media-row__info__title___3wy1Tosiig {\n      margin: 0;\n      margin-bottom: 0.15em;\n      font-size: 19px;\n      line-height: 26px;\n    }\n\n.MediaRow_media-row___1hg3sgIGrE .MediaRow_media-row__info___229HAKR5Wu .MediaRow_media-row__info__description___20jNVadKLx {\n      opacity: 0.5;\n      color: #000000;\n      margin: 0;\n      margin-bottom: 0.3em;\n    }\n", ""]);
// Exports
exports.locals = {
	"media-row": "MediaRow_media-row___1hg3sgIGrE",
	"mediaRow": "MediaRow_media-row___1hg3sgIGrE",
	"selected": "MediaRow_selected___2almYJQ3kn",
	"media-row__image": "MediaRow_media-row__image___1a90h_YzfR",
	"mediaRowImage": "MediaRow_media-row__image___1a90h_YzfR",
	"media-row__info": "MediaRow_media-row__info___229HAKR5Wu",
	"mediaRowInfo": "MediaRow_media-row__info___229HAKR5Wu",
	"media-row__info__title": "MediaRow_media-row__info__title___3wy1Tosiig",
	"mediaRowInfoTitle": "MediaRow_media-row__info__title___3wy1Tosiig",
	"media-row__info__description": "MediaRow_media-row__info__description___20jNVadKLx",
	"mediaRowInfoDescription": "MediaRow_media-row__info__description___20jNVadKLx"
};
module.exports = exports;
