import PropTypes from 'prop-types';
import Accordion from 'components/accordion/Accordion';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import SizeInput from 'components/input/SizeInput';
import PositionInput from 'components/input/PositionInput';
import { CMSWhiteInput } from 'components/input/Input';

const DraggableObject = ({ draggableObject, i, onChange, onDelete, exposeType }) => {
  const { size, position, spriteFile } = draggableObject;
  const accordionTitle = `Draggable Item #${i + 1}`;

  return (
    <Accordion title={accordionTitle} key={accordionTitle} onDelete={onDelete}>
      <ImageUploadInput
        label="Image"
        onChange={(value) => onChange('spriteFile', value)}
        value={spriteFile}
      />
      <SizeInput
        sizeX={size.x}
        sizeY={size.y}
        positionX={position.x}
        positionY={position.y}
        onChange={onChange}
      />
      <PositionInput positionX={position.x} positionY={position.y} onChange={onChange} />
      {exposeType && (
        <>
          <label>Draggable Object Type</label>
          <CMSWhiteInput
            label="Draggable Object Type"
            value={draggableObject.type}
            onChange={(value) => onChange('type', value)}
            isRequired
            debounced
          />
        </>
      )}
    </Accordion>
  );
};

DraggableObject.propTypes = {
  draggableObject: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  exposeType: PropTypes.bool,
};

DraggableObject.defaultProps = {
  exposeType: false,
  onDelete: null,
};

export default DraggableObject;
