import PropTypes from 'prop-types';
import Accordion from 'components/accordion/Accordion';
import AccordionSection from 'components/accordion/AccordionSection';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import SizeInput from 'components/input/SizeInput';
import PositionInput from 'components/input/PositionInput';
import AudioConfig from 'components/do-it-forms/components/AudioConfig';
import CheckboxInput from 'components/input/CheckboxInput';

const BuildItPair = ({ target, object, i, onTargetChange, onObjectChange, onDelete }) => {
  const accordionTitle = `Object/Target Pair #${i + 1}`;

  return (
    <Accordion title={accordionTitle} key={accordionTitle} onDelete={onDelete}>
      <AccordionSection
        title="Draggable Object Starting State"
        description="The default state of the Draggable Object before it has been dropped in the Drop Target."
      >
        <ImageUploadInput
          label="Starting Image"
          onChange={(value) => onObjectChange('spriteFile', value)}
          value={object.spriteFile}
        />
        <SizeInput sizeX={object.size.x} sizeY={object.size.y} onChange={onObjectChange} />
        <PositionInput
          positionX={object.position.x}
          positionY={object.position.y}
          onChange={onObjectChange}
        />
      </AccordionSection>
      <AccordionSection
        title="Draggable Object Accepted State"
        description="The final state of the Draggable Object after the it has been dropped and accepted."
      >
        <SizeInput
          label="Final Size"
          sizeX={object.finalSize?.x || object.size.x}
          sizeY={object.finalSize?.y || object.size.y}
          onChange={(_, value) => onObjectChange('finalSize', value)}
        />
        <ImageUploadInput
          label="Final Accepted State Image"
          onChange={(value) => onObjectChange('dropSpriteFile', value)}
          value={object.dropSpriteFile}
        />
        <AudioConfig
          label="Accepted Sound Effect"
          value={object.acceptSound}
          onChange={(value) => onObjectChange('acceptSound', value)}
        />
        <AudioConfig
          label="Rejected Sound Effect"
          value={object.rejectSound}
          onChange={(value) => onObjectChange('rejectSound', value)}
        />
        <CheckboxInput
          forKey={`removable-${i}`}
          label="Is Object Removable"
          description="Object can be picked up after being dropped on a target."
          value={object?.removable}
          onChange={(value) => onObjectChange('removable', value)}
        />
      </AccordionSection>
      <AccordionSection
        title="Drop Target"
        description="The destination for where the Draggable Object willl be dragged."
      >
        <ImageUploadInput
          label="Drop Target Image"
          onChange={(value) => onTargetChange('spriteFile', value)}
          value={target.spriteFile}
        />
        <SizeInput sizeX={target.size.x} sizeY={target.size.y} onChange={onTargetChange} />
        <PositionInput
          positionX={target.position.x}
          positionY={target.position.y}
          onChange={onTargetChange}
        />
      </AccordionSection>
    </Accordion>
  );
};

BuildItPair.propTypes = {
  target: PropTypes.object.isRequired,
  object: PropTypes.object.isRequired,
  i: PropTypes.number.isRequired,
  onTargetChange: PropTypes.func.isRequired,
  onObjectChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default BuildItPair;
