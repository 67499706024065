import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { useUser } from 'context/user-context/UserContext';

/**
 * The use of `forwardRef` is required by Tippy (used in NavBar.jsx).
 * Reference: https://github.com/atomiks/tippyjs-react#component-children
 */
const UserAvatar = forwardRef(({ size, className, account }, ref) => {
  const { user } = useUser();

  const userFullName = account?.name || `${user.givenName} ${user.surname}`;
  const avatarUrl = account?.avatarUrl || user.avatarUrl;

  return (
    <img
      ref={ref}
      alt={userFullName}
      src={avatarUrl}
      className={className}
      style={{
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        objectFit: 'cover',
      }}
    />
  );
});

UserAvatar.defaultProps = {
  size: 128,
  className: '',
  account: undefined,
};

UserAvatar.propTypes = {
  size: PropTypes.number,
  className: PropTypes.string,
  account: PropTypes.object,
};

export default UserAvatar;
