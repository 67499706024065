import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import * as contentService from 'services/content/contentService';
import { getBackgroundImageGradientStyles } from 'utils';

import EmptyState from 'components/empty-state/EmptyState';
import { CMSButton, CMSWhiteButton, CMSRedButton } from 'components/button/Button';
import SeriesForm from 'components/forms/SeriesForm';
import FormModal from 'components/modal/FormModal';
import EpisodeSelectionModal from 'components/modal/EpisodeSelectionModal';
import { ShowIcon, HideIcon } from 'components/Icon';
import { EpisodesStat } from 'components/collection-stat/CollectionStat';
import SeriesEpisodesTable from 'components/table/SeriesEpisodesTable';
import ToggleSwitch from 'components/toggle-switch/ToggleSwitch';

import styles from 'pages/content/styles.css';

const SeriesDetailPage = ({ seriesId, creatorId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [series, setSeries] = useState();
  const [seriesEpisodes, setSeriesEpisodes] = useState([]);
  const [backgroundImageGradient, setBackgroundImageGradient] = useState('');
  const [isSeriesModalOpen, setIsSeriesModalOpen] = useState(false);
  const [isEpisodeSelectionModalOpen, setIsEpisodeSelectionModalOpen] = useState(false);

  const updateSeriesDetails = ({ series, episodes }) => {
    if (typeof series !== 'undefined') {
      setSeries(series);
    }

    if (typeof episodes !== 'undefined') {
      setSeriesEpisodes(episodes);
    }
  };

  const getSeriesDetails = async () => {
    const { series, episodes: seriesEpisodes } = await contentService.getSeries({
      creatorId,
      seriesId,
    });
    updateSeriesDetails({ series, episodes: seriesEpisodes });
  };

  useEffect(() => {
    const getSeriesData = async () => {
      setIsLoading(true);
      await getSeriesDetails();
      setIsLoading(false);
    };
    getSeriesData();
  }, [seriesId, creatorId]);

  useEffect(() => {
    if (series) {
      setBackgroundImageGradient(
        getBackgroundImageGradientStyles({
          backgroundColor: series.backgroundColor,
          backgroundImageUrl: seriesEpisodes?.[0]?.thumbnailUrl,
        }),
      );
    }
  }, [series, seriesEpisodes]);

  const numEpisodes = seriesEpisodes.length;

  const addEpisodes = async (episodeIds) => {
    const { series, episodes } = await contentService.updateSeries({
      seriesId,
      creatorId,
      series: {
        episodeIds,
      },
    });

    updateSeriesDetails({ series, episodes });
    setIsEpisodeSelectionModalOpen(false);
  };

  const toggleSeriesStatus = async () => {
    const newStatus = !series.isPublished;
    const { series: updatesSeries } = await contentService.updateSeries({
      seriesId,
      creatorId,
      series: {
        isPublished: newStatus,
      },
    });
    setSeries(updatesSeries);
    NotificationManager.success(`Successfully ${newStatus ? 'published' : 'unpublished'} series.`);
  };

  const removeSeries = async () => {
    if (!window.confirm('Are you sure you want to delete this series?')) {
      return;
    }

    await contentService.removeSeries({ creatorId, seriesId });
    NotificationManager.success('Successfully deleted series!');

    window.location.href = `/creators/${creatorId}#series`;
  };

  const toggleSwitchOptions = [
    {
      id: 'radio1',
      label: 'Active',
      icon: <ShowIcon />,
      checked: series?.isPublished,
    },
    {
      id: 'radio2',
      label: 'Inactive',
      icon: <HideIcon />,
      checked: !series?.isPublished,
    },
  ];

  return isLoading ? null : (
    <div className={styles.collectionDetailPage}>
      <header
        className={styles.header}
        style={{
          backgroundImage: backgroundImageGradient,
        }}
      >
        <div className={styles.headerInfoContainer}>
          <img className={styles.headerLogo} src={series.logoUrl} alt={series.extendedName} />
          <div className={styles.metaContainer}>
            <div className={styles.stats}>
              <ToggleSwitch options={toggleSwitchOptions} onChange={toggleSeriesStatus} />
              <EpisodesStat num={numEpisodes} color="white" iconFill="rgba(255, 255, 255, 0.8)" />
            </div>
            <div className={styles.buttonGroup}>
              <CMSRedButton label="Delete Series" onClick={removeSeries} />
              <CMSButton label="Edit Details" onClick={() => setIsSeriesModalOpen(true)} />
            </div>
          </div>
        </div>
      </header>
      <section className={styles.episodesContainer}>
        {seriesEpisodes.length ? (
          <>
            <div className={styles.actionBar}>
              <p>Stories</p>
              <CMSWhiteButton
                label="+ Add Stories"
                onClick={() => setIsEpisodeSelectionModalOpen(true)}
              />
            </div>
            <SeriesEpisodesTable
              episodes={seriesEpisodes}
              seriesId={seriesId}
              creatorId={creatorId}
              updateSeriesDetails={updateSeriesDetails}
            />
          </>
        ) : (
          <EmptyState
            title="Add stories to get started"
            subtitle="Get started by adding your first story."
            cta="+ Add Story"
            onclick={() => setIsEpisodeSelectionModalOpen(true)}
            className={[styles.emptyState]}
          />
        )}
      </section>
      <FormModal
        creatorId={creatorId}
        formNode={SeriesForm}
        title="Edit Series Details"
        isOpen={isSeriesModalOpen}
        formProps={{
          creatorId,
          series,
          isNew: false,
          onSubmit: ({ series }) => setSeries(series),
        }}
        contentLabel="Edit Series Details"
        primaryFooterBtnLabel="Save"
        onRequestClose={() => setIsSeriesModalOpen(false)}
      />
      <EpisodeSelectionModal
        creatorId={creatorId}
        collectionEpisodes={seriesEpisodes}
        onSubmit={addEpisodes}
        isOpen={isEpisodeSelectionModalOpen}
        onClose={() => setIsEpisodeSelectionModalOpen(false)}
      />
    </div>
  );
};

SeriesDetailPage.defaultProps = {
  creatorId: null,
};

SeriesDetailPage.propTypes = {
  seriesId: PropTypes.string.isRequired,
  creatorId: PropTypes.string,
};

export default SeriesDetailPage;
