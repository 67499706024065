import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { INPUT_TYPES } from 'components/input/constants';
import { signup } from 'services/authentication/authenticationService';
import PageTemplate from 'pages/auth/PageTemplate';

const SignupPage = () => {
  const invitation = useLocation().state?.invitation;

  const [signupFormData, setSignupFormData] = useState({
    givenName: {
      value: '',
      isValid: undefined,
      type: INPUT_TYPES.TEXT,
      label: 'First Name',
    },
    surname: {
      value: '',
      isValid: undefined,
      type: INPUT_TYPES.TEXT,
      label: 'Last Name',
    },
    email: {
      value: invitation?.inviteeEmail || '',
      isValid: invitation?.inviteeEmail ? true : undefined,
      type: INPUT_TYPES.EMAIL,
      label: 'Email Address',
      readOnly: invitation?.inviteeEmail || false,
    },
    password: {
      value: '',
      isValid: undefined,
      type: INPUT_TYPES.PASSWORD,
      label: 'Create Password',
    },
  });

  const handleSubmit = async (formData) => {
    await signup(formData, { invitation });
  };

  return (
    <PageTemplate
      title="Creator Sign Up"
      formData={signupFormData}
      setFormData={setSignupFormData}
      submitFunction={handleSubmit}
      ctaLabel="Sign Up"
      footerText="Already have an account?"
      footerCtaLink="/login"
      footerCtaText="Log In"
      inviteCardCreator={invitation?.inviteChannel?.title}
      inviteCardImg={invitation?.inviteChannel?.avatarUrl}
    />
  );
};

export default SignupPage;
