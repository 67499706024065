import DropdownInput from 'components/input/DropdownInput';
import { CMSTextButton } from 'components/button/Button';
import ColorInput from 'components/input/ColorInput';
import DoItForm from 'components/do-it-forms/forms/DoIt';
import BaseForm from 'components/do-it-forms/forms/Base';
import Accordion from 'components/accordion/Accordion';
import AccordionSection from 'components/accordion/AccordionSection';
import BackgroundAccordion from 'components/do-it-forms/accordions/BackgroundAccordion';
import CanvasObjects from 'components/do-it-forms/components/CanvasObjects';
import AudioConfig from 'components/do-it-forms/components/AudioConfig';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import {
  getDefaultAudioConfig,
  getDefaultBackground,
  getDefaultCanvasObject,
} from 'components/do-it-forms/utils';

class DrawItForm extends BaseForm {
  getDefaultParams() {
    return {
      background: getDefaultBackground(),
      brushSound: getDefaultAudioConfig(),
      colorPalette: {
        colors: ['#FFFFFF', '#000000'],
        startingColor: '#FFFFFF',
      },
      canvasObjects: [],
    };
  }

  getDoitParams() {
    return this.state;
  }

  addColor() {
    this.setState({
      colorPalette: {
        ...this.state.colorPalette,
        colors: [...this.state.colorPalette.colors, '#FFFFFF'],
      },
    });
  }

  removeColor(i) {
    if (this.state.colorPalette.colors.length < 2) {
      alert('Oops! You must have at least one color.');
      return;
    }

    const colors = [
      ...this.state.colorPalette.colors.slice(0, i),
      ...this.state.colorPalette.colors.slice(i + 1),
    ];

    if (this.state.colorPalette.startingColor === this.state.colorPalette.colors[i]) {
      this.setState({
        colorPalette: {
          ...this.state.colorPalette,
          colors,
          startingColor: colors[0],
        },
      });
    } else {
      this.setState({
        colorPalette: {
          ...this.state.colorPalette,
          colors,
        },
      });
    }
  }

  replaceColor(i, newColor) {
    if (this.state.colorPalette.startingColor === this.state.colorPalette.colors[i]) {
      this.setState({
        colorPalette: {
          ...this.state.colorPalette,
          colors: [
            ...this.state.colorPalette.colors.slice(0, i),
            newColor,
            ...this.state.colorPalette.colors.slice(i + 1),
          ],
          startingColor: newColor,
        },
      });
    } else {
      this.setState({
        colorPalette: {
          ...this.state.colorPalette,
          colors: [
            ...this.state.colorPalette.colors.slice(0, i),
            newColor,
            ...this.state.colorPalette.colors.slice(i + 1),
          ],
        },
      });
    }
  }

  replaceStartingColor(newColor) {
    this.setState({
      colorPalette: {
        ...this.state.colorPalette,
        startingColor: newColor,
      },
    });
  }

  updateColorButton(spriteFile) {
    this.setState({
      colorPalette: {
        ...this.state.colorPalette,
        colorButton: { spriteFile },
      },
    });
  }

  render() {
    const { onClose, onDelete, doit, subsequentDoitId } = this.props;
    const { brushSound, background, colorPalette, canvasObjects } = this.state;

    return (
      <DoItForm
        title="Draw It"
        doit={doit}
        onClose={onClose}
        onSave={(options) => this.handleSave(options)}
        onDelete={onDelete}
        subsequentDoitId={subsequentDoitId}
      >
        <BackgroundAccordion
          value={background}
          onChange={(key, value) => this.setState({ [key]: value })}
        />
        <CanvasObjects
          canvasObjects={canvasObjects || []}
          getDefaultCanvasObject={getDefaultCanvasObject}
          setState={(value) => this.setState(value)}
        />
        <Accordion title="Toolbar">
          <AccordionSection
            title="Color Button"
            description="Specify the image you'd like to use to show colors in the toolbar."
          >
            <ImageUploadInput
              label="Color Button Image"
              onChange={(value) => this.updateColorButton(value)}
              value={colorPalette.colorButton?.spriteFile}
            />
          </AccordionSection>
          <AccordionSection
            title="Color Values"
            description="Specify the color values you want to provide in your palette. Half screen doits can display a maximum of 4, and full screen doits can show a maximum of 8."
          >
            {colorPalette.colors.map((color, i) => (
              <ColorInput
                label={`Color Swatch Color #${i + 1}`}
                onChange={(inputValue) => this.replaceColor(i, inputValue.toUpperCase())}
                value={color}
                onRemove={() => this.removeColor(i)}
              />
            ))}
            <br />
            <CMSTextButton label="Add Another Color" onClick={() => this.addColor()} />

            <DropdownInput
              label="Starting Color"
              options={colorPalette.colors.map((color) => color.toUpperCase())}
              value={colorPalette.startingColor.toUpperCase()}
              onChange={({ value }) => this.replaceStartingColor(value.toUpperCase())}
            />

            <AudioConfig
              label="Brush Sound"
              value={brushSound}
              onChange={(audio) => this.setState({ brushSound: audio })}
            />
          </AccordionSection>
        </Accordion>
      </DoItForm>
    );
  }
}

export default DrawItForm;
