// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.Card_card___1KErZcudgN {\n  background-color: #ffffff;\n  padding: 2em;\n  box-shadow: 0px 14px 33px rgba(0, 0, 0, 0.15);\n  border-radius: 15px;\n  width: 100%;\n  max-width: 40em;\n  margin: 0 auto;\n  margin-bottom: 2em;\n  overflow: hidden\n}\n\n.Card_card___1KErZcudgN.Card_card--large___4gXwCptxCk {\n    max-width: 65em;\n  }\n", ""]);
// Exports
exports.locals = {
	"card": "Card_card___1KErZcudgN",
	"card--large": "Card_card--large___4gXwCptxCk",
	"cardLarge": "Card_card--large___4gXwCptxCk"
};
module.exports = exports;
