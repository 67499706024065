export const LAYOUT_OPTIONS = {
  HALF_SCREEN: {
    label: 'Half Screen (Default)',
    value: 'HALF_SCREEN',
  },
  FULL_SCREEN: {
    label: 'Full Screen',
    value: 'FULL_SCREEN',
  },
};

export const TRANSITION_OPTIONS = {
  NONE: {
    label: 'None',
    value: 'NONE',
  },
  SLIDE_RIGHT: {
    label: 'Slide Right',
    value: 'SLIDE_RIGHT',
  },
};

export const FREEFORM_VARIANT_ID = 'freeform';

export const LAYOUT_TYPES = {
  autoGrid: 'AutoGrid',
  vertical: 'Vertical',
  horizontal: 'Horizontal',
  freeForm: 'FreeForm',
};
