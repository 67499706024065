import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import cx from 'classnames';

import { create, update } from 'components/forms/utils';
import { getBackgroundImageGradientStyles } from 'utils';

import FormSection from 'components/forms/FormSection';
import { CMSWhiteInput } from 'components/input/Input';
import { CMSWhiteButton, PurpleButton } from 'components/button/Button';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import ColorRadioGroup, { BACKGROUND_COLORS } from 'components/input/ColorRadioGroup';

import styles from 'components/forms/styles.css';

const AssetPreviewInfo = ({
  title,
  description,
  hint,
  uploadBtnLabel,
  asset,
  children,
  handleOnClick,
}) => (
  <>
    <div className={styles.assetPreviewContent}>
      <p className={styles.title}>{title}</p>
      <p className={styles.description}>{description}</p>
      <div className={styles.buttonGroup}>
        <ImageUploadInput
          className={styles.fileUploadInput}
          label={uploadBtnLabel}
          hideLabel
          onChange={({ awsPublicUrl, fileId }) => handleOnClick(awsPublicUrl, fileId)}
        />
        {asset && <CMSWhiteButton label="Remove" onClick={() => handleOnClick(null, null)} />}
      </div>
      <p>{hint}</p>
    </div>
    {children}
  </>
);

AssetPreviewInfo.defaultProps = {
  hint: '',
  children: undefined,
};

AssetPreviewInfo.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  hint: PropTypes.string,
  uploadBtnLabel: PropTypes.string.isRequired,
  asset: PropTypes.string.isRequired,
  children: PropTypes.node,
  handleOnClick: PropTypes.func.isRequired,
};

/**
 * When form is wrapped in `forwardRef` and uses the `useImperativeHandle` hook,
 * the parent component then has access to whatever data is selectively exposed.
 */
const CollectionForm = forwardRef(
  (
    {
      collection,
      creatorId,
      isNew,
      hideButtons,
      onSubmit,
      createPath,
      updatePath,
      createRedirectUrl,
    },
    ref,
  ) => {
    const [collectionDetails, updateCollectionDetails] = useState(collection);
    useEffect(() => updateCollectionDetails(collection), [collection]);

    const { title, description, backgroundImageUrl, backgroundColor } = collectionDetails;
    const attributes = ['title', 'description', 'backgroundImageId', 'backgroundColor'];

    async function submit() {
      if (isNew) {
        const response = await create({
          path: createPath({ creatorId }),
          attributes,
          obj: collectionDetails,
        });

        window.location.href = createRedirectUrl({
          creatorId: response?.creator?.creatorId,
          collectionId: response.collection.collectionId,
        });
      } else {
        const { collectionId } = collection;
        const updatedCollection = await update({
          path: updatePath({ creatorId, collectionId }),
          attributes,
          obj: collectionDetails,
        });

        NotificationManager.success('Successfully saved!');
        onSubmit(updatedCollection);
      }
    }

    useImperativeHandle(ref, () => ({ submit }));

    return (
      <div className={cx(styles.form, styles.collectionForm, !hideButtons && styles.padded)}>
        <FormSection title="Basic Info" mainClassName={styles.creatorInfo}>
          <CMSWhiteInput
            value={title}
            label="Collection Name"
            isRequired
            onChange={(title) => updateCollectionDetails({ ...collectionDetails, title })}
          />

          <CMSWhiteInput
            textarea
            value={description}
            label="Collection Description"
            placeholder="Describe your collection here…"
            onChange={(description) =>
              updateCollectionDetails({ ...collectionDetails, description })
            }
          />
        </FormSection>

        <FormSection title="Collection Appearance" mainClassName={styles.appearanceSection}>
          <section>
            <AssetPreviewInfo
              title="Collection Background Image"
              description="If you do not select an image, the thumbnail from the first story in the collection will be used instead."
              uploadBtnLabel="Image"
              asset={backgroundImageUrl}
              handleOnClick={(awsPublicUrl, fileId) =>
                updateCollectionDetails({
                  ...collectionDetails,
                  backgroundImageUrl: awsPublicUrl,
                  backgroundImageId: fileId,
                })
              }
            />
            <div className={styles.assetPreviewMedia}>
              <div
                className={styles.collectionHeaderPreview}
                style={{
                  backgroundImage: getBackgroundImageGradientStyles({
                    backgroundImageUrl,
                    backgroundColor,
                  }),
                }}
              >
                <p>Preview</p>
              </div>
            </div>
          </section>
          <section>
            <div className={styles.assetPreviewContent}>
              <p className={styles.title}>Background Color</p>
              <ColorRadioGroup
                name={isNew ? 'new-creator-bg-color' : 'creator-bg-color'}
                value={backgroundColor}
                onChange={(value) =>
                  updateCollectionDetails({
                    ...collectionDetails,
                    backgroundColor: value,
                  })
                }
              />
            </div>
          </section>
        </FormSection>

        {!hideButtons && (
          <PurpleButton label={isNew ? 'Create Collection' : 'Save Changes'} onClick={submit} />
        )}
      </div>
    );
  },
);

export const MultiCreatorCollectionForm = forwardRef((props, ref) => (
  <CollectionForm
    ref={ref}
    {...props}
    createPath={() => '/content/collections'}
    updatePath={({ collectionId }) => `/content/collections/${collectionId}`}
    createRedirectUrl={({ collectionId }) => `/admin/collections/${collectionId}`}
  />
));

CollectionForm.displayName = 'CollectionForm';

CollectionForm.defaultProps = {
  collection: {
    title: '',
    description: '',
    backgroundImageUrl: '',
    // Ideally we set the default to the collection's creator bgColor
    backgroundColor: BACKGROUND_COLORS.PURPLE.name,
  },
  creatorId: null,
  isNew: false,
  hideButtons: false,
  onSubmit: () => {},
  createPath: ({ creatorId }) => `/creators/${creatorId}/collections`,
  updatePath: ({ creatorId, collectionId }) => `/creators/${creatorId}/collections/${collectionId}`,
  createRedirectUrl: ({ creatorId, collectionId }) =>
    `/creators/${creatorId}/collections/${collectionId}`,
};

CollectionForm.propTypes = {
  collection: PropTypes.object,
  creatorId: PropTypes.string,
  isNew: PropTypes.bool,
  hideButtons: PropTypes.bool,
  onSubmit: PropTypes.func,
  createPath: PropTypes.func,
  updatePath: PropTypes.func,
  createRedirectUrl: PropTypes.func,
};

export default CollectionForm;
