import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import cx from 'classnames';

import { create, update } from 'components/forms/utils';
import FormSection from 'components/forms/FormSection';

import { CMSWhiteInput } from 'components/input/Input';
import { PurpleButton } from 'components/button/Button';
import { VideoUploadInput } from 'components/file-upload/FileUploadInput';
import TagsSelector from 'components/tags-selector/TagsSelector';

import styles from 'components/forms/styles.css';

/**
 * When form is wrapped in `forwardRef` and uses the `useImperativeHandle` hook,
 * the parent component then has access to whatever data is selectively exposed.
 */
const EpisodeForm = forwardRef(
  ({ episode, isNew, creatorId, hideButtons, isLoading, setIsLoading, setIsValid }, ref) => {
    const [episodeDetails, updateEpisodeDetails] = useState(episode);
    useEffect(() => updateEpisodeDetails(episode), []);

    const { title, video, description, tags } = episodeDetails;
    const attributes = ['title', 'videoId', 'description', 'tags'];

    const hasTopics = tags?.topics && tags.topics.length > 0;
    const canEpisodeBeCreated =
      title && description && hasTopics && video && typeof video === 'object';
    useEffect(() => setIsValid(canEpisodeBeCreated), [canEpisodeBeCreated]);

    const updateTags = (key, values) =>
      updateEpisodeDetails({ ...episodeDetails, tags: { ...episodeDetails.tags, [key]: values } });

    async function submit() {
      if (isLoading) {
        return;
      }

      setIsLoading(true);

      if (isNew) {
        const newEpisode = await create({
          attributes,
          path: `/creators/${creatorId}/episodes`,
          obj: episodeDetails,
        });

        window.location.href = `/creators/${creatorId}/episodes/${newEpisode.episodeId}`;
      } else {
        await update({
          attributes,
          path: `/creators/${creatorId}/episodes/${episode.episodeId}`,
          obj: episodeDetails,
        });

        NotificationManager.success('Successfully saved!');
      }

      setIsLoading(false);
    }

    useImperativeHandle(ref, () => ({ submit, isLoading }));

    return (
      <div className={cx(styles.form, !hideButtons && styles.padded)}>
        <FormSection title="Story Info" mainClassName={styles.creatorInfo}>
          <CMSWhiteInput
            value={title}
            label="Story Name"
            isRequired
            onChange={(title) => updateEpisodeDetails({ ...episodeDetails, title })}
          />

          <CMSWhiteInput
            textarea
            isRequired
            value={description}
            label="Story Description"
            placeholder="Describe the story here…"
            onChange={(description) => updateEpisodeDetails({ ...episodeDetails, description })}
          />

          <TagsSelector
            selected={tags}
            onSkillsChange={(skills) => updateTags('skills', skills)}
            onTopicsChange={(topics) => updateTags('topics', topics)}
          />

          <VideoUploadInput
            label="Video"
            onChange={(file) =>
              updateEpisodeDetails({ ...episodeDetails, video: file, videoId: file?.fileId })
            }
            value={video}
            creatorId={creatorId}
            dropAreaOnly
            hideLabel
          />
        </FormSection>

        {!hideButtons && (
          <PurpleButton
            label={isNew ? 'Create Story' : 'Save Changes'}
            onClick={submit}
            isDisabled={isLoading}
          />
        )}
      </div>
    );
  },
);

EpisodeForm.defaultProps = {
  episode: {
    title: '',
    description: '',
    tags: {
      skills: [],
      topics: [],
    },
  },
  isNew: false,
  hideButtons: false,
};

EpisodeForm.propTypes = {
  episode: PropTypes.object,
  isNew: PropTypes.bool,
  creatorId: PropTypes.string.isRequired,
  hideButtons: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  setIsValid: PropTypes.func.isRequired,
};

export default EpisodeForm;
