import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { DebounceInput } from 'react-debounce-input';

import * as styles from 'components/episode-edit-panel/EpisodeEditHeader.css';
import { ClockIcon, StackIcon } from 'components/Icon';
import { CMSWhiteButton } from 'components/button/Button';

import { UserContext } from 'context/user-context/UserContext';

const TITLE_INPUT_DEBOUNCE_TIMEOUT = 300;

class EpisodeEditHeader extends PureComponent {
  static propTypes = {
    title: PropTypes.string.isRequired,
    durationLong: PropTypes.string.isRequired,
    doitCount: PropTypes.number.isRequired,
    modifiedFormatted: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    hasChanged: PropTypes.bool.isRequired,
    onTitleChange: PropTypes.func.isRequired,
  };

  state = { isEditingTitle: false };

  titleInput = createRef();

  async handleSaveClick() {
    await this.props.onSave();

    if (this.state.isEditingTitle) {
      this.toggleEditingTitle();
    }
  }

  toggleEditingTitle() {
    this.setState((state) => ({ isEditingTitle: !state.isEditingTitle }));
  }

  render() {
    const { title, durationLong, doitCount, modifiedFormatted, hasChanged } = this.props;

    return (
      <div className={styles.episodeEditHeader}>
        <div className={styles.episodeEditHeaderLeft}>
          {this.state.isEditingTitle ? (
            <DebounceInput
              inputRef={this.titleInput}
              autoFocus
              value={title}
              debounceTimeout={TITLE_INPUT_DEBOUNCE_TIMEOUT}
              className={cx(styles.episodeEditHeaderLeftTitle, styles.editable)}
              onChange={(e) => this.props.onTitleChange(e.target.value)}
            />
          ) : (
            <span
              className={styles.episodeEditHeaderLeftTitle}
              onClick={() => this.toggleEditingTitle()}
            >
              {title}
            </span>
          )}
          <span className={styles.episodeEditHeaderLeftIcon}>
            <ClockIcon />
            {durationLong}
          </span>
          <span className={styles.episodeEditHeaderLeftIcon}>
            <StackIcon />
            {doitCount} Do Its
          </span>
        </div>
        <div className={styles.episodeEditHeaderRight}>
          <span>Last Saved {modifiedFormatted}</span>
          <UserContext.Consumer>
            {({ user }) => (
              <CMSWhiteButton
                label="Save"
                onClick={() => this.handleSaveClick()}
                isDisabled={!hasChanged || user.email === 'demo@hellosaurus.com'}
              />
            )}
          </UserContext.Consumer>
        </div>
      </div>
    );
  }
}

export default EpisodeEditHeader;
