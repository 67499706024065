import { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from 'components/accordion/Accordion.css';
import { CMSTextButton } from 'components/button/Button';
import { ArrowDownIcon, ArrowUpIcon } from 'components/Icon';

const Accordion = ({ title, children, onDelete }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleDelete = () => {
    confirm('Are you sure you want to delete this?') && onDelete();
  };

  return (
    <div className={styles.accordion}>
      <div className={cx(styles.accordionHeader, !isOpen && styles.accordionHeaderClosed)}>
        <h6>
          {title} {onDelete && <CMSTextButton onClick={handleDelete} label="(Delete)" />}
        </h6>

        <CMSTextButton
          icon={isOpen ? <ArrowDownIcon /> : <ArrowUpIcon />}
          onClick={() => setIsOpen(!isOpen)}
        />
      </div>
      {isOpen && <div className={styles.accordionBody}>{children}</div>}
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onDelete: PropTypes.func,
};

Accordion.defaultProps = {
  onDelete: null,
};

export default Accordion;
