import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import styles from 'components/tabbed-navigation/styles.css';

const TabbedNavigation = ({ tabs }) => {
  const urlHash = useLocation().hash?.replace('#', '');
  const defaultIndex = Math.max(
    0, // findIndex returns -1 if value is not found.
    tabs.findIndex((tab) => tab.value === urlHash),
  );

  return (
    <Tabs selectedTabClassName={styles.selectedTab} defaultIndex={defaultIndex}>
      <TabList className={styles.tabList}>
        {tabs.map((tab) => (
          <Tab
            key={tab.value}
            className={styles.tab}
            disabledClassName={styles.tabDisabled}
            {...tab.tabProps}
          >
            {tab.label}
          </Tab>
        ))}
      </TabList>

      {tabs.map((tab) => (
        <TabPanel key={tab.value}>{tab.panel}</TabPanel>
      ))}
    </Tabs>
  );
};

TabbedNavigation.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
      panel: PropTypes.element.isRequired,
      tabProps: PropTypes.object,
    }),
  ).isRequired,
};

export default TabbedNavigation;
