import { useState } from 'react';
import PropTypes from 'prop-types';
import TagInput from '@pathofdev/react-tag-input';
import cx from 'classnames';

import { COLLABORATOR_ROLE } from 'utils';
import { INPUT_TYPES } from 'components/input/constants';
import { isInputValid } from 'components/input/utils';
import request from 'services/request/requestService';

import DropdownInput from 'components/input/DropdownInput';
import { CMSWhiteButton, PurpleButton } from 'components/button/Button';

import styles from 'components/forms/styles.css';

const INPUT_DELIMITERS = [
  9, // Tab
  13, // Enter
  32, // Space
  186, // Semicolon
  188, // Comma
];

const InviteCollaboratorsForm = ({ creatorId, onClose, onInvite }) => {
  const [role, setRole] = useState(COLLABORATOR_ROLE.ADMIN);
  const [emails, setEmails] = useState([]);

  const sendInvites = async () => {
    const invites = emails.map((email) => ({ email, role }));
    await request(`/creators/${creatorId}/invitations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(invites),
    });
    onClose();
    onInvite();
  };

  return (
    <div className={cx(styles.form, styles.inviteCollaboratorsForm)}>
      <section className={styles.formBody}>
        <p className={styles.title}>Invite Team Members</p>
        <div className={styles.formWrapper}>
          <TagInput
            tags={emails}
            placeholder="Enter email…"
            removeOnBackspace
            delimiters={INPUT_DELIMITERS}
            onChange={(newEmail) => setEmails(newEmail)}
            validator={(value) => isInputValid(INPUT_TYPES.EMAIL, value)}
          />
          <DropdownInput
            className={styles.dropdown}
            value={role}
            options={[
              { value: COLLABORATOR_ROLE.ADMIN, label: 'Admin' },
              { value: COLLABORATOR_ROLE.CREATOR, label: 'Creator' },
              { value: COLLABORATOR_ROLE.OBSERVER, label: 'Observer' },
            ]}
            onChange={({ value }) => setRole(value)}
          />
        </div>
      </section>
      <footer className={styles.formFooter}>
        <CMSWhiteButton label="Cancel" onClick={onClose} />
        <PurpleButton label="Send Invites" isDisabled={!emails.length} onClick={sendInvites} />
      </footer>
    </div>
  );
};

InviteCollaboratorsForm.propTypes = {
  creatorId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onInvite: PropTypes.func.isRequired,
};

export default InviteCollaboratorsForm;
