/* eslint-disable no-confusing-arrow */
import PropTypes from 'prop-types';

import styles from './styles.css';

const Loading = ({ isLoading, children }) =>
  isLoading ? <h1 className={styles.loading}>Loading…</h1> : children;

Loading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  children: PropTypes.element.isRequired,
};

export default Loading;
