/* eslint-disable react/prop-types */

import { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import cx from 'classnames';

import { useUser } from 'context/user-context/UserContext';
import { isValidForm, getFormValues } from 'utils';
import { updateAccountInfo } from 'services/authentication/authenticationService';

import FormSection from 'components/forms/FormSection';
import UserAvatar from 'components/user-avatar/UserAvatar';
import { PurpleButton, CMSTextButton } from 'components/button/Button';
import { INPUT_TYPES } from 'components/input/constants';
import { CMSWhiteInput } from 'components/input/Input';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';

import styles from 'components/forms/styles.css';

const FORM_TYPES = {
  USER_INFO: 'user-info',
  PASSWORD: 'password',
};

const updateUser = async (setUser, params, successMessage = 'Successfully saved!') => {
  const updatedUser = await updateAccountInfo(params);
  setUser(updatedUser);
  NotificationManager.success(successMessage);
};

const UserInfoFormSection = ({ userInfoForm, handleFormChange, handleSubmit }) => (
  <FormSection title="Basic Info" mainClassName={styles.creatorInfo}>
    {Object.keys(userInfoForm).map((key) => {
      const { type, label, value, readonly = false } = userInfoForm[key];

      return (
        <CMSWhiteInput
          key={label}
          type={type}
          label={label}
          value={value}
          readOnly={readonly}
          onChange={(value, isValid) =>
            handleFormChange(FORM_TYPES.USER_INFO, { key, value, isValid })
          }
        />
      );
    })}
    <PurpleButton label="Save Changes" onClick={handleSubmit} />
  </FormSection>
);

const PasswordFormSection = ({ passwordForm, handleFormChange, handleSubmit }) => {
  const [isEditingPassword, setIsEditingPassword] = useState(false);
  const [canSubmitPassword, setCanSubmitPassword] = useState(false);

  useEffect(() => {
    const { currentPassword, newPassword, confirmPassword } = passwordForm;
    if (
      isValidForm(passwordForm) &&
      currentPassword.value !== newPassword.value &&
      newPassword.value === confirmPassword.value
    ) {
      setCanSubmitPassword(true);
    } else {
      setCanSubmitPassword(false);
    }
  }, [passwordForm]);

  return (
    <FormSection title="Password" mainClassName={styles.creatorInfo}>
      {!isEditingPassword ? (
        <>
          <CMSWhiteInput
            readOnly
            type="password"
            value="hellosaurus"
            label="Password"
            onChange={() => {}}
          />
          <PurpleButton label="Change Password" onClick={() => setIsEditingPassword(true)} />
        </>
      ) : (
        <form onSubmit={() => {}}>
          {Object.keys(passwordForm).map((key) => {
            const { type, label, value, placeholder } = passwordForm[key];

            return (
              <CMSWhiteInput
                key={label}
                type={type}
                label={label}
                value={value}
                placeholder={placeholder}
                onChange={(value, isValid) =>
                  handleFormChange(FORM_TYPES.PASSWORD, { key, value, isValid })
                }
              />
            );
          })}
          <PurpleButton
            isDisabled={!canSubmitPassword}
            label="Save Changes"
            onClick={() => handleSubmit(FORM_TYPES.PASSWORD)}
          />
        </form>
      )}
    </FormSection>
  );
};

const AccountForm = () => {
  const { user, setUser } = useUser();
  const { givenName, surname, email } = user;

  const [userInfoForm, setUserInfoForm] = useState({
    givenName: {
      value: givenName,
      label: 'First Name',
      type: INPUT_TYPES.TEXT,
    },
    surname: {
      value: surname,
      label: 'Last Name',
      type: INPUT_TYPES.TEXT,
    },
    email: {
      readonly: true,
      value: email,
      label: 'Email Address',
      type: INPUT_TYPES.EMAIL,
    },
  });

  const [passwordForm, setPasswordForm] = useState({
    currentPassword: {
      value: '',
      isValid: undefined,
      type: INPUT_TYPES.PASSWORD,
      label: 'Current Password',
      placeholder: 'Enter current password',
    },
    newPassword: {
      value: '',
      isValid: undefined,
      type: INPUT_TYPES.PASSWORD,
      label: 'New Password',
      placeholder: 'Enter new password',
    },
    confirmPassword: {
      value: '',
      isValid: undefined,
      type: INPUT_TYPES.PASSWORD,
      label: 'Confirm Password',
      placeholder: 'Enter confirm password',
    },
  });

  const handleFormChange = (formType, { key, value, isValid }) => {
    if (formType === FORM_TYPES.PASSWORD) {
      setPasswordForm({ ...passwordForm, [key]: { ...passwordForm[key], value, isValid } });
    } else {
      setUserInfoForm({ ...userInfoForm, [key]: { ...userInfoForm[key], value, isValid } });
    }
  };

  const handleSubmit = async (formType = FORM_TYPES.USER_INFO) => {
    if (formType === FORM_TYPES.PASSWORD) {
      const { newPassword, confirmPassword } = getFormValues(passwordForm);
      await updateUser(setUser, { password: newPassword, confirmPassword });
    } else {
      await updateUser(setUser, getFormValues(userInfoForm));
    }
  };

  return (
    <div className={cx(styles.form, styles.accountForm, styles.padded)}>
      <FormSection title="Profile Picture" mainClassName={styles.creatorInfo}>
        <section className={styles.avatarSection}>
          <UserAvatar size={88} />
          <div className={styles.buttonGroup}>
            <ImageUploadInput
              className={styles.fileUploadInput}
              hideLabel
              onChange={async ({ fileId }) => {
                await updateUser(
                  setUser,
                  { avatarFileId: fileId },
                  'Profile picture successfully updated!',
                );
              }}
            />
            <CMSTextButton
              label="Remove"
              onClick={async () => {
                await updateUser(
                  setUser,
                  { avatarFileId: null },
                  'Profile picture successfully removed!',
                );
              }}
            />
          </div>
        </section>
      </FormSection>

      <UserInfoFormSection
        userInfoForm={userInfoForm}
        handleFormChange={handleFormChange}
        handleSubmit={handleSubmit}
      />
      <PasswordFormSection
        passwordForm={passwordForm}
        handleFormChange={handleFormChange}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default AccountForm;
