import { PureComponent } from 'react';
import PropTypes from 'prop-types';

import * as styles from 'components/episode-edit-panel/EpisodeEditBody.css';

import EpisodeEditPanel from 'components/episode-edit-panel/EpisodeEditPanel';
import Timeline from 'components/timeline/Timeline';
import Unity from 'components/unity/Unity';
import unityUtils from 'components/unity/utils';

class EpisodeEditBody extends PureComponent {
  static propTypes = {
    newUnityBuild: PropTypes.bool,
    episodeId: PropTypes.string.isRequired,
    creatorId: PropTypes.string.isRequired,
    videoUrl: PropTypes.string.isRequired,
    videoName: PropTypes.string.isRequired,
    videoDurationSecs: PropTypes.number.isRequired,
    episodeDurationSecs: PropTypes.number.isRequired,
    doits: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    thumbnailFile: PropTypes.object,
    onDoitChange: PropTypes.func.isRequired,
    onDoitDelete: PropTypes.func.isRequired,
    onThumbnailFileChange: PropTypes.func.isRequired,
    episodeTitle: PropTypes.string.isRequired,
    handleReplaceVideo: PropTypes.func.isRequired,
  };

  static defaultProps = {
    thumbnailFile: null,
    newUnityBuild: false,
  };

  state = {
    playerCurrentTime: 0,
    viewingItemId: null,
  };

  componentDidMount() {
    unityUtils.onUnityItemIdChange(this.handleViewingItemIdChange);
    unityUtils.onUnityVideoTimeChange((time) => this.setState({ playerCurrentTime: time }));
  }

  handleTimelineClick(playerCurrentTime) {
    unityUtils.seek(playerCurrentTime);
  }

  handleViewingItemIdChange = (viewingItemId) => {
    this.setState({ viewingItemId });
  };

  render() {
    const {
      videoUrl,
      videoName,
      doits,
      thumbnailFile,
      episodeId,
      creatorId,
      onThumbnailFileChange,
      onDoitChange,
      onDoitDelete,
      episodeDurationSecs,
      videoDurationSecs,
      episodeTitle,
      handleReplaceVideo,
      newUnityBuild,
    } = this.props;
    const { playerCurrentTime, viewingItemId } = this.state;

    return (
      <div className={`${styles.episodeEditBody} ${newUnityBuild && styles.new}`}>
        <div className={styles.episodeEditBodyPreview}>
          <div className={styles.episodeEditBodyPreviewVideoContainer}>
            <Unity
              newUnityBuild={newUnityBuild}
              episodePayload={{
                episodeId,
                title: episodeTitle,
                doits,
                videoUrl,
                durationSecs: videoDurationSecs,
              }}
            />
          </div>
          <Timeline
            videoUrl={videoUrl}
            doits={doits}
            episodeDurationSecs={episodeDurationSecs}
            onDoitChange={onDoitChange}
            onDoitDelete={onDoitDelete}
            playerCurrentTime={playerCurrentTime}
            onPlayerCurrentTimeChange={(playerCurrentTime) =>
              this.handleTimelineClick(playerCurrentTime)
            }
            viewingItemId={viewingItemId}
            newUnityBuild={newUnityBuild}
          />
        </div>
        {!newUnityBuild && (
          <EpisodeEditPanel
            episodeId={episodeId}
            creatorId={creatorId}
            doits={doits}
            onDoitChange={onDoitChange}
            onDoitDelete={onDoitDelete}
            playerCurrentTime={playerCurrentTime}
            episodeDurationSecs={episodeDurationSecs}
            viewingItemId={viewingItemId}
            thumbnailFile={thumbnailFile}
            onViewingItemIdChange={(viewingItemId) => this.handleViewingItemIdChange(viewingItemId)}
            onThumbnailFileChange={onThumbnailFileChange}
            onPlayerCurrentTimeChange={(playerCurrentTime) =>
              this.handleTimelineClick(playerCurrentTime)
            }
            handleReplaceVideo={handleReplaceVideo}
            videoName={videoName}
          />
        )}
      </div>
    );
  }
}

export default EpisodeEditBody;
