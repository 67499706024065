import { useState, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

import { getMe, setMe } from 'services/authentication/storeService';

export const UserContext = createContext();
UserContext.displayName = 'UserContext';

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(getMe());

  useEffect(() => {
    if (user) {
      setMe(user);
    }
  }, [user]);

  return <UserContext.Provider value={{ user, setUser }}>{children}</UserContext.Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContext must be used with UserProvider!');
  }
  return context;
};
