import { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import { BundleTypes, dataKeyForBundleType } from 'utils';
import * as adminService from 'services/content/adminService';

import { CMSWhiteButton, CMSRedButton } from 'components/button/Button';
import EmptyState from 'components/empty-state/EmptyState';
import ToggleSwitch from 'components/toggle-switch/ToggleSwitch';
import { ShowIcon, HideIcon } from 'components/Icon';
import { CollectionsStat, CreatorsStat } from 'components/collection-stat/CollectionStat';
import FormModal from 'components/modal/FormModal';
import BundleForm from 'components/forms/BundleForm';
import BundleDataTable from 'components/table/BundleDataTable';
import {
  CreatorSelectionModal,
  CollectionSelectionModal,
  TopicSelectionModal,
  SeriesSelectionModal,
} from 'components/modal/BundleDataSelectionModal';
import Loading from 'components/loading/Loading';

import styles from 'pages/content/styles.css';

const dataSelectionModals = {
  [BundleTypes.CREATOR_BUNDLE]: CreatorSelectionModal,
  [BundleTypes.COLLECTION_BUNDLE]: CollectionSelectionModal,
  [BundleTypes.TOPIC_BUNDLE]: TopicSelectionModal,
  [BundleTypes.SERIES_BUNDLE]: SeriesSelectionModal,
};

const actionsTypes = {
  bundle: 'bundle',
  collections: 'collections',
  creators: 'creators',
  topics: 'topics',
  series: 'series',
  isBundleModalOpen: 'isBundleModalOpen',
  isBundleDataSelectionModalOpen: 'isBundleDataSelectionModalOpen',
};

const initialState = {
  bundle: null,
  collections: [],
  creators: [],
  topics: [],
  series: [],
  isBundleModalOpen: false,
  isBundleDataSelectionModalOpen: false,
};

const reducer = (state, { type, payload }) => ({ ...state, [type]: payload });

const BundleDetailPage = ({ bundleId }) => {
  const [
    {
      bundle,
      collections,
      creators,
      topics,
      series,
      isBundleModalOpen,
      isBundleDataSelectionModalOpen,
    },
    dispatch,
  ] = useReducer(reducer, initialState);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const updateBundleDetails = ({ bundle, collections, creators, topics, series }) => {
    dispatch({ type: actionsTypes.bundle, payload: bundle });

    if (collections) {
      dispatch({ type: actionsTypes.collections, payload: collections });
    }

    if (creators) {
      dispatch({ type: actionsTypes.creators, payload: creators });
    }

    if (topics) {
      dispatch({ type: actionsTypes.topics, payload: topics });
    }

    if (series) {
      dispatch({ type: actionsTypes.series, payload: series });
    }
  };

  useEffect(() => {
    const fetchBundle = async () => {
      const bundle = await adminService.getBundle({ bundleId });
      updateBundleDetails(bundle);
      setIsLoading(false);
    };
    fetchBundle();
  }, []);

  useEffect(() => {
    const normalizedData = [
      ...collections,
      ...creators.map((creator) => ({ type: 'CREATOR', data: { creator } })),
      ...topics.map((topic) => ({ type: 'TOPIC', data: { topic } })),
      ...series.map((series) => ({ type: 'SERIES', data: { series } })),
    ];

    setData(normalizedData);
  }, [collections, creators, topics, series]);

  const openBundleDataSelectionModal = () =>
    dispatch({ type: actionsTypes.isBundleDataSelectionModalOpen, payload: true });

  const addDataToBundle = async (dataIds) => {
    const updatedBundle = await adminService.updateBundle({
      bundleId,
      bundle: { [dataKeyForBundleType[bundle.type]]: dataIds },
    });

    updateBundleDetails(updatedBundle);
    dispatch({ type: actionsTypes.isBundleDataSelectionModalOpen, payload: false });
  };

  const toggleBundleStatus = async () => {
    const newStatus = !bundle.isPublished;
    const { bundle: updatedBundle } = await adminService.updateBundle({
      bundleId,
      bundle: { isPublished: newStatus },
    });
    dispatch({ type: actionsTypes.bundle, payload: updatedBundle });
    NotificationManager.success(`Successfully ${newStatus ? 'published' : 'unpublished'} bundle.`);
  };

  const removeBundle = async () => {
    if (!window.confirm('Are you sure you want to delete this bundle?')) {
      return;
    }

    await adminService.removeBundle({ bundleId });
    NotificationManager.success('Successfully deleted bundle!');

    window.location.href = '/admin/bundles';
  };

  const toggleSwitchOptions = [
    {
      id: 'radio1',
      label: 'Active',
      icon: <ShowIcon />,
      checked: bundle?.isPublished,
    },
    {
      id: 'radio2',
      label: 'Inactive',
      icon: <HideIcon />,
      checked: !bundle?.isPublished,
    },
  ];

  const DataSelectionModal = dataSelectionModals[bundle?.type];
  const selectedData = {
    [BundleTypes.CREATOR_BUNDLE]: creators,
    [BundleTypes.COLLECTION_BUNDLE]: collections,
    [BundleTypes.TOPIC_BUNDLE]: topics,
    [BundleTypes.SERIES_BUNDLE]: series,
  }[bundle?.type];

  return (
    <div className={styles.pageContainer}>
      <section>
        <div className={styles.panelHeader}>
          <h2 className={styles.title}>{bundle?.title ?? 'Loading…'}</h2>
          <div className={styles.metaContainer}>
            <div className={styles.stats}>
              <ToggleSwitch options={toggleSwitchOptions} onChange={toggleBundleStatus} />
              <CollectionsStat num={collections.length} />
              <CreatorsStat creators={creators} color="rgba(0,0,0,0.6)" />
            </div>
            <div className={styles.buttonGroup}>
              <CMSWhiteButton
                label="Edit Details"
                onClick={() => dispatch({ type: actionsTypes.isBundleModalOpen, payload: true })}
              />
              <CMSWhiteButton label="+ Add to Bundle" onClick={openBundleDataSelectionModal} />
              <CMSRedButton label="Delete Bundle" onClick={removeBundle} />
            </div>
          </div>
        </div>
      </section>
      <Loading isLoading={isLoading}>
        {data.length > 0 ? (
          <BundleDataTable
            bundle={bundle}
            data={data}
            setData={setData}
            updateBundleDetails={updateBundleDetails}
          />
        ) : (
          <EmptyState
            title="Add content to get started"
            subtitle="Get started by adding collections or creators."
            cta="+ Add Content"
            onclick={openBundleDataSelectionModal}
            className={[styles.emptyState]}
          />
        )}
      </Loading>
      <FormModal
        formNode={BundleForm}
        title="Edit Bundle Details"
        isOpen={isBundleModalOpen}
        formProps={{
          bundle,
          isNew: false,
          onSubmit: ({ bundle }) => dispatch({ type: actionsTypes.bundle, payload: bundle }),
        }}
        contentLabel="Edit Bundle Details"
        primaryFooterBtnLabel="Save"
        onRequestClose={() => dispatch({ type: actionsTypes.isBundleModalOpen, payload: false })}
      />
      {bundle && (
        <DataSelectionModal
          bundle={bundle}
          selectedData={selectedData}
          isOpen={isBundleDataSelectionModalOpen}
          onClose={() =>
            dispatch({ type: actionsTypes.isBundleDataSelectionModalOpen, payload: false })
          }
          onSubmit={addDataToBundle}
        />
      )}
    </div>
  );
};

BundleDetailPage.propTypes = {
  bundleId: PropTypes.string.isRequired,
};

export default BundleDetailPage;
