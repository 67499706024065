import PropTypes from 'prop-types';
import Accordion from 'components/accordion/Accordion';
import SizeInput from 'components/input/SizeInput';
import PositionInput from 'components/input/PositionInput';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';

const CameraDisplay = ({ cameraDisplay, onChange, placeholderImage, onPlaceholderImageChange }) => {
  const { size, position, spriteFile } = cameraDisplay;

  return (
    <Accordion title="Camera Display">
      <SizeInput sizeX={size.x} sizeY={size.y} onChange={onChange} />
      <PositionInput positionX={position.x} positionY={position.y} onChange={onChange} />
      <ImageUploadInput
        label="Camera Mask"
        onChange={(value) => onChange('spriteFile', value)}
        value={spriteFile}
      />
      <ImageUploadInput
        label="Placeholder Image"
        onChange={onPlaceholderImageChange}
        value={placeholderImage}
      />
    </Accordion>
  );
};

CameraDisplay.propTypes = {
  cameraDisplay: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onPlaceholderImageChange: PropTypes.object.isRequired,
  placeholderImage: PropTypes.func.isRequired,
};

export default CameraDisplay;
