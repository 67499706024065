/* eslint-disable no-alert, react/prop-types */

import { useMemo, useRef, useEffect, forwardRef } from 'react';

import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTable, useRowSelect } from 'react-table';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  HomescreenModuleTypes,
  HumanReadableModuleTypes,
  getBackgroundImageGradientStyles,
} from 'utils';

import { DragHandleIcon } from 'components/Icon';
import { CMSWhiteButton } from 'components/button/Button';

import styles from 'components/homescreen-modules-table/HomescreenModulesTable.css';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getListStyle = (isDraggingOver) => ({
  // prevent hover effects from triggering when dragging a table row over other rows
  pointerEvents: isDraggingOver ? 'none' : 'auto',
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  display: isDragging ? 'table' : 'table-row',
  background: isDragging ? '#FFFCF6' : 'transparent',
  boxShadow: isDragging ? '0px 9px 34px rgba(0, 0, 0, 0.2)' : 'none',
  borderRadius: isDragging ? '8px' : '0px',
  ...draggableStyle,
});

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

const HomescreenModulesTable = ({
  modules,
  setHomescreenModules,
  removeModules,
  selectedModules,
  setSelectedModules,
}) => {
  const columns = useMemo(
    () => [
      {
        id: 'selection',
        // The header can use the table's getToggleAllRowsSelectedProps method
        // to render a checkbox
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <div>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
          </div>
        ),
        // The cell can use the individual row's getToggleRowSelectedProps method
        // to the render a checkbox
        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
          </div>
        ),
      },
      {
        accessor: 'order',
        Header: () => null,
        Cell: ({ data, row }) => {
          const module = row.original;
          const order = data.indexOf(module) + 1;
          return <span>{order}</span>;
        },
      },
      {
        id: 'thumbnailUrl',
        Header: () => null,
        Cell: ({ row }) => {
          const { type, data } = row.original;
          let cell = null;

          switch (type) {
            case HomescreenModuleTypes.LARGE_COLLECTION_BUNDLE_CAROUSEL:
            case HomescreenModuleTypes.LARGE_CREATOR_BUNDLE_CAROUSEL:
            case HomescreenModuleTypes.SINGLE_CREATOR_COLLECTION_CAROUSEL:
            case HomescreenModuleTypes.MULTI_CREATOR_COLLECTION_CAROUSEL: {
              const { collection, bundle } = data;
              const bgImageStyle = getBackgroundImageGradientStyles(collection || bundle);

              cell = (
                <div className={styles.thumbnail} style={{ backgroundImage: `${bgImageStyle}` }} />
              );
              break;
            }
            case HomescreenModuleTypes.SINGLE_CREATOR_CAROUSEL: {
              const thumbnailUrl = data.creator.avatarUrl;
              const { title } = data.creator;

              cell = <img src={thumbnailUrl} alt={title} className={styles.thumbnail} />;
              break;
            }
            default:
              break;
          }

          return cell;
        },
      },
      {
        accessor: 'title',
        Header: 'Title',
        Cell: ({ row }) => {
          const { data } = row.original;
          const title = data?.bundle?.title || data?.collection?.title || data.creator.title;
          return <p className={styles.title}>{title}</p>;
        },
      },
      {
        accessor: 'type',
        Header: 'Type',
        Cell: ({ row }) => {
          const { type } = row.original;
          return <p className={styles.text}>{HumanReadableModuleTypes[type]}</p>;
        },
      },
      {
        id: 'creator',
        Header: 'Creator',
        Cell: ({ row }) => {
          const { data } = row.original;
          const creatorTitle = data?.creator?.title || 'Hellosaurus - Admin';
          return <p className={styles.text}>{creatorTitle}</p>;
        },
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsSelected,
    selectedFlatRows,
    state: { selectedRowData },
  } = useTable(
    {
      columns,
      data: modules,
      getRowId: useMemo(() => (row) => row.moduleId, []),
      useControlledState: (state) =>
        useMemo(
          () => ({
            ...state,
            selectedRowData: selectedModules,
          }),
          [state, selectedModules],
        ),
    },
    useRowSelect,
  );

  const prevSelectedModulesRef = useRef();
  useEffect(() => {
    prevSelectedModulesRef.current = selectedModules;
  }, [selectedModules]);
  const prevSelectedModules = prevSelectedModulesRef.current;

  useEffect(() => {
    /**
     * This effect is triggered whenever the number of selected rows changes. This
     * effect is needed to handle the case when the "Cancel" button in the snackbar
     * is clicked in order to uncheck all selected rows (`toggleAllRowsSelected(false)`).
     */
    const stringifiedSelected = JSON.stringify(selectedRowData);
    const stringifiedPrevSelectedModules = JSON.stringify(prevSelectedModules);

    if (stringifiedSelected !== stringifiedPrevSelectedModules && selectedRowData.length === 0) {
      toggleAllRowsSelected(false);
    }
  }, [selectedRowData]);

  useEffect(() => {
    const selectedModules = selectedFlatRows.map(({ original }) => original);
    setSelectedModules(selectedModules);
  }, [selectedFlatRows]);

  const handleDragEnd = async (result) => {
    if (result.destination) {
      const items = reorder(modules, result.source.index, result.destination.index);
      setHomescreenModules(items);
    }
  };

  return (
    <table {...getTableProps()} className={styles.table}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            <th>{/* empty `th` element required for drag handle's `td` */}</th>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <tbody
              ref={provided.innerRef}
              {...getTableBodyProps()}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {rows.map((row, index) => {
                prepareRow(row);
                const { moduleId } = row.original;

                return (
                  <Draggable key={moduleId} draggableId={moduleId} index={index}>
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...row.getRowProps()}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <td className={styles.dragHandleContainer}>
                          <span {...provided.dragHandleProps}>
                            <DragHandleIcon />
                          </span>
                        </td>

                        {row.cells.map((cell) => (
                          <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        ))}
                        <td className={cx(styles.actions)}>
                          <CMSWhiteButton
                            label="Remove"
                            onClick={() => removeModules(row.original)}
                          />
                        </td>
                      </tr>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </table>
  );
};

HomescreenModulesTable.defaultProps = {};

HomescreenModulesTable.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.object).isRequired,
  setHomescreenModules: PropTypes.func.isRequired,
};

export default HomescreenModulesTable;
