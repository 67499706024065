import PropTypes from 'prop-types';
import PositionInput from 'components/input/PositionInput';
import SizeInput from 'components/input/SizeInput';
import ColorInput from 'components/input/ColorInput';
import CheckboxInput from 'components/input/CheckboxInput';
import { CMSWhiteInput } from 'components/input/Input';
import Accordion from 'components/accordion/Accordion';
import { InlineBackgroundAccordion } from 'components/do-it-forms/accordions/BackgroundAccordion';

const QuestionZone = ({ setState, value }) => {
  const shouldDisplayQuestionBox = typeof value !== 'undefined';

  const onQuestionZoneChange = (key, value) => {
    setState(({ questionZone }) => ({
      questionZone: {
        ...questionZone,
        [key]: value,
      },
    }));
  };

  const onQuestionTextChange = (key, value) => {
    setState(({ questionZone }) => ({
      questionZone: {
        ...questionZone,
        questionText: {
          ...questionZone.questionText,
          [key]: value,
        },
      },
    }));
  };

  return (
    <Accordion title="Question Box">
      <CheckboxInput
        label="Display Question Box"
        forKey="display question box"
        onChange={(isEnabled) => {
          if (isEnabled) {
            setState(({ lastQuestionZone }) => ({
              lastQuestionZone: undefined,
              questionZone: lastQuestionZone,
            }));
          } else {
            setState(({ questionZone }) => ({
              questionZone: undefined,
              lastQuestionZone: questionZone,
            }));
          }
        }}
        value={shouldDisplayQuestionBox}
      />
      {shouldDisplayQuestionBox && (
        <>
          <Accordion title="Question Background">
            <InlineBackgroundAccordion
              value={value}
              forKey="question background"
              stateKey="questionZone"
              onChange={(key, value) => setState({ [key]: value })}
            />
            <PositionInput
              positionX={value.position.x}
              positionY={value.position.y}
              onChange={(key, position) => onQuestionZoneChange('position', position)}
            />
            <SizeInput
              sizeX={value.size.x}
              sizeY={value.size.y}
              onChange={(key, size) => onQuestionZoneChange('size', size)}
            />
          </Accordion>

          <Accordion title="Question Text">
            <CMSWhiteInput
              label="What is the question?"
              value={value.questionText.text}
              onChange={(text) => onQuestionTextChange('text', text)}
              debounced
            />
            <ColorInput
              label="Text Color"
              onChange={(color) => onQuestionTextChange('color', color)}
              value={value.questionText.color}
            />
            <PositionInput
              positionX={value.questionText.position.x}
              positionY={value.questionText.position.y}
              onChange={(key, position) => onQuestionTextChange('position', position)}
            />
            <SizeInput
              sizeX={value.questionText.size.x}
              sizeY={value.questionText.size.y}
              onChange={(key, size) => onQuestionTextChange('size', size)}
            />
          </Accordion>
        </>
      )}
    </Accordion>
  );
};

QuestionZone.propTypes = {
  setState: PropTypes.func.isRequired,
  value: PropTypes.object,
};

QuestionZone.defaultProps = {
  value: undefined,
};

export default QuestionZone;
