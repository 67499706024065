/* eslint-disable react/prop-types */

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { getBackgroundImageGradientStyles } from 'utils';
import Table, { TableFilters } from 'components/table/Table';

import styles from 'components/table/Table.css';

const SeriesTable = ({ series }) => {
  const history = useHistory();

  const data = useMemo(() => series, [series]);
  const columns = useMemo(
    () => [
      {
        id: 'backgroundImage',
        disableSortBy: true,
        Header: () => null,
        Cell: ({ row }) => {
          const { extendedName, logoUrl, thumbnailUrl, backgroundColor } = row.original.series;
          const bgImageStyle = getBackgroundImageGradientStyles({
            backgroundImageUrl: thumbnailUrl,
            backgroundColor,
          });
          return (
            <div
              className={cx(styles.thumbnail, styles.vertical)}
              style={{ backgroundImage: `${bgImageStyle}` }}
            >
              <img src={logoUrl} alt={extendedName} />
            </div>
          );
        },
        style: {
          width: 86,
        },
      },
      {
        id: 'title',
        accessor: (row) => row.series.extendedName,
        Header: 'Title',
        Cell: ({ row }) => {
          const { extendedName } = row.original.series;
          return <p className={styles.title}>{extendedName}</p>;
        },
        filter: TableFilters.FUZZY_TEXT,
      },
      {
        Header: 'Status',
        Cell: ({ row }) => {
          const { isPublished } = row.original.series;
          return <p className={styles.text}>{isPublished ? 'Active' : 'Inactive'}</p>;
        },
        filter: TableFilters.PUBLISHED,
      },
      {
        Header: 'Stories',
        Cell: ({ row }) => {
          const { features } = row.original.series;
          const { EPISODE_COUNT } = features;
          return <p className={styles.text}>{EPISODE_COUNT}</p>;
        },
        filter: TableFilters.FUZZY_TEXT,
      },
    ],
    [],
  );

  const onRowClick = ({ series: { seriesId, creatorId } }) =>
    history.push(`/creators/${creatorId}/series/${seriesId}`);

  return (
    <Table
      entityKey="series"
      datumIdAccessor="series.seriesId"
      data={data}
      columns={columns}
      onRowClick={onRowClick}
    />
  );
};

SeriesTable.propTypes = {
  series: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SeriesTable;
