import { useState, useEffect, createContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import { NotificationManager } from 'react-notifications';

import { ACCOUNT_TYPE_NAMES, COLLABORATOR_ROLE } from 'utils';
import request from 'services/request/requestService';
import { getCreator, updateCreatorPublishedStatus } from 'services/content/contentService';

import { useUser } from 'context/user-context/UserContext';
import { LOCAL_STORAGE_LAST_VISITED_CREATOR_ID_KEY } from 'services/authentication/constants';

import EmptyState from 'components/empty-state/EmptyState';
import EpisodeTable from 'components/table/EpisodeTable';
import MembersTable from 'components/table/MembersTable';
import CreatorForm from 'components/forms/CreatorForm';
import { CMSButton, CMSWhiteButton } from 'components/button/Button';
import TabbedNavigation from 'components/tabbed-navigation/TabbedNavigation';
import DropdownInput from 'components/input/DropdownInput';
import EpisodeForm from 'components/forms/EpisodeForm';
import FormModal from 'components/modal/FormModal';
import InviteCollaboratorsForm from 'components/forms/InviteCollaboratorsForm';
import ToggleSwitch from 'components/toggle-switch/ToggleSwitch';
import { ShowIcon, HideIcon } from 'components/Icon';
import CollectionsPage from 'pages/content/CollectionsPage';
import EarningsTab from 'pages/content/EarningsTab';
import SeriesPage from 'pages/content/SeriesPage';
import Loading from 'components/loading/Loading';

import styles from 'pages/content/styles.css';

const NEW_CREATOR_VALUE = 'new';

const onDropdownChange = (value, history, setIsCreatorModalOpen) => {
  if (value === NEW_CREATOR_VALUE) {
    setIsCreatorModalOpen(true);
    return;
  }

  history.push(`/creators/${value}`);
};

const prepareCreators = (creators) => {
  const existingCreators = creators.map(({ title, creatorId }) => ({
    label: title,
    value: creatorId,
  }));

  existingCreators.sort((a, b) => (a.label < b.label ? -1 : 1));

  const newCreator = {
    label: '+ New Creator',
    value: NEW_CREATOR_VALUE,
  };

  return [...existingCreators, newCreator];
};

export const CreatorPageContext = createContext();
CreatorPageContext.displayName = 'CreatorPageContext';

const CreatorPage = ({ creatorId, activeCreator, creators }) => {
  const { user } = useUser();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [episodes, setEpisodes] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [lastModified, setLastModified] = useState(new Date());
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isCreatorModalOpen, setIsCreatorModalOpen] = useState(false);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [isPublished, setIsPublished] = useState(false);

  const isSitewideAdmin = user?.accountType === ACCOUNT_TYPE_NAMES.ADMIN;
  const isCreatorAdmin = activeCreator?.role === COLLABORATOR_ROLE.ADMIN;
  const canViewEarnings = isSitewideAdmin || isCreatorAdmin;

  const getCollaborators = async () => request(`/creators/${creatorId}/collaborators`);

  const updateCollaborators = async () => {
    const collaborators = await getCollaborators();
    setCollaborators(collaborators);
  };

  const handleInvite = async () => {
    await updateCollaborators();
  };

  const toggleCreatorStatus = async () => {
    const status = !isPublished;
    const confirmation = window.confirm(
      `Are you sure you want to ${status ? 'publish' : 'unpublish'} this channel?`,
    );
    if (!confirmation) {
      return;
    }
    const updatedCreator = await updateCreatorPublishedStatus(creatorId, status);
    NotificationManager.success(
      `Successfully ${updatedCreator.isPublished ? 'published' : 'unpublished'} channel.`,
    );
    setIsPublished(updatedCreator.isPublished);
  };

  useEffect(() => {
    const getChannelData = async () => {
      setIsLoading(true);

      const { episodes, creator } = await getCreator(creatorId);
      setEpisodes(episodes);

      setIsPublished(creator?.isPublished);

      await updateCollaborators();

      setIsLoading(false);
      localStorage.setItem(LOCAL_STORAGE_LAST_VISITED_CREATOR_ID_KEY, creatorId);
    };
    getChannelData();
  }, [creatorId, lastModified]);

  const EpisodesPanel = (
    <section className={styles.episodesContainer}>
      {episodes.length ? (
        <EpisodeTable episodes={episodes} creatorId={creatorId} updateEpisodeOrder={setEpisodes} />
      ) : (
        <EmptyState
          title="Create Your First Story"
          subtitle="Get started by creating your first story."
          cta="+ Create Story"
          onclick={() => setIsOpen(true)}
          className={[styles.emptyState]}
        />
      )}
    </section>
  );
  const DetailsPanel = <CreatorForm creator={activeCreator} />;
  const TeamMembersPanel = (
    <section className={styles.membersContainer}>
      <div className={styles.sectionInfo}>
        <div>
          <p className={styles.membersTitle}>Members</p>
          <p className={styles.membersSubtitle}>
            Invite teammates to collaboratively create and manage content.
          </p>
        </div>
        <CMSWhiteButton label="+ Invite People" onClick={() => setIsInviteModalOpen(true)} />
        <Modal
          isOpen={isInviteModalOpen}
          style={{
            content: {
              width: '100%',
              maxWidth: '700px',
              height: 'fit-content',
              top: '50%',
              transform: 'translateY(-50%)',
              overflow: 'visible',
              background: '#FFFCF6',
              borderRadius: '10px',
            },
          }}
        >
          <InviteCollaboratorsForm
            creatorId={creatorId}
            onClose={() => setIsInviteModalOpen(false)}
            onInvite={handleInvite}
          />
        </Modal>
      </div>
      <MembersTable
        teamMembers={collaborators}
        activeCreator={activeCreator}
        updateCollaborators={updateCollaborators}
      />
    </section>
  );
  const CollectionsPanel = <CollectionsPage creatorId={creatorId} />;
  const EarningsPanel = <EarningsTab creatorId={creatorId} />;
  const SeriesPanel = <SeriesPage creatorId={creatorId} />;

  const tabs = [
    { value: 'stories', label: 'Stories', panel: EpisodesPanel },
    { value: 'series', label: 'Series', panel: SeriesPanel },
    { value: 'collections', label: 'Collections', panel: CollectionsPanel },
    { value: 'details', label: 'Details', panel: DetailsPanel },
    { value: 'team-members', label: 'Team Members', panel: TeamMembersPanel },
    {
      value: 'earnings',
      label: 'Earnings',
      panel: EarningsPanel,
      tabProps: { disabled: !canViewEarnings },
    },
  ];

  const toggleSwitchOptions = [
    {
      id: 'publish',
      label: 'Publish',
      icon: <ShowIcon />,
      checked: isPublished,
    },
    {
      id: 'draft',
      label: 'Draft',
      icon: <HideIcon />,
      checked: !isPublished,
    },
  ];

  return (
    <CreatorPageContext.Provider value={{ refreshCreator: () => setLastModified(new Date()) }}>
      <header className={styles.header}>
        <div className={styles.dropdownContainer}>
          <DropdownInput
            containerClassName={styles.dropdown}
            controlClassName={styles.dropdownControl}
            arrowClassName={styles.arrow}
            placeholderClassName={styles.placeholder}
            menuClassName={styles.dropdownMenu}
            options={prepareCreators(creators)}
            placeholder={activeCreator?.title || 'Loading...'}
            onChange={({ value }) => onDropdownChange(value, history, setIsCreatorModalOpen)}
          />
        </div>
        {isSitewideAdmin && (
          <div className={styles.toggle}>
            <ToggleSwitch options={toggleSwitchOptions} onChange={toggleCreatorStatus} />
          </div>
        )}
        {!!episodes.length && <CMSButton onClick={() => setIsOpen(true)} label="+ Create Story" />}
      </header>

      <Loading isLoading={isLoading}>
        <TabbedNavigation tabs={tabs} />
      </Loading>
      <FormModal
        creatorId={creatorId}
        formNode={EpisodeForm}
        title="Create Story"
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        formProps={{ creatorId }}
        contentLabel="New Story"
      />
      <FormModal
        formNode={CreatorForm}
        title="New Creator"
        isOpen={isCreatorModalOpen}
        onRequestClose={() => setIsCreatorModalOpen(false)}
        contentLabel="New Creator"
      />
    </CreatorPageContext.Provider>
  );
};

CreatorPage.propTypes = {
  creatorId: PropTypes.string.isRequired,
  activeCreator: PropTypes.object.isRequired,
  creators: PropTypes.array.isRequired,
};

export default CreatorPage;
