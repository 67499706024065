import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { INPUT_TYPES } from 'components/input/constants';
import { TargetIcon, LockIcon } from 'components/Icon';

import * as styles from 'components/input/Input.css';
import { CMSWhiteInput } from 'components/input/Input';

class SizeInput extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    sizeX: PropTypes.number.isRequired,
    sizeY: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  static defaultProps = {
    label: 'Size',
  };

  state = {
    locked: false,
    targetSelectorActive: false,
  };

  handleChange(key, value) {
    if (isNaN(value)) {
      return;
    }

    const existingSize = { x: this.props.sizeX, y: this.props.sizeY };
    const newSize = {
      ...existingSize,
      [key]: Number(value),
    };

    this.props.onChange('size', newSize);
  }

  handleRequestPositionClick() {
    window.alert(
      'This functionality is currently disabled while we rebuild it in Unity. Please use the number input instead.',
    );
  }

  handleLockAspectRatioClick() {
    window.alert(
      'This functionality is currently disabled while we rebuild it in Unity. Please use the number input instead.',
    );
  }

  render() {
    const { label, sizeX, sizeY } = this.props;
    const { locked, targetSelectorActive } = this.state;
    const coordinateInputStyles = cx(styles.coordinateInput, {
      [styles.coordinateInputLocked]: locked,
      [styles.coordinateInputActive]: targetSelectorActive,
    });

    return (
      <>
        <label>{label}</label>
        <div className={coordinateInputStyles}>
          <div>
            <CMSWhiteInput
              type={INPUT_TYPES.NUMBER}
              label="Width"
              value={sizeX}
              onChange={(value) => this.handleChange('x', value)}
              debounced
              isRequired
            />
          </div>
          <div>
            <CMSWhiteInput
              type={INPUT_TYPES.NUMBER}
              label="Height"
              value={sizeY}
              onChange={(value) => this.handleChange('y', value)}
              debounced
              isRequired
            />
          </div>
          <div
            onClick={this.handleRequestPositionClick.bind(this)}
            className={styles.coordinateInputTargetIcon}
          >
            <TargetIcon />
          </div>
          <div
            onClick={this.handleLockAspectRatioClick.bind(this)}
            className={styles.coordinateInputLockIcon}
          >
            <LockIcon />
          </div>
        </div>
      </>
    );
  }
}

export default SizeInput;
