import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { INPUT_TYPES } from 'components/input/constants';
import { resetPassword } from 'services/authentication/authenticationService';
import PageTemplate from 'pages/auth/PageTemplate';

const ResetPasswordPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const [resetPasswordFormData, setResetPasswordFormData] = useState({
    email: {
      value: query.get('email') || '',
      isValid: true,
      type: INPUT_TYPES.EMAIL,
      label: 'Email Address',
      readOnly: true,
    },
    password: {
      value: '',
      isValid: undefined,
      type: INPUT_TYPES.PASSWORD,
      label: 'New Password',
    },
    confirmPassword: {
      value: '',
      isValid: undefined,
      type: INPUT_TYPES.PASSWORD,
      label: 'Confirm Password',
    },
    token: {
      value: query.get('token'),
      isValid: true,
      type: INPUT_TYPES.HIDDEN,
    },
  });

  return (
    <PageTemplate
      title="Reset Password"
      formData={resetPasswordFormData}
      setFormData={setResetPasswordFormData}
      submitFunction={resetPassword}
      ctaLabel="Submit"
      footerCtaLink={`/forgot-password?email=${resetPasswordFormData.email.value}`}
      footerCtaText="Request New Password"
    />
  );
};

export default ResetPasswordPage;
