import { useState } from 'react';
import PageLayout from 'components/page-layout/PageLayout';
import EmptyState from 'components/empty-state/EmptyState';
import CreatorForm from 'components/forms/CreatorForm';
import FormModal from 'components/modal/FormModal';

import styles from 'pages/content/styles.css';

const CreatorsPage = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <PageLayout title="Welcome">
      <section className={styles.creatorsPage}>
        <EmptyState
          className={[styles.emptyStateCard]}
          title="Welcome to Hellosaurus!"
          subtitle="Start by making a new creator. Every creator can have an endless number of stories, so create away."
          cta="+ New Creator"
          onclick={() => setIsOpen(true)}
        />

        <div className={styles.emptyStateCard}>
          <p className={styles.title}>Looking to join a creator?</p>
          <p className={styles.content}>
            To join an existing creator, ask the administrator to invite you.
          </p>
        </div>
      </section>
      <FormModal
        formNode={CreatorForm}
        title="New Creator"
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="New Creator"
      />
    </PageLayout>
  );
};

export default CreatorsPage;
