// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SeriesPreviewCard_card___1v8fdpWT8f {\n  width: 200px;\n  height: 350px;\n  display: flex;\n  flex-direction: column;\n}\n\n.SeriesPreviewCard_card-body___1juax92Sq0 {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n\n  flex: 1;\n  background-repeat: no-repeat;\n  background-size: cover;\n  border-radius: 8px 8px 0 0;\n  padding: 10px;\n}\n\n.SeriesPreviewCard_card-body___1juax92Sq0 .SeriesPreviewCard_creator-avatar___xgSFAmrDiP {\n  width: 20px;\n  height: 20px;\n  border-radius: 50%;\n}\n\n.SeriesPreviewCard_card-body___1juax92Sq0 .SeriesPreviewCard_series-logo___4V5QWMcpts {\n  max-width: 125px;\n}\n\n.SeriesPreviewCard_card-footer___3TYTpZuOkK {\n  display: flex;\n  justify-content: space-between;\n  padding: 10px;\n  color: #ffffff;\n  font-size: 8px;\n  background: #a347ff;\n  border-radius: 0 0 8px 8px;\n}\n\n.SeriesPreviewCard_card-footer___3TYTpZuOkK .SeriesPreviewCard_tags___85uz7BHD4W {\n  display: flex;\n  flex-direction: column;\n}\n\n.SeriesPreviewCard_card-footer___3TYTpZuOkK .SeriesPreviewCard_tags___85uz7BHD4W > span:last-of-type {\n  font-weight: bold;\n}\n\n.SeriesPreviewCard_card-footer___3TYTpZuOkK button {\n  border: none;\n  border-radius: 4px;\n  color: #ffffff;\n  font-size: 8px;\n  background: rgba(255, 255, 255, 0.2);\n}\n", ""]);
// Exports
exports.locals = {
	"card": "SeriesPreviewCard_card___1v8fdpWT8f",
	"card-body": "SeriesPreviewCard_card-body___1juax92Sq0",
	"cardBody": "SeriesPreviewCard_card-body___1juax92Sq0",
	"creator-avatar": "SeriesPreviewCard_creator-avatar___xgSFAmrDiP",
	"creatorAvatar": "SeriesPreviewCard_creator-avatar___xgSFAmrDiP",
	"series-logo": "SeriesPreviewCard_series-logo___4V5QWMcpts",
	"seriesLogo": "SeriesPreviewCard_series-logo___4V5QWMcpts",
	"card-footer": "SeriesPreviewCard_card-footer___3TYTpZuOkK",
	"cardFooter": "SeriesPreviewCard_card-footer___3TYTpZuOkK",
	"tags": "SeriesPreviewCard_tags___85uz7BHD4W"
};
module.exports = exports;
