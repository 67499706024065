import PropTypes from 'prop-types';

import styles from 'components/input/Input.css';

const CheckboxInput = ({ label, description, value, onChange, forKey }) => (
  <div className={styles.checkboxContainer}>
    {label ? (
      <label htmlFor={forKey || label} className={styles.label}>
        {label} {description ? <span className={styles.description}>{description}</span> : null}
      </label>
    ) : null}

    <label className={styles.switch} htmlFor={forKey || label}>
      <input
        id={forKey || label}
        type="checkbox"
        checked={value}
        onChange={(e) => onChange(e.target.checked)}
      />
      <span className={styles.slider} />
    </label>
  </div>
);

CheckboxInput.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  forKey: PropTypes.string,
};

CheckboxInput.defaultProps = {
  label: null,
  value: false,
  description: null,
  forKey: null,
};

export default CheckboxInput;
