export const DEFAULT_DO_IT_DURATION_SECS = 30;

export const DEFAULT_DO_IT_SETTINGS_OBJECT = {
  allowRestart: true,
  dismissOnComplete: false,
};

export const DEFAULT_PARAMS_BY_TEMPLATE = {
  'sequence-it-template': {
    background: {
      spriteFile: null,
      spriteUri: '',
    },
    buttons: [
      {
        sequence: [
          {
            size: {
              x: 500,
              y: 500,
            },
            position: {
              x: 0,
              y: 0,
            },
            spriteFile: null,
            successSound: {
              audioFile: null,
              audioUri: '',
            },
            spriteUri: '',
          },
        ],
      },
    ]
  },
  'soundboard-it-template': {
    background: null,
    panels: [
      {
        background: {
          color: '#FFFFFF',
        },
        button: {
          spriteFile: null,
          pressedSpriteFile: null,
          buttonSound: {
            audioFile: null,
            audioUri: '',
          },
          numMaxPlays: 0,
          size: {
            x: 250,
            y: 250,
          },
          position: {
            x: 0,
            y: 0,
          },
          spriteUri: '',
          pressedSpriteUri: '',
        },
      },
    ],
    layoutSettings: {
      layoutType: 'FreeForm',
    },
  },
  'survey-it-template': {
    background: {
      spriteFile: null,
      spriteUri: '',
    },
    numRequiredResponses: 1,
    questionZone: {
      position: {
        x: 0,
        y: 300,
      },
      size: {
        x: 400,
        y: 200,
      },
      color: '#FFFFFF',
      spriteFile: null,
      questionText: {
        text: 'Question text',
        color: '#000000',
        position: {
          x: 0,
          y: 0,
        },
        size: {
          x: 400,
          y: 100,
        },
      },
      spriteUri: '',
    },
    choicesZone: {
      position: {
        x: 0,
        y: 0,
      },
      size: {
        x: 500,
        y: 500,
      },
      spriteFile: null,
      color: '#FFFFFF01',
      choices: [
        {
          selectedColor: '#FFFFFF',
          defaultColor: '#FFFFFF',
          buttonText: {
            text: 'Choice label',
            color: '#000000',
            size: {
              x: 100,
              y: 100,
            },
            position: {
              x: 0,
              y: 0,
            },
          },
          buttonSound: {
            audioFile: null,
            audioUri: '',
          },
          itemDefaultNode: {
            spriteFile: null,
            size: {
              x: 200,
              y: 200,
            },
            position: {
              x: 0,
              y: 0,
            },
            spriteUri: '',
          },
          selectedImage: null,
        },
      ],
      layoutSettings: {
        layoutType: 'FreeForm',
      },
      spriteUri: '',
    },
  },
  'trivia-it-template': {
    showConfetti: true,
    background: {
      spriteFile: null,
      spriteUri: '',
    },
    questionZone: {
      position: {
        x: 0,
        y: 300,
      },
      size: {
        x: 400,
        y: 200,
      },
      color: '#FFFFFF',
      spriteFile: null,
      questionText: {
        text: 'Question text',
        color: '#000000',
        position: {
          x: 0,
          y: 0,
        },
        size: {
          x: 400,
          y: 100,
        },
      },
      spriteUri: '',
    },
    answersZone: {
      position: {
        x: 0,
        y: 0,
      },
      size: {
        x: 500,
        y: 500,
      },
      spriteFile: null,
      answers: [
        {
          selectedColor: '#FFFFFF',
          defaultColor: '#FFFFFF',
          buttonText: {
            text: 'Answer label',
            color: '#000000',
            size: {
              x: 100,
              y: 100,
            },
            position: {
              x: 0,
              y: 0,
            },
          },
          buttonSound: {
            audioFile: null,
            audioUri: '',
          },
          itemDefaultNode: {
            spriteFile: null,
            size: {
              x: 200,
              y: 200,
            },
            position: {
              x: 0,
              y: 0,
            },
            spriteUri: '',
          },
          selectedImage: null,
          correctAnswer: false,
        },
      ],
      layoutSettings: {
        layoutType: 'FreeForm',
      },
      spriteUri: '',
    },
  },
  'find-it-template': {
    background: {
      spriteFile: null,
      spriteUri: '',
    },
    findables: [
      {
        position: {
          x: 0,
          y: 0,
        },
        size: {
          x: 150,
          y: 150,
        },
        tweenTo: {
          x: -250,
          y: 350,
        },
        tweenSizeToScaleFactor: 1,
        spriteFile: null,
        successSound: {
          audioFile: null,
          audioUri: '',
        },
        disableOnClick: false,
        spriteUri: '',
      },
    ],
  },
  'stamp-it-template': {
    background: {
      spriteFile: null,
      spriteUri: '',
    },
    maxNumStamps: 0,
    stamps: [
      {
        size: {
          x: 150,
          y: 150,
        },
        spriteFile: null,
        stampSound: {
          audioFile: null,
          audioUri: '',
        },
        spriteUri: '',
      },
    ],
    randomStampOrder: true,
  },
  'put-it-template': {
    background: {
      spriteFile: null,
      spriteUri: '',
    },
    draggableObjects: [
      {
        size: {
          x: 100,
          y: 100,
        },
        finalSize: {
          x: 100,
          y: 100,
        },
        position: {
          x: 200,
          y: 0,
        },
        spriteFile: null,
        type: 'DEFAULT',
        spriteUri: '',
      },
    ],
    dropTargets: [
      {
        spriteFile: null,
        position: {
          x: 0,
          y: 0,
        },
        size: {
          x: 250,
          y: 250,
        },
        acceptSound: {
          audioFile: null,
          audioUri: '',
        },
        dropSpriteFile: null,
        fullSpriteFile: null,
        maxNumDrops: 0,
        acceptedTypes: ['DEFAULT'],
        spriteUri: '',
        dropSpriteUri: '',
        fullSpriteUri: '',
      },
    ],
  },
  'draw-it-template': {
    background: {
      spriteFile: null,
      spriteUri: '',
    },
    brushSound: {
      audioFile: null,
      audioUri: '',
    },
    colorPalette: {
      colors: ['#FFFFFF', '#000000'],
      startingColor: '#FFFFFF',
    },
    canvasObjects: [],
  },
  'sort-it-template': {
    background: {
      spriteFile: null,
      spriteUri: '',
    },
    draggableObjects: [
      {
        size: {
          x: 100,
          y: 100,
        },
        finalSize: {
          x: 100,
          y: 100,
        },
        position: {
          x: 200,
          y: 0,
        },
        spriteFile: null,
        type: 'DEFAULT',
        spriteUri: '',
      },
    ],
    dropTargets: [
      {
        spriteFile: null,
        position: {
          x: 0,
          y: 0,
        },
        size: {
          x: 250,
          y: 250,
        },
        acceptSound: {
          audioFile: null,
          audioUri: '',
        },
        dropSpriteFile: null,
        fullSpriteFile: null,
        maxNumDrops: 0,
        acceptedTypes: ['DEFAULT'],
        spriteUri: '',
        dropSpriteUri: '',
        fullSpriteUri: '',
      },
    ],
  },
  'capture-it-template': {
    background: {
      spriteFile: null,
      spriteUri: '',
    },
    cameraDisplay: {
      size: {
        x: 250,
        y: 250,
      },
      position: {
        x: 0,
        y: 0,
      },
      spriteFile: null,
      spriteUri: '',
    },
    placeholderImage: null,
    backgroundObjects: [
      {
        "size":
            {
              "x": 300,
              "y": 300
            },
        "position":
            {
              "x": 0,
              "y": 0
            },
        "spriteFile": null,
        "spriteUri": ""
      }
    ],
    foregroundObjects: [
      {
        "size":
            {
              "x": 200,
              "y": 200
            },
        "position":
            {
              "x": 0,
              "y": 0
            },
        "spriteFile": null,
        "spriteUri": ""
      }
    ],
  },
  'build-it-template': {
    background: {
      spriteFile: null,
      spriteUri: '',
    },
    buildItTargets: [
      {
        spriteFile: null,
        position: {
          x: 0,
          y: 0,
        },
        size: {
          x: 250,
          y: 250,
        },
        acceptSound: {
          audioFile: null,
          audioUri: '',
        },
        dropSpriteFile: null,
        fullSpriteFile: null,
        maxNumDrops: 0,
        acceptedTypes: ['DEFAULT'],
        spriteUri: '',
        dropSpriteUri: '',
        fullSpriteUri: '',
      },
    ],
    buildItObjects: [
      {
        size: {
          x: 100,
          y: 100,
        },
        finalSize: {
          x: 100,
          y: 100,
        },
        position: {
          x: 200,
          y: 0,
        },
        spriteFile: null,
        type: 'DEFAULT',
        spriteUri: '',
      },
    ],
  },
  'assemble-it-template': {
    background: {
      spriteFile: null,
      spriteUri: '',
    },
    buildItTargets: [
      {
        spriteFile: null,
        position: {
          x: 0,
          y: 0,
        },
        size: {
          x: 250,
          y: 250,
        },
        acceptSound: {
          audioFile: null,
          audioUri: '',
        },
        dropSpriteFile: null,
        fullSpriteFile: null,
        maxNumDrops: 0,
        acceptedTypes: ['DEFAULT'],
        hideOnDrop: true,
        spriteUri: '',
        dropSpriteUri: '',
        fullSpriteUri: '',
      },
    ],
    buildItObjects: [
      {
        size: {
          x: 100,
          y: 100,
        },
        finalSize: {
          x: 100,
          y: 100,
        },
        position: {
          x: 200,
          y: 0,
        },
        spriteFile: null,
        type: 'DEFAULT',
        spriteUri: '',
      },
    ],
  },
  'wipe-it-template': {
    background: {
      spriteFile: null,
      spriteUri: '',
    },
    progressToComplete: 0.99,
    brushSound: {
      audioFile: null,
      audioUri: '',
    },
    canvas: {
      position: {
        x: 0,
        y: 0,
      },
      size: {
        x: 250,
        y: 250,
      },
      spriteFile: null,
      brush: {
        position: {
          x: 200,
          y: 0,
        },
        size: {
          x: 100,
          y: 100,
        },
        spriteFile: null,
        spriteUri: '',
      },
      spriteUri: '',
    },
  },
  'say-it-template': {
    background: {
      spriteFile: null,
      spriteUri: '',
    },
    sequence: [
      {
        size: {
          x: 500,
          y: 500,
        },
        position: {
          x: 0,
          y: 0,
        },
        spriteFile: null,
        successSound: {
          audioFile: null,
          audioUri: '',
        },
        stepType: 'micStep',
        stepVolumeThreshold: 0.25,
        stepDurationThreshold: 0,
        spriteUri: '',
      },
      {
        size: {
          x: 500,
          y: 500,
        },
        position: {
          x: 0,
          y: 0,
        },
        spriteFile: null,
        successSound: {
          audioFile: null,
          audioUri: '',
        },
        stepType: 'durationStep',
        spriteUri: '',
      },
    ],
    loopSteps: false,
    useUniversalSoundRequirements: true,
    sequenceVolumeThreshold: 0.25,
    sequenceDurationThreshold: 0,
    showSoundMeter: true,
  },
  'collage-it-template': {
    background: {
      spriteFile: null,
      spriteUri: '',
    },
    stacks: [
      {
        size: {
          x: 250,
          y: 250,
        },
        position: {
          x: 0,
          y: 0,
        },
        spriteFile: null,
        stamps: [
          {
            size: {
              x: 250,
              y: 250,
            },
            position: {
              x: 0,
              y: 0,
            },
            spriteFile: null,
            selectStampSound: {
              audioFile: null,
              audioUri: '',
            },
            placeStampSound: {
              audioFile: null,
              audioUri: '',
            },
            type: null,
            returnToOriginalPositionOnDropFailure: false,
            pressedSpriteConfig: {
              spriteFile: null,
              spriteUri: '',
            },
            spriteUri: '',
          },
        ],
        maxStamps: 0,
        singleStampStack: false,
        spriteUri: '',
      },
    ],
    canvasObjects: [],
    maxNumStamps: '0',
    tray: null,
  },
};
