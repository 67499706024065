// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.styles_auth__header___1jOd0VAIvC {\n  font-size: 32px;\n  margin: 80px 0 16px;\n  color: #000000;\n}\n\n.styles_auth__subheader___3P3qb_kqrR {\n  font-size: 16px;\n  line-height: 22px;\n  margin: 0 auto 32px;\n  color: rgba(0, 0, 0, 0.6);\n  text-align: center;\n  max-width: 320px;\n}\n\n.styles_footer__text___1ejV3zQkdL {\n  margin-top: 64px;\n  font-size: 18px;\n  font-weight: 500;\n  color: #000000;\n  text-align: center;\n}\n\n.styles_footer__text___1ejV3zQkdL a,\n  .styles_footer__text___1ejV3zQkdL a:visited {\n    text-transform: none;\n    color: #8b18e3;\n  }\n\n.styles_card__cta___1vdp6t7oyk {\n  margin-top: 32px;\n  font-size: 18px;\n  text-align: center;\n}\n\n.styles_card__cta___1vdp6t7oyk a,\n  .styles_card__cta___1vdp6t7oyk a:visited {\n    text-transform: none;\n    color: #8b18e3;\n  }\n\n.styles_invite__container___3JojoqCCHF {\n  display: flex;\n}\n\n.styles_invite__container___3JojoqCCHF img {\n    margin-right: 16px;\n    width: 40px;\n    height: 40px;\n    border: 1px solid rgba(0, 0, 0, 0.1);\n    border-radius: 100px;\n  }\n\n.styles_invite__container___3JojoqCCHF p {\n    color: #000000;\n    font-size: 16px;\n    line-height: 18.45px;\n    font-weight: 500;\n  }\n\n.styles_invite__container___3JojoqCCHF p span {\n      font-weight: 700;\n    }\n", ""]);
// Exports
exports.locals = {
	"auth__header": "styles_auth__header___1jOd0VAIvC",
	"authHeader": "styles_auth__header___1jOd0VAIvC",
	"auth__subheader": "styles_auth__subheader___3P3qb_kqrR",
	"authSubheader": "styles_auth__subheader___3P3qb_kqrR",
	"footer__text": "styles_footer__text___1ejV3zQkdL",
	"footerText": "styles_footer__text___1ejV3zQkdL",
	"card__cta": "styles_card__cta___1vdp6t7oyk",
	"cardCta": "styles_card__cta___1vdp6t7oyk",
	"invite__container": "styles_invite__container___3JojoqCCHF",
	"inviteContainer": "styles_invite__container___3JojoqCCHF"
};
module.exports = exports;
