/* eslint-disable no-alert, react/prop-types */

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTable, useSortBy } from 'react-table';
import { NotificationManager } from 'react-notifications';

import { useUser } from 'context/user-context/UserContext';
import { leaveCreator } from 'services/content/contentService';

import { CMSRedButton } from 'components/button/Button';

import styles from 'components/creator-teams-table/CreatorTeamsTable.css';

const ACCOUNT_STATUS_CODE_NAMES = {
  ADMIN: 'Admin',
  CREATOR: 'Creator',
  REGULAR_USER: 'RegularUser',
  UNKNOWN: 'Unknown',
};

const handleLeaveCreator = async (creatorId, leaveCreator, setLastModified) => {
  if (!window.confirm('Are you sure you want to leave this creator?')) {
    return;
  }

  await leaveCreator(creatorId);
  await setLastModified(new Date());
  NotificationManager.success('Successfully left creator!');
};

const CreatorTeamsTable = ({ creators, setLastModified }) => {
  const { user } = useUser();

  const columns = useMemo(
    () => [
      {
        id: 'title',
        Header: 'Name',
        Cell: ({ row }) => {
          const { avatarUrl, title } = row.original;
          return (
            <div className={styles.creatorInfo}>
              <img src={avatarUrl} alt={title} />
              <div>
                <p className={styles.title}>{title}</p>
              </div>
            </div>
          );
        },
      },
      {
        id: 'role',
        Header: 'Permission',
        Cell: ({ row }) => {
          const { role } = row.original;
          return <span className={styles.permission}>{role}</span>;
        },
      },
      {
        id: 'leave',
        Header: () => null,
        Cell: ({ row }) => {
          const { accountType } = user;
          const { creatorId } = row.original;
          return (
            <div className={styles.leaveAction}>
              {accountType !== ACCOUNT_STATUS_CODE_NAMES.ADMIN && (
                <CMSRedButton
                  label="Leave"
                  onClick={() => handleLeaveCreator(creatorId, leaveCreator, setLastModified)}
                />
              )}
            </div>
          );
        },
      },
    ],
    [],
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: creators,
      initialState: {
        sortBy: [
          {
            id: 'role',
            desc: false,
          },
        ],
      },
    },
    useSortBy,
  );

  return (
    <table {...getTableProps()} className={styles.table}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);

          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

CreatorTeamsTable.propTypes = {
  creators: PropTypes.arrayOf(PropTypes.object).isRequired,
  setLastModified: PropTypes.func.isRequired,
};

export default CreatorTeamsTable;
