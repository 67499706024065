import { Fragment } from 'react';
import PropTypes from 'prop-types';

import styles from 'components/toggle-button-group/ToggleButtonGroup.css';

const ToggleButtonGroup = ({ options, currentValue, onChange }) => (
  <div className={styles.toggleButtonsWrapper} onChange={onChange}>
    {options.map((option) => (
      <Fragment key={option}>
        <input
          key={option}
          id={option}
          type="radio"
          name="toggle-group"
          className={styles.toggleButton}
          value={option}
          defaultChecked={option === currentValue}
        />
        <label htmlFor={option}>{option}</label>
      </Fragment>
    ))}
  </div>
);

ToggleButtonGroup.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default ToggleButtonGroup;
