import PropTypes from 'prop-types';

import { CMSTextButton } from 'components/button/Button';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import PositionInput from 'components/input/PositionInput';
import SizeInput from 'components/input/SizeInput';
import Accordion from 'components/accordion/Accordion';
import DoItForm from 'components/do-it-forms/forms/DoIt';
import BaseForm from 'components/do-it-forms/forms/Base';
import BackgroundAccordion from 'components/do-it-forms/accordions/BackgroundAccordion';
import AudioConfig from 'components/do-it-forms/components/AudioConfig';
import { getDefaultAudioConfig, getDefaultBackground } from 'components/do-it-forms/utils';

import styles from 'components/do-it-forms/forms/DoItForm.css';

const toLatestSequenceItButtonConfig = (sequence) => [{ sequence: [...sequence] }];

const Frame = ({
  value: { size, position, spriteFile, successSound },
  index,
  onChange,
  onDelete,
  showDeleteButton,
}) => (
  <div className={styles.frame}>
    <h6 className={styles.frameHeader}>
      Frame #{index + 1} {showDeleteButton && <CMSTextButton onClick={onDelete} label="(Delete)" />}
    </h6>
    <ImageUploadInput
      label="Frame Image"
      onChange={(value) => onChange('spriteFile', value)}
      value={spriteFile}
    />
    <SizeInput sizeX={size.x} sizeY={size.y} onChange={onChange} />
    <PositionInput positionX={position.x} positionY={position.y} onChange={onChange} />
    <AudioConfig
      label="Sound Effect"
      value={successSound}
      onChange={(audio) => onChange('successSound', audio)}
    />
    <hr className={styles.frameDivider} />
  </div>
);

Frame.propTypes = {
  value: PropTypes.shape({
    size: PropTypes.objectOf(PropTypes.number).isRequired,
    position: PropTypes.objectOf(PropTypes.number).isRequired,
    spriteFile: PropTypes.object,
    successSound: PropTypes.object,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  showDeleteButton: PropTypes.bool.isRequired,
};

class SequenceItForm extends BaseForm {
  getDefaultParams() {
    return {
      background: getDefaultBackground(),
      buttons: [SequenceItForm.getSequenceItButtonConfig()],
    };
  }

  componentDidMount() {
    /* eslint-disable-next-line consistent-return */
    this.setState(({ buttons, sequence }) => {
      if (sequence && !buttons) {
        return {
          buttons: toLatestSequenceItButtonConfig(sequence),
        };
      }
    });
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.doit.itemId === prevState.itemId) {
      return prevState;
    }

    const buttons =
      nextProps.doit?.params?.buttons ||
      toLatestSequenceItButtonConfig(nextProps.doit?.params?.sequence);

    return {
      buttons,
      ...nextProps.doit?.params,
      itemId: nextProps.doit?.itemId,
    };
  }

  static getSequenceItButtonConfig() {
    return {
      sequence: [SequenceItForm.getSequenceItStepConfig()],
    };
  }

  static getSequenceItStepConfig(lastStep) {
    return {
      size: {
        x: lastStep?.size.x ?? 500,
        y: lastStep?.size.y ?? 500,
      },
      position: {
        x: lastStep?.position.x ?? 0,
        y: lastStep?.position.y ?? 0,
      },
      spriteFile: lastStep?.spriteFile ?? null,
      successSound: lastStep?.successSound ?? getDefaultAudioConfig(),
    };
  }

  renderSequenceAccordion({ sequence }, buttonIndex, isLastRemainingButton) {
    const accordionTitle = `Sequence #${buttonIndex + 1}`;
    const onChange = (prop, value) => {
      this.setState(({ buttons }) => {
        const buttonsCopy = [...buttons];
        buttonsCopy[buttonIndex][prop] = value;

        return {
          buttons: buttonsCopy,
        };
      });
    };
    const onDelete = isLastRemainingButton ? null : () => this.handleRemoveSequence(buttonIndex);

    const onChangeFrame = (frameIndex, prop, value) => {
      const sequenceCopy = [...sequence];
      sequenceCopy[frameIndex][prop] = value;
      onChange('sequence', sequenceCopy);
    };
    const onDeleteFrame = (frameIndex) => {
      onChange(
        'sequence',
        [...sequence].filter((_, index) => index !== frameIndex),
      );
    };
    const onAddFrame = () => {
      onChange('sequence', [
        ...sequence,
        SequenceItForm.getSequenceItStepConfig(sequence[sequence.length - 1]),
      ]);
    };

    return (
      <Accordion title={accordionTitle} key={accordionTitle} onDelete={onDelete}>
        {sequence.map((item, index, sequenceArr) => {
          const isLastRemainingFrame = sequenceArr.length === 1;
          return (
            <Frame
              value={item}
              index={index}
              onChange={(prop, value) => onChangeFrame(index, prop, value)}
              onDelete={() => onDeleteFrame(index)}
              showDeleteButton={!isLastRemainingFrame}
            />
          );
        })}
        <CMSTextButton label="Add Another Frame" onClick={onAddFrame} />
      </Accordion>
    );
  }

  handleAddSequence() {
    this.setState(({ buttons }) => ({
      buttons: [...buttons, SequenceItForm.getSequenceItButtonConfig()],
    }));
  }

  handleRemoveSequence(formIndex) {
    this.setState(({ buttons }) => ({
      buttons: buttons.filter((_, i) => i !== formIndex),
    }));
  }

  getDoitParams() {
    return {
      ...this.state,
      // hacky way to allow the first object in a multi-object sequence it to be
      // playable on clients w/ older Unity builds that don't have multi-object support
      sequence: this.state?.buttons?.[0]?.sequence ?? [SequenceItForm.getSequenceItStepConfig()],
    };
  }

  render() {
    const { onClose, onDelete, doit, subsequentDoitId } = this.props;
    const { buttons, background } = this.state;
    const sequenceAccordionElements = buttons?.map((item, i, buttonsArr) => {
      const hasOnlyOneButton = buttonsArr.length === 1;
      return this.renderSequenceAccordion(item, i, hasOnlyOneButton);
    });

    return (
      <DoItForm
        title="Sequence It"
        doit={doit}
        onClose={onClose}
        onSave={(options) => this.handleSave(options)}
        onDelete={onDelete}
        subsequentDoitId={subsequentDoitId}
      >
        <BackgroundAccordion
          value={background}
          onChange={(key, value) => this.setState({ [key]: value })}
        />
        {sequenceAccordionElements}
        <CMSTextButton label="Add Another Sequence" onClick={() => this.handleAddSequence()} />
      </DoItForm>
    );
  }
}

export default SequenceItForm;
