// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.styles_loading___3dQfeBykfj {\n  color: rgba(0, 0, 0, 0.6) !important;\n  text-align: center !important;\n}\n", ""]);
// Exports
exports.locals = {
	"loading": "styles_loading___3dQfeBykfj"
};
module.exports = exports;
