import { useState, useEffect } from 'react';

import { getFeaturedStories, upsertFeaturedStories } from 'services/content/adminService';

import { BACKGROUND_COLORS } from 'components/input/ColorRadioGroup';
import { EpisodesStat } from 'components/collection-stat/CollectionStat';
import { CMSWhiteButton } from 'components/button/Button';
import FeaturedStoriesTable from 'components/table/FeaturedStoriesTable';
import FormModal from 'components/modal/FormModal';
import FeaturedStoryAppearanceForm from 'components/forms/FeaturedStoryAppearanceForm';
import { MultiCreatorEpisodeSelectionModal } from 'components/modal/EpisodeSelectionModal';
import Loading from 'components/loading/Loading';

import styles from 'pages/content/styles.css';

const RESET_INDEX = -1;
const NEW_STORY_INDEX = 0;
const METADATA_DEFAULTS = {
  showStoryTitle: false,
  showSeriesThumbnail: false,
  backgroundColor: BACKGROUND_COLORS.PURPLE.name,
  order: 0,
};

const formatForRequest = (stories) =>
  stories.map(({ episode: { episodeId }, metadata }, index) => ({
    episodeId,
    ...metadata,
    order: index + 1,
  }));

const FeaturedStoriesPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [featuredStories, setFeaturedStories] = useState([]);
  const [isAddStoryModalOpen, setIsAddStoryModalOpen] = useState(false);
  const [isAppearanceModalOpen, setIsAppearanceModalOpen] = useState(false);
  const [activeStoryIndex, setActiveStoryIndex] = useState(RESET_INDEX);

  useEffect(() => {
    const fetchFeaturedStories = async () => {
      const featuredStories = await getFeaturedStories();
      setFeaturedStories(featuredStories);
      setIsLoading(false);
    };
    fetchFeaturedStories();
  }, []);

  useEffect(() => {
    if (activeStoryIndex !== RESET_INDEX) {
      setIsAddStoryModalOpen(false);
      setIsAppearanceModalOpen(true);
    }
  }, [activeStoryIndex]);

  const addNewStory = async ([episodeId]) => {
    const newStory = { episodeId, ...METADATA_DEFAULTS };
    const updatedStories = await upsertFeaturedStories([
      newStory,
      ...formatForRequest(featuredStories),
    ]);
    setFeaturedStories(updatedStories);
    setActiveStoryIndex(NEW_STORY_INDEX);
  };

  const updateStory = async (story) => {
    const featuredStoriesCopy = [...featuredStories];
    featuredStoriesCopy.splice(activeStoryIndex, 1, story);

    const updatedStories = await upsertFeaturedStories(formatForRequest(featuredStoriesCopy));
    setFeaturedStories(updatedStories);
    setActiveStoryIndex(RESET_INDEX);
  };

  const openAppearanceModal = (selectedStoryIndex) => {
    setActiveStoryIndex(selectedStoryIndex);
    setIsAppearanceModalOpen(true);
  };

  return (
    <div className={styles.pageContainer}>
      <section>
        <div className={styles.panelHeader}>
          <h2 className={styles.title}>Stories of the Week</h2>
          <div className={styles.metaContainer}>
            <div className={styles.stats}>
              <EpisodesStat num={featuredStories?.length || 0} />
            </div>
            <CMSWhiteButton label="+ Add Story" onClick={() => setIsAddStoryModalOpen(true)} />
          </div>
        </div>

        <Loading isLoading={isLoading}>
          <FeaturedStoriesTable
            stories={featuredStories}
            updateStories={setFeaturedStories}
            openAppearanceModal={openAppearanceModal}
          />
        </Loading>
      </section>

      <MultiCreatorEpisodeSelectionModal
        limit={1}
        collectionEpisodes={[]}
        isOpen={isAddStoryModalOpen}
        onClose={() => {
          setIsAddStoryModalOpen(false);
          setActiveStoryIndex(RESET_INDEX);
        }}
        onSubmit={addNewStory}
      />

      <FormModal
        formNode={FeaturedStoryAppearanceForm}
        title="Featured Story Appearance"
        contentLabel="Featured Story Appearance"
        primaryFooterBtnLabel="Save"
        formProps={{ story: featuredStories[activeStoryIndex], onSave: updateStory }}
        isOpen={isAppearanceModalOpen}
        onRequestClose={() => setIsAppearanceModalOpen(false)}
        style={{
          content: {
            width: '100%',
            maxWidth: '700px',
          },
        }}
      />
    </div>
  );
};

export default FeaturedStoriesPage;
