import cx from 'classnames';
import PropTypes from 'prop-types';

import { pluralize } from 'utils';

import { CollectionsIcon, EpisodeIcon, BundleIcon, CreatorIcon } from 'components/Icon';

import styles from 'components/collection-stat/CollectionStat.css';

const CollectionStat = ({ icon, stat, color }) => (
  <div className={styles.statWrapper}>
    {icon}
    <p style={{ color }}>{stat}</p>
  </div>
);

CollectionStat.defaultProps = {
  color: 'rgba(0,0,0,0.6)',
};

CollectionStat.propTypes = {
  icon: PropTypes.node.isRequired,
  stat: PropTypes.string.isRequired,
  color: PropTypes.string,
};

export const BundlesStat = ({ num, color, iconFill }) => (
  <CollectionStat
    icon={<BundleIcon fill={iconFill} />}
    stat={pluralize(num, 'Bundle', 'Bundles')}
    color={color}
  />
);

BundlesStat.defaultProps = {
  color: 'rgba(0,0,0,0.6)',
  iconFill: 'rgba(0,0,0,0.4)',
};

BundlesStat.propTypes = {
  num: PropTypes.number.isRequired,
  color: PropTypes.string,
  iconFill: PropTypes.string,
};

export const CollectionsStat = ({ num, color, iconFill, label }) => (
  <CollectionStat
    icon={<CollectionsIcon fill={iconFill} />}
    stat={pluralize(num, label.singular, label.plural)}
    color={color}
  />
);

CollectionsStat.defaultProps = {
  color: 'rgba(0,0,0,0.6)',
  iconFill: 'rgba(0,0,0,0.4)',
  label: { singular: 'Collection', plural: 'Collections' },
};

CollectionsStat.propTypes = {
  num: PropTypes.number.isRequired,
  color: PropTypes.string,
  iconFill: PropTypes.string,
  label: PropTypes.shape({ singular: PropTypes.string, plural: PropTypes.string }),
};

export const EpisodesStat = ({ num, color, iconFill }) => (
  <CollectionStat
    icon={<EpisodeIcon fill={iconFill} />}
    stat={pluralize(num, 'Story', 'Stories')}
    color={color}
  />
);

EpisodesStat.defaultProps = {
  color: 'rgba(0,0,0,0.6)',
  iconFill: 'rgba(0,0,0,0.4)',
};

EpisodesStat.propTypes = {
  num: PropTypes.number.isRequired,
  color: PropTypes.string,
  iconFill: PropTypes.string,
};

export const CreatorsStat = ({ creators, max, color }) => {
  const numCreators = creators.length;

  if (numCreators === 0) {
    return <CollectionStat icon={<CreatorIcon />} stat="0 Creators" color={color} />;
  }

  return (
    <div className={cx(styles.statWrapper, styles.creatorsStat)}>
      <div className={styles.avatarGroup}>
        {creators.slice(0, max).map(({ creatorId, avatarUrl, title }, index) => (
          <img
            key={creatorId}
            src={avatarUrl}
            alt={title}
            className={styles.avatar}
            style={{
              zIndex: index,
              marginLeft: index !== numCreators - 1 && '-10px',
            }}
          />
        ))}
      </div>
      <p style={{ color }}>{pluralize(numCreators, 'Creator', 'Creators')}</p>
    </div>
  );
};

CreatorsStat.defaultProps = {
  color: 'white',
  max: 3,
};

CreatorsStat.propTypes = {
  creators: PropTypes.arrayOf(PropTypes.object).isRequired,
  max: PropTypes.number,
  color: PropTypes.string,
};
