import PropTypes from 'prop-types';
import Accordion from 'components/accordion/Accordion';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import CheckboxInput from 'components/input/CheckboxInput';
import ColorInput, { UNITY_TRANSPARENT_BG_COLOR } from 'components/input/ColorInput';

export const InlineBackgroundAccordion = ({ onChange, value, stateKey, forKey }) => (
  <>
    <CheckboxInput
      label="Use Transparent Background"
      description="All objects will be overlayed on top of video."
      forKey={`${forKey} transparent background`}
      onChange={(inputValue) =>
        onChange(stateKey, {
          ...value,
          transparent: inputValue,
          color: inputValue ? UNITY_TRANSPARENT_BG_COLOR : value?.lastColor,
          lastColor: value?.color,
          spriteFile: inputValue ? null : value?.lastSpriteFile,
          lastSpriteFile: value?.spriteFile,
        })
      }
      value={value?.transparent}
    />

    {!value?.transparent && (
      <>
        <ImageUploadInput
          label="Background Image"
          value={value?.spriteFile}
          onChange={(inputValue) => onChange(stateKey, { ...value, spriteFile: inputValue })}
        />
        <ColorInput
          label="Background Color"
          onChange={(inputValue) => onChange(stateKey, { ...value, color: inputValue })}
          value={value?.color}
        />
      </>
    )}
  </>
);

const BackgroundAccordion = ({ onChange, value, stateKey, title, forKey }) => (
  <Accordion title={title}>
    <InlineBackgroundAccordion
      onChange={onChange}
      value={value}
      stateKey={stateKey}
      forKey={forKey}
    />
  </Accordion>
);

BackgroundAccordion.defaultProps = {
  stateKey: 'background',
  title: 'Background',
  forKey: null,
};

BackgroundAccordion.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  stateKey: PropTypes.string,
  title: PropTypes.string,
  forKey: PropTypes.string,
};

InlineBackgroundAccordion.defaultProps = {
  stateKey: 'background',
  forKey: null,
};

InlineBackgroundAccordion.propTypes = {
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  stateKey: PropTypes.string,
  forKey: PropTypes.string,
};

export default BackgroundAccordion;
