import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';

import request from 'services/request/requestService';
import { CMSButton } from 'components/button/Button';
import Loading from 'components/loading/Loading';

import styles from 'pages/content/styles.css';

const EarningsTab = ({ creatorId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isVerified, setIsVerified] = useState(false);
  const [payouts, setPayouts] = useState([]);
  const [totalOwed, setTotalOwed] = useState(null);
  const [totalPaid, setTotalPaid] = useState(null);
  const [accountLink, setAccountLink] = useState(null);

  const handlePayoutsResponse = (payoutsResponse) => {
    setPayouts(payoutsResponse.payouts);
    setTotalOwed(payoutsResponse.totalOwed);
    setTotalPaid(payoutsResponse.totalPaid);
    setIsVerified(payoutsResponse.isVerified);
    setAccountLink(payoutsResponse.accountLink);
    setIsLoading(false);
    if (payoutsResponse.message) {
      NotificationManager.success(payoutsResponse.message);
    }
  };

  useEffect(() => {
    const getPayouts = async () => {
      const payoutsResponse = await request(`/payouts/${creatorId}`);
      handlePayoutsResponse(payoutsResponse);
    };
    getPayouts();
  }, []);

  const PayoutCTA = () => {
    if (isVerified) {
      return (
        <>
          <CMSButton
            onClick={async () => {
              setIsLoading(true);
              try {
                const payoutsResponse = await request(`/payouts/${creatorId}/request`, {
                  method: 'POST',
                });
                handlePayoutsResponse(payoutsResponse);
              } finally {
                setIsLoading(false);
              }
            }}
            isDisabled={totalOwed <= 0}
            label="Request Payout"
          />
          {totalOwed <= 0 && (
            <>
              <br />
              <p>You can request a payout once you have owed earnings.</p>
            </>
          )}
        </>
      );
    }

    return (
      <CMSButton
        onClick={() => {
          window.location.href = accountLink;
        }}
        label="Configure Payment Details"
      />
    );
  };

  return (
    <Loading isLoading={isLoading}>
      <div className={styles.earningsTab}>
        {payouts.length > 0 && (
          <table>
            <tr>
              <th>Amount</th>
              <th>Status</th>
              <th>Description</th>
            </tr>
            {payouts.map(({ reason, amount, isPaid }) => (
              <tr>
                <td>
                  {(amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                </td>
                <td>{isPaid ? 'Paid' : 'Pending'}</td>
                <td>{reason}</td>
              </tr>
            ))}
          </table>
        )}

        <div className={styles.summaryContainer}>
          {payouts.length === 0 && (
            <>
              <h2>Your earnings will appear here.</h2>
              <p>
                Your earnings are tabulated on a quarterly basis and will appear here when ready.
                Keep creating and sharing Hellosaurus content to earn more!
              </p>
            </>
          )}
          <div className={styles.amounts}>
            <h3 className={styles.totalPaid}>
              {Math.floor(totalPaid / 100)}
              <span className={styles.cents}>{(totalPaid % 100).toString().padEnd(2, '0')}</span>
              <span className={styles.title}>Total Paid</span>
            </h3>
            <h3 className={styles.totalOwed}>
              {Math.floor(totalOwed / 100)}
              <span className={styles.cents}>{(totalOwed % 100).toString().padEnd(2, '0')}</span>
              <span className={styles.title}>Total Owed</span>
            </h3>
          </div>
          <div>
            <PayoutCTA />
          </div>
        </div>
      </div>
    </Loading>
  );
};

EarningsTab.propTypes = {
  creatorId: PropTypes.string.isRequired,
};

export default EarningsTab;
