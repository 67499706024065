import DoItForm from 'components/do-it-forms/forms/DoIt';
import BaseForm from 'components/do-it-forms/forms/Base';
import BackgroundAccordion from 'components/do-it-forms/accordions/BackgroundAccordion';
import CanvasObjects from 'components/do-it-forms/components/CanvasObjects';
import CameraDisplay from 'components/do-it-forms/components/CameraDisplay';
import {
  getDefaultBackground,
  getDefaultCanvasObject,
  getDefaultCameraDisplay,
} from 'components/do-it-forms/utils';

class CaptureItForm extends BaseForm {
  getDefaultParams() {
    return {
      background: getDefaultBackground(),
      cameraDisplay: getDefaultCameraDisplay(),
      placeholderImage: null,
      backgroundObjects: [],
      foregroundObjects: [],
    };
  }

  getDoitParams() {
    return this.state;
  }

  render() {
    const { onClose, onDelete, doit, subsequentDoitId } = this.props;
    const { background, backgroundObjects, foregroundObjects, cameraDisplay, placeholderImage } =
      this.state;

    return (
      <DoItForm
        title="Capture It"
        doit={doit}
        onClose={onClose}
        onSave={(options) => this.handleSave(options)}
        onDelete={onDelete}
        subsequentDoitId={subsequentDoitId}
      >
        <BackgroundAccordion
          value={background}
          onChange={(key, value) => this.setState({ [key]: value })}
        />
        <CameraDisplay
          cameraDisplay={cameraDisplay}
          onChange={(key, value) =>
            this.setState({ cameraDisplay: { ...cameraDisplay, [key]: value } })
          }
          placeholderImage={placeholderImage}
          onPlaceholderImageChange={(value) => this.setState({ placeholderImage: value })}
        />
        <CanvasObjects
          canvasObjects={foregroundObjects || []}
          getDefaultCanvasObject={getDefaultCanvasObject}
          setState={(value) => this.setState(value)}
          keyName="foregroundObjects"
        />
        <CanvasObjects
          canvasObjects={backgroundObjects || []}
          getDefaultCanvasObject={getDefaultCanvasObject}
          setState={(value) => this.setState(value)}
          objectName="Background Object"
          keyName="backgroundObjects"
        />
      </DoItForm>
    );
  }
}

export default CaptureItForm;
