import { useState } from 'react';
import PropTypes from 'prop-types';
import { FullHeightModal } from 'components/modal/Modal';
import { getPreviouslyCreatedDoits } from 'services/doit/doitService';
import PaginatedList from 'components/paginated-list/PaginatedList';
import MediaRow from 'components/media-row/MediaRow';
import { CMSButton, CMSWhiteButton } from 'components/button/Button';

const DoItLibrary = ({ onSelection, creatorId }) => {
  const [isOpen, setOpen] = useState(false);
  const [activeDoIt, setActiveDoIt] = useState({});

  const footerButtons = [
    <CMSWhiteButton label="Cancel" onClick={() => setOpen(false)} />,
    <CMSButton
      label="Copy to Story"
      isDisabled={!activeDoIt?.itemId}
      onClick={() => {
        onSelection(activeDoIt);
        setOpen(false);
      }}
    />,
  ];

  const ListComponent = ({ author, createdFormatted, thumbnailUrl, doit }) => {
    const title = doit?.name || doit.baseTemplateId;
    const description = `${author} (${createdFormatted})`;

    return (
      <MediaRow
        imageUrl={thumbnailUrl}
        title={title}
        description={description}
        selected={doit.itemId === activeDoIt.itemId}
      />
    );
  };

  ListComponent.propTypes = {
    author: PropTypes.string.isRequired,
    createdFormatted: PropTypes.string.isRequired,
    thumbnailUrl: PropTypes.string.isRequired,
    doit: PropTypes.object.isRequired,
  };

  return (
    <>
      <CMSButton label="View Do It Library" onClick={() => setOpen(true)} />
      <FullHeightModal
        title="Do It Library"
        isOpen={isOpen}
        onRequestClose={() => setOpen(false)}
        footerButtons={footerButtons}
      >
        <PaginatedList
          twoColumnLayout
          ListComponent={ListComponent}
          loadFunction={(url) => getPreviouslyCreatedDoits(url)}
          initialUrl={`/creators/${creatorId}/doits`}
          getIdFromItem={(row) => row.doit.itemId}
          onClick={(row) => setActiveDoIt(row.doit)}
          activeItemId={activeDoIt.itemId}
        />
      </FullHeightModal>
    </>
  );
};

DoItLibrary.propTypes = {
  onSelection: PropTypes.func.isRequired,
  creatorId: PropTypes.string.isRequired,
};

export default DoItLibrary;
