import { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { PlayIcon, StopIcon } from 'components/Icon';
import {
  ACCEPTED_IMAGE_MIME_TYPES,
  ACCEPTED_AUDIO_MIME_TYPES,
  ACCEPTED_VIDEO_MIME_TYPES,
} from 'components/file-upload/constants';

import styles from 'components/asset-preview/AssetPreview.css';

const ImagePreview = ({ src, alt }) => (
  <div className={styles.uploadImage}>
    <img src={src} alt={alt} />
  </div>
);

ImagePreview.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

const VideoPreview = ({ src, alt }) => (
  <div className={styles.uploadVideo}>
    <video src={src} alt={alt} />
  </div>
);

VideoPreview.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

const AudioPreview = ({ src, mimeType }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  async function playAudio() {
    await audioRef.current.load();
    audioRef.current.play();
    setIsPlaying(true);
  }

  function stopAudio() {
    audioRef.current.pause();
    audioRef.current.currentTime = 0;
    setIsPlaying(false);
  }

  async function toggleAudio() {
    if (isPlaying) {
      stopAudio();
      return;
    }

    await playAudio();
  }

  return (
    <div
      className={styles.uploadAudio}
      onClick={async (e) => {
        e.stopPropagation(); // don't open the upload modal
        await toggleAudio();
      }}
    >
      <audio src={src} type={mimeType} ref={audioRef} onEnded={() => setIsPlaying(false)} />
      {isPlaying ? <StopIcon /> : <PlayIcon />}
    </div>
  );
};

AudioPreview.propTypes = {
  src: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
};

const AssetPreview = (props) => {
  const { mimeType } = props;

  if (ACCEPTED_IMAGE_MIME_TYPES.includes(mimeType)) {
    return <ImagePreview {...props} />;
  }

  if (ACCEPTED_AUDIO_MIME_TYPES.includes(mimeType)) {
    return <AudioPreview {...props} />;
  }

  if (ACCEPTED_VIDEO_MIME_TYPES.includes(mimeType)) {
    return <VideoPreview {...props} />;
  }

  return <p>Oops! An error occurred: we cannot currently preview that asset type.</p>;
};

AssetPreview.propTypes = {
  src: PropTypes.string.isRequired,
  mimeType: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

AssetPreview.defaultProps = {
  alt: '',
};

export default AssetPreview;
