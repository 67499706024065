/* eslint-disable no-alert, react/prop-types */

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import { pluralize } from 'utils';
import Table, { TableFilters } from 'components/table/Table';

import styles from 'components/table/Table.css';

const BundlesTable = ({ bundles }) => {
  const history = useHistory();

  const data = useMemo(() => bundles, [bundles]);
  const columns = useMemo(
    () => [
      {
        id: 'title',
        accessor: (row) => row.bundle.title,
        Header: 'Title',
        Cell: ({ row }) => {
          const { title } = row.original.bundle;
          return <p className={styles.title}>{title}</p>;
        },
        filter: TableFilters.FUZZY_TEXT,
      },
      {
        id: 'type',
        accessor: (row) => row.bundle.type,
        Header: 'Bundle Type',
        Cell: ({ row }) => {
          const { type } = row.original.bundle;
          const BundleTypesCopy = {
            COLLECTION_BUNDLE: 'Collection',
            CREATOR_BUNDLE: 'Creator',
            MIXED_BUNDLE: 'Mixed',
            SERIES_BUNDLE: 'Library - Series tab',
            TOPIC_BUNDLE: 'Homescreen - Topics footer module',
          };

          return <p className={styles.text}>{BundleTypesCopy[type]}</p>;
        },
        filter: TableFilters.FUZZY_TEXT,
      },
      {
        id: 'Status',
        accessor: (row) => String(row.bundle.isPublished),
        Header: 'Status',
        Cell: ({ row }) => {
          const { isPublished } = row.original.bundle;
          return <p className={styles.text}>{isPublished ? 'Active' : 'Inactive'}</p>;
        },
        filter: TableFilters.PUBLISHED,
      },
      {
        id: 'creators',
        accessor: (row) => row.creators?.length || 0,
        Header: 'Creators',
        Cell: ({ row }) => {
          const { creators } = row.original;

          return (
            <p className={styles.text}>
              {creators ? pluralize(creators.length, 'Creator', 'Creators') : '--'}
            </p>
          );
        },
        filter: TableFilters.FUZZY_TEXT,
      },
      {
        id: 'collections',
        accessor: (row) => row.collections?.length || 0,
        Header: 'Collections',
        Cell: ({ row }) => {
          const { collections } = row.original;

          return (
            <p className={styles.text}>
              {collections ? pluralize(collections.length, 'Collection', 'Collections') : '--'}
            </p>
          );
        },
        filter: TableFilters.FUZZY_TEXT,
      },
    ],
    [],
  );

  const onRowClick = ({ bundle: { bundleId } }) => history.push(`/admin/bundles/${bundleId}`);

  return (
    <Table
      entityKey="bundle"
      datumIdAccessor="bundle.bundleId"
      data={data}
      columns={columns}
      onRowClick={onRowClick}
    />
  );
};

BundlesTable.propTypes = {
  bundles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default BundlesTable;
