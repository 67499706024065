/* eslint-disable no-alert, react/prop-types */

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NotificationManager } from 'react-notifications';

import * as adminService from 'services/content/adminService';
import { getEpisodeStatus, pluralize, getBackgroundImageGradientStyles, noop } from 'utils';

import Table from 'components/table/Table';
import { CMSWhiteButton, CMSRedButton } from 'components/button/Button';

import styles from 'components/table/Table.css';

const formatForRequest = (stories) =>
  stories.map(({ episode: { episodeId }, metadata }, index) => ({
    episodeId,
    ...metadata,
    order: index,
  }));

const FeaturedStoriesTable = ({ stories, updateStories, openAppearanceModal }) => {
  const handleRemove = async (episodeId) => {
    if (!window.confirm('Are you sure you want to remove that story?')) {
      return;
    }

    const filteredStories = stories.filter(({ episode }) => episode.episodeId !== episodeId);

    const updatedStories = await adminService.upsertFeaturedStories(
      formatForRequest(filteredStories),
    );

    updateStories(updatedStories);
    NotificationManager.success('Successfully removed story.');
  };

  const handleReorder = async (items) => {
    updateStories(items);
    const stories = await adminService.upsertFeaturedStories(formatForRequest(items));
    updateStories(stories);
    NotificationManager.success('Successfully reordered stories.');
  };

  const columns = useMemo(
    () => [
      {
        id: 'thumbnailUrl',
        Header: () => null,
        Cell: ({
          row: {
            original: { episode, metadata },
          },
        }) => {
          const { thumbnailUrl: storyThumbnailUrl } = episode;
          const { extendedName, logoUrl, thumbnailUrl: seriesThumbnailUrl } = episode.series ?? {};
          const { showSeriesThumbnail, backgroundColor } = metadata;

          const displayedThumbnail = showSeriesThumbnail ? seriesThumbnailUrl : storyThumbnailUrl;

          const bgImageStyle = getBackgroundImageGradientStyles({
            backgroundImageUrl: displayedThumbnail,
            backgroundColor,
          });

          return (
            <div
              className={cx(styles.thumbnail, styles.vertical)}
              style={{ backgroundImage: `${bgImageStyle}` }}
            >
              <img src={logoUrl} alt={extendedName} />
            </div>
          );
        },
        style: { width: '86px' },
      },
      {
        accessor: 'title',
        Header: 'Title',
        Cell: ({
          row: {
            original: {
              episode: { title, series },
            },
          },
        }) => (
          <div>
            <p className={styles.title}>{title}</p>
            <p className={cx(styles.text, styles.light)}>{series?.extendedName ?? 'No Series'}</p>
          </div>
        ),
      },
      {
        accessor: 'durationLong',
        Header: 'Length',
        Cell: ({
          row: {
            original: {
              episode: { durationLong },
            },
          },
        }) => <p className={styles.text}>{durationLong}</p>,
      },
      {
        accessor: 'availability',
        Header: 'Status',
        Cell: ({
          row: {
            original: {
              episode: { availability, releaseDateFormatted },
            },
          },
        }) => (
          <div>
            <p className={styles.text}>{getEpisodeStatus(availability)}</p>
            <p className={cx(styles.text, styles.light)}>{releaseDateFormatted}</p>
          </div>
        ),
      },
      {
        accessor: 'numActivities',
        Header: 'Activities',
        Cell: ({
          row: {
            original: {
              episode: {
                attributes: { numActivities },
              },
            },
          },
        }) => <p className={styles.text}>{pluralize(numActivities, 'Activity', 'Activities')}</p>,
      },
      {
        accessor: 'topics',
        Header: 'Topics Covered',
        Cell: ({
          row: {
            original: {
              episode: {
                tags: { topics },
              },
            },
          },
        }) => <p className={styles.text}>{topics?.map(({ label }) => label)?.join(', ')}</p>,
      },
    ],
    [],
  );

  const HoverActions = ({ episode }, index) => (
    <>
      <CMSWhiteButton label="Edit Appearance" onClick={() => openAppearanceModal(index)} />
      <CMSRedButton label="Remove" onClick={() => handleRemove(episode.episodeId)} />
    </>
  );

  return (
    <Table
      data={stories}
      columns={columns}
      datumIdAccessor="episode.episodeId"
      hideFilters
      hoverActions={HoverActions}
      onReorder={handleReorder}
    />
  );
};

FeaturedStoriesTable.defaultProps = {
  stories: [],
  updateStories: noop,
};

FeaturedStoriesTable.propTypes = {
  stories: PropTypes.arrayOf(PropTypes.object),
  updateStories: PropTypes.func,
};

export default FeaturedStoriesTable;
