import { getDefaultAudioConfig, getDefaultBackground } from 'components/do-it-forms/utils';
import BaseForm from 'components/do-it-forms/forms/Base';
import DoItForm from 'components/do-it-forms/forms/DoIt';
import AudioConfig from 'components/do-it-forms/components/AudioConfig';

import { CMSTextButton } from 'components/button/Button';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';

import Accordion from 'components/accordion/Accordion';
import AccordionSection from 'components/accordion/AccordionSection';
import BackgroundAccordion from 'components/do-it-forms/accordions/BackgroundAccordion';

import { CMSWhiteInput } from 'components/input/Input';
import { INPUT_TYPES } from 'components/input/constants';
import RangeInput from 'components/input/RangeInput';
import SizeInput from 'components/input/SizeInput';
import CheckboxInput from 'components/input/CheckboxInput';
import PositionInput from 'components/input/PositionInput';

const STEP_TYPES = {
  MIC_STEP: 'micStep',
  DURATION_STEP: 'durationStep',
};

class SayItForm extends BaseForm {
  getDefaultParams() {
    return {
      background: getDefaultBackground(),

      /**
       * A sequence is [n >= 1] number of mic steps, followed by exactly 1 duration step. There
       * should always be at least two steps in a sequence (one mic step, and one duration step).
       * Those two steps essentially represent the starting and ending states of the sequence.
       * Any/all additional steps are, effectively, "in-between" steps.
       * Source: https://hellosaurus.slack.com/archives/C012GNW4BT3/p1627313821003200?thread_ts=1627312913.001400&cid=C012GNW4BT3
       */
      sequence: [SayItForm.getSayItMicStepConfig(), SayItForm.getSayItDurationStepConfig()],

      loopSteps: false,
      useUniversalSoundRequirements: true,
      sequenceVolumeThreshold: 0.25,
      sequenceDurationThreshold: 0,
      showSoundMeter: false,
    };
  }

  static getSayItStepConfig(lastStep) {
    return {
      size: {
        x: lastStep?.size.x ?? 500,
        y: lastStep?.size.y ?? 500,
      },
      position: {
        x: lastStep?.position.x ?? 0,
        y: lastStep?.position.y ?? 0,
      },
      spriteFile: lastStep?.spriteFile ?? null,
      successSound: lastStep?.successSound ?? getDefaultAudioConfig(),
    };
  }

  static getSayItMicStepConfig(lastStep) {
    return {
      ...SayItForm.getSayItStepConfig(lastStep),
      stepType: STEP_TYPES.MIC_STEP,
      stepVolumeThreshold: lastStep?.stepVolumeThreshold ?? 0.25,
      stepDurationThreshold: lastStep?.stepDurationThreshold ?? 0,
    };
  }

  static getSayItDurationStepConfig() {
    return {
      ...SayItForm.getSayItStepConfig(),
      stepType: STEP_TYPES.DURATION_STEP,
    };
  }

  renderSequenceAccordion(item, i) {
    const { useUniversalSoundRequirements } = this.state;
    const { size, position, spriteFile, successSound, stepVolumeThreshold, stepDurationThreshold } =
      item;

    const accordionTitle = `Flow Asset #${i + 1}`;
    const isLastSequenceStep = this.state.sequence.length - 1 === i;

    const onChange = (prop, value) => {
      this.setState(({ sequence }) => {
        const sequenceCopy = [...sequence];
        sequenceCopy[i][prop] = value;

        return {
          sequence: sequenceCopy,
        };
      });
    };
    const onDelete = i > 0 && !isLastSequenceStep ? () => this.handleRemoveSequence(i) : null;

    return (
      <Accordion title={accordionTitle} key={accordionTitle} onDelete={onDelete}>
        <ImageUploadInput
          label="Image Asset"
          onChange={(value) => onChange('spriteFile', value)}
          value={spriteFile}
        />
        <br />
        <SizeInput sizeX={size.x} sizeY={size.y} onChange={onChange} />
        <PositionInput positionX={position.x} positionY={position.y} onChange={onChange} />

        <br />
        <AccordionSection title="Success Rules">
          <AudioConfig
            label="Success State Sound Effect"
            value={successSound}
            onChange={(audio) => onChange('successSound', audio)}
          />
        </AccordionSection>

        {!useUniversalSoundRequirements && !isLastSequenceStep && (
          <>
            <RangeInput
              label="Volume Threshold"
              value={stepVolumeThreshold}
              onChange={(value) => onChange('stepVolumeThreshold', value)}
            />
            <br />
            <CMSWhiteInput
              type={INPUT_TYPES.NUMBER}
              label="Threshold Duration (seconds)"
              value={stepDurationThreshold}
              onChange={(value) => onChange('stepDurationThreshold', value)}
              debounced
            />
          </>
        )}
      </Accordion>
    );
  }

  handleAddSequence() {
    this.setState(({ sequence }) => {
      const sequenceCopy = [...sequence];
      const secondToLastIndex = sequence.length - 2;
      const newSequenceStep = SayItForm.getSayItMicStepConfig(sequence[secondToLastIndex]);
      sequenceCopy.splice(secondToLastIndex, 0, newSequenceStep);

      return {
        sequence: sequenceCopy,
      };
    });
  }

  handleRemoveSequence(formIndex) {
    this.setState(({ sequence }) => ({
      sequence: sequence.filter((_, i) => i !== formIndex),
    }));
  }

  getDoitParams() {
    return this.state;
  }

  render() {
    const { onClose, onDelete, doit, subsequentDoitId } = this.props;
    const {
      sequence,
      background,
      loopSteps,
      useUniversalSoundRequirements,
      sequenceVolumeThreshold,
      sequenceDurationThreshold,
    } = this.state;
    const sequenceAccordionElements = sequence.map((item, i) =>
      this.renderSequenceAccordion(item, i),
    );

    return (
      <DoItForm
        title="Say It"
        doit={doit}
        onClose={onClose}
        onSave={(options) => this.handleSave(options)}
        onDelete={onDelete}
        subsequentDoitId={subsequentDoitId}
      >
        <BackgroundAccordion
          value={background}
          onChange={(key, value) => this.setState({ [key]: value })}
        />
        <Accordion title="Flow" key="flow">
          <AccordionSection
            title="Flow Settings"
            description="General settings for the Say It Sequence"
          >
            <CheckboxInput
              label="Loop Flow"
              description="If toggled on this Flow will return back to its original state once completed."
              forKey="loopSteps"
              value={loopSteps}
              onChange={(value) => this.onChange('loopSteps', value)}
            />
            <CheckboxInput
              label="Set Global Threshold"
              description="If toggled on this Flow will use the same volume and duration requirements for all steps."
              forKey="useUniversalSoundRequirements"
              value={useUniversalSoundRequirements}
              onChange={(value) => this.onChange('useUniversalSoundRequirements', value)}
            />

            {useUniversalSoundRequirements && (
              <>
                <br />
                <RangeInput
                  label="Global Volume Threshold"
                  value={sequenceVolumeThreshold}
                  onChange={(value) => this.onChange('sequenceVolumeThreshold', value)}
                />
                <br />
                <CMSWhiteInput
                  type={INPUT_TYPES.NUMBER}
                  label="Threshold Duration (seconds)"
                  value={sequenceDurationThreshold}
                  onChange={(value) => this.onChange('sequenceDurationThreshold', value)}
                  debounced
                />
              </>
            )}
          </AccordionSection>

          {sequenceAccordionElements}
        </Accordion>
        <CMSTextButton label="Add Another Asset" onClick={() => this.handleAddSequence()} />
      </DoItForm>
    );
  }
}

export default SayItForm;
