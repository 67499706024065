/* eslint-disable no-alert, react/prop-types */

import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTable, useSortBy, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table';
import { matchSorter } from 'match-sorter';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import get from 'lodash.get';

import { PUBLISH_STATUSES } from 'utils';
import {
  reorderItems,
  getListStyle,
  getItemStyle,
  getDragHandleStyle,
} from 'components/table/utils';

import { DragHandleIcon } from 'components/Icon';
import SearchInput from 'components/input/SearchInput';
import ToggleButtonGroup from 'components/toggle-button-group/ToggleButtonGroup';

import styles from 'components/table/Table.css';
import ImgSortNone from 'assets/images/sort-none.png';
import ImgSortAsc from 'assets/images/sort-asc.png';
import ImgSortDesc from 'assets/images/sort-desc.png';

export const TableFilters = {
  FUZZY_TEXT: 'fuzzyText',
  PUBLISHED: 'published',
};

function GlobalFilter({ placeholder, globalFilter, setGlobalFilter }) {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value);
  }, 200);

  return (
    <SearchInput
      placeholder={placeholder}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    />
  );
}

function StatusFilter({ filters, setFilter }) {
  return (
    <ToggleButtonGroup
      options={Object.values(PUBLISH_STATUSES)}
      currentValue={filters[0].value}
      onChange={(e) => setFilter('Status', e.target.value)}
    />
  );
}

function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

const Table = ({
  data,
  columns,
  datumIdAccessor,
  entityKey,
  hideHeaders,
  hideFilters,
  hoverActions,
  onReorder,
  onRowClick,
}) => {
  const [isServerSorted, setIsServerSorted] = useState(true);

  const hasHoverActions = typeof hoverActions === 'function';
  const hasClickableRows = typeof onRowClick === 'function';
  const hasOrderableRows = datumIdAccessor && typeof onReorder === 'function';

  const showHoverEffects = hasHoverActions || hasClickableRows || hasOrderableRows;

  const handleRowClick = (rowData) => {
    if (hasClickableRows) {
      onRowClick(rowData);
    }
  };

  const handleDragEnd = (result) => {
    if (result.destination) {
      const items = reorderItems(data, result.source.index, result.destination.index);
      onReorder(items);
    }
  };

  const filterTypes = useMemo(
    () => ({
      [TableFilters.FUZZY_TEXT]: fuzzyTextFilterFn,
      [TableFilters.PUBLISHED]: (rows, _, filterValue) => {
        switch (filterValue) {
          case PUBLISH_STATUSES.ACTIVE:
            return rows.filter(({ original }) => original[entityKey].isPublished);
          case PUBLISH_STATUSES.INACTIVE:
            return rows.filter(({ original }) => !original[entityKey].isPublished);
          case PUBLISH_STATUSES.ALL:
          default:
            return rows;
        }
      },
    }),
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preFilteredRows,
    setFilter,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      initialState: {
        filters: useMemo(() => [{ id: 'Status', value: PUBLISH_STATUSES.ALL }], []),
      },
      columns,
      data,
      filterTypes,
      manualSortBy: isServerSorted,
    },
    useGlobalFilter,
    useFilters,
    !hasOrderableRows && useSortBy,
  );

  return (
    <>
      {hideFilters ? null : (
        <section className={styles.filterBar}>
          <StatusFilter
            filters={state.filters}
            preFilteredRows={preFilteredRows}
            setFilter={setFilter}
          />
          <GlobalFilter
            placeholder={`Search ${entityKey === 'series' ? 'series' : `${entityKey}s`}`}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </section>
      )}

      <table {...getTableProps()} className={styles.table}>
        {hideHeaders ? null : (
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {hasOrderableRows && (
                  <th>{/* empty `th` element required for drag handle's `td` */}</th>
                )}
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()} style={column.style}>
                    {column.canSort && !hasOrderableRows ? (
                      <div
                        className={cx(styles.container, column.isSorted && styles.containerActive)}
                        {...column.getSortByToggleProps()}
                        onClick={(e) => {
                          setIsServerSorted(false);
                          return column.getSortByToggleProps().onClick(e);
                        }}
                      >
                        <img
                          src={
                            // eslint-disable-next-line no-nested-ternary
                            column.isSorted
                              ? column.isSortedDesc
                                ? ImgSortDesc
                                : ImgSortAsc
                              : ImgSortNone
                          }
                          alt="Sort"
                          className={styles.sortIcon}
                        />
                        {column.render('Header')}
                      </div>
                    ) : (
                      <div>{column.render('Header')}</div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
        )}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <tbody
                ref={provided.innerRef}
                {...getTableBodyProps()}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {rows.map((row, index) => {
                  prepareRow(row);
                  return (
                    <Draggable
                      key={get(row.original, datumIdAccessor)}
                      draggableId={get(row.original, datumIdAccessor)}
                      index={index}
                      isDragDisabled={!hasOrderableRows}
                    >
                      {(provided, snapshot) => (
                        <tr
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...row.getRowProps()}
                          className={cx(
                            showHoverEffects && styles.withHover,
                            hasClickableRows && styles.clickable,
                          )}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          onClick={() => handleRowClick(row.original)}
                        >
                          {hasOrderableRows && (
                            <td className={styles.dragHandleContainer}>
                              <span
                                {...provided.dragHandleProps}
                                style={getDragHandleStyle(snapshot.isDragging)}
                              >
                                <DragHandleIcon />
                              </span>
                            </td>
                          )}
                          {row.cells.map((cell) => (
                            <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                          ))}
                          {hasHoverActions && (
                            <td className={styles.actions}>{hoverActions(row.original, index)}</td>
                          )}
                        </tr>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </DragDropContext>
      </table>
    </>
  );
};

Table.defaultProps = {
  entityKey: null,
  hideHeaders: false,
  hideFilters: false,
  hoverActions: null,
  onReorder: null,
  onRowClick: null,
};

Table.propTypes = {
  entityKey: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  datumIdAccessor: PropTypes.string.isRequired,
  hideHeaders: PropTypes.bool,
  hideFilters: PropTypes.bool,
  hoverActions: PropTypes.func,
  onReorder: PropTypes.func,
  onRowClick: PropTypes.func,
};

export default Table;
