import PropTypes from 'prop-types';
import { components } from 'react-select';

import {
  BundleSelectContents,
  CreatorSelectContents,
  CollectionSelectContents,
  ModuleTypeSelectContents,
} from 'components/input/components/SelectContents';

const SelectionTypes = {
  BUNDLE: {
    id: 'bundle',
    Component: BundleSelectContents,
  },
  CREATOR: {
    id: 'creator',
    Component: CreatorSelectContents,
  },
  COLLECTION: {
    id: 'collection',
    Component: CollectionSelectContents,
  },
  MODULE_TYPE: {
    id: 'module-type',
    Component: ModuleTypeSelectContents,
  },
};

const BaseOption = ({ selectionType: { Component }, data, ...rest }) => (
  <components.Option {...rest}>
    <Component data={data} showCountLabel />
  </components.Option>
);

BaseOption.propTypes = {
  data: PropTypes.object.isRequired,
  selectionType: PropTypes.oneOf(Object.keys(SelectionTypes).map((key) => SelectionTypes[key]))
    .isRequired,
};

const BaseSingleValue = ({ selectionType: { Component }, data, ...rest }) => (
  <components.SingleValue {...rest}>
    <Component data={data} />
  </components.SingleValue>
);

BaseSingleValue.propTypes = {
  data: PropTypes.object.isRequired,
  selectionType: PropTypes.oneOf(Object.keys(SelectionTypes).map((key) => SelectionTypes[key]))
    .isRequired,
};

export const CreatorOption = (props) => (
  <BaseOption selectionType={SelectionTypes.CREATOR} {...props} />
);
export const CreatorSingleValue = (props) => (
  <BaseSingleValue selectionType={SelectionTypes.CREATOR} {...props} />
);

export const BundleOption = (props) => (
  <BaseOption selectionType={SelectionTypes.BUNDLE} {...props} />
);
export const BundleSingleValue = (props) => (
  <BaseSingleValue selectionType={SelectionTypes.BUNDLE} {...props} />
);

export const CollectionOption = (props) => (
  <BaseOption selectionType={SelectionTypes.COLLECTION} {...props} />
);
export const CollectionSingleValue = (props) => (
  <BaseSingleValue selectionType={SelectionTypes.COLLECTION} {...props} />
);

export const ModuleTypeOption = (props) => (
  <BaseOption selectionType={SelectionTypes.MODULE_TYPE} {...props} />
);
