import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NotificationManager } from 'react-notifications';

import { pluralize, getEpisodeStatus } from 'utils';
import * as adminService from 'services/content/adminService';
import * as contentService from 'services/content/contentService';

import { ALL_CREATORS_SIDEBAR_ITEM_ID } from 'components/modal/constants';
import { FullHeightModal } from 'components/modal/Modal';
import { CreatorsIcon } from 'components/Icon';
import { CMSButton, CMSTextButton, CMSWhiteButton } from 'components/button/Button';

import styles from 'components/modal/Modal.css';

const CreatorsSidebar = ({ selectedEpisodes, creators, activeCreatorId, setActiveCreatorId }) => (
  <div className={styles.sidebar}>
    <ul>
      <li
        className={cx(activeCreatorId === ALL_CREATORS_SIDEBAR_ITEM_ID && styles.active)}
        onClick={() => setActiveCreatorId(ALL_CREATORS_SIDEBAR_ITEM_ID)}
      >
        <div className={styles.creatorPreview}>
          <CreatorsIcon />
          <span>All Creators</span>
        </div>
      </li>
    </ul>
    <hr />
    <ul className={styles.creatorsList}>
      {creators.map(({ creatorId, avatarUrl, title }) => {
        const selectedEpisodesCount = selectedEpisodes.filter(
          (episode) => episode.creatorId === creatorId,
        ).length;

        return (
          <li
            key={creatorId}
            className={cx(activeCreatorId === creatorId && styles.active)}
            onClick={() => setActiveCreatorId(creatorId)}
          >
            <div className={styles.creatorPreview}>
              <img src={avatarUrl} alt={title} />
              <span>{title}</span>
            </div>
            {selectedEpisodesCount > 0 && (
              <span className={styles.episodeCountPill}>{selectedEpisodesCount}</span>
            )}
          </li>
        );
      })}
    </ul>
  </div>
);

CreatorsSidebar.propTypes = {
  selectedEpisodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  creators: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeCreatorId: PropTypes.string.isRequired,
  setActiveCreatorId: PropTypes.func.isRequired,
};

const EpisodeSelectionModal = ({
  creatorId,
  collectionService,
  collectionEpisodes,
  limit,
  isOpen,
  onClose,
  onSubmit,
}) => {
  const [creators, setCreators] = useState([]);
  const [allEpisodes, setAllEpisodes] = useState([]);
  const [activeCreatorId, setActiveCreatorId] = useState(ALL_CREATORS_SIDEBAR_ITEM_ID);
  const [filteredEpisodes, setFilteredEpisodes] = useState(allEpisodes);
  const [selectedEpisodes, setSelectedEpisodes] = useState([]);

  useEffect(() => {
    const fetchCreatorsAndEpisodes = async () => {
      const { creators, episodes } = await collectionService.getAllEpisodes({ creatorId });
      setCreators(creators);
      setAllEpisodes(episodes);
    };
    fetchCreatorsAndEpisodes();
  }, []);

  useEffect(() => setActiveCreatorId(ALL_CREATORS_SIDEBAR_ITEM_ID), [isOpen]);

  useEffect(() => {
    const selectedEpisodes = collectionEpisodes.map(({ episodeId, creator }) => ({
      episodeId,
      creatorId: creator.creatorId,
    }));
    setSelectedEpisodes(selectedEpisodes);
  }, [isOpen, creators, allEpisodes, collectionEpisodes]);

  useEffect(() => {
    if (activeCreatorId === ALL_CREATORS_SIDEBAR_ITEM_ID) {
      setFilteredEpisodes(allEpisodes);
    } else {
      setFilteredEpisodes(
        allEpisodes.filter((episode) => {
          const { creatorId } = episode.creator;
          return creatorId === activeCreatorId;
        }),
      );
    }
  }, [activeCreatorId, allEpisodes]);

  const isMultiCreator = creators.length > 1;
  const selectedEpisodesCount = selectedEpisodes.length;

  const toggleEpisodeSelection = ({ creatorId, episodeId, isSelected }) => {
    const filteredEpisodes = selectedEpisodes.filter((episode) => episode.episodeId !== episodeId);

    if (isSelected) {
      return setSelectedEpisodes(filteredEpisodes);
    }

    if (selectedEpisodesCount >= limit) {
      return NotificationManager.warning('Story selection limit reached!');
    }

    return setSelectedEpisodes((episodes) => [...episodes, { creatorId, episodeId }]);
  };

  const clearEpisodeSelection = () => setSelectedEpisodes([]);

  const handleAddEpisodes = () => onSubmit(selectedEpisodes.map(({ episodeId }) => episodeId));

  const footerButtons = [
    <CMSWhiteButton label="Cancel" onClick={onClose} />,
    <CMSButton
      label={`Add ${pluralize(selectedEpisodesCount, 'Story', 'Stories')}`}
      onClick={handleAddEpisodes}
    />,
  ];

  return (
    <FullHeightModal
      title="Select Stories"
      footerButtons={footerButtons}
      isOpen={isOpen}
      onRequestClose={onClose}
      className={cx(styles.modal, isMultiCreator && styles.withSidebar)}
    >
      {isMultiCreator && (
        <CreatorsSidebar
          creators={creators}
          activeCreatorId={activeCreatorId}
          setActiveCreatorId={setActiveCreatorId}
          selectedEpisodes={selectedEpisodes}
        />
      )}
      <div className={styles.episodeSelectionModal}>
        <div className={styles.listHeader}>
          <div>
            <p className={styles.heading}>Stories</p>
          </div>
          <div className={styles.actionsContainer}>
            <p className={styles.numSelected}>{selectedEpisodesCount} Selected</p>
            <CMSTextButton label="Clear" onClick={clearEpisodeSelection} />
          </div>
        </div>
        <div className={styles.episodeCardList}>
          {filteredEpisodes.length ? (
            filteredEpisodes.map((episode) => {
              const {
                episodeId,
                title,
                availability,
                durationLong,
                releaseDateFormatted,
                thumbnailUrl,
                creator,
              } = episode;
              const { creatorId } = creator;
              const isSelected = selectedEpisodes.some(
                (episode) => episode.episodeId === episodeId,
              );

              return (
                <div key={episodeId} className={styles.episodeCard}>
                  <div
                    className={cx(styles.thumbnailContainer, isSelected && styles.selected)}
                    onClick={() => toggleEpisodeSelection({ creatorId, episodeId, isSelected })}
                  >
                    <img src={thumbnailUrl} alt={title} />
                    <span className={styles.episodeDuration}>{durationLong}</span>
                  </div>
                  <p>{title}</p>
                  <p className={styles.episodeMetaInfo}>
                    <span
                      className={cx(
                        styles.availabilityPill,
                        styles[getEpisodeStatus(availability)],
                      )}
                    >
                      {getEpisodeStatus(availability)}
                    </span>{' '}
                    <span className={styles.releaseDate}>{releaseDateFormatted}</span>
                  </p>
                </div>
              );
            })
          ) : (
            <div className={styles.emptyState}>
              <p className={styles.title}>No Stories</p>
              <p className={styles.subtitle}>No stories have been added to this creator yet.</p>
            </div>
          )}
        </div>
      </div>
    </FullHeightModal>
  );
};

export const MultiCreatorEpisodeSelectionModal = (props) => (
  <EpisodeSelectionModal {...props} collectionService={adminService} />
);

EpisodeSelectionModal.defaultProps = {
  creatorId: null,
  collectionService: contentService,
  limit: Infinity,
};

EpisodeSelectionModal.propTypes = {
  creatorId: PropTypes.string,
  collectionService: PropTypes.object,
  collectionEpisodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  limit: PropTypes.number,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default EpisodeSelectionModal;
