// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.DoItTemplateButton_do-it-button___g_GxMfc7qP {\n  box-shadow: 2px 12px 28px rgba(0, 0, 0, 0.15);\n  border-radius: 5px;\n  overflow: hidden;\n  width: 100%;\n  height: 100%;\n  color: #ffffff;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 18px;\n  font-weight: 600;\n  font-size: 20px;\n  cursor: pointer;\n  -webkit-user-select: none;\n          user-select: none;\n}\n\n.DoItTemplateButton_with-variants___1z9UTutxgh {\n  padding: 8px 4px;\n  cursor: default;\n}\n\n.DoItTemplateButton_variant___39HxbXWxhB {\n  display: none;\n  background: rgba(255, 255, 255, 0.35);\n  margin: 0 4px;\n  font-size: 12px;\n  color: #5a3e75;\n  padding: 14px 0;\n  border-radius: 2.5px;\n  width: 100%;\n  text-align: center;\n  cursor: pointer;\n  transition: all 0.3s ease;\n}\n\n.DoItTemplateButton_variant___39HxbXWxhB img {\n    height: 14px;\n    width: 14px;\n    margin: 0 6px 0 0;\n  }\n\n.DoItTemplateButton_variant___39HxbXWxhB:hover {\n  background: rgba(255, 255, 255, 0.6);\n}\n\n.DoItTemplateButton_with-variants___1z9UTutxgh:hover .DoItTemplateButton_variant___39HxbXWxhB {\n    display: flex;\n    justify-content: center;\n  }\n\n.DoItTemplateButton_with-variants___1z9UTutxgh:hover .DoItTemplateButton_name___2ARncXnmaT {\n    display: none;\n  }\n", ""]);
// Exports
exports.locals = {
	"do-it-button": "DoItTemplateButton_do-it-button___g_GxMfc7qP",
	"doItButton": "DoItTemplateButton_do-it-button___g_GxMfc7qP",
	"with-variants": "DoItTemplateButton_with-variants___1z9UTutxgh",
	"withVariants": "DoItTemplateButton_with-variants___1z9UTutxgh",
	"variant": "DoItTemplateButton_variant___39HxbXWxhB",
	"name": "DoItTemplateButton_name___2ARncXnmaT"
};
module.exports = exports;
