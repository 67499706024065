import sadHellosaurus from 'assets/images/hellosaurus-sad.gif';

import * as styles from 'pages/BrowserIncompatiblePage.css';

import { CMSButton } from 'components/button/Button';
import PageLayout from 'components/page-layout/PageLayout';

const BrowserIncompatiblePage = () => (
  <PageLayout title="Browser Compatibility Error">
    <div className={styles.browserIncompatiblePage}>
      <img src={sadHellosaurus} alt="Sad Hellosaurus" />
      <h1>Uh oh! Your web browser isn&apos;t compatible with our creator tools.</h1>
      <p>
        Please download the latest version of Google Chrome or Firefox to continue creating with
        Hellosaurus.
      </p>
      <div className={styles.buttonHolder}>
        <CMSButton label="Download Chrome" href="https://google.com/chrome" isLarge />
        <CMSButton label="Download Firefox" href="https://www.firefox.com" isLarge />
      </div>
    </div>
  </PageLayout>
);

export default BrowserIncompatiblePage;
