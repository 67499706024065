// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.EpisodeEditPanel_episode-edit-panel___2-SAwEQ0_R {\n  max-width: 35em;\n  width: 100%;\n  background-color: #ffffff;\n  z-index: 1;\n  border-left: 1px solid #000000;\n  position: relative;\n  flex-shrink: 0;\n}\n\n.EpisodeEditPanel_episode-edit-panel___2-SAwEQ0_R section {\n    margin-top: 3em;\n    padding: 0 2em;\n  }\n\n.EpisodeEditPanel_episode-edit-panel___2-SAwEQ0_R section .EpisodeEditPanel_episode-edit-panel__templates___3HgpD-QISL {\n      display: grid;\n      grid-template-columns: repeat(2, 1fr);\n      grid-gap: 1em\n    }\n\n.EpisodeEditPanel_episode-edit-panel___2-SAwEQ0_R section .EpisodeEditPanel_episode-edit-panel__templates___3HgpD-QISL > div {\n        height: 59px;\n      }\n\n.EpisodeEditPanel_episode-edit-panel___2-SAwEQ0_R .EpisodeEditPanel_video-name___3Efmw6yOoe {\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 16px;\n    text-transform: uppercase;\n    color: #6f6f6f;\n    letter-spacing: 0.5px;\n  }\n\n.EpisodeEditPanel_episode-edit-panel___2-SAwEQ0_R label {\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 16px;\n    text-transform: uppercase;\n    color: #6f6f6f;\n    letter-spacing: 0.5px;\n    margin: 1em 0 0.75em;\n  }\n", ""]);
// Exports
exports.locals = {
	"episode-edit-panel": "EpisodeEditPanel_episode-edit-panel___2-SAwEQ0_R",
	"episodeEditPanel": "EpisodeEditPanel_episode-edit-panel___2-SAwEQ0_R",
	"episode-edit-panel__templates": "EpisodeEditPanel_episode-edit-panel__templates___3HgpD-QISL",
	"episodeEditPanelTemplates": "EpisodeEditPanel_episode-edit-panel__templates___3HgpD-QISL",
	"video-name": "EpisodeEditPanel_video-name___3Efmw6yOoe",
	"videoName": "EpisodeEditPanel_video-name___3Efmw6yOoe"
};
module.exports = exports;
