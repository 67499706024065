import * as utils from 'utils';
import { CMSTextButton } from 'components/button/Button';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import PositionInput from 'components/input/PositionInput';
import SizeInput from 'components/input/SizeInput';
import ScaleInput from 'components/input/ScaleInput';
import Accordion from 'components/accordion/Accordion';
import DoItForm from 'components/do-it-forms/forms/DoIt';
import BaseForm from 'components/do-it-forms/forms/Base';
import BackgroundAccordion from 'components/do-it-forms/accordions/BackgroundAccordion';
import AccordionSection from 'components/accordion/AccordionSection';
import AudioConfig from 'components/do-it-forms/components/AudioConfig';
import { getDefaultBackground, getDefaultAudioConfig } from 'components/do-it-forms/utils';

class FindItForm extends BaseForm {
  getDefaultParams() {
    return {
      background: getDefaultBackground(),
      findables: [FindItForm.getDefaultFindable()],
    };
  }

  static getDefaultFindable(lastFindable) {
    return {
      position: {
        x: lastFindable?.position.x ?? 0,
        y: lastFindable?.position.y ?? 0,
      },
      size: {
        x: lastFindable?.size.x ?? 150,
        y: lastFindable?.size.y ?? 150,
      },
      tweenTo: {
        x: lastFindable?.tweenTo.x ?? -250,
        y: lastFindable?.tweenTo.y ?? 350,
      },
      tweenSizeToScaleFactor: lastFindable?.tweenSizeToScaleFactor ?? 1,
      spriteFile: lastFindable?.spriteFile ?? null,
      successSound: lastFindable?.successSound ?? getDefaultAudioConfig(),
      disableOnClick: lastFindable?.disableOnClick ?? false,
    };
  }

  renderFindableAccordions(
    { size, position, spriteFile, successSound, tweenTo, tweenSizeToScaleFactor, swapToSpriteFile },
    i,
  ) {
    const accordionTitle = `Findable Item #${i + 1}`;
    const onChange = (prop, value) => {
      this.setState(({ findables }) => {
        const findablesCopy = utils.deepClone(findables);
        findablesCopy[i][prop] = value;

        return {
          findables: findablesCopy,
        };
      });
    };
    const onDelete = i > 0 ? () => this.handleRemoveFindable(i) : null;

    return (
      <Accordion title={accordionTitle} key={accordionTitle} onDelete={onDelete}>
        <AccordionSection
          title="Starting State"
          description="The state of the Findable item before it is found."
        >
          <ImageUploadInput
            label="Image"
            onChange={(value) => {
              onChange('spriteFile', value);

              if (!swapToSpriteFile) {
                onChange('swapToSpriteFile', value);
              }
            }}
            value={spriteFile}
          />
          <SizeInput
            sizeX={size.x}
            sizeY={size.y}
            positionX={position.x}
            positionY={position.y}
            onChange={onChange}
          />
          <PositionInput positionX={position.x} positionY={position.y} onChange={onChange} />
        </AccordionSection>
        <AccordionSection
          title="Found State"
          description="The state of the Findable item after it is found."
        >
          <ImageUploadInput
            label="Image"
            onChange={(value) => onChange('swapToSpriteFile', value)}
            value={swapToSpriteFile}
          />
          <PositionInput
            positionX={tweenTo.x}
            positionY={tweenTo.y}
            onChange={(_, position) => onChange('tweenTo', position)}
          />
          <ScaleInput
            value={tweenSizeToScaleFactor}
            onChange={(value) => onChange('tweenSizeToScaleFactor', Number(value))}
          />
          <AudioConfig
            label="Found Sound Effect"
            onChange={(value) => onChange('successSound', value)}
            value={successSound}
          />
        </AccordionSection>
      </Accordion>
    );
  }

  handleAddFindable() {
    this.setState(({ findables }) => {
      const lastFindableCopy = findables.length
        ? utils.deepClone(findables[findables.length - 1])
        : FindItForm.getDefaultFindable();

      return {
        findables: [...findables, lastFindableCopy],
      };
    });
  }

  handleRemoveFindable(formIndex) {
    this.setState(({ findables }) => ({
      findables: findables.filter((_, i) => i !== formIndex),
    }));
  }

  getDoitParams() {
    return this.state;
  }

  render() {
    const { onClose, onDelete, doit, subsequentDoitId } = this.props;
    const { findables, background } = this.state;
    const findableAccordionElements = findables.map((item, i) =>
      this.renderFindableAccordions(item, i),
    );

    return (
      <DoItForm
        title="Find It"
        doit={doit}
        onClose={onClose}
        onSave={(options) => this.handleSave(options)}
        onDelete={onDelete}
        subsequentDoitId={subsequentDoitId}
      >
        <BackgroundAccordion
          value={background}
          onChange={(key, value) => this.setState({ [key]: value })}
        />
        {findableAccordionElements}
        <CMSTextButton label="Add Another Findable Item" onClick={() => this.handleAddFindable()} />
      </DoItForm>
    );
  }
}

export default FindItForm;
