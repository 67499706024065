// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n/* ToggleSwitch.jsx variables */\n\n.AccountPage_main___3QCSbhY_j3 {\n  display: flex;\n}\n\n.AccountPage_pageContents___3-V4BBFJwx {\n  flex-grow: 1;\n}\n\n.AccountPage_pageContents___3-V4BBFJwx h1 {\n    color: #000000;\n    font-size: 2em;\n    text-align: left;\n    margin: 40px 40px 0;\n  }\n\n.AccountPage_pageContents___3-V4BBFJwx h2 {\n    color: #000000;\n    font-size: 18px;\n  }\n\n.AccountPage_pageContents___3-V4BBFJwx .AccountPage_emptyState___225XFrJGnX {\n    /* 160px is combined height of page title and tabbed navigation */\n    height: calc(100vh - 80px - 160px);\n  }\n\n.AccountPage_pageContents___3-V4BBFJwx .AccountPage_emptyState___225XFrJGnX h1 {\n      margin: 0;\n    }\n", ""]);
// Exports
exports.locals = {
	"main": "AccountPage_main___3QCSbhY_j3",
	"pageContents": "AccountPage_pageContents___3-V4BBFJwx",
	"emptyState": "AccountPage_emptyState___225XFrJGnX"
};
module.exports = exports;
