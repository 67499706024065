import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { parseUrl, stringifyUrl } from 'query-string';
import { CMSWhiteButton } from 'components/button/Button';
import * as styles from 'components/paginated-list/PaginatedList.css';

class PaginatedList extends PureComponent {
  static propTypes = {
    ListComponent: PropTypes.elementType.isRequired,
    ListEmptyComponent: PropTypes.elementType,
    loadFunction: PropTypes.func.isRequired,
    initialUrl: PropTypes.string,
    getIdFromItem: PropTypes.func.isRequired,
    onClick: PropTypes.func,
    lastModified: PropTypes.string,
    twoColumnLayout: PropTypes.bool,
  };

  static defaultProps = {
    lastModified: null,
    ListEmptyComponent: () => <p>Nothing here yet…</p>,
    onClick: () => {},
    twoColumnLayout: false,
    initialUrl: undefined,
  };

  state = {
    isLoading: true,
    currentUrl: this.props.initialUrl,
    paginationResponse: null,
  };

  async componentDidMount() {
    await this.loadPaginationResponse(this.props.initialUrl);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.lastModified !== prevProps.lastModified) {
      await this.refreshResults();
    }
  }

  handleClick(url) {
    this.setState({ isLoading: true, currentUrl: url });
    this.loadPaginationResponse(url);
  }

  async loadPaginationResponse(url) {
    const paginationResponse = await this.props.loadFunction(url);
    this.setState({ isLoading: false, paginationResponse });
  }

  async refreshResults() {
    const { currentUrl } = this.state;

    if (currentUrl) {
      const parsedUrl = parseUrl(currentUrl);
      parsedUrl.query.page = 1;
      const pageOneUrl = stringifyUrl(parsedUrl);
      await this.loadPaginationResponse(pageOneUrl);
    } else {
      await this.loadPaginationResponse();
    }
  }

  render() {
    const { isLoading, paginationResponse } = this.state;

    if (isLoading || !paginationResponse) {
      return (
        <div className={styles.paginatedList}>
          <p>Loading…</p>
        </div>
      );
    }

    const {
      ListComponent,
      ListEmptyComponent,
      getIdFromItem,
      onClick,
      twoColumnLayout,
    } = this.props;
    const { items, links, metadata } = paginationResponse;
    const listElements = items.map((item) => {
      const key = getIdFromItem(item);
      return (
        <li key={key} onClick={() => onClick(item)}>
          <ListComponent {...item} />
        </li>
      );
    });

    if (!listElements.length) {
      return (
        <div className={styles.paginatedList}>
          <ListEmptyComponent />
        </div>
      );
    }

    return (
      <div className={cx(styles.paginatedList, twoColumnLayout && styles.twoColumnLayout)}>
        <ul className={styles.paginatedListItems}>{listElements}</ul>
        {links && (
          <div className={styles.paginatedListActions}>
            <CMSWhiteButton
              label="First"
              onClick={() => this.handleClick(links.first)}
              isDisabled={isLoading || !links.first}
            />
            <CMSWhiteButton
              label="Previous"
              onClick={() => this.handleClick(links.previous)}
              isDisabled={isLoading || !links.previous}
            />
            <span>
              {metadata.currentPage}/{metadata.pageCount}
            </span>
            <CMSWhiteButton
              label="Next"
              onClick={() => this.handleClick(links.next)}
              isDisabled={isLoading || !links.next}
            />
            <CMSWhiteButton
              label="Last"
              onClick={() => this.handleClick(links.last)}
              isDisabled={isLoading || !links.last}
            />
          </div>
        )}
      </div>
    );
  }
}

export default PaginatedList;
