import PropTypes from 'prop-types';
import { DebounceInput } from 'react-debounce-input';
import * as utils from 'utils';

const TimestampPlaceValue = ({ onChange, value }) => (
  <DebounceInput
    value={utils.padNumber(value)}
    onChange={(e) => onChange(Number(e.target.value))}
    debounceTimeout={500}
  />
);

TimestampPlaceValue.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
};

export default TimestampPlaceValue;
