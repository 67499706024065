import { useEffect } from 'react';
import DoItForm from 'components/do-it-forms/forms/DoIt';
import BaseForm from 'components/do-it-forms/forms/Base';
import BackgroundAccordion from 'components/do-it-forms/accordions/BackgroundAccordion';
import {
  getDefaultBackground,
  getDefaultCollageStack,
  getDefaultCollageItStamp,
  getDefaultCanvasObject,
} from 'components/do-it-forms/utils';
import Accordion from 'components/accordion/Accordion';
import AccordionSection from 'components/accordion/AccordionSection';
import AudioConfig from 'components/do-it-forms/components/AudioConfig';
import ObjectList from 'components/do-it-forms/components/ObjectList';
import SizeInput from 'components/input/SizeInput';
import PositionInput from 'components/input/PositionInput';
import CheckboxInput from 'components/input/CheckboxInput';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import CounterInput from 'components/input/CounterInput';

const CollageItStamp = ({ i, value, onChange, onDelete }) => {
  const accordionTitle = `Stamp #${i + 1}`;

  return (
    <Accordion key={accordionTitle} title={accordionTitle} onDelete={onDelete}>
      <ImageUploadInput
        label="Image"
        onChange={(value) => onChange('spriteFile', value)}
        value={value.spriteFile}
      />
      <SizeInput sizeX={value.size.x} sizeY={value.size.y} onChange={onChange} />
      <AudioConfig
        label="Selected Sound Effect"
        value={value.selectStampSound}
        onChange={(value) => onChange('selectStampSound', value)}
      />
      <AudioConfig
        label="Placement Sound Effect"
        value={value.placeStampSound}
        onChange={(value) => onChange('placeStampSound', value)}
      />
    </Accordion>
  );
};

const CollageStack = ({ i, value: stack, onChange, onDelete, positionedInTray }) => {
  const accordionTitle = `Stack #${i + 1}`;

  useEffect(() => {
    if (stack.singleStampStack) {
      // erase all but first stamp in the stack
      onChange('stamps', [stack.stamps[0]]);
    }
  }, [stack.singleStampStack]);

  return (
    <Accordion key={accordionTitle} title={accordionTitle} onDelete={onDelete}>
      <AccordionSection title="Stack Settings" description="Size and position of the Stamp Stack">
        {!positionedInTray && (
          <>
            <SizeInput sizeX={stack.size.x} sizeY={stack.size.y} onChange={onChange} />
            <PositionInput
              positionX={stack.position.x}
              positionY={stack.position.y}
              onChange={onChange}
            />
          </>
        )}
        <CheckboxInput
          forKey={accordionTitle}
          label="Single-Stamp Stack"
          description="Toggle on to create a stack of the same stamp. NOTE: enabling will erase all but the first stamp in this stack."
          value={stack.singleStampStack}
          onChange={(value) => onChange('singleStampStack', value)}
        />

        <CounterInput
          label="Number Of Stamps in Stack"
          value={stack.maxStamps}
          onChange={(value) => onChange('maxStamps', value)}
        />

        <br />

        <ObjectList
          objects={stack.stamps}
          getDefault={getDefaultCollageItStamp}
          onChange={(stamps) => onChange('stamps', stamps)}
          name="Stamp"
          Component={CollageItStamp}
          addingDisabled={stack.singleStampStack}
        />
      </AccordionSection>
    </Accordion>
  );
};

class CollageItForm extends BaseForm {
  getDefaultParams() {
    return {
      background: getDefaultBackground(),
      stacks: [getDefaultCollageStack()],
      canvasObjects: [],
      maxNumStamps: '0',
      tray: null,
    };
  }

  getDoitParams() {
    return this.state;
  }

  render() {
    const { onClose, onDelete, doit, subsequentDoitId } = this.props;
    const { background, stacks, canvasObjects, maxNumStamps, tray } = this.state;

    return (
      <DoItForm
        title="Collage It"
        doit={doit}
        onClose={onClose}
        onSave={(options) => this.handleSave(options)}
        onDelete={onDelete}
        subsequentDoitId={subsequentDoitId}
      >
        <BackgroundAccordion
          value={background}
          onChange={(key, value) => this.setState({ [key]: value })}
        />

        <Accordion title="Collage It Settings">
          <CheckboxInput
            label="Display Tray"
            description="If toggled on, all Stacks will appear in an auto-layout swipeable tray."
            value={Boolean(tray)}
            onChange={(value) => this.onChange('tray', value ? getDefaultCanvasObject() : null)}
          />

          <CounterInput
            label="Maximum Number Of Stamps"
            value={maxNumStamps}
            onChange={(value) => this.onChange('maxNumStamps', value)}
          />
        </Accordion>

        <ObjectList
          objects={stacks}
          onChange={(value) => this.onChange('stacks', value)}
          getDefault={getDefaultCollageStack}
          name="Stack"
          Component={CollageStack}
          positionedInTray={Boolean(tray)}
        />
      </DoItForm>
    );
  }
}

export default CollageItForm;
