import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { NotificationManager } from 'react-notifications';
import { useLocation } from 'react-router-dom';

import { getEpisode, updateEpisode } from 'services/content/contentService';

import { CMSWhiteInput } from 'components/input/Input';
import { PurpleButton } from 'components/button/Button';
import { INPUT_TYPES } from 'components/input/constants';
import { isInputValid } from 'components/input/utils';
import TagsSelector from 'components/tags-selector/TagsSelector';

import styles from 'pages/tools/GrantAccessPage.css';
import formStyles from 'components/forms/styles.css';

const EpisodeDetailPage = ({ creatorId, episodeId, activeCreator }) => {
  const location = useLocation();
  const [episodeDetails, updateEpisodeDetails] = useState(location.state?.episode || {});

  const { title, description, tags } = episodeDetails;

  useEffect(() => {
    const fetchEpisode = async () => {
      const episode = await getEpisode(creatorId, episodeId);
      updateEpisodeDetails(episode);
    };
    fetchEpisode();
  }, [creatorId, episodeId]);

  const updateTags = (key, values) =>
    updateEpisodeDetails({ ...episodeDetails, tags: { ...episodeDetails.tags, [key]: values } });

  const saveEpisodeDetails = async () => {
    const episode = await updateEpisode(creatorId, episodeId, { title, description, tags });
    updateEpisodeDetails(episode);

    NotificationManager.success('Story details saved!');
  };

  return (
    <div className={styles.grantAccessPage}>
      <h2>
        <span style={{ fontSize: '80%', textTransform: 'uppercase' }}>Creator:</span>{' '}
        {activeCreator.title}
      </h2>
      <h1>Story Details</h1>

      <div className={formStyles.form}>
        <section className={formStyles.formBody}>
          <div className={cx(formStyles.formWrapper, styles.formWrapper)}>
            <CMSWhiteInput
              value={title}
              label="Title"
              placeholder="Add a title that describes your story"
              isRequired
              onChange={(title) => updateEpisodeDetails({ ...episodeDetails, title })}
            />
            <CMSWhiteInput
              textarea
              value={description}
              label="Description"
              placeholder="Tell viewers about your story"
              isRequired
              onChange={(description) => updateEpisodeDetails({ ...episodeDetails, description })}
            />
            <TagsSelector
              selected={tags}
              onSkillsChange={(skills) => updateTags('skills', skills)}
              onTopicsChange={(topics) => updateTags('topics', topics)}
            />
          </div>
        </section>
        <footer className={formStyles.formFooter}>
          <PurpleButton
            label="Save Changes"
            isDisabled={
              !isInputValid(INPUT_TYPES.TEXT, title) || !isInputValid(INPUT_TYPES.TEXT, description)
            }
            onClick={saveEpisodeDetails}
          />
        </footer>
      </div>
    </div>
  );
};

EpisodeDetailPage.propTypes = {
  creatorId: PropTypes.string.isRequired,
  episodeId: PropTypes.string.isRequired,
  activeCreator: PropTypes.string.isRequired,
};

export default EpisodeDetailPage;
