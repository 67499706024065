/* eslint-disable react/prop-types */

import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { noop, getTopicTileBackgroundStyles } from 'utils';
import request from 'services/request/requestService';

import SelectInput from 'components/input/SelectInput';

import styles from 'components/tags-selector/TagsSelector.css';

const NOT_FOUND_INDEX = -1;

const TagSection = ({ label, children }) => (
  <div>
    <label>{label}</label>
    {children}
  </div>
);

const TopicCard = ({ topic, isSelected, onClick }) => (
  <div
    className={cx(styles.topicCard, isSelected && styles.selected)}
    style={getTopicTileBackgroundStyles(topic.color)}
    onClick={() => onClick(topic)}
  >
    <img className={styles.backgroundImage} src={topic.dinoUrl} alt={topic.title} />
    <span className={styles.title}>{topic.title}</span>
  </div>
);

const TopicsSelector = ({ selected, onTopicsChange, onSkillsChange }) => {
  const [_topics, setTopics] = useState([]);
  const [_skills, setSkills] = useState([]);

  useEffect(() => {
    const fetchAllPossibleTags = async () => {
      const skillsRequest = request('/skills');
      const topicsRequest = request('/topics');

      const [skills, topics] = await Promise.all([skillsRequest, topicsRequest]);
      setSkills(skills.map((skill) => ({ ...skill, id: skill.skillId, label: skill.value })));
      setTopics(topics.map((topic) => ({ ...topic, id: topic.topicId })));
    };

    fetchAllPossibleTags();
  }, []);

  const toggleSelection = (topic) => {
    const foundTopicIndex = selected.topics.findIndex(
      (prevSelectedTopic) => prevSelectedTopic.id === topic.topicId,
    );

    if (foundTopicIndex === NOT_FOUND_INDEX) {
      onTopicsChange([...selected.topics, topic]);
    } else {
      const topicsCopy = [...selected.topics];
      topicsCopy.splice(foundTopicIndex, 1);
      onTopicsChange(topicsCopy);
    }
  };

  const SkillsSelectInput = () => (
    <SelectInput
      isMulti
      options={_skills}
      isRequired={false}
      defaultValue={selected.skills.map((skill) => ({ ...skill, value: skill.label }))}
      onChange={onSkillsChange}
    />
  );

  return (
    <>
      <TagSection label="What topic(s) is your story about?">
        <div className={styles.topicsContainer}>
          {_topics.map((topic) => {
            const isSelected = selected.topics.some(
              (storyTopic) => storyTopic.id === topic.topicId,
            );

            return (
              <TopicCard
                key={topic.topicId}
                topic={topic}
                isSelected={isSelected}
                onClick={toggleSelection}
              />
            );
          })}
        </div>
      </TagSection>

      <TagSection label="What skills will the child practice?">
        <SkillsSelectInput />
      </TagSection>
    </>
  );
};

TopicsSelector.defaultProps = {
  selected: {
    topics: [],
    skills: [],
  },
  onTopicsChange: noop,
  onSkillsChange: noop,
};

TopicsSelector.propTypes = {
  selected: PropTypes.shape({
    topics: PropTypes.arrayOf(PropTypes.object),
    skills: PropTypes.arrayOf(PropTypes.object),
  }),
  onTopicsChange: PropTypes.func,
  onSkillsChange: PropTypes.func,
};

export default TopicsSelector;
