import { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

import { getAccessToken } from 'services/authentication/storeService';
import unityUtils from 'components/unity/utils';
import styles from 'components/unity/Unity.css';

class Unity extends Component {
  static propTypes = {
    newUnityBuild: PropTypes.bool,
    episodePayload: PropTypes.shape({
      episodeId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      doits: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
      videoUrl: PropTypes.string.isRequired,
    }).isRequired,
  };

  componentDidMount() {
    const token = getAccessToken();
    window.axios = axios;

    unityUtils.updateUnityConfig(this.props.episodePayload);
    unityUtils.loadAndInstantiateUnity({
      newUnityBuild: this.props.newUnityBuild,
      onLoadComplete: () => {
        unityUtils.updateUserJWT(token);
      },
    });
  }

  shouldComponentUpdate(nextProps, _nextState) {
    return JSON.stringify(this.props) !== JSON.stringify(nextProps);
  }

  componentDidUpdate() {
    unityUtils.updateUnityConfig(this.props.episodePayload);
    unityUtils.updateDoits();
  }

  componentWillUnmount() {
    unityUtils.destroyUnity();
  }

  render() {
    return (
      <div id="unityContainer" className={styles.unityContainer}>
        <canvas id="unityCanvas" />
      </div>
    );
  }
}

export default Unity;
