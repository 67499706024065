import { BORDER_SIZE_PX } from 'components/timeline/constants';
import * as coreUtils from 'utils';

export const { getTimestampFromSeconds } = coreUtils;

export function getPercentFromMousePosition({ pageX }, timelineElement) {
  const parentOffsetLeft = timelineElement.offsetParent.offsetLeft + BORDER_SIZE_PX;
  const timelineContainerParentWidth = timelineElement.clientWidth + BORDER_SIZE_PX;
  const mousePositionX = Math.max(
    Math.min(pageX - parentOffsetLeft, timelineContainerParentWidth),
    0,
  );
  const mousePercent = (mousePositionX / timelineContainerParentWidth) * 100;

  return mousePercent;
}
