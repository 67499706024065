import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Link } from 'react-router-dom';
import * as styles from 'components/button/Button.css';
import { FacebookIcon, TwitterIcon } from 'components/Icon';
import Configuration from 'shared/configuration';

const ButtonTypes = {
  DARK: 'dark',
  LIGHT_BLUE: 'light-blue',
  ORANGE: 'orange',
  YELLOW: 'yellow',
  FACEBOOK: 'facebook',
  TWITTER: 'twitter',
  PURPLE: 'purple',
  CMS: 'cms',
  CMS_WHITE: 'cms-white',
  CMS_BLACK: 'cms-black',
  CMS_RED: 'cms-red',
  CMS_TEXT: 'cms-text',
};
const { PRODUCTION_URL } = new Configuration();
const URL_ENCODED = encodeURI(`https://${PRODUCTION_URL}`);

const LinkElement = (props) => {
  if (props.href && props.href.startsWith('/')) {
    return (
      <Link {...props} to={props.href}>
        {props.children}
      </Link>
    );
  }

  const target = props.href ? '_blank noopener noreferrer' : undefined;

  return (
    <a {...props} target={target}>
      {props.children}
    </a>
  );
};
LinkElement.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node.isRequired,
};
LinkElement.defaultProps = {
  href: null,
};

class Button extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    href: PropTypes.string,
    type: PropTypes.string,
    icon: PropTypes.element,
    isHidden: PropTypes.bool,
    onClick: PropTypes.func,
    isRounded: PropTypes.bool,
    isDisabled: PropTypes.bool,
    isLarge: PropTypes.bool,
    ModalComponent: PropTypes.object,
  };

  static defaultProps = {
    label: null,
    href: null,
    icon: null,
    isHidden: false,
    onClick: null,
    isRounded: false,
    isDisabled: false,
    isLarge: false,
    ModalComponent: null,
    type: null,
  };

  state = {
    isModalOpen: false,
  };

  handleModalOpen() {
    this.setState({ isModalOpen: true });
  }

  handleModalClose() {
    this.setState({ isModalOpen: false });
  }

  render() {
    const {
      label,
      href,
      type,
      icon,
      isHidden,
      onClick,
      isRounded,
      isDisabled,
      isLarge,
      ModalComponent,
    } = this.props;
    const { isModalOpen } = this.state;

    const buttonStyles = cx(styles.btn, styles[`btn--${type}`], {
      [styles.btnRounded]: isRounded,
      [styles.btnHidden]: isHidden,
      [styles.btnIconOnly]: !label,
      [styles.btnDisabled]: isDisabled,
      [styles.btnLarge]: isLarge,
    });
    const handleOnClick = () => {
      if (isDisabled) {
        return;
      }

      if (ModalComponent) {
        this.handleModalOpen();
        return;
      }

      onClick && onClick();
    };

    return (
      <>
        <LinkElement className={buttonStyles} href={href} onClick={handleOnClick}>
          {icon && <span className={styles.btnIcon}>{icon}</span>}
          {label && <span>{label}</span>}
        </LinkElement>
        {ModalComponent && (
          <ModalComponent isOpen={isModalOpen} onClose={() => this.handleModalClose()} />
        )}
      </>
    );
  }
}

export const DarkButton = (props) => <Button {...props} type={ButtonTypes.DARK} />;
export const LightBlueButton = (props) => <Button {...props} type={ButtonTypes.LIGHT_BLUE} />;
export const IconButton = (props) => <Button {...props} type={ButtonTypes.DARK} isRounded />;
export const OrangeButton = (props) => <Button {...props} type={ButtonTypes.ORANGE} />;
export const YellowButton = (props) => <Button {...props} type={ButtonTypes.YELLOW} />;
export const PurpleButton = (props) => <Button {...props} type={ButtonTypes.PURPLE} />;
export const FacebookShareButton = (props) => (
  <Button
    {...props}
    href={`https://facebook.com/sharer/sharer.php?u=${URL_ENCODED}`}
    icon={<FacebookIcon />}
    label="Share on Facebook"
    type={ButtonTypes.FACEBOOK}
  />
);
export const TwitterShareButton = (props) => {
  const textEncoded = encodeURI(props.text);

  return (
    <Button
      {...props}
      href={`https://twitter.com/intent/tweet/?text=${textEncoded}&amp;url=${URL_ENCODED}`}
      icon={<TwitterIcon />}
      label="Share on Twitter"
      type={ButtonTypes.TWITTER}
    />
  );
};

TwitterShareButton.propTypes = {
  ...Button.propTypes,
  text: PropTypes.string.isRequired,
};

export const CMSButton = (props) => <Button {...props} type={ButtonTypes.CMS} />;
export const CMSWhiteButton = (props) => <Button {...props} type={ButtonTypes.CMS_WHITE} />;
export const CMSBlackButton = (props) => <Button {...props} type={ButtonTypes.CMS_BLACK} />;
export const CMSRedButton = (props) => <Button {...props} type={ButtonTypes.CMS_RED} />;
export const CMSTextButton = (props) => <Button {...props} type={ButtonTypes.CMS_TEXT} />;
