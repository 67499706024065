/* eslint-disable react/prop-types */

import PropTypes from 'prop-types';
import cx from 'classnames';
import Dropdown from 'react-dropdown';

import styles from 'components/input/Input.css';

class DropdownInput extends Dropdown {
  static propTypes = {
    options: PropTypes.array.isRequired,
    label: PropTypes.string,
    baseClassName: PropTypes.string,
    className: PropTypes.string,
    controlClassName: PropTypes.string,
    placeholderClassName: PropTypes.string,
    menuClassName: PropTypes.string,
    arrowClassName: PropTypes.string,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    containerClassName: PropTypes.string,
  };

  render() {
    return (
      <div className={cx(styles.dropdownInput, this.props.containerClassName)}>
        {this.props.label && <label>{this.props.label}</label>}
        <Dropdown {...this.props} />
      </div>
    );
  }
}

export default DropdownInput;
