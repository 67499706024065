// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.styles_tabList___3ukKRvZ7Il {\n  border-bottom: 1px solid #f2efea;\n  margin: 25px 0 10px !important;\n  padding: 0 40px !important;\n}\n\n.styles_tab___2wZmnrQWuT {\n  display: inline-block;\n  position: relative;\n  cursor: pointer;\n  list-style: none;\n  font-weight: bold;\n  color: #6f6f6f;\n  padding-bottom: 10px;\n  border-bottom: solid 3px transparent;\n  -webkit-user-select: none;\n          user-select: none\n}\n\n.styles_tab___2wZmnrQWuT.styles_tabDisabled___2UnL2DPff4 {\n    color: #b6b4b0\n  }\n\n.styles_tab___2wZmnrQWuT.styles_tabDisabled___2UnL2DPff4:hover {\n      cursor: not-allowed;\n    }\n\n.styles_tab___2wZmnrQWuT:not(:last-of-type) {\n  margin-right: 32px;\n}\n\n.styles_selectedTab___3C6ZovyRPh {\n  color: #8b18e3;\n  border-bottom: solid 3px #8b18e3;\n}\n", ""]);
// Exports
exports.locals = {
	"tabList": "styles_tabList___3ukKRvZ7Il",
	"tab": "styles_tab___2wZmnrQWuT",
	"tabDisabled": "styles_tabDisabled___2UnL2DPff4",
	"selectedTab": "styles_selectedTab___3C6ZovyRPh"
};
module.exports = exports;
