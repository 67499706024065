import { EMAIL_REGEX, INPUT_TYPES } from 'components/input/constants';

function isPresent(value) {
  return Boolean(value);
}

function isStringPresent(string) {
  return isPresent(string);
}

function isEmailValid(string) {
  return isStringPresent(string) && string.match(EMAIL_REGEX);
}

function isPasswordValid(password) {
  return isStringPresent(password) && password.length >= 6;
}

function isNumberValid(value) {
  if (value === '') {
    return false;
  }

  const valueNumber = Number(value);

  return !isNaN(valueNumber);
}

// eslint-disable-next-line import/prefer-default-export
export function isInputValid(type, value) {
  switch (type) {
    case INPUT_TYPES.EMAIL:
      return isEmailValid(value);
    case INPUT_TYPES.TEXT:
      return isStringPresent(value);
    case INPUT_TYPES.DATE:
      return isPresent(value);
    case INPUT_TYPES.PASSWORD:
      return isPasswordValid(value);
    case INPUT_TYPES.NUMBER:
      return isNumberValid(value);
    default:
      throw Error(`The input type=${type} is not supported!`);
  }
}

/**
 *
 * @param {Number} opacity Desired alpha value for the primary Hellosaurus purple (#8b18e3).
 *  Appropriate values is a number between 0.0 (fully transparent) and 1.0 (fully opaque).
 */
export function getPrimaryColorByOpacity(opacity = 1.0) {
  if (opacity < 0 || opacity > 1) {
    return 'rgba(139, 24, 227, 1)';
  }
  return `rgba(139, 24, 227, ${opacity})`;
}
