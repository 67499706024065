/* eslint-disable jsx-a11y/anchor-is-valid, no-script-url */

import { createRef, PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';
import cx from 'classnames';
import { withRouter } from 'react-router';

import { NotificationManager } from 'react-notifications';
import standardUploadIcon from 'assets/images/upload-icon.png';
import retinaUploadIcon from 'assets/images/upload-icon@2x.png';

import styles from 'components/file-upload/FileUploadModal.css';
import { CMSButton, CMSWhiteButton } from 'components/button/Button';
import Image from 'components/image/Image';
import * as uploadService from 'services/upload/uploadService';
import CMSInput from 'components/input/Input';
import AssetPreview from 'components/asset-preview/AssetPreview';

ReactModal.setAppElement('#root');

class FileUploadModal extends PureComponent {
  static propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    acceptedMimeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    match: PropTypes.object, // React Router's injected props via withRouter
    uploadType: PropTypes.string.isRequired,
    episodeId: PropTypes.string,
    creatorId: PropTypes.string,
    showFilesFromShowOnly: PropTypes.bool, // Whether to list uploads made for this show only
    dropAreaOnly: PropTypes.bool, // Only render the drop area, not the modal and other goodies
  };

  static defaultProps = {
    match: {},
    showFilesFromShowOnly: false,
    dropAreaOnly: false,
    episodeId: null,
    creatorId: null,
  };

  state = {
    existingFiles: [],
    newFiles: [],
    selectedFile: null,
    url: '',
    isDraggingOver: false,
    isUploading: false,
  };

  fileInputRef = createRef();

  async loadExistingFiles() {
    const { acceptedMimeTypes, uploadType, showFilesFromShowOnly, episodeId } = this.props;
    const existingFiles = await uploadService.getUploads(
      acceptedMimeTypes.join(),
      uploadType,
      showFilesFromShowOnly && episodeId ? episodeId : null,
    );
    this.setState({ existingFiles });
  }

  async componentDidUpdate(prevProps) {
    const { isOpen } = this.props;

    if (isOpen === prevProps.isOpen) {
      return;
    }

    if (isOpen) {
      window.addEventListener('keydown', this.handleWindowKeydown, true);
      await this.loadExistingFiles();
    } else {
      window.removeEventListener('click', this.handleWindowKeydown, true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleWindowKeydown, true);
  }

  handleWindowKeydown = ({ key, keyCode }) => {
    const isEscapeKey = key === 'Escape' || key === 'Esc' || keyCode === 27;

    if (!isEscapeKey || !this.props.isOpen) {
      return;
    }

    this.handleClose();
  };

  handleClose(file) {
    this.props.onClose(file);
  }

  handleUploadRequestedClick() {
    if (this.fileInputRef) {
      this.fileInputRef.current.click();
    }
  }

  async handleUploadChange(e, files = e.target.files) {
    if (!files.length) {
      return;
    }

    if (this.state.isUploading) {
      return;
    }

    this.setState({ isUploading: true, isDraggingOver: false });

    const episodeId = this.props.match?.params?.episodeId || null;
    const creatorId = this.props.creatorId || null;

    try {
      const uploadedFiles = await uploadService.uploadFiles(
        files,
        this.props.uploadType,
        episodeId,
        creatorId,
      );

      this.setState(({ newFiles }) => ({
        newFiles: [...newFiles, ...uploadedFiles],
        selectedFile: uploadedFiles[0],
        isUploading: false,
      }));

      if (uploadedFiles.length === 1) {
        this.handleClose(uploadedFiles[0]);
      }
    } catch (e) {
      this.setState({ isUploading: false });
    }
  }

  handleFileClick(selectedFile) {
    this.setState({ selectedFile });
  }

  toFileElements(files) {
    const { selectedFile } = this.state;

    return files.map((file) => {
      const { editedName, awsPublicUrl, fileId, formattedCreated, mimeType } = file;
      const uploadStyles = cx(
        styles.upload,
        fileId === selectedFile?.fileId && styles.uploadActive,
      );

      return (
        <li
          key={fileId}
          className={uploadStyles}
          onClick={() => this.handleFileClick(file)}
          onDoubleClick={() => this.handleClose(file)}
        >
          <AssetPreview src={awsPublicUrl} alt={editedName} mimeType={mimeType} />
          <div className={styles.uploadDetails}>
            <p className={styles.uploadDetailsName}>{editedName}</p>
            <p className={styles.uploadDetailsDate}>Uploaded: {formattedCreated}</p>
          </div>
        </li>
      );
    });
  }

  handleUrlChange(url) {
    this.setState({ url });
  }

  async handleUploadFromUrlClick() {
    const { uploadType, match } = this.props;
    const { url } = this.state;
    const episodeId = match?.params?.episodeId || null;
    const uploadedFile = await uploadService.uploadFileFromUrl(url, uploadType, episodeId);

    this.setState(({ newFiles }) => ({
      newFiles: [...newFiles, uploadedFile],
      selectedFile: uploadedFile,
      url: '',
    }));
    this.handleClose(uploadedFile);
  }

  handleDrop(e) {
    e.preventDefault();

    function getFilesFromDropEvent({ dataTransfer }) {
      if (dataTransfer.items) {
        return Array.from(dataTransfer.items)
          .filter((item) => item.kind === 'file')
          .map((item) => item.getAsFile());
      }

      return Array.from(dataTransfer.files);
    }

    const files = getFilesFromDropEvent(e);
    const { acceptedMimeTypes } = this.props;
    const filteredFiles = files.filter((file) => {
      if (!acceptedMimeTypes.includes(file.type)) {
        NotificationManager.error(
          `Accepted file types: ${acceptedMimeTypes.join(', ')}`,
          `Wrong file type "${file.name}"`,
        );
        return false;
      }

      return true;
    });

    this.handleUploadChange(e, filteredFiles);
  }

  handleDragOver(e) {
    e.preventDefault();
    this.setState({ isDraggingOver: true });
  }

  handleDragLeave() {
    this.setState({ isDraggingOver: false });
  }

  getHeadingText() {
    const { isDragging, isUploading } = this.state;

    if (isUploading) {
      return 'Uploading…';
    }

    if (isDragging) {
      return 'Dragging…';
    }

    return 'Choose or Upload';
  }

  render() {
    const { isOpen, acceptedMimeTypes, showFilesFromShowOnly, dropAreaOnly } = this.props;
    const { existingFiles, newFiles, selectedFile, url, isDraggingOver, isUploading } = this.state;
    const existingFileElements = this.toFileElements(existingFiles);
    const newFileElements = this.toFileElements(newFiles);
    const continueLabel = selectedFile ? `Select ${selectedFile.editedName}` : 'Select';
    const uploadsLabel = showFilesFromShowOnly ? 'Uploads for Show' : 'My Uploads';
    const modalStyles = cx(styles.modal, {
      [styles.modalDragging]: isDraggingOver,
      [styles.modalUploading]: isUploading,
      [styles.modalDropAreaOnly]: dropAreaOnly,
    });
    const headingText = this.getHeadingText();

    if (dropAreaOnly) {
      return (
        isOpen && (
          <div className={modalStyles}>
            <div className={styles.modalBody}>
              <div
                className={styles.modalBodyDropArea}
                onDrop={this.handleDrop.bind(this)}
                onDragOver={this.handleDragOver.bind(this)}
                onDragLeave={this.handleDragLeave.bind(this)}
              >
                <Image standard={standardUploadIcon} retina={retinaUploadIcon} alt="Upload" />
                <CMSButton
                  label={isUploading ? 'Loading...' : 'Upload'}
                  onClick={() => this.handleUploadRequestedClick()}
                  isDisabled={isUploading}
                />
                <p>(or drag here)</p>
                <input
                  ref={this.fileInputRef}
                  type="file"
                  hidden
                  onChange={this.handleUploadChange.bind(this)}
                  accept={acceptedMimeTypes.join()}
                />
              </div>
            </div>
          </div>
        )
      );
    }

    return (
      <ReactModal isOpen={isOpen} style={{ overlay: { zIndex: 11000 } }}>
        <div className={modalStyles}>
          <div className={styles.modalHeader}>
            <h1>{headingText}</h1>
          </div>
          <div className={styles.modalBody}>
            <div
              className={styles.modalBodyDropArea}
              onDrop={this.handleDrop.bind(this)}
              onDragOver={this.handleDragOver.bind(this)}
              onDragLeave={this.handleDragLeave.bind(this)}
            >
              <Image standard={standardUploadIcon} retina={retinaUploadIcon} alt="Upload" />
              <CMSButton
                label="Upload"
                onClick={() => this.handleUploadRequestedClick()}
                isDisabled={isUploading}
              />
              <p>(or drag here)</p>
              <input
                ref={this.fileInputRef}
                type="file"
                hidden
                onChange={this.handleUploadChange.bind(this)}
                multiple
                accept={acceptedMimeTypes.join()}
              />
            </div>
            <div className={styles.modalBodyFileNavigator}>
              <form onSubmit={(e) => e.preventDefault() && this.handleUploadFromUrlClick()}>
                <CMSInput
                  label="Upload from URL"
                  placeholder="e.g. https://google.com.png"
                  onChange={(url) => this.handleUrlChange(url)}
                  value={url}
                />
                <CMSButton
                  label="Upload from URL"
                  onClick={() => this.handleUploadFromUrlClick()}
                  isDisabled={!url}
                />
                {/* Allows submission of form through pressing enter */}
                <button aria-label="Submit" type="submit" hidden />
              </form>
              {newFileElements.length ? (
                <div className={styles.uploadContainer}>
                  <h6>Just Uploaded</h6>
                  <ul>{newFileElements}</ul>
                </div>
              ) : null}
              <div className={styles.uploadContainer}>
                <h6>{uploadsLabel}</h6>
                {!newFileElements.length && !existingFileElements.length ? (
                  <p>Upload a file to get started!</p>
                ) : null}
                {existingFileElements.length ? <ul>{existingFileElements}</ul> : null}
              </div>
            </div>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.modalFooterLeft} />
            <div className={styles.modalFooterRight}>
              <CMSWhiteButton label="Cancel" onClick={() => this.handleClose()} />
              <CMSButton
                label={continueLabel}
                onClick={() => this.handleClose(selectedFile)}
                isDisabled={!selectedFile}
              />
            </div>
          </div>
        </div>
      </ReactModal>
    );
  }
}

export default withRouter(FileUploadModal);
