import { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';

import * as adminService from 'services/content/adminService';
import { HomescreenModuleTypes } from 'utils';

import Snackbar from 'components/snackbar/Snackbar';
import { CMSWhiteButton, CMSRedButton } from 'components/button/Button';
import HomescreenModulesTable from 'components/homescreen-modules-table/HomescreenModulesTable';
import HomescreenModuleForm from 'components/forms/HomescreenModuleForm';
import FormModal from 'components/modal/FormModal';

import styles from 'pages/content/styles.css';

const transformModulesToRequestBody = (homescreenModules) =>
  homescreenModules.map(({ type, data }) => {
    let moduleBody = {};

    switch (type) {
      case HomescreenModuleTypes.SINGLE_CREATOR_CAROUSEL: {
        const { channelId } = data.creator;
        moduleBody = { type, channelId };
        break;
      }
      case HomescreenModuleTypes.SINGLE_CREATOR_COLLECTION_CAROUSEL: {
        const { collectionId } = data.collection;
        moduleBody = { type, collectionId };
        break;
      }
      case HomescreenModuleTypes.MULTI_CREATOR_COLLECTION_CAROUSEL: {
        const { collectionId } = data.collection;
        moduleBody = { type, multiCreatorCollectionId: collectionId };
        break;
      }
      case HomescreenModuleTypes.LARGE_COLLECTION_BUNDLE_CAROUSEL: {
        const { bundleId } = data.bundle;
        moduleBody = { type, bundleId };
        break;
      }
      case HomescreenModuleTypes.LARGE_CREATOR_BUNDLE_CAROUSEL: {
        const { bundleId } = data.bundle;
        moduleBody = { type, bundleId };
        break;
      }
      default:
        break;
    }

    return moduleBody;
  });

const HomescreenPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPublishing, setIsPublishing] = useState(false);
  const [lastPublishedDate, setLastPublishedDate] = useState(null);
  const [homescreenModules, setHomescreenModules] = useState([]);
  const [workingModules, setWorkingModules] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [isHomescreenModuleModalOpen, setIsHomescreenModuleModalOpen] = useState(false);

  useEffect(() => {
    const fetchHomescreenModules = async () => {
      setIsLoading(true);

      const { modules, lastPublishedDate } = await adminService.getHomescreenModules();
      const validModules = modules.filter(({ type }) => !!HomescreenModuleTypes[type]);

      setLastPublishedDate(new Date(lastPublishedDate));
      setHomescreenModules(validModules);
      setWorkingModules(validModules);
      setIsLoading(false);
    };
    fetchHomescreenModules();
  }, []);

  useEffect(() => {
    setWorkingModules(homescreenModules.filter(({ type }) => !!HomescreenModuleTypes[type]));
  }, [homescreenModules]);

  useEffect(() => {
    const stringifiedModules = JSON.stringify(homescreenModules);
    const stringifiedWorkingModules = JSON.stringify(workingModules);

    if (stringifiedWorkingModules !== stringifiedModules) {
      setHasChanged(true);
    } else {
      setHasChanged(false);
    }
  }, [workingModules]);

  const removeModules = (modules) => {
    const moduleIdsToRemove = [].concat(modules || []).map(({ moduleId }) => moduleId);
    const filteredModules = workingModules.filter(
      ({ moduleId }) => !moduleIdsToRemove.includes(moduleId),
    );
    setWorkingModules(filteredModules);
    setSelectedModules([]);
  };

  const publishHomescreen = async (modules) => {
    setIsPublishing(true);
    const transformedModules = transformModulesToRequestBody(modules);

    try {
      const { modules: updatedHomescreenModules, lastPublishedDate } =
        await adminService.publishHomescreen({
          modules: transformedModules,
        });
      setLastPublishedDate(new Date(lastPublishedDate));
      setHomescreenModules(updatedHomescreenModules);
      NotificationManager.success('Successfully published homescreen!');
    } catch (error) {
      // allow error to be handled by `requestService`
    } finally {
      setIsPublishing(false);
    }
  };

  const lastPublishedDateString = lastPublishedDate
    ? `${lastPublishedDate.toLocaleDateString()} ${lastPublishedDate.toLocaleTimeString()}`
    : 'unknown';

  return (
    <div className={styles.pageContainer}>
      <section>
        <div className={styles.panelHeader}>
          <h2 className={styles.title}>App Homescreen</h2>
          <div className={styles.metaContainer}>
            <div className={styles.stats}>
              Last Published: {isLoading ? 'loading…' : lastPublishedDateString}
            </div>
            <CMSWhiteButton
              label={isPublishing ? 'Publishing…' : 'Publish to App'}
              isDisabled={!hasChanged || isPublishing}
              onClick={() => publishHomescreen(workingModules)}
            />
          </div>
        </div>
        <div className={styles.tableHeader}>
          <h3>Sections</h3>
          <CMSWhiteButton
            label="+ Add Section"
            isDisabled={isLoading}
            onClick={() => setIsHomescreenModuleModalOpen(true)}
          />
        </div>
        {isLoading ? (
          <h1 className={styles.loading}>Loading…</h1>
        ) : (
          <HomescreenModulesTable
            modules={workingModules}
            setHomescreenModules={setWorkingModules}
            removeModules={removeModules}
            selectedModules={selectedModules}
            setSelectedModules={setSelectedModules}
          />
        )}
      </section>

      <FormModal
        formNode={HomescreenModuleForm}
        title="Add Section"
        contentLabel="Add Section"
        primaryFooterBtnLabel="Add Section"
        isOpen={isHomescreenModuleModalOpen}
        formProps={{ setWorkingModules }}
        onRequestClose={() => setIsHomescreenModuleModalOpen(false)}
        style={{
          content: {
            height: 'max-content',
            minHeight: 0,
            maxHeight: '450px',
            maxWidth: '584px',
          },
        }}
      />
      {selectedModules.length > 0 && (
        <Snackbar>
          <p>{selectedModules.length} Selected</p>
          <div className={styles.snackbarButtonGroup}>
            <CMSRedButton label="Remove" onClick={() => removeModules(selectedModules)} />
            <CMSWhiteButton label="Cancel" onClick={() => setSelectedModules([])} />
          </div>
        </Snackbar>
      )}
    </div>
  );
};

export default HomescreenPage;
