/* eslint-disable no-alert, no-else-return, react/prop-types */

import { useMemo } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { INVITE_STATUS, COLLABORATOR_ROLE } from 'utils';
import { useUser } from 'context/user-context/UserContext';
import request from 'services/request/requestService';

import Table from 'components/table/Table';
import UserAvatar from 'components/user-avatar/UserAvatar';
import DropdownInput from 'components/input/DropdownInput';

import styles from 'components/table/Table.css';

const revokeAccess = async ({ id, isActiveMember }, creatorId, updateCollaborators) => {
  if (!window.confirm('Are you sure you want to remove this person?')) {
    return;
  }

  const method = 'DELETE';
  if (isActiveMember) {
    await request(`/creators/${creatorId}/users/${id}`, { method });
  } else {
    await request(`/creators/${creatorId}/invitations/${id}`, { method });
  }
  await updateCollaborators();
};

const updateStatus = async ({ id, isActiveMember, value }, creatorId, updateCollaborators) => {
  const method = 'PUT';
  if (isActiveMember) {
    const body = JSON.stringify({ userId: id, role: value });
    await request(`/creators/${creatorId}/users`, { method, body });
  } else {
    const body = JSON.stringify({ role: value });
    await request(`/creators/${creatorId}/invitations/${id}`, { method, body });
  }
  await updateCollaborators();
};

const getPendingStyles = (status) => cx(status === INVITE_STATUS.PENDING && styles.pending);

const MembersTable = ({ teamMembers, activeCreator, updateCollaborators }) => {
  const { user } = useUser();
  const { creatorId } = activeCreator;
  const userRole = activeCreator.role;

  const handleOnChange = async (id, status, value) => {
    const isActiveMember = !!status;
    switch (value) {
      case COLLABORATOR_ROLE.REMOVE:
        await revokeAccess({ id, isActiveMember }, creatorId, updateCollaborators);
        break;
      case COLLABORATOR_ROLE.ADMIN:
      case COLLABORATOR_ROLE.CREATOR:
      case COLLABORATOR_ROLE.OBSERVER:
        await updateStatus({ id, isActiveMember, value }, creatorId, updateCollaborators);
        break;
      default:
        break;
    }
  };

  const data = useMemo(() => teamMembers, [teamMembers]);
  const columns = useMemo(
    () => [
      {
        id: 'member-info',
        Header: () => null,
        Cell: ({ row }) => {
          const { name, email, status } = row.original;
          return (
            <div className={cx(styles.userInfo, getPendingStyles(status))}>
              <UserAvatar size={48} account={row.original} />
              <div>
                <p className={styles.name}>{name}</p>
                <p className={styles.email}>{email}</p>
              </div>
            </div>
          );
        },
      },
      {
        accessor: 'status',
        id: 'status',
        Header: () => null,
        Cell: ({ row }) => (
          <span className={getPendingStyles(status)}>
            {row.original.status === INVITE_STATUS.PENDING ? 'Pending' : 'Active'}
          </span>
        ),
      },
      {
        accessor: 'role',
        id: 'role',
        Header: () => null,
        Cell: ({ row }) => {
          const { id, email, role, status } = row.original;

          const isSelf = user.email === email;
          const isAdmin = userRole === COLLABORATOR_ROLE.ADMIN;

          return (
            <div className={styles.memberRole}>
              <DropdownInput
                options={[
                  { value: COLLABORATOR_ROLE.ADMIN, label: 'Admin' },
                  { value: COLLABORATOR_ROLE.CREATOR, label: 'Creator' },
                  { value: COLLABORATOR_ROLE.OBSERVER, label: 'Observer' },
                  {
                    value: COLLABORATOR_ROLE.REMOVE,
                    label: 'Remove',
                    className: styles.dropdownRemove,
                  },
                ]}
                value={role}
                /**
                 * if the logged-in user is not an admin of the active creator,
                 * or if the current collaborator being rendered in this table
                 * matches the logged-in user, prevent said user from being able
                 * to change anyone elses or their own role, respectively.
                 */
                disabled={!isAdmin || isSelf}
                className={styles.dropdown}
                onChange={({ value }) => handleOnChange(id, status, value)}
              />
            </div>
          );
        },
      },
    ],
    [],
  );

  return <Table data={data} columns={columns} hideHeaders hideFilters disableHoverEffects />;
};

MembersTable.propTypes = {
  teamMembers: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeCreator: PropTypes.object.isRequired,
  updateCollaborators: PropTypes.func.isRequired,
};

export default MembersTable;
