import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { getMyCreators } from 'services/content/contentService';

import NavBar from 'components/navbar/NavBar';
import TabbedNavigation from 'components/tabbed-navigation/TabbedNavigation';
import AccountForm from 'components/forms/AccountForm';
import CreatorTeamsTable from 'components/creator-teams-table/CreatorTeamsTable';
import EmptyState from 'components/empty-state/EmptyState';
import CreatorForm from 'components/forms/CreatorForm';
import FormModal from 'components/modal/FormModal';

import styles from 'pages/account/AccountPage.css';

const AccountPage = () => {
  const [myCreators, setMyCreators] = useState([]);
  const [lastModified, setLastModified] = useState(new Date());
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const loadMyCreators = async () => {
      const creators = await getMyCreators();
      await setMyCreators(creators);
    };
    loadMyCreators();
  }, [lastModified]);

  const DetailsPanel = <AccountForm />;
  const CreatorTeamsPanel = (
    <>
      {myCreators.length ? (
        <CreatorTeamsTable creators={myCreators} setLastModified={setLastModified} />
      ) : (
        <EmptyState
          className={[styles.emptyState]}
          title="Add Your First Creator!"
          subtitle="Start by making a new creator. Every creator can have an endless number of stories, so create away."
          cta="+ New Creator"
          onclick={() => setIsOpen(true)}
        />
      )}
    </>
  );

  const tabs = [
    { value: 'details', label: 'Details', panel: DetailsPanel },
    { value: 'creator-teams', label: 'Creator Teams', panel: CreatorTeamsPanel },
  ];

  return (
    <div>
      <Helmet>
        <title>My Account :: Hellosaurus CMS</title>
      </Helmet>
      <NavBar />
      <main className={styles.main}>
        <div className={styles.pageContents}>
          <h1>My Account</h1>
          <TabbedNavigation tabs={tabs} />
        </div>
      </main>
      <FormModal
        formNode={CreatorForm}
        title="New Creator"
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        contentLabel="New Creator"
      />
    </div>
  );
};

export default AccountPage;
