import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import FileUploadModal from 'components/file-upload/FileUploadModal';
import AssetPreview from 'components/asset-preview/AssetPreview';

import * as styles from 'components/file-upload/FileUploadInput.css';
import {
  ACCEPTED_IMAGE_MIME_TYPES,
  ACCEPTED_AUDIO_MIME_TYPES,
  ACCEPTED_VIDEO_MIME_TYPES,
} from 'components/file-upload/constants';
import * as uploadService from 'services/upload/uploadService';

import { CrossIcon } from 'components/Icon';

class FileUploadInput extends PureComponent {
  static propTypes = {
    label: PropTypes.string.isRequired,
    hideLabel: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    acceptedMimeTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    value: PropTypes.object,
    uploadType: PropTypes.string,
    showFilesFromShowOnly: PropTypes.bool, // Whether to list uploads made for this show only
    episodeId: PropTypes.string,
    creatorId: PropTypes.string,
    dropAreaOnly: PropTypes.bool, // Only render the drop area, not the modal and other goodies
    className: PropTypes.string,
  };

  static defaultProps = {
    episodeId: null,
    creatorId: null,
  };

  static defaultProps = {
    value: null,
    showFilesFromShowOnly: false,
    hideLabel: false,
    dropAreaOnly: false,
    className: '',
  };

  static defaultProps = {
    uploadType: uploadService.UploadTypes.DO_IT_ASSET,
  };

  state = {
    isModalOpen: false,
  };

  handleClick() {
    this.setState({ isModalOpen: true });
  }

  handleClose(selectedFile) {
    this.setState({ isModalOpen: false });

    if (selectedFile) {
      this.props.onChange(selectedFile);
    }
  }

  handleRemove() {
    /* eslint-disable-next-line no-alert */
    const confirmation = window.confirm('Are you sure you want to remove this asset?');

    if (confirmation) {
      this.props.onChange(null);
    }
  }

  render() {
    const {
      label,
      hideLabel,
      acceptedMimeTypes,
      episodeId,
      creatorId,
      uploadType,
      showFilesFromShowOnly,
      dropAreaOnly,
      value,
      className,
    } = this.props;
    const { isModalOpen } = this.state;

    return (
      <div className={styles.container}>
        {hideLabel ? null : <label className={styles.label}>{label}</label>}
        <div
          className={cx(styles.fileUploadInput, className)}
          onClick={this.handleClick.bind(this)}
        >
          {value && (
            <AssetPreview
              src={value.awsPublicUrl}
              mimeType={value.mimeType}
              alt={value.editedName}
            />
          )}
          <div className={styles.fileUploadInputDetails}>
            {value ? <p>{value.editedName}</p> : null}
            {!value ? <p>Upload {label}</p> : null}
          </div>
          {value && (
            <div
              className={styles.remove}
              onClick={(e) => {
                e.stopPropagation();
                this.handleRemove();
              }}
            >
              <CrossIcon fill="6f6f6f" />
            </div>
          )}
        </div>
        <FileUploadModal
          isOpen={isModalOpen}
          onClose={(selectedFile) => this.handleClose(selectedFile)}
          acceptedMimeTypes={acceptedMimeTypes}
          episodeId={episodeId}
          creatorId={creatorId}
          uploadType={uploadType}
          showFilesFromShowOnly={showFilesFromShowOnly}
          dropAreaOnly={dropAreaOnly}
        />
      </div>
    );
  }
}

export const ImageUploadInput = (props) => (
  <FileUploadInput {...props} acceptedMimeTypes={ACCEPTED_IMAGE_MIME_TYPES} />
);
export const AudioUploadInput = (props) => (
  <FileUploadInput {...props} acceptedMimeTypes={ACCEPTED_AUDIO_MIME_TYPES} />
);
export const VideoUploadInput = (props) => (
  <FileUploadInput
    {...props}
    acceptedMimeTypes={ACCEPTED_VIDEO_MIME_TYPES}
    uploadType={uploadService.UploadTypes.EPISODE_VIDEO}
  />
);
export const ThumbnailUploadInput = (props) => (
  <ImageUploadInput
    {...props}
    uploadType={uploadService.UploadTypes.THUMBNAIL}
    showFilesFromShowOnly
  />
);
