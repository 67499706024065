// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.BrowserIncompatiblePage_browser-incompatible-page___27tXeqUWzf {\n  text-align: center;\n  max-width: 650px;\n  margin: auto;\n}\n\n.BrowserIncompatiblePage_browser-incompatible-page___27tXeqUWzf img {\n    height: 200px;\n    margin: 4em auto 0;\n  }\n\n.BrowserIncompatiblePage_browser-incompatible-page___27tXeqUWzf h1 {\n    font-size: 40px;\n    line-height: 50px;\n    margin: 0.5em 0 0.25em;\n    color: #7715c1;\n  }\n\n.BrowserIncompatiblePage_browser-incompatible-page___27tXeqUWzf p {\n    font-size: 22px;\n    line-height: 150%;\n    margin: 0 0 0.5em;\n    color: #b6b4b0;\n  }\n\n.BrowserIncompatiblePage_browser-incompatible-page___27tXeqUWzf .BrowserIncompatiblePage_buttonHolder___1STyyQ3cgx {\n    display: flex;\n  }\n\n.BrowserIncompatiblePage_browser-incompatible-page___27tXeqUWzf a {\n    max-width: 300px;\n    margin: auto;\n    text-align: center;\n  }\n\n.BrowserIncompatiblePage_browser-incompatible-page___27tXeqUWzf a span {\n      width: 100%;\n    }\n", ""]);
// Exports
exports.locals = {
	"browser-incompatible-page": "BrowserIncompatiblePage_browser-incompatible-page___27tXeqUWzf",
	"browserIncompatiblePage": "BrowserIncompatiblePage_browser-incompatible-page___27tXeqUWzf",
	"buttonHolder": "BrowserIncompatiblePage_buttonHolder___1STyyQ3cgx"
};
module.exports = exports;
