// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n/* ToggleSwitch.jsx variables */\n\n.EmptyState_empty-state___gnrCcsHink {\n  display: flex;\n  align-items: center;\n  height: calc(100vh - 80px);\n  flex-direction: column;\n  justify-content: center;\n}\n\n.EmptyState_empty-state___gnrCcsHink h1 {\n    font-size: 24px;\n    color: #000000;\n    max-width: 525px;\n  }\n\n.EmptyState_empty-state___gnrCcsHink p {\n    color: #6f6f6f;\n    font-weight: 400;\n    font-size: 16px;\n    line-height: 140%;\n    max-width: 425px;\n    text-align: center;\n    margin-bottom: 32px !important;\n  }\n", ""]);
// Exports
exports.locals = {
	"empty-state": "EmptyState_empty-state___gnrCcsHink",
	"emptyState": "EmptyState_empty-state___gnrCcsHink"
};
module.exports = exports;
