import { LAYOUT_OPTIONS, TRANSITION_OPTIONS } from 'components/do-it-forms/forms/constants';

const DOIT_COPY_POSITION_OFFSET = 10;

export function getDefaultAudioConfig() {
  return {
    audioFile: null,
  };
}

export function getDefaultImage(size = true, position = true) {
  const image = {
    spriteFile: null,
  };

  if (size) {
    image.size = {
      x: 200,
      y: 200,
    };
  }

  if (position) {
    image.position = {
      x: 0,
      y: 0,
    };
  }

  return image;
}

export function getDefaultBackground() {
  return getDefaultImage(false, false);
}

export function getDefaultDropTarget(lastDropTarget) {
  return {
    spriteFile: null,
    position: {
      x: lastDropTarget?.position?.x ? lastDropTarget.position?.x + DOIT_COPY_POSITION_OFFSET : 0,
      y: lastDropTarget?.position?.y ? lastDropTarget.position?.y + DOIT_COPY_POSITION_OFFSET : 0,
    },
    size: {
      x: lastDropTarget?.size?.x ?? 250,
      y: lastDropTarget?.size?.y ?? 250,
    },
    acceptSound: getDefaultAudioConfig(),
    dropSpriteFile: null, // Displayed for duration of audioFile
    fullSpriteFile: null, // Displayed when all draggableObjects are placed in the dropTarget
    maxNumDrops: 0,
    acceptedTypes: lastDropTarget?.acceptedTypes ?? ['DEFAULT'],
  };
}

export function getDefaultDraggableObject(lastDraggableObject) {
  return {
    size: {
      x: lastDraggableObject?.size?.x ?? 100,
      y: lastDraggableObject?.size?.y ?? 100,
    },
    finalSize: {
      x: lastDraggableObject?.finalSize?.x ?? 100,
      y: lastDraggableObject?.finalSize?.y ?? 100,
    },
    position: {
      x: lastDraggableObject?.position
        ? lastDraggableObject.position?.x + DOIT_COPY_POSITION_OFFSET
        : 0,
      y: lastDraggableObject?.position
        ? lastDraggableObject.position?.y + DOIT_COPY_POSITION_OFFSET
        : 0,
    },
    spriteFile: lastDraggableObject?.spriteFile ?? null,
    type: lastDraggableObject?.type ?? 'DEFAULT',
  };
}

export function getDefaultCanvasObject(lastCanvasObject) {
  return {
    size: {
      x: lastCanvasObject?.size.x ?? 250,
      y: lastCanvasObject?.size.y ?? 250,
    },
    position: {
      x: lastCanvasObject ? lastCanvasObject.position.x + DOIT_COPY_POSITION_OFFSET : 0,
      y: lastCanvasObject ? lastCanvasObject.position.y + DOIT_COPY_POSITION_OFFSET : 0,
    },
    spriteFile: lastCanvasObject?.spriteFile ?? null,
  };
}

export function getDefaultTransitionForLayout(layoutType) {
  return layoutType === LAYOUT_OPTIONS.FULL_SCREEN.value
    ? TRANSITION_OPTIONS.NONE.value
    : TRANSITION_OPTIONS.SLIDE_RIGHT.value;
}

export function getDefaultCameraDisplay() {
  return {
    size: {
      x: 250,
      y: 250,
    },
    position: {
      x: 0,
      y: 0,
    },
    spriteFile: null,
  };
}

function getDefaultResponse(isFreeForm) {
  return {
    ...(isFreeForm
      ? { size: { x: 200, y: 200 }, position: { x: 0, y: 0 } }
      : { selectedColor: '#FFFFFF', defaultColor: '#FFFFFF' }),
    buttonText: {
      text: '',
      color: '#000000',
      size: { x: 400, y: 150 },
      position: { x: 0, y: 0 },
    },
    buttonSound: getDefaultAudioConfig(),
    itemDefaultNode: getDefaultImage(true, true),
    selectedImage: null,
  };
}

export function getDefaultChoice(isFreeForm) {
  return getDefaultResponse(isFreeForm);
}

export function getDefaultAnswer(isFreeForm) {
  return {
    ...getDefaultResponse(isFreeForm),
    correctAnswer: false,
  };
}

export function getDefaultCollageItStamp(lastStamp) {
  return {
    ...getDefaultCanvasObject(lastStamp),
    selectStampSound: getDefaultAudioConfig(),
    placeStampSound: getDefaultAudioConfig(),
    type: null,
    returnToOriginalPositionOnDropFailure: false,
    pressedSpriteConfig: getDefaultBackground(),
  };
}

export function getDefaultCollageStack(lastStack) {
  return {
    ...getDefaultCanvasObject(lastStack),
    stamps: [getDefaultCollageItStamp()],
    maxStamps: 0,
    singleStampStack: false,
  };
}
