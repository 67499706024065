// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.CreatorBanner_creator-banner___2iOn_muf_J,\n.CreatorBanner_creator-banner___2iOn_muf_J div {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.CreatorBanner_creator-banner___2iOn_muf_J {\n  position: sticky;\n  top: -18px;\n  margin: -18px -32px 0;\n  padding: 14px 32px;\n  background: #f2e7fc;\n  grid-gap: 16px;\n  gap: 16px;\n  z-index: 13000;\n}\n\n.CreatorBanner_creator-banner___2iOn_muf_J .CreatorBanner_label___32Q9EWE7Fx {\n  color: rgba(0, 0, 0, 0.6);\n  font-size: 12px;\n  line-height: 14px;\n  letter-spacing: 0.1em;\n  text-transform: uppercase;\n}\n\n.CreatorBanner_creator-banner___2iOn_muf_J div {\n  grid-gap: 10px;\n  gap: 10px;\n}\n\n.CreatorBanner_creator-banner___2iOn_muf_J p {\n  color: #000000;\n  margin: 0;\n  font-size: 14px;\n  line-height: 16px;\n}\n\n.CreatorBanner_creator-banner___2iOn_muf_J img {\n  max-width: 24px;\n  max-height: 24px;\n  border-radius: 4px;\n}\n", ""]);
// Exports
exports.locals = {
	"creator-banner": "CreatorBanner_creator-banner___2iOn_muf_J",
	"creatorBanner": "CreatorBanner_creator-banner___2iOn_muf_J",
	"label": "CreatorBanner_label___32Q9EWE7Fx"
};
module.exports = exports;
