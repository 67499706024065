import { HomescreenModuleTypes, HumanReadableModuleTypes } from 'utils';

// eslint-disable-next-line import/prefer-default-export
export const HOMESCREEN_SECTION_TYPES = {
  SINGLE_CREATOR_CAROUSEL: {
    value: HomescreenModuleTypes.SINGLE_CREATOR_CAROUSEL,
    label: HumanReadableModuleTypes.SINGLE_CREATOR_CAROUSEL,
    description: 'All stories from a given creator.',
  },
  SINGLE_CREATOR_COLLECTION_CAROUSEL: {
    value: HomescreenModuleTypes.SINGLE_CREATOR_COLLECTION_CAROUSEL,
    label: HumanReadableModuleTypes.SINGLE_CREATOR_COLLECTION_CAROUSEL,
    description: 'Collection that includes stories from only one creator.',
  },
  MULTI_CREATOR_COLLECTION_CAROUSEL: {
    value: HomescreenModuleTypes.MULTI_CREATOR_COLLECTION_CAROUSEL,
    label: HumanReadableModuleTypes.MULTI_CREATOR_COLLECTION_CAROUSEL,
    description: 'Collection that includes stories from multiple creators.',
  },
  LARGE_COLLECTION_BUNDLE_CAROUSEL: {
    value: HomescreenModuleTypes.LARGE_COLLECTION_BUNDLE_CAROUSEL,
    label: HumanReadableModuleTypes.LARGE_COLLECTION_BUNDLE_CAROUSEL,
    description: 'Bundle of collections',
  },
  LARGE_CREATOR_BUNDLE_CAROUSEL: {
    value: HomescreenModuleTypes.LARGE_CREATOR_BUNDLE_CAROUSEL,
    label: HumanReadableModuleTypes.LARGE_CREATOR_BUNDLE_CAROUSEL,
    description: 'Bundle of creators',
  },
};
