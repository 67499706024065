import * as utils from 'utils';
import { CMSTextButton } from 'components/button/Button';
import Accordion from 'components/accordion/Accordion';
import AccordionSection from 'components/accordion/AccordionSection';

import DoItForm from 'components/do-it-forms/forms/DoIt';
import BaseForm from 'components/do-it-forms/forms/Base';
import { ImageUploadInput } from 'components/file-upload/FileUploadInput';
import DropdownInput from 'components/input/DropdownInput';
import SizeInput from 'components/input/SizeInput';
import CounterInput from 'components/input/CounterInput';
import PositionInput from 'components/input/PositionInput';
import AudioConfig from 'components/do-it-forms/components/AudioConfig';
import { getDefaultAudioConfig, getDefaultBackground } from 'components/do-it-forms/utils';
import { FREEFORM_VARIANT_ID, LAYOUT_TYPES } from 'components/do-it-forms/forms/constants';
import BackgroundAccordion, {
  InlineBackgroundAccordion,
} from 'components/do-it-forms/accordions/BackgroundAccordion';

class SoundboardItForm extends BaseForm {
  getDefaultParams() {
    const isFreeForm = this.props.doit.variantId === FREEFORM_VARIANT_ID;
    return {
      background: isFreeForm ? getDefaultBackground() : null,
      panels: [SoundboardItForm.getDefaultPanel(null, isFreeForm)],
      layoutSettings: {
        layoutType: isFreeForm ? LAYOUT_TYPES.freeForm : LAYOUT_TYPES.autoGrid,
      },
    };
  }

  static getDefaultPanel(lastPanel, isFreeForm) {
    return {
      background: {
        color: isFreeForm ? '#FFFFFF01' : '#FFFFFF',
      },
      button: {
        spriteFile: null,
        pressedSpriteFile: null,
        buttonSound: getDefaultAudioConfig(),
        numMaxPlays: lastPanel?.button.numMaxPlays ?? 0,
        size: {
          x: lastPanel?.button.size.x ?? 250,
          y: lastPanel?.button.size.x ?? 250,
        },
        position: {
          x: lastPanel?.button.position.x ?? 0,
          y: lastPanel?.button.position.y ?? 0,
        },
      },
    };
  }

  renderPanelAccordions({ background, button }, i, isFreeForm) {
    const { spriteFile, pressedSpriteFile, buttonSound, numMaxPlays, size, position } = button;
    const accordionTitle = `Button #${i + 1}`;
    const onChange = (baseProp, subProp, value) => {
      this.setState(({ panels }) => {
        const panelsCopy = utils.deepClone(panels);
        panelsCopy[i][baseProp][subProp] = value;

        return {
          panels: panelsCopy,
        };
      });
    };
    const onButtonChange = (...args) => onChange('button', ...args);
    const onBackgroundChange = (value) => {
      this.setState(({ panels }) => {
        const panelsCopy = utils.deepClone(panels);
        panelsCopy[i].background = value;

        return {
          panels: panelsCopy,
        };
      });
    };
    const onDelete = this.state.panels.length > 1 ? () => this.handleRemovePanel(i) : null;

    return (
      <Accordion title={accordionTitle} key={accordionTitle} onDelete={onDelete}>
        {!isFreeForm && (
          <AccordionSection
            title="Button Background"
            description="The background displayed behind the button assets"
          >
            <InlineBackgroundAccordion
              value={background}
              forKey={accordionTitle}
              onChange={(_, value) => onBackgroundChange(value)}
            />
          </AccordionSection>
        )}

        <AccordionSection
          title="Button Settings"
          description="Size and position of the soundboard button"
        >
          <SizeInput sizeX={size.x} sizeY={size.y} onChange={onButtonChange} />
          <PositionInput positionX={position.x} positionY={position.y} onChange={onButtonChange} />
        </AccordionSection>
        <AccordionSection
          title="Button States"
          description="State of the soundboard button before and after tapping"
        >
          <ImageUploadInput
            label="Resting Image"
            onChange={(file) => {
              onButtonChange('spriteFile', file);

              if (!pressedSpriteFile) {
                onButtonChange('pressedSpriteFile', file);
              }
            }}
            value={spriteFile}
          />
          <ImageUploadInput
            label="Pressed Image"
            onChange={(file) => onButtonChange('pressedSpriteFile', file)}
            value={pressedSpriteFile}
          />
          <AudioConfig
            label="Pressed Sound Effect"
            value={buttonSound}
            onChange={(value) => onButtonChange('buttonSound', value)}
          />
        </AccordionSection>
        <CounterInput
          label="Number of presses allowed"
          value={numMaxPlays}
          onChange={(value) => onButtonChange('numMaxPlays', value)}
        />
      </Accordion>
    );
  }

  handleAddPanel(isFreeForm) {
    this.setState(({ panels }) => {
      const lastPanel = panels[panels.length - 1];
      return { panels: [...panels, SoundboardItForm.getDefaultPanel(lastPanel, isFreeForm)] };
    });
  }

  handleRemovePanel(index) {
    this.setState(({ panels }) => ({
      panels: panels.filter((_, i) => i !== index),
    }));
  }

  getDoitParams() {
    return this.state;
  }

  render() {
    const { onClose, onDelete, doit, subsequentDoitId } = this.props;
    const { panels, layoutSettings, background } = this.state;
    const isFreeForm = doit.variantId === FREEFORM_VARIANT_ID;
    const panelAccordionElements = panels.map((panel, i) =>
      this.renderPanelAccordions(panel, i, isFreeForm),
    );

    return (
      <DoItForm
        title={`Soundboard It (${isFreeForm ? 'Freeform' : 'Auto Layout'})`}
        doit={doit}
        onClose={onClose}
        onSave={(options) => this.handleSave(options)}
        onDelete={onDelete}
        subsequentDoitId={subsequentDoitId}
      >
        {!isFreeForm && (
          <Accordion title="Soundboard Layout">
            <DropdownInput
              label="Layout Type"
              onChange={({ value }) => {
                this.setState(({ layoutSettings }) => ({
                  layoutSettings: { ...layoutSettings, layoutType: value },
                }));
              }}
              value={layoutSettings?.layoutType || LAYOUT_TYPES.autoGrid}
              options={[LAYOUT_TYPES.autoGrid, LAYOUT_TYPES.vertical, LAYOUT_TYPES.horizontal]}
            />
          </Accordion>
        )}

        {isFreeForm && (
          <BackgroundAccordion
            value={background}
            onChange={(key, value) => this.setState({ [key]: value })}
          />
        )}

        {panelAccordionElements}
        <CMSTextButton label="Add Another Button" onClick={() => this.handleAddPanel(isFreeForm)} />
      </DoItForm>
    );
  }
}

export default SoundboardItForm;
