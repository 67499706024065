// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* TODO: determine if any of these are still needed. */\n\n.GrantAccessPage_grant-access-page___1_sut1HMzV {\n  max-width: 720px;\n  margin: 2em auto;\n}\n\n.GrantAccessPage_grant-access-page___1_sut1HMzV h1 {\n    font-size: 2em;\n    text-align: left;\n    margin: 0 1em 0.25em 0;\n  }\n\n.GrantAccessPage_grant-access-page___1_sut1HMzV p {\n    color: rgba(0, 0, 0, 0.6);\n    line-height: 1.5;\n    margin-bottom: 2em;\n  }\n\n.GrantAccessPage_grant-access-page___1_sut1HMzV .GrantAccessPage_form-wrapper___RU2XuGqfJ1 > div {\n    margin-bottom: 0.75em;\n  }\n\n.GrantAccessPage_resendSection___2Vk1Btbffe {\n  border-top: 3px solid grey;\n  margin-top: 3em;\n}\n\n.GrantAccessPage_resendInvite___25eO5PNZY1 {\n  max-width: 620px;\n  width: 100%;\n  display: inline-flex;\n  justify-content: space-between;\n  align-items: baseline;\n}", ""]);
// Exports
exports.locals = {
	"grant-access-page": "GrantAccessPage_grant-access-page___1_sut1HMzV",
	"grantAccessPage": "GrantAccessPage_grant-access-page___1_sut1HMzV",
	"form-wrapper": "GrantAccessPage_form-wrapper___RU2XuGqfJ1",
	"formWrapper": "GrantAccessPage_form-wrapper___RU2XuGqfJ1",
	"resendSection": "GrantAccessPage_resendSection___2Vk1Btbffe",
	"resendInvite": "GrantAccessPage_resendInvite___25eO5PNZY1"
};
module.exports = exports;
