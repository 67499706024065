import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { INPUT_TYPES } from 'components/input/constants';
import { forgotPassword } from 'services/authentication/authenticationService';
import PageTemplate from 'pages/auth/PageTemplate';

const ForgotPasswordPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const [forgotPasswordData, setForgotPasswordData] = useState({
    email: {
      value: query.get('email') || '',
      isValid: undefined,
      type: INPUT_TYPES.EMAIL,
      label: 'Email Address',
    },
  });

  return (
    <PageTemplate
      title="Forgot Password?"
      subtitle="Enter the email address associated with your Hellosaurus account."
      formData={forgotPasswordData}
      setFormData={setForgotPasswordData}
      submitFunction={forgotPassword}
      ctaLabel="Submit"
      footerCtaLink={`/login?email=${forgotPasswordData.email.value}`}
      footerCtaText="Back to Log In"
    />
  );
};

export default ForgotPasswordPage;
