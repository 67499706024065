import { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { NotificationManager } from 'react-notifications';
import cx from 'classnames';

import { create, update } from 'components/forms/utils';

import FormSection from 'components/forms/FormSection';
import { CMSWhiteInput } from 'components/input/Input';
import { PurpleButton } from 'components/button/Button';
import SelectInput from 'components/input/SelectInput';
import { ModuleTypeOption } from 'components/input/components/SelectComponents';

import styles from 'components/forms/styles.css';

const DEFAULT_BUNDLE_COLOR = 'LIGHTBEIGE';

const selectStyles = {
  default: {
    borderColor: '#533870',
    backgroundColor: 'initial',
  },
  disabled: {
    borderColor: '#e8e5e1',
    backgroundColor: '#faf7f1',
  },
};

const BundleTypes = {
  COLLECTION_BUNDLE: {
    value: 'COLLECTION_BUNDLE',
    label: 'Collection Bundle',
    description: 'Bundle of collections',
  },
  CREATOR_BUNDLE: {
    value: 'CREATOR_BUNDLE',
    label: 'Creator Bundle',
    description: 'Bundle of creators',
  },
};

/**
 * When form is wrapped in `forwardRef` and uses the `useImperativeHandle` hook,
 * the parent component then has access to whatever data is selectively exposed.
 */
const BundleForm = forwardRef(({ bundle, isNew, hideButtons, onSubmit }, ref) => {
  const [bundleDetails, updateBundleDetails] = useState(bundle);
  useEffect(() => updateBundleDetails(bundle), [bundle]);

  const { type, title, description } = bundleDetails;

  async function submit() {
    const createAttributes = ['type', 'title', 'description', 'backgroundImageId'];
    const updateAttributes = ['title', 'description', 'backgroundImageId'];

    if (isNew) {
      const response = await create({
        path: '/bundles',
        attributes: createAttributes,
        obj: bundleDetails,
      });
      window.location.href = `/admin/bundles/${response?.bundle?.bundleId}`;
    } else {
      const { bundleId } = bundle;
      const updatedBundle = await update({
        path: `/bundles/${bundleId}`,
        attributes: updateAttributes,
        obj: bundleDetails,
      });
      NotificationManager.success('Successfully saved!');
      onSubmit(updatedBundle);
    }
  }

  useImperativeHandle(ref, () => ({ submit }));

  return (
    <div className={cx(styles.form, styles.collectionForm, !hideButtons && styles.padded)}>
      <FormSection title="Basic Info" mainClassName={styles.creatorInfo}>
        <CMSWhiteInput
          value={title}
          label="Bundle Name"
          isRequired
          onChange={(title) => updateBundleDetails({ ...bundleDetails, title })}
        />

        <CMSWhiteInput
          textarea
          value={description}
          label="Bundle Description"
          placeholder="Describe your bundle here…"
          onChange={(description) => updateBundleDetails({ ...bundleDetails, description })}
        />

        <SelectInput
          defaultValue={BundleTypes[type]}
          isDisabled={!isNew}
          label="Bundle Type"
          placeholder="Select bundle type…"
          isSearchable={false}
          options={Object.values(BundleTypes)}
          onChange={({ value }) => updateBundleDetails({ ...bundleDetails, type: value })}
          customComponents={{ Option: ModuleTypeOption }}
          styleOverrides={{
            control: (base, { isDisabled }) => ({
              ...base,
              maxWidth: '300px',
              ...selectStyles[isDisabled ? 'disabled' : 'default'],
            }),
            indicatorsContainer: (base) => ({
              ...base,
              margin: '0 !important',
            }),
          }}
        />
      </FormSection>

      {!hideButtons && (
        <PurpleButton label={isNew ? 'Create Bundle' : 'Save Changes'} onClick={submit} />
      )}
    </div>
  );
});

BundleForm.displayName = 'BundleForm';

BundleForm.defaultProps = {
  bundle: {
    type: BundleTypes.COLLECTION_BUNDLE.value,
    title: '',
    description: '',
    /**
     * Note: background images and color are not yet supported on the client,
     * therefore we've opted to not expose these settings in the creation or update
     * of bundles at this time.
     */
    backgroundImageUrl: '',
    backgroundColor: DEFAULT_BUNDLE_COLOR,
  },
  isNew: false,
  hideButtons: false,
  onSubmit: () => {},
};

BundleForm.propTypes = {
  bundle: PropTypes.object,
  isNew: PropTypes.bool,
  hideButtons: PropTypes.bool,
  onSubmit: PropTypes.func,
};

export default BundleForm;
