import PropTypes from 'prop-types';

import * as styles from 'components/snackbar/Snackbar.css';

const Snackbar = ({ children }) => <div className={styles.snackbar}>{children}</div>;

Snackbar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Snackbar;
