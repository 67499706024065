import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { INPUT_TYPES } from 'components/input/constants';
import { login } from 'services/authentication/authenticationService';
import PageTemplate from 'pages/auth/PageTemplate';

const LoginPage = () => {
  const query = new URLSearchParams(useLocation().search);
  const invitation = useLocation().state?.invitation;
  const [loginFormData, setLoginFormData] = useState({
    email: {
      value: invitation?.inviteeEmail || query.get('email') || '',
      isValid: invitation?.inviteeEmail ? true : undefined,
      type: INPUT_TYPES.EMAIL,
      label: 'Email Address',
      readOnly: invitation?.inviteeEmail || false,
    },
    password: {
      value: '',
      isValid: undefined,
      type: INPUT_TYPES.PASSWORD,
      label: 'Password',
    },
  });

  const handleSubmit = async (formData) => {
    await login(formData, { invitation });
  };

  return (
    <PageTemplate
      title="Creator Log In"
      formData={loginFormData}
      setFormData={setLoginFormData}
      submitFunction={handleSubmit}
      ctaLabel="Log In"
      footerText="Don't have an account?"
      footerCtaLink="/signup"
      footerCtaText="Sign Up"
      cardCtaLink={`/forgot-password?email=${loginFormData.email.value}`}
      cardCtaText="Forgot password?"
      inviteCardCreator={invitation?.inviteChannel?.title}
      inviteCardImg={invitation?.inviteChannel?.avatarUrl}
    />
  );
};

export default LoginPage;
