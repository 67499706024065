import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import * as contentService from 'services/content/contentService';

import { CollectionsStat, EpisodesStat } from 'components/collection-stat/CollectionStat';
import { CMSWhiteButton } from 'components/button/Button';
import EmptyState from 'components/empty-state/EmptyState';
import FormModal from 'components/modal/FormModal';
import SeriesTable from 'components/table/SeriesTable';
import SeriesForm from 'components/forms/SeriesForm';
import Loading from 'components/loading/Loading';

import styles from 'pages/content/styles.css';

const SeriesPage = ({ title, creatorId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [series, setSeries] = useState([]);
  const [isSeriesModalOpen, setIsSeriesModalOpen] = useState(false);

  const seriesCount = series.length;
  const seriesEpisodeCount = series.reduce(
    (sum, { series }) => sum + (series?.attributes?.numEpisodes || 0),
    0,
  );

  useEffect(() => {
    const fetchSeries = async () => {
      const series = await contentService.getAllSeriesForCreator({ creatorId });
      setSeries(series.map((s) => ({ series: s })));
      setIsLoading(false);
    };
    fetchSeries();
  }, []);

  return (
    <div className={styles.pageContainer}>
      <section>
        <div className={styles.panelHeader}>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.metaContainer}>
            <div className={styles.stats}>
              <CollectionsStat num={seriesCount} label={{ singular: 'Series', plural: 'Series' }} />
              {seriesCount > 0 && <EpisodesStat num={seriesEpisodeCount} />}
            </div>
            <CMSWhiteButton label="+ New Series" onClick={() => setIsSeriesModalOpen(true)} />
          </div>
        </div>
        <Loading isLoading={isLoading}>
          {seriesCount > 0 ? (
            <SeriesTable series={series} />
          ) : (
            <EmptyState
              title="Create your first series"
              subtitle="Get started by creating your first series."
              cta="+ New Series"
              onclick={() => setIsSeriesModalOpen(true)}
              className={[styles.emptyState]}
            />
          )}
        </Loading>
      </section>
      <FormModal
        creatorId={creatorId}
        formNode={SeriesForm}
        title="Create Series"
        contentLabel="Create Series"
        primaryFooterBtnLabel="Save"
        formProps={{ creatorId }}
        isOpen={isSeriesModalOpen}
        onRequestClose={() => setIsSeriesModalOpen(false)}
      />
    </div>
  );
};

SeriesPage.defaultProps = {
  title: 'Series',
  creatorId: null,
};

SeriesPage.propTypes = {
  title: PropTypes.string,
  creatorId: PropTypes.string,
};

export default SeriesPage;
